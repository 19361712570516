import React, { Component } from "react";
import PropTypes from "prop-types";
import { default as imagesUploadModalPubSub } from "lib/PubSub/ImagesUploadModal/ImagesUploadModal";
import FolderPicker from "views/components/FolderPicker/FolderPicker";
import style from "./style.module.css";
import { noop, Logger, chainPromises, isEmpty } from "lib";
import { formatFileSize } from "lib/files/fileUtils";
import getFileExtension from "lib/getFileExtension";
import { Modal, Loading } from "views/components";
import ImageJaggedMountainIcon from "views/components/icons/ImageJaggedMountainIcon";
import TickCircledIcon from "views/components/icons/TickCircledIcon";
import CrossCircledIcon from "views/components/icons/CrossCircledIcon";
import { Caret2Icon } from "views/components/icons";

const acceptedFileTypes = ["png", "jpg", "jpeg", "svg", "gif"];

const initialState = (pages, folderId) => ({
  name: null,
  currentPage: pages.FOLDER_SELECTION,
  selectedFolderId: folderId || null,
  uploadingImages: {}
});

class BrandAssetsUploadModal extends Component {
  static PAGES = {
    FOLDER_SELECTION: "FOLDER_SELECTION",
    UPLOAD: "UPLOAD"
  };

  constructor(props) {
    super(props);

    this.getFolderSelectionContent = this.getFolderSelectionContent.bind(this);
    this.getUploadContent = this.getUploadContent.bind(this);
    this.handleChangeSelectedFolder = this.handleChangeSelectedFolder.bind(
      this
    );
    this.handleChangeCurrentPage = this.handleChangeCurrentPage.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.getPageContents = this.getPageContents.bind(this);
    this.setFolderContext = this.setFolderContext.bind(this);
    this.processImage = this.processImage.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.getSubTextLabel = this.getSubTextLabel.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleFlagFileAsFinishedUploading = this.handleFlagFileAsFinishedUploading.bind(
      this
    );

    const fileTypes = this.props.acceptedFileTypes || acceptedFileTypes;

    this.state = {
      ...initialState(BrandAssetsUploadModal.PAGES),
      acceptedFileTypes: fileTypes,
      acceptedFileExtensionList: `.${fileTypes.join(", .")}`,
      errors: null
    };
  }

  componentDidMount() {
    this.setFolderContext();
  }

  componentDidUpdate(prevProps, prevState) {
    const { errors: propErrors, uploadErrors, isOpen } = this.props;
    const { uploadingImages } = this.state;
    imagesUploadModalPubSub.onStateChange(prevState, this.state);

    if (isOpen !== prevProps.isOpen) {
      // when opening or closing the modal
      const isAtLeastOneUploading = Object.values(uploadingImages).some(
        upload => !upload.isFinished
      );
      // in the case that no uploads are still happening
      if (!isAtLeastOneUploading) {
        // return to the original state
        this.setState(
          initialState(BrandAssetsUploadModal.PAGES, this.props.folderId)
        );
        this.setFolderContext();
      }
    }

    if (isOpen === false && prevProps.isOpen === true) {
      this.setState({ errors: null });
    }

    if (propErrors !== prevProps.errors) {
      this.setState({
        errors: propErrors
      });
    }

    if (uploadErrors !== prevProps.uploadErrors && isOpen === true) {
      const errorKeys = Object.keys(uploadErrors);
      const uploadingAnimationKeys = Object.keys(uploadingImages);

      let uploadingMedia = {};
      let errors = {};

      uploadingAnimationKeys.forEach(animation => {
        const animationKey = animation.replaceAll(" ", "-").toLowerCase();
        if (errorKeys.includes(animationKey)) {
          uploadingMedia[animation] = Object.assign(
            {},
            uploadingImages[animation],
            {
              isFinished: true,
              isError: true
            }
          );
          errors[animation] = {
            value:
              uploadErrors[animationKey][0].value +
              this.props.isSmartImageTransfer
                ? " Your Smart ID has not been transferred"
                : ""
          };
        }
      });

      this.setState({
        uploadingImages: {
          ...this.state.uploadingImages,
          ...uploadingMedia
        },
        errors: {
          ...this.state.errors,
          ...errors
        }
      });
    }

    // when upload modal is rendered over the transfer smart image modal
    // we should remove one modals background color
    const uploadModalDom = document.querySelector(
      `.${style.uploadModalDetails}`
    );
    if (
      this.props.isOpen &&
      this.props.isSmartImageTransfer &&
      uploadModalDom
    ) {
      uploadModalDom.parentNode.style.backgroundColor = "inherit";
    }
  }

  handleOnClose() {
    this.props.onClose({
      onSuccess: () => {
        if (this.props.isSmartImageTransfer)
          this.props.handleCloseTransferModal();
      }
    });
  }

  setFolderContext() {
    if (this.props.folderId) {
      this.handleChangeSelectedFolder(this.props.folderId);
    }
  }

  handleChangeCurrentPage(page) {
    this.setState({
      currentPage: page
    });
  }

  getPageContents(type) {
    const {
      onClose,
      isSmartImageTransfer = false,
      handleCloseTransferModal,
      handleSmartImageUploadClose = noop
    } = this.props;
    const getModalHeader = () => {
      if (isSmartImageTransfer)
        return (
          <div className={style.transferUploadHeader}>
            <div
              onClick={() =>
                onClose({ onSuccess: () => handleSmartImageUploadClose() })
              }
            >
              <Caret2Icon
                size="20px"
                color="#afb3b6"
                style={{
                  transform: "rotate(90deg)",
                  marginRight: "8px"
                }}
              />
            </div>
            Upload to...
          </div>
        );
      return "Upload to...";
    };

    const folderSelectionContent = {
      header: getModalHeader(),
      content: this.getFolderSelectionContent(),
      buttons: [
        {
          name: "Cancel",
          theme: "gray",
          onClick: () => this.handleOnClose()
        },
        {
          name: `Upload${isSmartImageTransfer ? " & Transfer" : ""}`,
          theme: "blueSolid",
          disabled: false,
          onClick: () => this.triggerImageSelector()
        }
      ],
      buttonsClassName: isSmartImageTransfer
        ? style.transferButtons
        : style.selectButtons,
      modalStyle: isSmartImageTransfer
        ? style.transferModalDetails
        : style.uploadModalTo
    };

    const uploadContent = {
      header: "Upload details",
      content: this.getUploadContent(),
      buttons: [
        {
          name: "Hide",
          theme: "blueSolid",
          disabled: false,
          onClick: () => {
            onClose({
              onSuccess: () => {
                this.clearErrors();
                if (isSmartImageTransfer) handleCloseTransferModal();
              }
            });
          }
        }
      ],
      buttonsClassName: style.uploadButtons,
      modalStyle: isSmartImageTransfer
        ? style.transferDetailsModal
        : style.uploadModalDetails
    };

    switch (type) {
      case BrandAssetsUploadModal.PAGES.FOLDER_SELECTION: {
        return folderSelectionContent;
      }
      case BrandAssetsUploadModal.PAGES.UPLOAD: {
        return uploadContent;
      }
      default: {
        return folderSelectionContent;
      }
    }
  }

  handleChangeSelectedFolder(folderId) {
    const { selectedFolderId } = this.state;
    if (selectedFolderId !== folderId) {
      this.setState({
        selectedFolderId: folderId
      });
    }
  }

  stopBrowserDefaultBehaviour(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  handleFlagFileAsFinishedUploading(fileName, resolve) {
    const { uploadingImages } = this.state;

    if (
      uploadingImages[fileName] &&
      uploadingImages[fileName].isFinished === false
    ) {
      this.setState({
        uploadingImages: Object.assign({}, uploadingImages, {
          [fileName]: Object.assign({}, uploadingImages[fileName], {
            isFinished: true
          })
        })
      });
      resolve();
    }
  }

  async handleFileSelect({ event }) {
    this.stopBrowserDefaultBehaviour(event);
    this.handleChangeCurrentPage(BrandAssetsUploadModal.PAGES.UPLOAD);

    if (this.props.disabled) return;

    const files = event.target.files;

    const uploadingImages = Array.from(files).reduce(
      (previous, current, index) => {
        return Object.assign({}, previous, {
          [current.name]: {
            size: formatFileSize(files[index].size, 1),
            isFinished: false
          }
        });
      },
      {}
    );

    this.setState({
      file: files[0],
      uploadingImages,
      imageError: null
    });

    const createImageProcessPromise = file => {
      return () =>
        new Promise(resolve => {
          this.processImage(file).then(
            result => {
              this.setState({ fileSrc: result });
              this.props.handleTeamSmartAssetUploadTransfer({
                file,
                onSuccess: () =>
                  this.handleFlagFileAsFinishedUploading(file.name, resolve),
                folderId: this.state.selectedFolderId,
                smartImage: this.props.smartImage
              });
            },
            ({ error }) => {
              this.setState({ imageError: error });
            }
          );
        });
    };

    /* set up the promise containers for the upload */
    const uploadPromises = Object.values(files).map(file =>
      createImageProcessPromise(file)
    );

    /* run the promises in chain */
    await chainPromises(uploadPromises);

    /* when all promises complete update state */
    this.setState({ imagesUploading: false });

    // overwrite event value to trigger onChange event in case of same image being selected again
    event.target.value = "";
  }

  processImage(file) {
    const fileExtension = getFileExtension(file.name);

    if (!this.state.acceptedFileTypes.includes(fileExtension))
      return Promise.reject({
        error: "Images must be in PNG, GIF or JPG format."
      });

    const promise = new Promise((resolve, reject) => {
      const errorCallback = msg => () => {
        Logger.warn(msg);
        return reject({ error: "We were not able to process your image." });
      };

      const validateFileAsImage = fileSrc => {
        const image = new Image();
        image.onerror = errorCallback("Image onload error");
        image.onload = () => {
          Logger.info({ app: "Image processed successfully" });
          /* validate image size here*/
          resolve(fileSrc);
        };
        image.src = fileSrc;
      };

      const reader = new FileReader();
      reader.onerror = errorCallback("File Reader onload error");

      reader.onload = ({ target }) => validateFileAsImage(target.result);
      reader.readAsDataURL(file);
    });

    return promise;
  }

  triggerImageSelector() {
    document.getElementById("ImageUpload").click();
  }

  clearErrors() {
    const { clearUploadErrors = noop } = this.props;

    this.setState({
      errors: null
    });
    clearUploadErrors();
  }

  getSubTextLabel() {
    const {
      destinationName = "Brand Images",
      isSmartImageTransfer
    } = this.props;
    const uploadType = destinationName === "My Videos" ? "videos" : "images";
    let message = `Your ${uploadType} are now uploading and will appear as they are completed.`;

    if (isSmartImageTransfer) {
      message +=
        " Your Smart ID will automatically be transferred to the new image.";
    }

    return message;
  }

  getUploadContent() {
    const {
      assetFolders,
      destinationName = "Brand Images",
      isSmartImageTransfer
    } = this.props;
    const { uploadingImages, selectedFolderId, errors } = this.state;

    let selectedFolderName = destinationName;
    if (selectedFolderId !== null && !isEmpty(assetFolders)) {
      const selectedFolder = assetFolders.find(
        folder => folder.id === selectedFolderId
      );
      if (selectedFolder) {
        selectedFolderName = selectedFolder.name;
      }
    }

    const getUploadSuccessIcon = image => {
      if (!image.isFinished && !image.isError) {
        return <Loading size="14px" />;
      }
      if (image.isFinished && image.isError) {
        return <CrossCircledIcon color="#e64826" size="14px" />;
      }
      if (image.isFinished && !image.isError) {
        return <TickCircledIcon size="14px" color="#00bd62" />;
      }
    };

    return (
      <div
        className={
          isSmartImageTransfer ? style.tansferContent : style.uploadContent
        }
      >
        <div className={style.uploadContentSubText}>
          {this.getSubTextLabel()}
        </div>
        <div className={style.uploadUploadingTo}>
          Uploading to:{" "}
          <span className={style.uploadLocation}>{selectedFolderName}</span>
        </div>
        <div className={style.uploadTable}>
          {Object.keys(uploadingImages).map(key => (
            <>
              <div className={style.uploadTableRow}>
                <div className={style.uploadRowLeft}>
                  <div className={style.uploadImageIconWrapper}>
                    <ImageJaggedMountainIcon
                      isTabVersion={false}
                      size="24px"
                      color="#9a9b9d"
                    />
                  </div>
                  <div className={style.uploadRowText}>{key}</div>
                </div>
                <div className={style.uploadRowRight}>
                  <div>{uploadingImages[key].size}</div>
                  <div>{getUploadSuccessIcon(uploadingImages[key])}</div>
                </div>
              </div>
              {errors && errors[key] && (
                <div className={style.uploadError}>{errors[key].value}</div>
              )}
            </>
          ))}
        </div>
      </div>
    );
  }

  getFolderSelectionContent() {
    const {
      assetFolders,
      destinationName = "Brand Images",
      isSmartImageTransfer = false
    } = this.props;
    const { selectedFolderId } = this.state;

    return (
      <FolderPicker
        folders={assetFolders}
        onClick={this.handleChangeSelectedFolder}
        destinationName={destinationName}
        selectedFolderId={selectedFolderId}
        className={isSmartImageTransfer ? style.smartImageTransferContent : ""}
      />
    );
  }

  render() {
    const { isOpen, isSmartImageTransfer = false } = this.props;
    const { currentPage } = this.state;

    const pageContent = this.getPageContents(currentPage);

    return (
      <Modal
        isOpen={isOpen}
        contentLabel="Preview"
        onRequestClose={() => this.handleOnClose()}
        header={pageContent.header}
        className={pageContent.modalStyle}
        buttons={pageContent.buttons}
        buttonsClassName={pageContent.buttonsClassName}
      >
        {pageContent.content}
        <input
          type="file"
          accept={this.state.acceptedFileExtensionList}
          id={"ImageUpload"}
          className={style.inputfile}
          onChange={e => this.handleFileSelect({ event: e })}
          multiple={!isSmartImageTransfer}
        />
      </Modal>
    );
  }
}

BrandAssetsUploadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUploadUserTeamImage: PropTypes.func,
  imageFolders: PropTypes.array
};

export default BrandAssetsUploadModal;
