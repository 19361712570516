import { MEDIA_SORT_OPTIONS } from "lib/constants";
import Dropdown2 from "views/components/dropdown2/Dropdown2";
import style from "./common.module.css";
import AZDownIcon from "views/components/icons/AZDownIcon";
import AZUpIcon from "views/components/icons/AZUpIcon";
import ClockArrowClockwiseIcon from "views/components/icons/ClockArrowClockwiseIcon";
import ClockArrowAntiClockwiseIcon from "views/components/icons/ClockArrowAntiClockwiseIcon";
import React from "react";
import ToggleCheckBox from "../toggleCheckBox";

export class BrandKitSortButton extends React.Component {
  constructor(props) {
    super(props);

    this.getLabel = this.getLabel.bind(this);
    this.optionsRenderer = this.optionsRenderer.bind(this);
    this.getOptionIcon = this.getOptionIcon.bind(this);
  }

  getLabel() {
    const { sortKey } = this.props;

    return (
      <div className={style.sortDropdownLabel}>
        {this.getOptionIcon(sortKey)}
        {MEDIA_SORT_OPTIONS[sortKey].label}
      </div>
    );
  }

  getOptionIcon(optionKey) {
    switch (optionKey) {
      case "UPDATEDAT_ASC": {
        return (
          <ClockArrowAntiClockwiseIcon size={20} color={style.easilGrayXDark} />
        );
      }
      case "NAME_ASC": {
        return <AZDownIcon color={style.easilGrayXDark} />;
      }
      case "NAME_DESC": {
        return <AZUpIcon color={style.easilGrayXDark} />;
      }
      case "UPDATEDAT_DESC":
      default: {
        return (
          <ClockArrowClockwiseIcon size={20} color={style.easilGrayXDark} />
        );
      }
    }
  }

  optionsRenderer({ option, onChange }) {
    return (
      <>
        <div className={style.sortDropdownOptions}>
          {Object.values(MEDIA_SORT_OPTIONS).map(option => {
            return (
              <div
                className={style.sortDropdownOption}
                disabled={this.props.filterSmartImages}
                data-disabled={this.props.filterSmartImages}
                onClick={() => onChange(option.key)}
              >
                {this.getOptionIcon(option.key)}
                {option.label}
              </div>
            );
          })}
        </div>
        {this.props.smartImageFilter && (
          <div className={style.sortFiltersWrapper}>
            <div className={style.filtersText}>Filters</div>
            <div className={style.sortSmartImages}>
              <div className={style.smartImageOnly}>Smart Images Only</div>
              <ToggleCheckBox
                height={"20px"}
                width={"38px"}
                checked={this.props.filterSmartImages}
                onChange={this.props.toggleSmartImageFilter}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  render() {
    const { onSortUpdate } = this.props;

    const sortDropdownOptions = Object.values(MEDIA_SORT_OPTIONS);

    return (
      <Dropdown2
        className={style.sortDropdown}
        disabled={false}
        value={this.getLabel}
        isCustomValue={true}
        selected={null}
        onChange={onSortUpdate}
        optionsRenderer={this.optionsRenderer}
        options={sortDropdownOptions}
        dropdownClassName={style.sortDropdownPopout}
        wrapperClassName={style.sortDropdownWrapper}
        isNewCaret
      />
    );
  }
}

export default BrandKitSortButton;
