import React, { Component } from "react";
import PropTypes from "prop-types";
import IconButton from "views/components/IconButton";
import TrashIcon from "views/components/icons/TrashIcon";
import FolderWideIcon from "views/components/icons/FolderWideIcon";
import TwoPeopleIcon from "views/components/icons/TwoPeopleIcon";
import Tooltip from "views/components/Tooltip";
import PopoutItemMenu from "views/components/popout/PopoutItemMenu/PopoutItemMenu";
import confirmArchiveFolder from "../confirmArchiveFolder";
import { noop } from "lib";
import style from "./style.module.css";
import { isSharedFolder } from "lib/sharedFoldersUtils";

class FolderButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      folderName: props.folder.name,
      showMenu: false
    };

    this.onUpdateFolderName = this.onUpdateFolderName.bind(this);
    this.setMenuState = this.setMenuState.bind(this);
    this.onDeleteFolder = this.onDeleteFolder.bind(this);
    this.getMenuComponent = this.getMenuComponent.bind(this);
    this.getMenuActionsDetails = this.getMenuActionsDetails.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.archiveFolder = this.archiveFolder.bind(this);
    this.getButtonTitle = this.getButtonTitle.bind(this);
    this.getTitleClassName = this.getTitleClassName.bind(this);
    this.getSharedFolderTooltip = this.getSharedFolderTooltip.bind(this);
  }

  setMenuState(state) {
    this.setState({ showMenu: state });
  }

  onUpdateFolderName(name) {
    this.setState({ folderName: name });

    this.props.updateFolder({
      ...this.props.folder,
      name: name
    });
  }

  onDeleteFolder() {
    this.setMenuState(false);

    this.props.deleteFolder(this.props.folder.id);
  }

  getMenuActionsDetails() {
    const actionsMap = {
      archive: {
        name: "Archive",
        Icon: props => <TrashIcon version="2" {...props} />,
        onClick: this.archiveFolder
      },
      shareFolder: {
        name: "Share with other teams",
        Icon: props => <TwoPeopleIcon />,
        onClick: this.props.openShareFolderModal
      }
    };

    return this.props.menuActions
      .map(action => actionsMap[action])
      .filter(x => x);
  }

  archiveFolder() {
    confirmArchiveFolder({
      onOk: () => {
        this.onDeleteFolder();
      }
    });
  }

  getMenuComponent() {
    if (!this.props.menuActions || !this.props.canModifyFolder) {
      return null;
    }

    const { updateFolder = noop, canModifyFolder, folder } = this.props;
    const canRenameFolder =
      +updateFolder !== noop && +canModifyFolder && +!folder.sharedWithMe;

    return (
      <PopoutItemMenu
        onClose={() => this.setMenuState(false)}
        onRename={canRenameFolder ? this.onUpdateFolderName : undefined}
        className={style.popout}
        itemName={this.state.folderName}
        actions={this.getMenuActionsDetails()}
        actionsClassName={style.folderPopoutActions}
      />
    );
  }

  onButtonClick() {
    this.setMenuState(false);

    this.props.onClick();
  }

  getSharedFolderTooltip() {
    const { allocations, folder } = this.props;
    if (folder.sharedByMe && !allocations) {
      return "Sharing with sub-teams";
    } else if (folder.sharedWithMe) {
      return "Shared from parent team";
    } else if (allocations && allocations.length === 1) {
      return `Sharing with ${allocations[0].name} Sub-Team`;
    } else if (allocations && allocations.length > 1) {
      return `Sharing with ${allocations[0].name} and ${allocations.length -
        1} other Sub-Team${allocations.length > 2 ? "s" : ""}`;
    }
  }

  getButtonTitle(isSharedFolder) {
    if (!isSharedFolder) return this.state.folderName;

    const tipText = this.getSharedFolderTooltip();

    return (
      <div className={style.sharedFolderTitle}>
        <TwoPeopleIcon data-tip={tipText} data-for="shared-folder-button-tip" />
        {this.state.folderName}
        <Tooltip
          id="shared-folder-button-tip"
          place="bottom"
          className={style.buttonToolTip}
        />
      </div>
    );
  }

  getTitleClassName(isSharedFolder) {
    if (!isSharedFolder) return this.props.titleClassName;
    return [this.props.titleClassName, style.sharedTitle]
      .filter(x => x)
      .join(" ");
  }

  render() {
    const isFolderShared = isSharedFolder(
      this.props.folder,
      this.props.allocations
    );
    const title = this.getButtonTitle(isFolderShared);
    const titleClassName = this.getTitleClassName(isFolderShared);

    return (
      <IconButton
        Icon={FolderWideIcon}
        iconColor="#9a9b9d"
        MenuComponent={this.getMenuComponent()}
        className={this.props.buttonClassName}
        onClick={this.onButtonClick}
        setMenuState={this.setMenuState}
        showMenu={this.state.showMenu}
        title={title}
        wrapperClassName={this.props.wrapperClassName}
        titleClassName={titleClassName}
      />
    );
  }
}

FolderButton.displayName = "FolderButton";

FolderButton.folderType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
});

FolderButton.propTypes = {
  buttonClassName: PropTypes.string,
  onClick: PropTypes.func,
  deleteFolder: PropTypes.func,
  updateFolder: PropTypes.func,
  folder: FolderButton.folderType.isRequired,
  menuActions: PropTypes.arrayOf(PropTypes.string)
};

export default FolderButton;
