import * as types from "./designsTypes";
import designAdapter from "../../ui/editor/designAdapter/designAdapter";
import { push } from "react-router-redux";
import { ActionCreators } from "redux-undo";
import { apiDesignsPageSelector } from "state/api/designs";
import { designById } from "./designsSelectors";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import {
  apiDesignsResourceSelector,
  teamDesignApiPageSelector
} from "state/api/designs/apiDesignsSelectors";
import { fetchBrandKitColorGroups } from "state/ui/brandKit/BrandKitActions";
import { getParameterByName } from "lib/queryStringUtils";
import { cloneDeep, noop, omitBy, isNil, getPath } from "lib/lodash";
import Logger from "lib/logger";
import reduxStoreExpiry from "lib/reduxStoreExpiry";
import { objectToQueryString } from "lib/query/query";
import * as editorActionTypes from "state/ui/editor/editorActionTypes";
import {
  currentSubscriptionPlan,
  getEasilCatalogueDefaultSize
} from "state/entities/subscriptions/subscriptionsSelectors";
import PATHS from "routes/paths";
import Subscriptions from "lib/subscriptions";
import { RESET_EDITOR_HISTORY } from "state/ui/editor/editorActionTypes";
import { initTakeoverModal } from "state/ui/takeoverSharedDesignModal/takeoverSharedDesignModalActions";
import { handleDesignRequestFailure } from "lib/handleDesignRequestFailure";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";
import {
  currentUserSelector,
  currentUserIdSelector
} from "state/currentUser/currentUserSelectors";
import { handleEmptyFilterDesign } from "state/entities/designFolders/designFoldersActions";
import { parseElementsForRendering } from "lib/Renderer/DesignDataParser";
import { getSearchPath } from "state/ui/location/locationSelectors";
import { getFilters } from "lib/locationHelpers";
import { asyncAction } from "lib/asyncHelpers";
import { getLayers } from "lib/designLayerUtil";
import { latestDataForDesigns } from "state/entities/designsData/designsDataSelectors";
import { teamArchivedDesignApiPageSelector } from "state/api/designs/team/archived/archivedApiSelectors";
import { makeToast } from "state/ui/toaster/ToasterActions";
import { sendPortalMessage } from "lib/portalUtil";
import { isEditorInIframe } from "views/components/Editor/utils";

const defaultSharedTypes = [
  types.DESIGNS_SHARED_REQUEST,
  types.DESIGNS_SHARED_REQUEST_SUCCESS,
  types.DESIGNS_SHARED_REQUEST_FAILURE
];

const pendingApprovalTypes = [
  types.DESIGNS_PENDING_APPROVAL_REQUEST,
  types.DESIGNS_PENDING_APPROVAL_REQUEST_SUCCESS,
  types.DESIGNS_PENDING_APPROVAL_REQUEST_FAILURE
];

const pendingApprovalTeamTypes = [
  types.DESIGNS_PENDING_APPROVAL_TEAM_REQUEST,
  types.DESIGNS_PENDING_APPROVAL_TEAM_REQUEST_SUCCESS,
  types.DESIGNS_PENDING_APPROVAL_TEAM_REQUEST_FAILURE
];

const pendingApprovalSharedTypes = [
  types.DESIGNS_PENDING_APPROVAL_SHARED_REQUEST,
  types.DESIGNS_PENDING_APPROVAL_SHARED_REQUEST_SUCCESS,
  types.DESIGNS_PENDING_APPROVAL_SHARED_REQUEST_FAILURE
];

const approvedSharedTypes = [
  types.DESIGNS_APPROVED_SHARED_REQUEST,
  types.DESIGNS_APPROVED_SHARED_REQUEST_SUCCESS,
  types.DESIGNS_APPROVED_SHARED_REQUEST_FAILURE
];

const declinedApprovalTypes = [
  types.DESIGNS_DECLINED_APPROVAL_REQUEST,
  types.DESIGNS_DECLINED_APPROVAL_REQUEST_SUCCESS,
  types.DESIGNS_DECLINED_APPROVAL_REQUEST_FAILURE
];

const declinedApprovalSharedTypes = [
  types.DESIGNS_DECLINED_APPROVAL_SHARED_REQUEST,
  types.DESIGNS_DECLINED_APPROVAL_SHARED_REQUEST_SUCCESS,
  types.DESIGNS_DECLINED_APPROVAL_SHARED_REQUEST_FAILURE
];

const paramsWithFilters = (defaultParams, filters, resource, state) => {
  const params = Object.assign({}, defaultParams);

  if (filters) {
    if (filters.size && filters.size !== "null") params.size = filters.size;
    if (filters.folderId) params.folderId = filters.folderId;
    if (filters.categoryId && filters.categoryId !== "null")
      params.categoryId = filters.categoryId;
  }

  if (state) {
    const plan = currentSubscriptionPlan(state);
    const subscription = Subscriptions.get(plan.code);
    if (
      plan &&
      (!filters.size || filters.size === "null") &&
      [PATHS.catalogueAll, PATHS.catalogueEasil].includes(resource) &&
      !(filters.folderId || filters.categoryId)
    ) {
      params.size = subscription.easilCatalogueDefaultSize;
    }
  }

  return params;
};

// builds input design and designData objects into a v2 format designData object
export const buildDesignObject = ({ design, designData }) => {
  // Match expected object structure of designAdapter function
  const data = {
    restrictions: designData.documentRestrictions,
    pages: designData.data,
    template: { bleed: designData.bleed },
    designId: designData.designId,
    designDataId: designData.id,
    ordered: designData.ordered,
    createdAt: design.createdAt,
    updatedAt: design.updatedAt
  };

  return Object.assign({}, design, data);
};

const designDataFromTemplateToDraft = ({ designsData, designsCreation }) => {
  const designDataId = Object.keys(designsCreation).map(
    id => designsCreation[id]
  )[0].designData;
  return designsData[designDataId];
};

export const refetchAllPendingApprovalDesignsPages = () => (
  dispatch,
  getState
) => {
  const pendingApprovalApiState = getState().api.designs.personal.approvals
    .pendingApproval;

  const pageNumbers = Object.keys(pendingApprovalApiState.pages);

  pageNumbers.forEach(pageNumber =>
    dispatch(fetchPendingApprovalDesignsForTeam(pageNumber))
  );
};

export const fetchPendingApprovalDesignsForTeam = (page = 1) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: pendingApprovalTeamTypes,
      endpoint: `/teams/${teamId}/drafts/pending-approval`,
      request: {
        page: page,
        pageSize: getState().api.designs.personal.approvals.pendingApproval
          .pageSize
      },
      schema: schemas.DESIGNS_ARRAY
    }
  });
};

export const fetchDraftsDesigns = ({ filters, userId, teamId, page = 1 }) => (
  dispatch,
  getState
) => {
  const params = paramsWithFilters({}, filters);

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.DESIGNS_DRAFTS_REQUEST,
        types.DESIGNS_DRAFTS_REQUEST_SUCCESS,
        types.DESIGNS_DRAFTS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/drafts`,
      request: {
        page: page,
        pageSize: getState().api.designs.personal.drafts.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY
    }
  });
};

export const fetchDraftsDesignsFromFolder = ({
  userId,
  teamId,
  filters,
  page = 1,
  onSuccess = noop
}) => (dispatch, getState) => {
  const params = paramsWithFilters({}, filters);

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.DESIGNS_BY_FOLDER_REQUEST,
        types.DESIGNS_BY_FOLDER_REQUEST_SUCCESS,
        types.DESIGNS_BY_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/drafts`,
      request: {
        page: page,
        pageSize: getState().api.designs.personal.drafts.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY,
      onSuccess
    }
  });
};

/**
 * @desc - makes a request to fetchTemplateDesignsFromFolder in the case where team templates are only stored in folders
 * @param {string} teamId, filters - filters includes the folderId to be checked
 */
export const promiseFetchTemplateDesignsFromFolder = ({
  teamId: incomingTeamId,
  filters,
  page = 1
}) => (dispatch, getState) => {
  // default the current teamId
  const teamId = incomingTeamId || currentTeamIdSelector(getState());

  return new Promise((resolve, reject) =>
    dispatch(
      checkForFolderContentIfNeeded({
        teamId,
        filters,
        page,
        onSuccess: response => {
          resolve(response);
        }
      })
    )
  );
};

export const shouldFetchTemplateDesignsFromFolder = (state, filters) => {
  // get the template folder
  const catalogueFolderPage = getPath(
    state,
    `api.designs.team.folders.${filters.folderId}.sizes.all.pages.1`,
    {}
  );

  if (!catalogueFolderPage) return true;

  if (catalogueFolderPage.isFetching) return false;

  const isExpired = reduxStoreExpiry.isDataExpired(
    catalogueFolderPage.lastFetched,
    5
  );

  return isExpired;
};

export const checkForFolderContentIfNeeded = args => (dispatch, getState) => {
  if (shouldFetchTemplateDesignsFromFolder(getState(), args.filters)) {
    return dispatch(fetchTemplateDesignsFromFolder(args));
  } else {
    const pages = getPath(
      getState(),
      `api.designs.team.folders.${args.filters.folderId}.sizes.${args.filters
        .size || "all"}.pages`,
      {}
    );
    const lastPage = Object.keys(pages).pop();
    const templateDesigns = pages[lastPage];
    args.onSuccess(templateDesigns);
  }
};

export const fetchTemplateDesignsFromFolder = ({
  teamId,
  filters,
  page = 1,
  onSuccess = noop
}) => (dispatch, getState) => {
  const params = paramsWithFilters({}, filters);

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.DESIGNS_BY_TEAM_FOLDER_REQUEST,
        types.DESIGNS_BY_TEAM_FOLDER_REQUEST_SUCCESS,
        types.DESIGNS_BY_TEAM_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/catalogue`,
      request: {
        page: page,
        pageSize: getState().api.designs.team.templates.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY,
      onSuccess
    }
  });
};

export const fetchArchivedDesigns = ({ filters, userId, teamId, page = 1 }) => (
  dispatch,
  getState
) => {
  const params = paramsWithFilters({ archived: true }, filters);

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.DESIGNS_ARCHIVED_REQUEST,
        types.DESIGNS_ARCHIVED_REQUEST_SUCCESS,
        types.DESIGNS_ARCHIVED_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/drafts`,
      request: {
        page: page,
        pageSize: getState().api.designs.personal.archived.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY
    }
  });
};

export const updateDesignForMasonry = ({
  design,
  designType = "draft",
  isStatus = false,
  onSuccess = noop
}) => dispatch => {
  const resetDesignFilters = () => {
    dispatch(
      handleEmptyFilterDesign({
        designId: design.id
      })
    );
  };

  if (isStatus) {
    return dispatch(
      updateDesignsStatusAndRefetchLastPage({
        design,
        designType,
        onSuccess: () => {
          resetDesignFilters();
          onSuccess();
        }
      })
    );
  }

  dispatch(updateDesign({ design }));
};

export const archiveMultipleDesignsForMasonry = ({
  designs,
  designType,
  onSuccess = noop,
  onFailure = noop
}) => (dispatch, getState) => {
  const state = getState();
  let _designs = cloneDeep(designs);

  if (typeof _designs[0] === "string") {
    _designs = _designs.map(designId => {
      const currentDesign = designById({ state, designId });
      return {
        ...currentDesign,
        archived: true
      };
    });
  }

  return dispatch(
    archiveDesignsAndRefetchLastPage({
      designs: _designs,
      designType,
      onSuccess: () => {
        onSuccess();
      },
      onFailure: () => {
        dispatch(
          makeToast({
            textComponent: `Some selected designs could not be archived.\nPlease contact support if the issue\npersists`,
            type: "noButton",
            icon: "warning"
          })
        );
        onFailure();
      }
    })
  );
};

export const updateMultipleDesignsStatusForMasonry = ({
  designs,
  designType,
  onSuccess = noop,
  onFailure = noop
}) => dispatch => {
  return dispatch(
    updateDesignsStatusAndRefetchLastPage({
      designs,
      designType,
      onSuccess: () => {
        onSuccess();
      },
      onFailure: () => {
        dispatch(
          makeToast({
            textComponent: `Some selected designs could not be archived.\nPlease contact support if the issue\npersists`,
            type: "noButton",
            icon: "warning"
          })
        );
        onFailure();
      }
    })
  );
};

export const updateDesign = ({
  design,
  isStatus = false,
  onSuccess = noop
}) => ({
  [CALL_API]: {
    method: "PUT",
    service: SERVICES.ASSET,
    types: [
      types.DESIGN_DRAFT_UPDATE_REQUEST,
      types.DESIGN_DRAFT_UPDATE_REQUEST_SUCCESS,
      types.DESIGN_DRAFT_UPDATE_REQUEST_FAILURE
    ],
    endpoint: `/designs/${design.id}`,
    request: {
      body: {
        ...design
      }
    },
    schema: schemas.DESIGNS,
    onSuccess: response => onSuccess(response)
  }
});

export const updateDesignRequiresApprovalStatus = ({
  designId,
  requiresApproval
}) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ASSET,
      types: [
        types.DESIGNS_TEMPLATES_REQUIRES_APPROVAL_UPDATE_REQUEST,
        types.DESIGNS_TEMPLATES_REQUIRES_APPROVAL_UPDATE_REQUEST_SUCCESS,
        types.DESIGNS_TEMPLATES_REQUIRES_APPROVAL_UPDATE_REQUEST_FAILURE
      ],
      endpoint: `/designs/${designId}/requires-approval`,
      request: {
        body: {
          designId,
          requiresApproval
        }
      },
      schema: schemas.NONE
    }
  });
};

export const toggleDesignRequiresApprovalStatus = ({ designId }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const design = designById({ state, designId });

  dispatch(
    updateDesignRequiresApprovalStatus({
      designId,
      requiresApproval: !design.requiresApproval
    })
  );
};

export const updateDraftDesignsStatus = ({
  designs,
  design,
  onSuccess = noop,
  onFailure = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "PATCH",
      service: SERVICES.ASSET,
      types: [
        types.DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST,
        types.DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST_SUCCESS,
        types.DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST_FAILURE
      ],
      endpoint: `/designs`,
      request: {
        body: designs || [design]
      },
      schema: schemas.DESIGNS_STATUS_UPDATE_ARRAY,
      onSuccess: () => onSuccess(),
      onFailure: () => onFailure()
    }
  });
};

export const updateTemplateDesignsStatus = ({
  designs,
  design,
  onSuccess = noop,
  onFailure = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "PATCH",
      service: SERVICES.ASSET,
      types: [
        types.TEAM_TEMPLATES_STATUS_UPDATE_REQUEST,
        types.TEAM_TEMPLATES_STATUS_UPDATE_REQUEST_SUCCESS,
        types.TEAM_TEMPLATES_STATUS_UPDATE_REQUEST_FAILURE
      ],
      endpoint: `/designs`,
      request: {
        body: designs || [design]
      },
      schema: schemas.DESIGNS_STATUS_UPDATE_ARRAY,
      onSuccess: () => onSuccess(),
      onFailure: () => onFailure()
    }
  });
};

export const updateDesignsStatus = ({
  designs,
  design,
  designType = "draft",
  onSuccess = noop,
  onFailure = noop
}) => dispatch => {
  if (designType === "template") {
    return dispatch(
      updateTemplateDesignsStatus({
        designs,
        design,
        onSuccess,
        onFailure
      })
    );
  }

  return dispatch(
    updateDraftDesignsStatus({
      designs,
      design,
      onSuccess,
      onFailure
    })
  );
};

export const deleteArchivedDesign = ({ designId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = currentUserIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ASSET,
      types: [
        types.DESIGNS_DELETE_REQUEST,
        types.DESIGNS_DELETE_REQUEST_SUCCESS,
        types.DESIGNS_DELETE_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/drafts/${designId}`,
      schema: schemas.NONE,
      onSuccess: () => onSuccess(),
      extra: {
        designId: designId
      }
    }
  });
};

export const openDesignInEditor = ({
  design,
  designData
}) => async dispatch => {
  const openDesignInEditor = () => dispatch(push(`/editor/${design.id}`));

  dispatch(
    processDesignDataAndPushToStore({
      design,
      designData,
      onSuccess: openDesignInEditor
    })
  );
};

const processDesignDataAndPushToStore = ({
  design,
  designData,
  onSuccess = noop
}) => async (dispatch, getState) => {
  const data = buildDesignObject({ design, designData });

  const designObject = await designAdapter(data);

  dispatch({ type: editorActionTypes.LOAD_DESIGN, data: designObject });
  dispatch(ActionCreators.clearHistory());
  onSuccess(designObject);
};

export const createDraftFromTemplate = ({ design }) => dispatch => {
  const onSuccess = response => {
    const { designs, designsData, designsCreation } = response.entities;
    const design = Object.keys(designs).map(id => designs[id])[0];
    const designData = designDataFromTemplateToDraft({
      designsData,
      designsCreation
    });

    dispatch(openDesignInEditor({ design, designData }));
  };
  dispatch(createDesign({ design, onSuccess }));
};

/**
 * @desc - makes a request to fetchDesign and makes an action based on the sharing state in the result
 * @param {string} designId - the id for the design to check the locked status of
 */
export const fetchSharedDesign = ({ designId }) => (dispatch, getState) => {
  const onSuccess = response => {
    const { designs } = response.entities;

    const design = Object.values(designs).pop();

    if (!design.isLocked) {
      // if not locked then just go to the design
      const redirectPath = PATHS.buildEditorDesignPath(designId);
      dispatch(push(redirectPath));
    } else {
      // if we know its locked collect the user data and show the take over modal
      dispatch(initTakeoverModal({ designId }));
    }
  };

  // make fetchDesign request with our custom onSuccess
  dispatch(fetchDesign({ designId, onSuccess }));
};

export const fetchDesign = ({ designId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  const location = getState().router.location;
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.DESIGN_REQUEST,
        types.DESIGN_REQUEST_SUCCESS,
        types.DESIGN_REQUEST_FAILURE
      ],
      request: {
        extra: {
          designId
        }
      },
      endpoint: `/designs/${designId}`,
      schema: schemas.DESIGNS,
      onSuccess: response => onSuccess(response),
      onFailure: response =>
        handleDesignRequestFailure(response, dispatch, location)
    }
  });
};

/**
 * @desc checks for the presence of a design in redux and if it is not present fetches it from the api
 * @param {string} designId - the id for the design to fetch
 * @param {function} onSuccess - optional function to run when a design is fetched or found in redux
 * @param {function} resolve - optional function for resolving an async call for this function, runs when a design is fetched or found in redux
 * @returns {null} nothing will be returned, to get a design from this function pass a resolve or on success
 */
export const fetchDesignIfNeeded = ({
  designId,
  onSuccess = noop,
  resolve = noop
}) => (dispatch, getState) => {
  const state = getState();
  const design = designById({ state, designId });

  if (design) {
    onSuccess(design);
    return resolve(design);
  }

  const combinedSuccessFunction = response => {
    onSuccess(response);
    resolve(response);
  };

  dispatch(fetchDesign({ designId, onSuccess: combinedSuccessFunction }));
};

/**
 * @desc runs fetchDesignIdNeeded with a promise return
 * @param {object} args - a set of arguments to pass through to @fetchDesignIfNeeded
 * @see fetchDesignIfNeeded
 * @returns {Promise}
 */
export const asyncFetchDesignIfNeeded = args => dispatch =>
  new Promise(resolve => {
    args.resolve = resolve;
    dispatch(fetchDesignIfNeeded(args));
  });

export const loadDesign = ({ designId }) => (dispatch, getState) => {
  const onSuccess = response => {
    const {
      ids: id,
      entities: { designData: designsData }
    } = response;
    const design = getState().entities.designs[designId];
    const designData = designsData[id];

    dispatch(processDesignDataAndPushToStore({ design, designData }));
  };

  const fetchedDesign = _response => {
    dispatch(fetchDesignData({ designId, onSuccess, checkSmartImages: true }));
  };

  dispatch(fetchDesign({ designId, onSuccess: fetchedDesign }));
  dispatch(fetchBrandKitColorGroups({ teamId: getState().ui.currentTeam.id }));
};

export const createResize = ({
  userId,
  teamId,
  designId,
  collectionId,
  templateCode,
  measurements,
  onSuccess
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.DESIGN_RESIZE_REQUEST,
        types.DESIGN_RESIZE_REQUEST_SUCCESS,
        types.DESIGN_RESIZE_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/collections/${collectionId}/designs`,
      request: {
        body: {
          userId,
          teamId,
          designId,
          collectionId,
          templateCode,
          measurements
        }
      },
      schema: schemas.DESIGN_RESIZE,
      onSuccess: response => onSuccess(response)
    }
  });
};

// fetch all designs within a collection
export const fetchCollectionDesigns = collectionId => ({
  [CALL_API]: {
    method: "GET",
    service: SERVICES.ASSET,
    endpoint: `/collections/${collectionId}/designs`,
    schema: schemas.COLLECTION_DESIGNS,
    types: [
      types.COLLECTION_DESIGNS_REQUEST,
      types.COLLECTION_DESIGNS_REQUEST_SUCCESS,
      types.COLLECTION_DESIGNS_REQUEST_FAILURE
    ],
    extra: {
      collectionId
    }
  }
});

// fetch collection with price
export const fetchCollectionDesignsWithPrice = collectionId => ({
  [CALL_API]: {
    method: "GET",
    service: SERVICES.ASSET,
    endpoint: `/collections/${collectionId}`,
    schema: schemas.COLLECTION,
    types: [
      types.COLLECTION_PRICE_REQUEST,
      types.COLLECTION_PRICE_REQUEST_SUCCESS,
      types.COLLECTION_PRICE_REQUEST_FAILURE
    ],
    extra: {
      collectionId
    }
  }
});

export const resizeDesignAndLoad = ({
  userId,
  teamId,
  designId,
  collectionId,
  templateCode,
  measurements
}) => dispatch => {
  const onSuccess = response => {
    const { designs, designsData, designResize } = response.entities;
    const design = Object.keys(designs).map(id => designs[id])[0];
    const designData = designDataFromTemplateToDraft({
      designsData,
      designsCreation: designResize
    });

    // Send design data to portal
    const designMessage = {
      event: "portal.design.loaded",
      design
    };
    sendPortalMessage(designMessage);

    dispatch(openDesignInEditor({ design, designData }));
  };
  dispatch(
    createResize({
      userId,
      teamId,
      designId,
      collectionId,
      templateCode,
      measurements,
      onSuccess
    })
  );
};

export const archiveDesignsAndRefetchLastPage = ({
  designs,
  design,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  dispatch(
    archiveDesigns({
      designs,
      design,
      onSuccess: response => {
        const designsDraftStore = getState().api.designs.personal.drafts;

        const draftPages = Object.keys(designsDraftStore.pages || {});

        const lastDraftPageNumber = draftPages.sort()[draftPages.length - 1];

        onSuccess(response);

        const location = getSearchPath(getState());
        const filters = getFilters(location);

        dispatch(
          fetchDesignsIfNeeded({
            filters,
            page: lastDraftPageNumber,
            resource: PATHS.workspaceDrafts,
            shouldClearPages: false
          })
        );
      },
      onFailure: () => {
        onFailure();
      }
    })
  );
};

export const archiveDesigns = ({
  designs,
  design,
  onSuccess = noop,
  onFailure = noop
}) => (dispatch, getState) => {
  const state = getState();

  const teamId = currentTeamIdSelector(state);
  const userId = currentUserIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ASSET,
      types: [
        types.DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST,
        types.DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST_SUCCESS,
        types.DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/drafts`,
      request: {
        body: designs || [design]
      },
      schema: schemas.DESIGNS_MULTI_STATUS_UPDATE_ARRAY,
      onSuccess: response => {
        onSuccess(response);
      },
      onFailure: () => onFailure()
    }
  });
};

/* this is used by the masonry  */
export const updateDesignsStatusAndRefetchLastPage = ({
  designs,
  design,
  designType = "draft",
  onSuccess = noop,
  onFailure = noop
}) => (dispatch, getState) => {
  dispatch(
    updateDesignsStatus({
      designs,
      design,
      designType,
      onSuccess: response => {
        const designsDraftStore = getState().api.designs.personal.drafts;
        const designsTemplatesStore = getState().api.designs.team.templates;

        const draftPages = Object.keys(designsDraftStore.pages || {});
        const templatePages = Object.keys(designsTemplatesStore.pages || {});

        const lastDraftPageNumber = draftPages.sort()[draftPages.length - 1];
        const lastTemplatePageNumber = templatePages.sort()[
          templatePages.length - 1
        ];

        onSuccess();

        const location = getSearchPath(getState());
        const filters = getFilters(location);

        dispatch(
          fetchDesignsIfNeeded({
            filters,
            page: lastDraftPageNumber,
            resource: PATHS.workspaceDrafts,
            shouldClearPages: !(designType === "draft")
          })
        );
        dispatch(
          fetchDesignsIfNeeded({
            filters,
            page: lastTemplatePageNumber,
            resource: PATHS.catalogueTemplates,
            shouldClearPages: designType === "draft"
          })
        );
      },
      onFailure: () => {
        onFailure();
      }
    })
  );
};

export const createDesignCopy = args => dispatch => {
  dispatch(
    createNewDesign({
      ...args,
      types: [
        types.DESIGNS_DRAFTS_COPY_REQUEST,
        types.DESIGNS_DRAFTS_COPY_REQUEST_SUCCESS,
        types.DESIGNS_DRAFTS_COPY_REQUEST_FAILURE
      ]
    })
  );
};

export const createDesign = args => dispatch => {
  dispatch(
    createNewDesign({
      ...args,
      types: [
        types.DESIGNS_DRAFTS_CREATE_REQUEST,
        types.DESIGNS_DRAFTS_CREATE_REQUEST_SUCCESS,
        types.DESIGNS_DRAFTS_CREATE_REQUEST_FAILURE
      ]
    })
  );
};

export const createNewDesign = ({
  design,
  folderId,
  onSuccess = noop,
  types,
  size
}) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types,
      endpoint: `/designs`,
      request: {
        body: {
          teamId: getState().ui.currentTeam.id,
          parentId: design.id
        }
      },
      extra: {
        provisionalId: `TEMP_ID_${Date.now()}`,
        folderId,
        size
      },
      schema: schemas.DESIGN_CREATION,
      onSuccess: response => onSuccess(response)
    }
  });
};

export const createNewCustomDesign = ({
  designMeasurements,
  folderId,
  onSuccess = noop,
  onFailure = noop
}) => (dispatch, getState) => {
  const moveToEditor = response => {
    const {
      entities: { designs, designsData }
    } = response;

    const design = Object.values(designs)[0];
    const designData = Object.values(designsData)[0];

    dispatch(openDesignInEditor({ design, designData }));
  };

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.DESIGNS_DRAFTS_CUSTOM_CREATE_REQUEST,
        types.DESIGNS_DRAFTS_CUSTOM_CREATE_REQUEST_SUCCESS,
        types.DESIGNS_DRAFTS_CUSTOM_CREATE_REQUEST_FAILURE
      ],
      endpoint: `/designs`,
      request: {
        body: {
          teamId: getState().ui.currentTeam.id,
          measurements: designMeasurements
        }
      },
      extra: {
        provisionalId: `TEMP_ID_${Date.now()}`,
        folderId
      },
      schema: schemas.DESIGN_CREATION,
      onSuccess: response => {
        moveToEditor(response);
        onSuccess(response);
      },
      onFailure
    }
  });
};

/**
 * @desc - performs the fetchDesignData Action  in an asyncronous manner
 * @param {string} designId - the identifier for the design to fetch
 * @param {boolean} isCacheAllowed - whether to allow cached redux designData that has not expired
 * @param {function} onSuccess - a function to run when the request is successful
 * @see fetchDesignData for core functionality
 */
export const fetchDesignDataAsync = ({
  designId,
  isCacheAllowed,
  onSuccess = noop
}) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const newSuccessFunction = response => {
      resolve(response);
      onSuccess(response);
    };

    if (isCacheAllowed) {
      // get the current designData for this designId if it already exists in redux
      const designDatas = latestDataForDesigns({
        state: getState(),
        designIds: [designId]
      });
      // confirm that the designData is not stale
      if (
        designDatas &&
        designDatas[0] &&
        !reduxStoreExpiry.isDataExpired(designDatas[0].lastFetched, 5)
      ) {
        // return the resolve with the designData
        return newSuccessFunction({
          entities: { designData: { [designId]: designDatas[0] } }
        });
      }
    }

    dispatch(fetchDesignData({ designId, onSuccess: newSuccessFunction }));
  });

/**
 * @desc - fetches the designData for a given designId
 * @param {string} designId - the identifier for the design to fetch
 * @param {function} onSuccess - a function to run when the request is successful
 * @see fetchDesignDataAsync for async functionality
 */
export const fetchDesignData = ({
  designId,
  onSuccess = noop,
  checkSmartImages = false
}) => (dispatch, getState) => {
  const location = getState().router.location;
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.DESIGNS_DATA_REQUEST,
        types.DESIGNS_DATA_REQUEST_SUCCESS,
        types.DESIGNS_DATA_REQUEST_FAILURE
      ],
      endpoint: `/designs/${designId}/data?checkForSmartImages=${checkSmartImages}`,
      schema: schemas.DESIGN_DATA,
      onSuccess: response => {
        return onSuccess(response);
      },
      onFailure: response =>
        handleDesignRequestFailure(response, dispatch, location)
    }
  });
};

/**
 * Fetch Design data from the Proxy API for rendering. This should only be used for rendering
 * purposes as the response data is mutated for rendering.
 * @param {string} designDataId UUID of the design version.
 * @param {string} designId UUID of the design.
 * @param {number} layerNumber 0 based page index. A negative number will indicate 'all' layers
 * @param {number} page 0 based page index.
 * @param {boolean} isThumb Whether the page is rendered for thumbnail generation.
 * @returns {Promise<Design>}
 */
export const fetchDesignDataFromProxyTmp = async (
  designDataId,
  designId,
  layerNumber,
  page,
  isThumb
) => {
  const designDataUrl = `${SERVICES.RENDER}/proxy/designs/${designId}/data/${designDataId}`;
  const designDataResponse = await fetch(designDataUrl);
  const designDataJson = await designDataResponse.json();

  if (designDataJson.error) {
    throw new Error(`Proxy response: ${JSON.stringify(designDataJson.error)}`);
  }

  let pageElements = designDataJson.designData.data[page].elements;
  if (layerNumber >= 0) {
    const layers = getLayers(designDataJson.designData.data[page]);
    if (!layers.length || !layers[layerNumber]) {
      console.error(`Failed to retrieve elements for layer ${layerNumber}`);
      throw Error(`Failed to retrieve elements for layer ${layerNumber}`);
    }
    pageElements = layers[layerNumber].elements;
  }

  // Before adapting the response into the UI designData format, modify the data for rendering
  const trimmedDesignJson = cloneDeep(designDataJson);
  const pageData = cloneDeep(designDataJson.designData.data[page]);
  trimmedDesignJson.designData.data = [pageData];
  trimmedDesignJson.designData.data[0].elements = parseElementsForRendering(
    pageElements,
    isThumb
  );

  const designObject = buildDesignObject(trimmedDesignJson);
  const designData = await designAdapter(designObject);

  // TODO: If we are requesting a specific layer, we should check if fonts are actually required.
  const designFontsUrl = `${SERVICES.RENDER}/proxy/designs/${designId}/data/${designDataId}/fonts?pageNumber=${page}`;
  const designFontsResponse = await fetch(designFontsUrl);

  if (designFontsResponse.status === 200) {
    designData.fonts = await designFontsResponse.json();
  }

  return designData;
};

export const fetchDesignDataAndLoad = ({ designId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  const success = response => {
    const design = getState().entities.designs[designId];
    const designData = Object.keys(response.entities.designData).map(
      id => response.entities.designData[id]
    )[0];

    dispatch(
      processDesignDataAndPushToStore({ design, designData, onSuccess })
    );
  };

  dispatch(
    fetchDesignData({ designId, onSuccess: success, checkSmartImages: true })
  );
};

export const fetchDesignDataAndRedirect = ({
  designId,
  redirectTo
}) => dispatch => {
  const onSuccess = _response => {
    const redirectPath = redirectTo || `/editor/${designId}`;
    dispatch(push(redirectPath));
  };

  dispatch(fetchDesignDataAndLoad({ designId, onSuccess }));
};

/**
 * @desc - takes the user to the editor and opens the design for the designId given
 * @param {string} designId - the id of the design to open in the editor
 */
export const redirectToDesign = ({ designId }) => dispatch => {
  const redirectPath = PATHS.buildEditorDesignPath(designId);
  dispatch(push(redirectPath));
};

/**
 * @desc - checks the sharing status of a design and opens the design in the editor if possible
 * @param {string} designId - the id for the design to open in the editor
 */
export const openDraftDesignId = ({ designId }) => (dispatch, getState) => {
  dispatch({ type: RESET_EDITOR_HISTORY });

  const state = getState();

  const design = designById({ state, designId });

  // check if in the shared workspace, in here the isShared param is not on the design
  const isSharedWorkspace = PATHS.isWorkspaceShared(
    state.router.location.pathname
  );

  const isShared = design.isShared || isSharedWorkspace;

  if (isShared && design.isLocked) {
    // if we know that the design is locked then just set the modal to display
    dispatch(initTakeoverModal({ designId }));
  } else if (isShared) {
    // if we just know that the design is shared but not if its currently locked then we should try to get the design and perform our action based on the result
    dispatch(fetchSharedDesign({ designId }));
  } else {
    // otherwise just open the design
    dispatch(redirectToDesign({ designId }));
  }
};

export const shouldFetchDesigns = ({ state, page, resource }) => {
  const designsApiPage = apiDesignsPageSelector({
    state,
    page,
    resource
  });

  if (!designsApiPage) return true;

  if (designsApiPage.isFetching) return false;

  return true;
  /*   return designsStore.didInvalidate;*/
};

export const fetchDesignsIfNeeded = ({
  page = 1,
  resource,
  filters,
  shouldClearPages
}) => (dispatch, getState) => {
  const userId = getState().currentUser.id;
  const teamId = getState().ui.currentTeam.id;

  if (shouldFetchDesigns({ state: getState(), page, resource })) {
    dispatch(
      fetchDesigns({
        userId,
        teamId,
        page,
        resource,
        filters,
        shouldClearPages
      })
    );
  }
};

export const fetchTeamAllDesigns = ({
  filters,
  teamId,
  resource,
  page = 1,
  shouldClearPages
}) => (dispatch, getState) => {
  const params = paramsWithFilters({}, filters, resource, getState());

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.TEAM_DESIGNS_REQUEST,
        types.TEAM_DESIGNS_REQUEST_SUCCESS,
        types.TEAM_DESIGNS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/catalogue`,
      request: {
        page: page,
        pageSize: getState().api.designs.team.templates.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY,
      extra: { shouldClearPages }
    }
  });
};

export const shouldFetchTeamDesigns = (state, page) => {
  const teamDesignApiPage = teamDesignApiPageSelector(state, page);

  if (!teamDesignApiPage) return true;

  if (teamDesignApiPage.isFetching) return false;

  const isExpired = reduxStoreExpiry.isDataExpired(
    teamDesignApiPage.lastFetched,
    5
  );

  return isExpired;
};

export const fetchTeamDesignsIfNeeded = ({ filters, page = 1 } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  if (shouldFetchTeamDesigns(getState(), page)) {
    dispatch(fetchTeamDesigns({ filters, page, teamId }));
  }
};

export const fetchTeamDesigns = ({ filters, teamId, page = 1 }) => (
  dispatch,
  getState
) => {
  const params = paramsWithFilters({ scope: "team" }, filters);

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.TEAM_TEMPLATES_DESIGNS_REQUEST,
        types.TEAM_TEMPLATES_DESIGNS_REQUEST_SUCCESS,
        types.TEAM_TEMPLATES_DESIGNS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/catalogue`,
      request: {
        page: page,
        pageSize: getState().api.designs.team.templates.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY
    }
  });
};

export const fetchEasilDesigns = ({ filters, teamId, resource, page = 1 }) => (
  dispatch,
  getState
) => {
  // need to check if wanting tutorials here so we can direct to the tutorials call
  if (filters.categoryId === "Tutorials") {
    // we are looking for tutorials, go get them
    dispatch(fetchTutorialDesignsApiCall({ filters, teamId, resource, page }));
    return;
  }
  dispatch(fetchEasilDesignsApiCall({ filters, teamId, resource, page }));
};

export const fetchEasilDesignsApiCall = ({
  filters,
  teamId,
  resource,
  page = 1
}) => (dispatch, getState) => {
  const params = paramsWithFilters(
    { scope: "subscription" },
    filters,
    resource,
    getState()
  );

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET_V2,
      types: [
        types.EASIL_TEMPLATES_DESIGNS_REQUEST,
        types.EASIL_TEMPLATES_DESIGNS_REQUEST_SUCCESS,
        types.EASIL_TEMPLATES_DESIGNS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/catalogue/subscription`,
      request: {
        page: page,
        pageSize: getState().api.designs.team.templates.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY
    }
  });
};

export const fetchTutorialDesignsApiCall = ({
  filters,
  resource,
  page = 1
}) => (dispatch, getState) => {
  const params = paramsWithFilters(
    { scope: "subscription" },
    filters,
    resource,
    getState()
  );

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.EASIL_TUTORIAL_DESIGNS_REQUEST,
        types.EASIL_TUTORIAL_DESIGNS_REQUEST_SUCCESS,
        types.EASIL_TUTORIAL_DESIGNS_REQUEST_FAILURE
      ],
      endpoint: `/tutorials`,
      request: {
        page,
        pageSize: getState().api.designs.team.templates.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY
    }
  });
};

export const shouldFetchArchivedTeamDesigns = (state, page) => {
  const teamArchivedDesignApiPage = teamArchivedDesignApiPageSelector(
    state,
    page
  );

  if (!teamArchivedDesignApiPage) return true;

  if (teamArchivedDesignApiPage.isFetching) return false;

  const isExpired = reduxStoreExpiry.isDataExpired(
    teamArchivedDesignApiPage.lastFetched,
    5
  );

  return isExpired;
};

export const fetchTeamArchivedDesignsIfNeeded = ({ filters, page = 1 }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  if (shouldFetchArchivedTeamDesigns(state, page)) {
    dispatch(fetchTeamArchivedDesigns({ filters, page, teamId }));
  }
};

export const fetchTeamArchivedDesigns = ({
  filters,
  teamId: incomingTeamId,
  page = 1
}) => (dispatch, getState) => {
  const teamId = incomingTeamId || currentTeamIdSelector(getState());

  const params = paramsWithFilters({ archived: true, scope: "team" }, filters);

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.TEAM_ARCHIVED_DESIGNS_REQUEST,
        types.TEAM_ARCHIVED_DESIGNS_REQUEST_SUCCESS,
        types.TEAM_ARCHIVED_DESIGNS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/catalogue`,
      request: {
        page: page,
        pageSize: getState().api.designs.team.archived.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY
    }
  });
};

export const refetchDesignsPages = ({
  resource,
  filters,
  shouldClearPages
}) => (dispatch, getState) => {
  const designsStore = apiDesignsResourceSelector({
    state: getState(),
    resource
  });
  const pages = shouldClearPages ? ["1"] : Object.keys(designsStore.pages);

  pages.forEach(page =>
    dispatch(
      fetchDesignsIfNeeded({ filters, page, resource, shouldClearPages })
    )
  );
};

export const fetchDesignsNextPage = ({ resource, filters }) => (
  dispatch,
  getState
) => {
  const designsStore = apiDesignsResourceSelector({
    state: getState(),
    resource
  });
  const pages = Object.keys(designsStore.pages);

  const lastPageNumber = Number(pages.sort()[pages.length - 1]);
  const lastPage = designsStore.pages[lastPageNumber];

  const nextPage =
    lastPage.ids.length < designsStore.pageSize
      ? lastPageNumber
      : pages.length + 1;

  dispatch(fetchDesignsIfNeeded({ page: nextPage, resource, filters }));
};

export const fetchDesigns = ({
  teamId,
  page = 1,
  resource,
  userId,
  filters,
  shouldClearPages
}) => (dispatch, getState) => {
  const state = getState();

  switch (resource || state.router.location.pathname) {
    case PATHS.workspaceFolders:
      return dispatch(
        fetchDraftsDesignsFromFolder({ userId, teamId, filters, page })
      );
    case PATHS.workspaceDrafts:
      return dispatch(fetchDraftsDesigns({ filters, userId, teamId, page }));

    case PATHS.workspaceArchived:
      return dispatch(fetchArchivedDesigns({ filters, userId, teamId, page }));

    case PATHS.workspaceDraftsSearch: {
      return dispatch(fetchDesignSearch({ section: "workspace", page }));
    }

    case PATHS.catalogueAll:
      return dispatch(
        fetchTeamAllDesigns({
          filters,
          userId,
          teamId,
          resource,
          page,
          shouldClearPages
        })
      );

    case PATHS.catalogueTemplates:
      return dispatch(fetchTeamDesigns({ filters, userId, teamId, page }));

    case PATHS.catalogueEasil:
      return dispatch(
        fetchEasilDesigns({ filters, userId, teamId, resource, page })
      );

    case PATHS.catalogueArchived:
      return dispatch(
        fetchTeamArchivedDesigns({ filters, userId, teamId, page })
      );

    case PATHS.catalogueFolders:
      return dispatch(
        fetchTemplateDesignsFromFolder({ userId, teamId, filters, page })
      );

    case PATHS.catalogueEasilSearch: {
      return dispatch(
        fetchDesignSearch({ scope: "easil", section: "catalogue", page })
      );
    }

    case PATHS.cataloguePurchased: {
      return dispatch(
        fetchPurchasedDesignsForCurrentUserTeam({
          filters,
          scope: "purchased",
          section: "catalogue",
          page,
          resource
        })
      );
    }

    case PATHS.cataloguePurchasedSearch: {
      return dispatch(
        fetchPurchasedDesignsSearch({
          filters,
          scope: "purchased",
          section: "catalogue",
          page,
          resource
        })
      );
    }

    case PATHS.catalogueTemplatesSearch: {
      return dispatch(
        fetchDesignSearch({
          scope: "team",
          section: "catalogue",
          page,
          filters,
          searchType: "size"
        })
      );
    }

    case PATHS.workspaceApproved:
      return dispatch(fetchDesignsApproved({ page }));

    case PATHS.workspaceApprovedSearch: {
      return dispatch(
        fetchDesignSearch({ section: "workspace", status: "APPROVED", page })
      );
    }

    case PATHS.workspaceShared: {
      return dispatch(fetchDesignsShared({ page }));
    }

    case PATHS.workspaceBrandManagerApproved: {
      return dispatch(fetchTeamDesignsApproved({ page }));
    }

    case PATHS.workspaceBrandManagerApprovedSearch: {
      return dispatch(fetchTeamDesignsApprovedSearch({ page }));
    }

    default:
      Logger.error(`Unable to Fetch, Invalid resource Type: ${resource}`);
      return;
  }
};

export const fetchTeamDesignsApproved = ({
  page = 1,
  onSuccess = noop
} = {}) => (dispatch, getState) => {
  const state = getState();

  const teamId = state.ui.currentTeam.id;

  const params = {};

  const size = getParameterByName("size", state.router.location.search);

  if (!isNil(size)) {
    params["size"] = size;
  }

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.TEAM_DESIGNS_APPROVED_REQUEST,
        types.TEAM_DESIGNS_APPROVED_REQUEST_SUCCESS,
        types.TEAM_DESIGNS_APPROVED_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/drafts/approved`,
      request: {
        page: page,
        pageSize: getState().api.designs.personal.approvals.approved.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY,
      onSuccess
    }
  });
};

export const fetchTeamDesignsApprovedSearch = ({
  page = 1,
  onSuccess = noop
} = {}) => (dispatch, getState) => {
  const state = getState();

  const teamId = state.ui.currentTeam.id;

  const params = {};

  const term = getParameterByName("term", state.router.location.search);

  if (!isNil(term)) {
    params["term"] = term;
  }

  const size = getParameterByName("size", state.router.location.search);

  if (!isNil(size)) {
    params["size"] = size;
  }

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.SEARCH_TEAM_DESIGNS_APPROVED_REQUEST,
        types.SEARCH_TEAM_DESIGNS_APPROVED_REQUEST_SUCCESS,
        types.SEARCH_TEAM_DESIGNS_APPROVED_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/drafts/approved`,
      request: {
        page: page,
        pageSize: getState().api.designs.personal.approvals.approved.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY,
      onSuccess
    }
  });
};

export const fetchDesignsApproved = ({ page = 1, onSuccess = noop } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();

  const userId = state.currentUser.id;
  const teamId = state.ui.currentTeam.id;

  const params = {};

  const size = getParameterByName("size", state.router.location.search);

  if (!isNil(size)) {
    params["size"] = size;
  }

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.DESIGNS_APPROVED_REQUEST,
        types.DESIGNS_APPROVED_REQUEST_SUCCESS,
        types.DESIGNS_APPROVED_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/drafts/approved`,
      request: {
        page: page,
        pageSize: getState().api.designs.personal.approvals.approved.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY,
      onSuccess
    }
  });
};

export const fetchDesignsSharedApproved = args => dispatch => {
  return dispatch(
    fetchDesignsShared({
      ...args,
      ...{ status: "APPROVED", typesArray: approvedSharedTypes }
    })
  );
};

export const fetchDesignsSharedPendingApproval = args => dispatch => {
  return dispatch(
    fetchDesignsShared({
      ...args,
      ...{ status: "PENDING_APPROVAL", typesArray: pendingApprovalSharedTypes }
    })
  );
};

export const fetchDesignsSharedDeclinedApproval = args => dispatch => {
  return dispatch(
    fetchDesignsShared({
      ...args,
      ...{ status: "DECLINED", typesArray: declinedApprovalSharedTypes }
    })
  );
};

export const fetchDesignsShared = ({
  page = 1,
  onSuccess = noop,
  status = "DRAFT",
  typesArray = defaultSharedTypes
} = {}) => (dispatch, getState) => {
  const state = getState();

  const userId = state.currentUser.id;
  const teamId = state.ui.currentTeam.id;

  const params = {
    status
  };

  const size = getParameterByName("size", state.router.location.search);

  if (!isNil(size)) {
    params["size"] = size;
  }

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: typesArray,
      endpoint: `/teams/${teamId}/users/${userId}/drafts/shared`,
      request: {
        page: page,
        pageSize: getState().api.designs.personal.shared.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY,
      onSuccess
    }
  });
};

export const refetchAllDraftDesignsPendingApproval = () => (
  dispatch,
  getState
) => {
  const pendingApprovalApiState = getState().api.designs.personal.approvals
    .pendingApproval;

  const pageNumbers = Object.keys(pendingApprovalApiState.pages);

  pageNumbers.forEach(page => dispatch(fetchDesignsPendingApproval({ page })));
};

export const refetchAllDraftDesignsDeclinedApproval = () => (
  dispatch,
  getState
) => {
  const pendingApprovalApiState = getState().api.designs.personal.approvals
    .pendingApproval;

  const pageNumbers = Object.keys(pendingApprovalApiState.pages);

  pageNumbers.forEach(page => dispatch(fetchDesignsDeclinedApproval({ page })));
};

export const fetchDesignsPendingApproval = ({ page = 1 } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();

  const userId = state.currentUser.id;
  const teamId = state.ui.currentTeam.id;

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: pendingApprovalTypes,
      endpoint: `/teams/${teamId}/users/${userId}/drafts/pending-approval`,
      request: {
        page
      },
      schema: schemas.DESIGNS_ARRAY
    }
  });
};

export const fetchDesignsDeclinedApproval = args => dispatch => {
  return dispatch(
    fetchDraftDesigns({
      ...args,
      ...{ status: "DECLINED", typesArray: declinedApprovalTypes }
    })
  );
};

export const fetchDraftDesigns = ({
  page = 1,
  onSuccess = noop,
  status = "DRAFT",
  typesArray = defaultSharedTypes
} = {}) => (dispatch, getState) => {
  const state = getState();

  const userId = state.currentUser.id;
  const teamId = state.ui.currentTeam.id;

  const params = {
    status
  };

  const size = getParameterByName("size", state.router.location.search);

  if (!isNil(size)) {
    params["size"] = size;
  }

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: typesArray,
      endpoint: `/teams/${teamId}/users/${userId}/drafts`,
      request: {
        page: page,
        pageSize: getState().api.designs.personal.shared.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY,
      onSuccess
    }
  });
};

export const fetchPurchasedDesignsSearch = ({
  section,
  page,
  scope,
  status
}) => (dispatch, getState) => {
  const state = getState();

  const urlParams = new URLSearchParams(state.router.location.search);
  const term = urlParams.get("term");
  let size = urlParams.get("size");
  const searchType = urlParams.get("searchType");
  if (searchType === "all") size = null;

  if (!state.api.designs.team.search.isSearching) {
    return dispatch(
      searchDesignsApiCall({
        term,
        size,
        section,
        page,
        scope,
        searchType,
        status
      })
    );
  } else {
    /* noop; */
  }
};

export const fetchDesignSearch = ({
  section,
  page,
  scope,
  status,
  filters = {},
  searchType: incomingSearchType
}) => (dispatch, getState) => {
  const state = getState();

  const urlParams = new URLSearchParams(state.router.location.search);
  const term = filters.term || urlParams.get("term");
  let size = filters.size || urlParams.get("size");
  const searchType = incomingSearchType || urlParams.get("searchType");
  if (searchType === "all") size = null;

  if (!state.api.designs.team.search.isSearching) {
    return dispatch(
      searchDesignsApiCall({
        term,
        size,
        section,
        page,
        scope,
        searchType,
        status
      })
    );
  } else {
    /* noop; */
  }
};

const cleanParams = params => {
  return omitBy(params, param => isNil(param) || param === "null");
};

export const searchApprovedDesigns = ({
  term,
  size,
  categoryId,
  highlightedId
}) => (dispatch, getState) => {
  const state = getState();

  const currentUrlParams = new URLSearchParams(state.router.location.search);
  const _categoryId = categoryId || currentUrlParams.get("categoryId");

  const urlParams = {
    context: "workspace",
    categoryId: _categoryId,
    highlightedId: highlightedId,
    size: size,
    term: term
  };

  const urlParamsCleared = cleanParams(urlParams);

  dispatch(
    push(
      `${PATHS.workspaceApprovedSearch}${objectToQueryString(urlParamsCleared)}`
    )
  );
};

export const searchBrandManagerApprovedDesigns = ({
  term,
  size,
  categoryId,
  highlightedId
}) => (dispatch, getState) => {
  const state = getState();

  const currentUrlParams = new URLSearchParams(state.router.location.search);
  const _categoryId = categoryId || currentUrlParams.get("categoryId");

  const urlParams = {
    context: "workspace",
    categoryId: _categoryId,
    highlightedId: highlightedId,
    size: size,
    term: term
  };

  const urlParamsCleared = cleanParams(urlParams);

  dispatch(
    push(
      `${PATHS.workspaceBrandManagerApprovedSearch}${objectToQueryString(
        urlParamsCleared
      )}`
    )
  );
};

export const searchWorkspaceDesigns = ({
  term,
  size,
  categoryId,
  highlightedId
}) => (dispatch, getState) => {
  const state = getState();

  const currentUrlParams = new URLSearchParams(state.router.location.search);
  const _categoryId = categoryId || currentUrlParams.get("categoryId");

  const urlParams = {
    term: term,
    context: "workspace",
    size: size,
    categoryId: _categoryId,
    highlightedId: highlightedId
  };

  const urlParamsCleared = cleanParams(urlParams);

  dispatch(
    push(
      `${PATHS.workspaceDraftsSearch}${objectToQueryString(urlParamsCleared)}`
    )
  );
};

export const searchCatalogueDesigns = ({
  term,
  size,
  categoryId,
  section,
  searchType
}) => (dispatch, getState) => {
  const state = getState();

  const currentUrlParams = new URLSearchParams(state.router.location.search);
  const _size =
    size || currentUrlParams.get("size") || getEasilCatalogueDefaultSize(state);
  const _categoryId =
    categoryId || currentUrlParams.get("categoryId") || "Popular Sizes";
  const _searchType = searchType || currentUrlParams.get("searchType") || "all";

  const urlParams = {
    term: term,
    context: "templates",
    size: _size,
    categoryId: _categoryId,
    searchType: _searchType
  };

  const [, , , subSection] = state.router.location.pathname.split("/");

  const catalogueSearchPathname = PATHS.buildCatalogueSearchPath(subSection);

  dispatch(push(`${catalogueSearchPathname}${objectToQueryString(urlParams)}`));
};

export const searchTemplatesCatalogueDesigns = ({
  term,
  size,
  categoryId,
  section,
  searchType = "size",
  highlightedId
}) => (dispatch, getState) => {
  const state = getState();

  const currentUrlParams = new URLSearchParams(state.router.location.search);

  const _categoryId =
    categoryId || currentUrlParams.get("categoryId") || "Popular Sizes";

  const urlParams = {
    term: term,
    context: "templates",
    categoryId: _categoryId,
    searchType,
    highlightedId:
      highlightedId || currentUrlParams.get("highlightedId") || _categoryId
  };

  if (size) {
    urlParams.size = size;
  }

  const [, , , subSection] = state.router.location.pathname.split("/");

  const catalogueSearchPathname = PATHS.buildCatalogueSearchPath(subSection);

  dispatch(push(`${catalogueSearchPathname}${objectToQueryString(urlParams)}`));
};

export const searchPurchasedCatalogueDesigns = ({
  term,
  size,
  categoryId,
  section,
  searchType = "all",
  highlightedId
}) => (dispatch, getState) => {
  const state = getState();

  const currentUrlParams = new URLSearchParams(state.router.location.search);

  const _categoryId = categoryId || currentUrlParams.get("categoryId") || "All";

  const urlParams = {
    term: term,
    context: "templates",
    categoryId: _categoryId,
    searchType,
    highlightedId: highlightedId || _categoryId
  };

  if (size) {
    urlParams.size = size;
  }

  const [, , , subSection] = state.router.location.pathname.split("/");

  const catalogueSearchPathname = PATHS.buildCatalogueSearchPath(subSection);

  dispatch(push(`${catalogueSearchPathname}${objectToQueryString(urlParams)}`));
};

export const searchDesigns = ({
  term,
  size,
  categoryId,
  highlightedId,
  section,
  searchType
}) => (dispatch, getState) => {
  if (!(term || size || highlightedId) || !section) {
    return;
  }

  const state = getState();

  switch (true) {
    // Nested routes (ie. workspace/approved) need to come before their parent (ie workspace)
    case state.router.location.pathname.includes(
      PATHS.workspaceBrandManagerApproved
    ): {
      dispatch(
        searchBrandManagerApprovedDesigns({
          term,
          size,
          categoryId,
          highlightedId,
          section
        })
      );
      break;
    }

    case state.router.location.pathname.includes(PATHS.workspaceApproved): {
      dispatch(
        searchApprovedDesigns({
          term,
          size,
          categoryId,
          highlightedId
        })
      );
      break;
    }

    case state.router.location.pathname.includes(PATHS.workspace): {
      dispatch(
        searchWorkspaceDesigns({
          term,
          size,
          categoryId,
          highlightedId,
          section
        })
      );
      break;
    }

    case [PATHS.catalogueTemplatesSearch, PATHS.catalogueTemplates].includes(
      state.router.location.pathname
    ): {
      dispatch(
        searchTemplatesCatalogueDesigns({
          term,
          size,
          categoryId,
          section,
          searchType,
          highlightedId
        })
      );
      break;
    }

    case [PATHS.cataloguePurchasedSearch, PATHS.cataloguePurchased].includes(
      state.router.location.pathname
    ): {
      dispatch(
        searchPurchasedCatalogueDesigns({
          term,
          size,
          categoryId,
          highlightedId: highlightedId || categoryId,
          section,
          searchType: "size"
        })
      );
      break;
    }

    case state.router.location.pathname.includes(PATHS.catalogue): {
      dispatch(
        searchCatalogueDesigns({ term, size, categoryId, section, searchType })
      );
      break;
    }

    default:
      Logger.error("Invalid Design Search");
  }
};

export const searchDesignsApiCall = ({
  term,
  size,
  section,
  status,
  page = 1,
  scope,
  searchType = "size",
  onSuccess = noop
}) => (dispatch, getState) => {
  const sectionToContextMap = {
    workspace: "workspace",
    catalogue: "templates",
    purchased: "templates"
  };

  const context = sectionToContextMap[section];

  let params = {
    context,
    term,
    size,
    status
  };

  if (scope) {
    params.scope = scope;
  }

  params = cleanParams(params);

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.DESIGNS_SEARCH_REQUEST,
        types.DESIGNS_SEARCH_REQUEST_SUCCESS,
        types.DESIGNS_SEARCH_REQUEST_FAILURE
      ],
      endpoint: `/designs`,
      request: {
        page: page,
        pageSize: getState().api.designs.personal.search.pageSize,
        params: params,
        extra: {
          searchType: searchType || "size"
        }
      },
      schema: schemas.DESIGNS_ARRAY,
      onSuccess
    }
  });
};

export const shareDesignApiCall = ({
  usersId,
  collectionId,
  onSuccess = noop
}) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;

  const body = usersId.map(userId => ({
    teamId,
    collectionId,
    userId
  }));

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.DESIGN_SHARE_REQUEST,
        types.DESIGN_SHARE_REQUEST_SUCCESS,
        types.DESIGN_SHARE_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/collections/${collectionId}/allocations`,
      request: {
        body
      },
      schema: schemas.NONE,
      onSuccess
    }
  });
};

export const shareDesignsApiCall = ({
  collectionIds,
  userIds,
  onSuccess = noop
}) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;

  const body = collectionIds.map(collectionId => ({
    teamId,
    collectionId,
    userIds
  }));

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.DESIGNS_SHARE_REQUEST,
        types.DESIGNS_SHARE_REQUEST_SUCCESS,
        types.DESIGNS_SHARE_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/collections/allocations`,
      request: {
        body
      },
      schema: schemas.NONE,
      onSuccess
    }
  });
};

export const unshareDesignApiCall = ({
  teamId,
  collectionId,
  allocationId,
  onSuccess = noop,
  isUserRemovingSelf
}) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ASSET,
      types: [
        types.DESIGN_UNSHARE_REQUEST,
        types.DESIGN_UNSHARE_REQUEST_SUCCESS,
        types.DESIGN_UNSHARE_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/collections/${collectionId}/allocations/${allocationId}`,
      request: {
        extra: {
          allocationId,
          isUserRemovingSelf,
          collectionId
        }
      },
      schema: schemas.NONE,
      onSuccess: onSuccess
    }
  });
};

export const unapproveDesignApiCall = ({
  designId,
  comment = null,
  onSuccess
}) => (dispatch, getState) => {
  dispatch(
    designActionApiCall({
      designId,
      actionsType: [
        types.DESIGN_UNAPPROVE_REQUEST,
        types.DESIGN_UNAPPROVE_REQUEST_SUCCESS,
        types.DESIGN_UNAPPROVE_REQUEST_FAILURE
      ],
      comment,
      action: "UNAPPROVED",
      onSuccess
    })
  );
};

export const declineDesignApiCall = ({
  designId,
  comment = null,
  onSuccess
}) => (dispatch, getState) => {
  dispatch(
    designActionApiCall({
      designId,
      actionsType: [
        types.DESIGN_DECLINE_REQUEST,
        types.DESIGN_DECLINE_REQUEST_SUCCESS,
        types.DESIGN_DECLINE_REQUEST_FAILURE
      ],
      comment,
      action: "DECLINED",
      onSuccess
    })
  );
};

export const approveDesignApiCall = ({
  designId,
  comment = null,
  onSuccess
}) => (dispatch, getState) => {
  dispatch(
    designActionApiCall({
      designId,
      actionsType: [
        types.DESIGN_APPROVE_REQUEST,
        types.DESIGN_APPROVE_REQUEST_SUCCESS,
        types.DESIGN_APPROVE_REQUEST_FAILURE
      ],
      comment,
      action: "APPROVED",
      onSuccess
    })
  );
};

export const requestDesignApprovalApiCall = ({
  designId,
  comment = null,
  onSuccess
}) => (dispatch, getState) => {
  dispatch(
    designActionApiCall({
      designId,
      actionsType: [
        types.DESIGN_REQUEST_APPROVAL_REQUEST,
        types.DESIGN_REQUEST_APPROVAL_REQUEST_SUCCESS,
        types.DESIGN_REQUEST_APPROVAL_REQUEST_FAILURE
      ],
      comment,
      action: "REQUESTED",
      onSuccess
    })
  );
};

export const designActionApiCall = ({
  designId,
  actionsType,
  action,
  comment = null,
  onSuccess = noop
}) => (dispatch, getState) => {
  const state = getState();
  const userId = currentUserSelector(state).id;
  const teamId = currentTeamIdSelector(state);

  const body = {
    comment,
    action,
    designId,
    userId,
    teamId,
    sendNotifications: !isEditorInIframe()
  };

  if (body.comment === "") {
    body.comment = null;
  }

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: actionsType,
      endpoint: `/teams/${teamId}/drafts/${designId}/approval-action`,
      request: {
        body
      },
      schema: schemas.DESIGN_ACTION,
      onSuccess
    }
  });
};

export const cancelDesignApprovalRequestApiCall = ({
  designId,
  comment = null,
  onSuccess
}) => dispatch => {
  dispatch(
    designActionApiCall({
      designId,
      actionsType: [
        types.DESIGN_CANCEL_APPROVAL_REQUEST,
        types.DESIGN_CANCEL_APPROVAL_REQUEST_SUCCESS,
        types.DESIGN_CANCEL_APPROVAL_REQUEST_FAILURE
      ],
      comment,
      action: "CANCELLED",
      onSuccess
    })
  );
};

export const revertToDraft = ({ designId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  dispatch({
    [CALL_API]: {
      method: "PATCH",
      service: SERVICES.ASSET,
      types: [
        types.DESIGN_REVERT_APPROVAL_REQUEST,
        types.DESIGN_REVERT_APPROVAL_REQUEST_SUCCESS,
        types.DESIGN_REVERT_APPROVAL_REQUEST_FAILURE
      ],
      endpoint: `/designs`,
      request: {
        body: [
          {
            id: designId,
            status: "DRAFT"
          }
        ]
      },
      schema: schemas.DESIGNS_STATUS_UPDATE_ARRAY,
      onSuccess
    }
  });
};

export const fetchPurchasedDesignsForCurrentUserTeam = ({
  filters,
  page = 1,
  resolve,
  reject
} = {}) => (dispatch, getState) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = currentUserIdSelector(state);

  return dispatch(
    fetchPurchasedDesigns({ filters, page, teamId, userId, resolve, reject })
  );
};

export const fetchPurchasedDesigns = ({
  filters,
  teamId,
  userId,
  page = 1,
  resolve = noop,
  reject = noop
}) => (dispatch, getState) => {
  const params = paramsWithFilters({ scope: "team" }, filters);

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.PURCHASED_TEMPLATES_DESIGNS_REQUEST,
        types.PURCHASED_TEMPLATES_DESIGNS_REQUEST_SUCCESS,
        types.PURCHASED_TEMPLATES_DESIGNS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/purchased/templates`,
      request: {
        page: page,
        pageSize: getState().api.designs.purchased.templates.pageSize,
        params
      },
      schema: schemas.DESIGNS_ARRAY,
      onSuccess: resolve,
      onFailure: reject
    }
  });
};

export const asyncFetchPurchasedDesignsForCurrentUserTeam = asyncAction(
  fetchPurchasedDesignsForCurrentUserTeam
);
