import style from "./style.module.css";

import { NoComponent } from "views/components";
import FileMagnifierIcon from "views/components/icons/FileMagnifierIcon";
import ImageFileIcon from "views/components/icons/ImageFileIcon";
import EmptyImagesIcon from "views/components/icons/EmptyImagesIcon";
import PATHS from "routes/paths";

export const getStateValues = changeQuery => ({
  // empty component option for unset input values
  empty: {
    title: "",
    titleStyle: { display: "none" },
    content: "",
    contentStyle: { display: "none" },
    showButton: false,
    icon: {
      IconComponent: NoComponent,
      iconStyle: { display: "none" }
    }
  },
  folder: {
    title: "This folder contains no designs",
    content:
      "Start by choosing a template from Easil’s extensive range\n of ready-made templates, or create your own.",
    showButton: true
  },
  archivedWorkspaceWithEasil: {
    title: "You have no archived designs",
    content:
      "Start by choosing a template from Easil’s extensive range\n of ready-made templates, or create your own.",
    showButton: true,
    button: {
      buttonLocation: PATHS.catalogueEasil
    }
  },
  archivedWorkspaceWithoutEasil: {
    title: "You have no archived designs",
    content: "Start by choosing a Team Template, or create your own.",
    showButton: false
  },
  archivedTemplatesWithEasil: {
    title: "You have no archived designs",
    content:
      "Start by choosing a template from Easil’s extensive range\n of ready-made templates, or create your own.",
    showButton: true,
    button: {
      buttonLocation: PATHS.catalogueEasil
    }
  },
  archivedTemplatesWithoutEasil: {
    title: "You have no archived designs",
    content: "Start by choosing a Team Template, or create your own.",
    showButton: false
  },
  catalogueFolder: {
    content: "",
    showButton: false,
    title: "This design folder is empty!"
  },
  search: ({ term, changeQuery }) => ({
    title: `No search results could be found matching '${term}'`,
    titleStyle: { fontSize: "20px", lineHeight: "1.2", marginBottom: "24px" },
    content:
      "Try searching for another size by clicking on the size\nthumbnails above, or clearing your search.",
    contentStyle: { fontSize: "16px", color: "#57595d" },
    showButton: true,
    button: {
      buttonText: "Clear search",
      buttonTheme: "blueSolid",
      buttonOnClick: () => {
        changeQuery({
          dropTerms: ["term", "searchType"],
          pathname: window.location.pathname.replace("/search", "")
        });
      }
    },
    icon: {
      IconComponent: FileMagnifierIcon,
      iconStyle: { width: "46px", height: "56px", marginBottom: "24px" }
    }
  }),
  images: buttonOnClick => ({
    title: "This is your image storage area",
    titleStyle: { fontSize: "20px", lineHeight: "1.2", marginBottom: "16px" },
    content: "Once you upload your images, they'll appear here",
    contentStyle: { fontSize: "16px", color: "#57595d" },
    showButton: true,
    button: {
      buttonText: "Upload images",
      buttonTheme: "blueSolid",
      buttonOnClick: buttonOnClick
    },
    icon: {
      IconComponent: ImageFileIcon,
      iconStyle: { width: "46px", height: "56px", marginBottom: "22px" }
    },
    containerClassName: style.imagesEmptyContainer
  }),
  imageSearch: (buttonOnClick, imageSearchTerm) => ({
    title: `No images could be found matching the term '${imageSearchTerm}'`,
    titleStyle: { fontSize: "20px", lineHeight: "1.2", marginBottom: "16px" },
    content: "Please check your search term and try again",
    contentStyle: { fontSize: "16px", color: "#57595d" },
    showButton: true,
    button: {
      buttonText: "Clear Search",
      buttonTheme: "blueSolid",
      buttonOnClick: buttonOnClick
    },
    icon: {
      IconComponent: ImageFileIcon,
      iconStyle: { width: "46px", height: "56px", marginBottom: "22px" }
    },
    containerClassName: style.imagesEmptyContainer
  }),
  imagesFolder: buttonOnClick => ({
    title: "This folder contains no images",
    titleStyle: { fontSize: "20px", lineHeight: "1.2", marginBottom: "16px" },
    content: "Start by uploading images. Once you do, you'll see it here.",
    contentStyle: { fontSize: "16px", color: "#57595d" },
    showButton: true,
    button: {
      buttonText: "Upload images",
      buttonTheme: "blueSolid",
      buttonOnClick: buttonOnClick
    },
    icon: {
      IconComponent: ImageFileIcon,
      iconStyle: { width: "46px", height: "56px", marginBottom: "22px" }
    },
    containerClassName: style.imagesEmptyContainer
  }),
  smartImageEmpty: () => ({
    title: "This folder contains no images",
    titleStyle: { fontSize: "20px", lineHeight: "1.2", marginBottom: "16px" },
    showButton: false,
    icon: {
      IconComponent: ImageFileIcon,
      iconStyle: { width: "46px", height: "56px", marginBottom: "22px" }
    },
    containerClassName: style.smartImageEmptyContainer
  }),
  animations: buttonOnClick => ({
    title: "This is your Video storage area",
    titleStyle: { fontSize: "20px", lineHeight: "1.2", marginBottom: "16px" },
    content: "Once you upload your Videos, they'll appear here",
    contentStyle: { fontSize: "16px", color: "#57595d" },
    showButton: true,
    button: {
      buttonText: "Upload Videos",
      buttonTheme: "blueSolid",
      buttonClassName: style.animationsEmptyButton,
      buttonOnClick: buttonOnClick
    },
    icon: {
      IconComponent: ImageFileIcon,
      iconStyle: { width: "46px", height: "56px", marginBottom: "22px" }
    },
    containerClassName: style.imagesEmptyContainer
  }),
  animationsSearch: (buttonOnClick, imageSearchTerm) => ({
    title: `No Videos could be found matching the term '${imageSearchTerm}'`,
    titleStyle: { fontSize: "20px", lineHeight: "1.2", marginBottom: "16px" },
    content: "Try searching for another Video, or clearing your search.",
    contentStyle: { fontSize: "16px", color: "#57595d" },
    showButton: true,
    button: {
      buttonText: "Clear Search",
      buttonTheme: "blueSolid",
      buttonClassName: style.animationsEmptyButton,
      buttonOnClick: buttonOnClick
    },
    icon: {
      IconComponent: ImageFileIcon,
      iconStyle: { width: "46px", height: "56px", marginBottom: "22px" }
    },
    containerClassName: style.imagesEmptyContainer
  }),
  animationsFolder: buttonOnClick => ({
    title: "This folder contains no Videos",
    titleStyle: { fontSize: "20px", lineHeight: "1.2", marginBottom: "16px" },
    content: "Start by uploading Videos. Once you do, you'll see it here.",
    contentStyle: { fontSize: "16px", color: "#57595d" },
    showButton: true,
    button: {
      buttonText: "Upload Videos",
      buttonTheme: "blueSolid",
      buttonClassName: style.animationsEmptyButton,
      buttonOnClick: buttonOnClick
    },
    icon: {
      IconComponent: ImageFileIcon,
      iconStyle: { width: "46px", height: "56px", marginBottom: "22px" }
    },
    containerClassName: style.imagesEmptyContainer
  }),
  brandAnimations: buttonOnClick => ({
    title: "Upload short videos for you and your team to use in designs!",
    titleStyle: { fontSize: "20px", lineHeight: "1.2", marginBottom: "16px" },
    content:
      "MP4 videos up to 60 seconds each can be added here.\nUse the 'Upload Videos' button to select your files, or simply drag them from your computer to this space.",
    contentStyle: { fontSize: "16px", color: "#57595d" },
    showButton: true,
    button: {
      buttonText: "Upload Videos",
      buttonTheme: "blueSolid",
      buttonClassName: style.animationsEmptyButton,
      buttonOnClick: buttonOnClick
    },
    icon: {
      IconComponent: EmptyImagesIcon,
      iconStyle: { width: "46px", height: "56px", marginBottom: "22px" }
    },
    containerClassName: style.brandAnimationsEmptyContainer
  }),
  brandAnimationsSearch: imageSearchTerm => ({
    title: `No Videos could be found matching the term '${imageSearchTerm}'`,
    titleStyle: { fontSize: "20px", lineHeight: "1.2", marginBottom: "16px" },
    content: "Try searching for another Video, or clearing your search.",
    contentStyle: { fontSize: "16px", color: "#57595d" },
    showButton: false,
    icon: {
      IconComponent: ImageFileIcon,
      iconStyle: { width: "46px", height: "56px", marginBottom: "22px" }
    },
    containerClassName: style.imagesEmptyContainer
  }),
  emptyTeamTemplatesWithoutEasil: {
    title: "Your Team Templates are coming soon!",
    content:
      "Your Team's Designer is working on some awesome templates\nexclusively for your Team's use. Check back here soon\n(or give your Designer a friendly nudge..).",
    showButton: false
  },
  emptyWorkSpace: {
    title: "Welcome to your Workspace",
    content:
      "It's your own private space to create, edit and store your designs. Click on\nany Template, and your personal copy will be saved here. Want to share\nyour design with your Team? Convert your designs to Templates, and your Team\nwill be able to make their own copies to work on.",
    showButton: true
  },
  emptyTeamTemplatesWithEasil: {
    title: "Your Team doesn't have any Templates yet!",
    content:
      "Start by choosing a template from Easil’s extensive range\n of ready-made templates, or create your own.",
    showButton: true
  },
  emptyTeamTemplatesDesignsInFolder: {
    title: "Your Team Templates are filed in Folders",
    content:
      "Click on the folders above to view the templates contained in each.",
    showButton: false
  },
  drafts: {
    title: "You have no designs",
    content:
      "Start by choosing a template from Easil’s extensive range\n of ready-made templates, or create your own.",
    showButton: true
  },
  workspaceFolder: {
    content:
      "File your designs from 'My Designs'\n" +
      "by clicking on the black arrow icon, and\n" +
      "'Move to folder'.",
    showButton: false,
    title: "This design folder is empty!"
  }
});

export default getStateValues;
