import { schema, normalize } from "normalizr";
import { IMAGE_ORIGINS } from "lib/constants";

const random = () => Math.floor(Math.random() * (999999 - 1 + 1)) + 1;

const usersSchema = new schema.Entity("users");

const approvalNotificationSchema = new schema.Entity(
  "approvalNotification",
  {},
  {
    idAttribute: entity => {
      return `${entity.teamId}_${entity.type}`;
    },
    processStrategy: (entity, _parent, _value) => {
      return {
        ...entity,
        id: `${entity.teamId}_${entity.type}`
      };
    }
  }
);
const approvalNotificationArraySchema = new schema.Array(
  approvalNotificationSchema
);

const allocationsSchema = new schema.Entity("allocations");
const pendingUsersSchema = new schema.Entity("pendingUsers");

const designActionSchema = new schema.Entity("designAction");
const designActionArraySchema = new schema.Array(designActionSchema);

const searchSuggestionsSchema = new schema.Entity(
  "searchSuggestions",
  {},
  {
    idAttribute: () => "suggestions"
  }
);

const creditsSchema = new schema.Entity(
  "credits",
  {},
  {
    idAttribute: () => "singleEntry"
  }
);

const designsSchema = new schema.Entity("designs");
const designsDataSchema = new schema.Entity("designsData");
const designCreationSchema = new schema.Entity(
  "designsCreation",
  {
    design: designsSchema,
    designData: designsDataSchema
  },
  {
    idAttribute: response => response.design.id
  }
);

const teamsSchema = new schema.Entity("teams");

const teamSettingsSchema = new schema.Entity(
  "teamSettings",
  {},
  {
    idAttribute: "teamId"
  }
);

const organisationsSchema = new schema.Entity("organisations");

const invitationsSchema = new schema.Entity("invitations");

const none = new schema.Entity("none");

const personalOrganisationCreation = new schema.Entity(
  "personalOrganisationCreation",
  {
    organisation: organisationsSchema,
    user: usersSchema
  }
);

const usersInvitedSchema = new schema.Entity("users", undefined, {
  idAttribute: response => response.body.id,
  processStrategy: (entity, _parent, _value) => {
    if (entity.status === 201) return entity.body;

    entity.body.id = `temp-${random()}`;

    return entity.body;
  }
});

const designsStatusUpdateSchema = new schema.Entity(
  "designStatusUpdate",
  {
    body: designsSchema
  },
  {
    idAttribute: response => {
      return response.body.id;
    },
    processStrategy: (entity, _parent, _value) => {
      return entity.body;
    }
  }
);

const designMultiStatusUpdateSchema = new schema.Entity(
  "design",
  {},
  {
    idAttribute: "id"
  }
);

const designMultiStatusUpdateArraySchema = new schema.Array(
  designMultiStatusUpdateSchema
);

const designsStatusUpdateArraySchema = new schema.Array(
  designsStatusUpdateSchema
);

const invoiceSchema = new schema.Entity("invoices");

const invoicesSchema = new schema.Array(invoiceSchema);

const brandKit = new schema.Entity("brandKit");

const colorGroup = new schema.Entity(
  "colorGroup",
  {},
  {
    processStrategy: (entity, _parent, _value) => {
      const baseId = random();
      const colorsEntities = entity.colors.reduce((acc, color) => {
        let id = baseId;
        const previousItem = Object.values(acc).pop();

        if (previousItem) {
          id = previousItem.id + 1;
        }

        acc[id] = {
          id,
          color
        };
        return acc;
      }, {});

      entity.colors = colorsEntities;

      return entity;
    }
  }
);

const brandKitColors = new schema.Array(colorGroup);

const brandKitFont = new schema.Entity(
  "brandKitFont",
  {},
  {
    idAttribute: "id",
    processStrategy: (entity, _parent, _value) => {
      return entity;
    }
  }
);

const brandKitFontUpload = new schema.Entity(
  "brandKitFontUpload",
  {},
  {
    idAttribute: "fontId",
    processStrategy: (entity, _parent, _value) => {
      return entity;
    }
  }
);

const brandKitFonts = new schema.Array(brandKitFont);

const brandKitImage = new schema.Entity(
  "brandKitImage",
  {},
  {
    idAttribute: "mediaId",
    processStrategy: (entity, _parent, _value) => ({
      ...entity,
      title:
        (entity.media || {}).name ||
        entity.name ||
        entity.url.substring(entity.url.lastIndexOf("/") + 1),
      src: entity.previewUrl,
      originalSrc: entity.url,
      previewSrc: entity.previewUrl,
      thumbSrc: entity.thumbnailUrl,
      format: entity.url.split(".").pop(),
      // TODO: Work out actual dimensions.
      dimensions: {
        width: 1200,
        height: 3000
      }
    })
  }
);

const brandKitImages = new schema.Array(brandKitImage);

const userFontUpload = new schema.Entity(
  "userFontUpload",
  {},
  {
    idAttribute: "fontId",
    processStrategy: (entity, _parent, _value) => {
      return entity;
    }
  }
);

const publicFont = new schema.Entity(
  "publicFont",
  {},
  {
    idAttribute: "id",
    processStrategy: (entity, _parent, _value) => {
      return {
        fontId: entity.id,
        ...entity
      };
    }
  }
);

const publicFonts = new schema.Array(publicFont);

const userTeamImage = new schema.Entity(
  "userTeamImage",
  {},
  {
    idAttribute: "mediaId",
    processStrategy: (entity, _parent, _value) => {
      entity.origin = IMAGE_ORIGINS.userTeamImage;
      return entity;
    }
  }
);
const userTeamImages = new schema.Array(userTeamImage);

const userTeamSmartImage = new schema.Entity(
  "userTeamSmartImage",
  {},
  {
    idAttribute: "mediaId",
    processStrategy: (entity, _parent, _value) => {
      return entity;
    }
  }
);
const userTeamSmartImages = new schema.Array(userTeamSmartImage);

const userTeamAnimation = new schema.Entity(
  "userTeamAnimation",
  {},
  {
    idAttribute: "mediaId",
    processStrategy: (entity, _parent, _value) => {
      entity.origin = IMAGE_ORIGINS.userTeamAnimation;
      return entity;
    }
  }
);
const userTeamAnimations = new schema.Array(userTeamAnimation);

const UserTeamSearchedAnimation = new schema.Entity(
  "userTeamSearchedAnimation",
  {},
  {
    processStrategy: (entity, _parent, _value) => {
      entity.mediaId = entity.id;
      entity.origin = IMAGE_ORIGINS.userTeamAnimation;
      return entity;
    }
  }
);

const searchedAnimationsSchema = new schema.Array(UserTeamSearchedAnimation);

const StockSearchedAnimation = new schema.Entity(
  "stockSearchedAnimation",
  {},
  {
    processStrategy: (entity, _parent, _value) => {
      entity.mediaId = entity.id;
      entity.origin = IMAGE_ORIGINS.stockAnimation;
      return entity;
    }
  }
);

const searchedStockAnimationsSchema = new schema.Array(StockSearchedAnimation);

const teamAnimation = new schema.Entity(
  "teamAnimation",
  {},
  {
    idAttribute: "mediaId",
    processStrategy: (entity, _parent, _value) => {
      entity.origin = IMAGE_ORIGINS.teamAnimation;
      return entity;
    }
  }
);
const teamAnimations = new schema.Array(teamAnimation);

const stockAnimation = new schema.Entity(
  "stockAnimation",
  {},
  {
    idAttribute: "id",
    processStrategy: (entity, _parent, _value) => {
      entity.origin = IMAGE_ORIGINS.stockAnimation;
      return entity;
    }
  }
);
const stockAnimations = new schema.Array(stockAnimation);

const teamSearchedAnimation = new schema.Entity(
  "teamSearchedAnimation",
  {},
  {
    processStrategy: (entity, _parent, _value) => {
      entity.mediaId = entity.id;
      entity.origin = IMAGE_ORIGINS.teamAnimation;
      return entity;
    }
  }
);

const searchedTeamAnimationsSchema = new schema.Array(teamSearchedAnimation);

const teamImage = new schema.Entity(
  "teamImage",
  {},
  {
    idAttribute: "mediaId",
    processStrategy: (entity, _parent, _value) => {
      entity.origin = IMAGE_ORIGINS.teamImage;
      return entity;
    }
  }
);
const teamImages = new schema.Array(teamImage);

const teamSmartImage = new schema.Entity(
  "teamSmartImage",
  {},
  {
    idAttribute: "mediaId",
    processStrategy: (entity, _parent, _value) => {
      return entity;
    }
  }
);
const teamSmartImages = new schema.Array(teamSmartImage);

const teamLogo = new schema.Entity(
  "teamLogo",
  {},
  {
    idAttribute: "mediaId",
    processStrategy: (entity, _parent, _value) => {
      entity.origin = IMAGE_ORIGINS.teamLogo;
      return entity;
    }
  }
);
const teamLogos = new schema.Array(teamLogo);

const userTeamFont = new schema.Entity(
  "userTeamFont",
  {},
  {
    idAttribute: "fontId",
    processStrategy: (entity, _parent, _value) => {
      return {
        id: entity.fontId,
        ...entity
      };
    }
  }
);
const userTeamFonts = new schema.Array(userTeamFont);

const subscription = new schema.Entity(
  "subscription",
  {},
  {
    idAttribute: "planId"
  }
);
const subscriptions = new schema.Array(subscription);

const subscriptionPlan = new schema.Entity(
  "subscriptionPlan",
  {},
  {
    processStrategy: (entity, _parent, _value) => {
      entity.description = "per user, per month.";

      return entity;
    }
  }
);
const subscriptionPlans = new schema.Array(subscriptionPlan);

const card = new schema.Entity(
  "card",
  {},
  {
    idAttribute: "stripeCardId"
  }
);
const cards = new schema.Array(card);
const catalogueSizeSchema = new schema.Entity(
  "catalogueSize",
  {},
  {
    idAttribute: "templateCode",
    processStrategy: (entity, _parent, _value) => {
      return entity;
    }
  }
);
const catalogueSizesSchema = new schema.Array(catalogueSizeSchema);
const workspaceSizeSchema = new schema.Entity(
  "workspaceSize",
  {},
  {
    idAttribute: "templateCode",
    processStrategy: (entity, _parent, _value) => {
      return entity;
    }
  }
);
const workspaceSizesSchema = new schema.Array(workspaceSizeSchema);

const animatedElementsSchema = new schema.Entity(
  "animatedElements",
  {},
  {
    idAttribute: () => 0,
    processStrategy: value => {
      const values = Object.values(value);
      return values.reduce(
        (animatedElements, dataPoint) => ({
          ...animatedElements,
          [dataPoint.animationDataKey]: dataPoint
        }),
        {}
      );
    }
  }
);

const designDataSchema = new schema.Entity(
  "designData",
  {},
  {
    processStrategy: value => {
      return {
        ...value,
        data: [
          ...value.data.map(page => {
            const animatedElementsForPage = normalize(
              page.animatedElements || {},
              animatedElementsSchema
            );
            return {
              ...page,
              animatedElements:
                animatedElementsForPage.entities.animatedElements[0]
            };
          })
        ]
      };
    }
  }
);
const designResizeSchema = new schema.Entity(
  "designResize",
  {
    design: designsSchema,
    designData: designsDataSchema
  },
  {
    idAttribute: response => response.design.id
  }
);

const downloadSchema = new schema.Entity(
  "download",
  {},
  {
    idAttribute: "renderId"
  }
);
const downloadsSchema = new schema.Array(downloadSchema);

const userTeamFavoriteSchema = new schema.Entity(
  "userTeamFavorite",
  {},
  {
    idAttribute: "mediaId",
    processStrategy: (entity, _parent, _value) => {
      return {
        createdAt: entity.createdAt,
        updatedAt: entity.updatedAt,
        id: entity.mediaId,
        mediaId: entity.mediaId,
        teamId: entity.teamId,
        userId: entity.userId,
        src: entity.previewUrl,
        originalSrc: entity.url,
        previewSrc: entity.previewUrl,
        thumbSrc: entity.thumbnailUrl,
        type: entity.url.split(".").pop() === "svg" ? "vector" : "image",
        favorited: true
      };
    }
  }
);
const userTeamFavoritesSchema = new schema.Array(userTeamFavoriteSchema);

const collectionDesignSchema = new schema.Entity("collectionDesign");
const collectionDesignsSchema = new schema.Array(collectionDesignSchema);

const collectionSchema = new schema.Entity(
  "collection",
  {},
  {
    processStrategy: (entity, _parent, _value) => {
      entity.ids = entity.designs.map(design => design.id);
      return entity;
    }
  }
);
const collectionsSchema = new schema.Array(collectionSchema);

const folderSchema = new schema.Entity("folder");
const foldersSchema = new schema.Array(folderSchema);

const designFolderSchema = new schema.Entity(
  "designFolder",
  {},
  {
    idAttribute: "designId"
  }
);

const designFoldersSchema = new schema.Array(designFolderSchema);

const designFolderAddSchema = new schema.Entity(
  "designFolder",
  {},
  {
    processStrategy: (entity, _parent, _value) => {
      entity.id = entity.folderId;
      return entity;
    },
    idAttribute: "designId"
  }
);

const designFoldersAddSchema = new schema.Array(designFolderAddSchema);

const tagSchema = new schema.Entity("tag");
const tagsSchema = new schema.Array(tagSchema);

const designTagSchema = new schema.Entity("designTag");
const designTagsSchema = new schema.Array(designTagSchema);

const graphicSchema = new schema.Entity("graphic");
const graphicsSchema = new schema.Array(graphicSchema);

const shippingRateSchema = new schema.Entity("shippingRate");
const shippingRatesSchema = new schema.Array(shippingRateSchema);

const billingDetailsSchema = new schema.Entity(
  "billingDetails",
  {},
  {
    idAttribute: "orderId"
  }
);

const shippingDetailsSchema = new schema.Entity(
  "shippingDetails",
  {},
  {
    idAttribute: "orderId"
  }
);

const orderDesignSchema = new schema.Entity(
  "orderDesign",
  {},
  {
    idAttribute: "id"
  }
);

const orderCollectionSchema = new schema.Entity(
  "orderCollection",
  {},
  {
    idAttribute: "collectionId"
  }
);

const orderDesignPrintItemSchema = new schema.Entity(
  "orderDesignPrintItem",
  {},
  {
    idAttribute: "id"
  }
);

const orderSchema = new schema.Entity(
  "order",
  {},
  {
    idAttribute: response => response.order.id
  }
);

const orderV2Schema = new schema.Entity(
  "order",
  {},
  {
    idAttribute: response => response.id
  }
);

const couponOrderSchema = new schema.Entity(
  "order",
  {},
  {
    idAttribute: "id"
  }
);

const ordersEntitySchema = new schema.Entity("orders");
const ordersSchema = new schema.Array(ordersEntitySchema);

const orderTransactionSchema = new schema.Entity("orderTransaction");
const orderTransactionsSchema = new schema.Array(orderTransactionSchema);

const printPricingSchema = new schema.Entity("printPricing");
const printPricingsSchema = new schema.Array(printPricingSchema);

const teamBillingSchema = new schema.Entity(
  "teamBilling",
  {},
  {
    idAttribute: "teamId"
  }
);

const UserTeamSearchedImage = new schema.Entity(
  "userTeamSearchedImage",
  {},
  {
    processStrategy: (entity, _parent, _value) => {
      entity.mediaId = entity.id;
      entity.origin = IMAGE_ORIGINS.userTeamImage;
      return entity;
    }
  }
);

const searchedImagesSchema = new schema.Array(UserTeamSearchedImage);

const BrandSearchedImage = new schema.Entity(
  "brandSearchedImage",
  {},
  {
    processStrategy: (entity, _parent, _value) => {
      entity.mediaId = entity.id;
      entity.origin = IMAGE_ORIGINS.teamImage;
      return entity;
    }
  }
);

const searchedBrandImagesSchema = new schema.Array(BrandSearchedImage);

const BrandSearchedLogo = new schema.Entity(
  "brandSearchedLogo",
  {},
  {
    processStrategy: (entity, _parent, _value) => {
      entity.mediaId = entity.id;
      entity.origin = IMAGE_ORIGINS.teamLogo;
      return entity;
    }
  }
);

const searchedBrandLogosSchema = new schema.Array(BrandSearchedLogo);

const stockSearchedImageSchema = new schema.Entity(
  "stockSearchedImage",
  {},
  {
    processStrategy: (entity, _parent, _value) => {
      entity.mediaId = entity.id;
      entity.origin = IMAGE_ORIGINS.stock;
      return entity;
    }
  }
);

const sotckSearchedImagesSchema = new schema.Array(stockSearchedImageSchema);

const resetPasswordTokenSchema = new schema.Entity(
  "resetPasswordToken",
  {},
  {
    idAttribute: "userId"
  }
);

const userPreferences = new schema.Entity(
  "userPreferences",
  {},
  {
    idAttribute: "userId"
  }
);

const smartText = new schema.Entity(
  "smartText",
  {},
  {
    idAttribute: "teamId"
  }
);

const smartAssets = new schema.Entity(
  "smartAssets",
  {},
  {
    idAttribute: "teamId"
  }
);

const schemas = {
  ORGANISATION: organisationsSchema,
  USERS: usersSchema,
  USERS_ARRAY: [usersSchema],
  ALLOCATIONS: allocationsSchema,
  ALLOCATIONS_ARRAY: [allocationsSchema],
  PENDING_USERS: pendingUsersSchema,
  PENDING_USERS_ARRAY: [pendingUsersSchema],
  DESIGNS: designsSchema,
  DESIGNS_ARRAY: [designsSchema],
  SEARCH_SUGGESTIONS: searchSuggestionsSchema,
  CREDITS: creditsSchema,
  DESIGNS_STATUS_UPDATE_ARRAY: designsStatusUpdateArraySchema,
  DESIGNS_MULTI_STATUS_UPDATE_ARRAY: designMultiStatusUpdateArraySchema,
  DESIGN_CREATION: designCreationSchema,
  DESIGN_DATA: designDataSchema,
  DESIGN_RESIZE: designResizeSchema,
  TEAMS: teamsSchema,
  TEAM_SETTINGS: teamSettingsSchema,
  TEAMS_ARRAY: [teamsSchema],
  INVITATIONS: invitationsSchema,
  USERS_INVITED_ARRAY: [usersInvitedSchema],
  PERSONAL_ORG_CREATION: personalOrganisationCreation,
  BRAND_KIT: brandKit,
  BRAND_KIT_COLORS: brandKitColors,
  BRAND_KIT_COLOR_GROUP: colorGroup,
  BRAND_KIT_FONT: brandKitFont,
  BRAND_KIT_FONTS: brandKitFonts,
  BRAND_KIT_FONT_UPLOAD: brandKitFontUpload,
  BRAND_KIT_IMAGE: brandKitImage,
  BRAND_KIT_IMAGES: brandKitImages,
  USER_FONT_UPLOAD: userFontUpload,
  // PUBLIC_FONT: publicFont,
  PUBLIC_FONTS_ARRAY: publicFonts,
  USER_TEAM_FONTS_ARRAY: userTeamFonts,
  USER_TEAM_IMAGES_ARRAY: userTeamImages,
  USER_TEAM_SMART_IMAGES_ARRAY: userTeamSmartImages,
  USER_TEAM_SMART_IMAGE: userTeamSmartImage,
  USER_TEAM_ANIMATIONS_ARRAY: userTeamAnimations,
  TEAM_ANIMATIONS_ARRAY: teamAnimations,
  STOCK_ANIMATIONS_ARRAY: stockAnimations,
  TEAM_IMAGES_ARRAY: teamImages,
  TEAM_SMART_IMAGES_ARRAY: teamSmartImages,
  TEAM_SMART_IMAGE: teamSmartImage,
  TEAM_LOGOS_ARRAY: teamLogos,
  SMART_TEXT: smartText,
  SMART_ASSETS: smartAssets,
  SUBSCRIPTION: subscription,
  SUBSCRIPTIONS_ARRAY: subscriptions,
  SUBSCRIPTION_PLAN: subscriptionPlan,
  SUBSCRIPTION_PLANS: subscriptionPlans,
  CARD: card,
  CARDS: cards,
  CATALOGUE_SIZE: catalogueSizeSchema,
  CATALOGUE_SIZES: catalogueSizesSchema,
  WORKSPACE_SIZE: workspaceSizeSchema,
  WORKSPACE_SIZES: workspaceSizesSchema,
  COLLECTION_DESIGN: collectionDesignSchema,
  COLLECTION_DESIGNS: collectionDesignsSchema,
  COLLECTION: collectionSchema,
  COLLECTIONS: collectionsSchema,
  DOWNLOAD: downloadSchema,
  DOWNLOADS: downloadsSchema,
  FOLDER: folderSchema,
  FOLDERS: foldersSchema,
  DESIGN_FOLDER: designFolderSchema,
  DESIGN_FOLDERS: designFoldersSchema,
  DESIGN_FOLDER_ADD: designFolderAddSchema,
  DESIGN_FOLDERS_ADD: designFoldersAddSchema,
  TAG: tagSchema,
  TAGS: tagsSchema,
  DESIGN_TAG: designTagSchema,
  DESIGN_TAGS: designTagsSchema,
  USER_TEAM_FAVORITE: userTeamFavoriteSchema,
  USER_TEAM_FAVORITES: userTeamFavoritesSchema,
  GRAPHIC: graphicSchema,
  GRAPHICS: graphicsSchema,
  SHIPPING_RATE: shippingRateSchema,
  SHIPPING_RATES: shippingRatesSchema,
  ORDER: orderSchema,
  ORDER_V2: orderV2Schema,
  ORDERS: ordersSchema,
  PAYMENT_ORDER: ordersEntitySchema,
  COUPON_ORDER: couponOrderSchema,
  BILLING_DETAILS: billingDetailsSchema,
  SHIPPING_DETAILS: shippingDetailsSchema,
  ORDER_TRANSACTION: orderTransactionSchema,
  ORDER_TRANSACTIONS: orderTransactionsSchema,
  ORDER_DESIGN: orderDesignSchema,
  ORDER_DESIGN_PRINT_ITEM: orderDesignPrintItemSchema,
  ORDER_COLLECTION: orderCollectionSchema,
  PRINT_PRICING: printPricingSchema,
  PRINT_PRICINGS: printPricingsSchema,
  TEAM_BILLING: teamBillingSchema,
  IMAGE_SEARCH_RESULT: searchedImagesSchema,
  ANIMATION_SEARCH_RESULT: searchedAnimationsSchema,
  STOCK_ANIMATION_SEARCH_RESULT: searchedStockAnimationsSchema,
  BRAND_ANIMATION_SEARCH_RESULT: searchedTeamAnimationsSchema,
  STOCK_IMAGE_SEARCH_RESULT: sotckSearchedImagesSchema,
  BRAND_IMAGE_SEARCH_RESULT: searchedBrandImagesSchema,
  BRAND_LOGO_SEARCH_RESULT: searchedBrandLogosSchema,
  RESET_PASSWORD_TOKEN: resetPasswordTokenSchema,
  USER_PREFERENCES: userPreferences,
  DESIGN_ACTION: designActionSchema,
  DESIGN_ACTIONS: designActionArraySchema,
  APPROVAL_NOTIFICATIONS_ARRAY: approvalNotificationArraySchema,
  INVOICES: invoicesSchema,
  NONE: none
};

export default schemas;
