import React, { Component } from "react";
import PropTypes from "prop-types";

import style from "./style.module.css";

import { Input, Modal } from "views/components";

class AddFolderModal extends Component {
  constructor(props) {
    super(props);

    this.handleUpdate = this.handleUpdate.bind(this);

    this.state = {
      name: null
    };
  }

  //the DidUpdate it takes the prevProps, if the component is not open,
  //it will show the current prop and it will dismiss all toast in state.
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.props.removeAllToast();
    }
  }

  handleUpdate(attribute, value) {
    const attributeValue = value === "" ? null : value;
    this.setState({ [attribute]: attributeValue });
  }

  handleCreate() {
    const { name } = this.state;
    this.props.onCreate({ name });
  }

  render() {
    const { isOpen, onClose, errors, submitting } = this.props;

    const { name } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        contentLabel="Preview"
        onRequestClose={onClose}
        header="Create a new folder"
        className={style.fontModal}
        buttons={[
          {
            name: "Cancel",
            theme: "gray",
            onClick: onClose
          },
          {
            name: submitting ? "Creating..." : "Create",
            theme: "blueSolid",
            disabled: name === null || submitting,
            onClick: () => this.handleCreate()
          }
        ]}
      >
        {errors && (
          <div className={style.errors}>
            {errors.map((error, index) => (
              <div key={`create-folder-error-${index}`} className={style.error}>
                {error.message}
              </div>
            ))}
          </div>
        )}
        <div className={style.modalForm}>
          <div className={style.modalFormGroup}>
            <div className={style.modalFormLabel}>Folder Name</div>
            <div className={style.modalFormField}>
              <Input
                onChange={event =>
                  this.handleUpdate("name", event.target.value)
                }
                placeholder="Enter a name"
                className={`${style.modalFormInput} ${style.modalFormFieldCreateFolderNameInput}`}
                autoFocus
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

AddFolderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired
};

export default AddFolderModal;
