import React from "react";
import { Loading, Modal } from "views/components";
import style from "./style.module.css";
import Scrollable from "views/components/Scrollable/Scrollable";
import BreadCrumbList from "../BreadCrumb/BreadCrumbList";
import FolderButtonContainer from "views/containers/FolderButtonContainer";
import { isImportantPropsChanged } from "lib/elements";
import DesignsEmptyState from "views/containers/designsPageMasonry/DesignsEmptyState";
import AssetResultCard from "../AssetResultCard/AssetResultCard";
import SearchInput from "views/components/searchInput/SearchInput";
import { keyCodes, getPath } from "lib";
import BrandKitSortButton from "../BrandKit/BrandKitSortButton";
import { MEDIA_SORT_OPTIONS } from "lib/constants";
import { ImagesUploadButton } from "views/containers/DesignsPageContainer/Workspace/ImagesButtons";
import PATHS from "routes/paths";

export class SmartImageTransferModal extends React.Component {
  constructor(props) {
    super(props);
    this.getModalButtons = this.getModalButtons.bind(this);
    this.renderFoldersSection = this.renderFoldersSection.bind(this);
    this.getAssets = this.getAssets.bind(this);
    this.getAssetsFromFolder = this.getAssetsFromFolder.bind(this);
    this.renderGrid = this.renderGrid.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClearInput = this.handleClearInput.bind(this);
    this.getIsLoading = this.getIsLoading.bind(this);
    this.handleSortUpdate = this.handleSortUpdate.bind(this);
    this.containerRef = React.createRef();
    this.getSortOption = this.getSortOption.bind(this);
    this.handleFetchAssets = this.handleFetchAssets.bind(this);

    this.state = {
      selectedAssetId: null,
      selectedFolderId: null,
      assets: [],
      searchTerm: "",
      isSearchFocused: false,
      sortKey: "UPDATEDAT_DESC",
      isUploadingAsset: false
    };
  }

  componentDidMount() {
    if (PATHS.isBrandKit(window.location.pathname)) {
      this.handleFetchAssets();
    }
    this.getAssets();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      isImportantPropsChanged(prevProps, this.props, [
        "rootAssets",
        "folders"
      ]) ||
      this.state.selectedFolderId !== prevState.selectedFolderId
    ) {
      this.getAssets();
    }

    const isBrandKit = PATHS.isBrandKit(window.location.pathname);
    const sortOption = this.getSortOption();
    // brand kit requires refect when opening modal from folder or
    // if returning from a folder to root in modal
    if (
      isBrandKit &&
      prevState.selectedFolderId &&
      !this.state.selectedFolderId
    ) {
      this.handleFetchAssets();
    }

    if (prevState.searchTerm && !this.state.searchTerm) {
      this.props.handleClearInput();
      if (isBrandKit) {
        this.handleFetchAssets();
      }
    }

    if (prevState.sortKey !== this.state.sortKey) {
      this.props.refetchAssets({
        term: this.props.term,
        folderId: this.state.selectedFolderId,
        sortBy: sortOption.sortKey,
        sortOrder: sortOption.direction,
        skipStateClear: true
      });
    }

    if (
      Object.keys(prevProps.assetEntities).length !==
        Object.keys(this.props.assetEntities).length &&
      this.state.isUploadingAsset
    ) {
      const prevIds = Object.keys(prevProps.assetEntities);
      const currentIds = Object.keys(this.props.assetEntities);
      // Filter out keys that are not in obj1
      const uploadedMediaId = currentIds.find(key => !prevIds.includes(key));

      this.props.updateSmartImage({
        smartImage: {
          ...this.props.smartImage,
          mediaId: uploadedMediaId
        }
      });
    }
  }

  handleFetchAssets(isNextPage) {
    const sortOption = this.getSortOption();
    this.props.onFetchAssets({
      folderId: this.state.selectedFolderId,
      sortBy: sortOption.sortKey,
      sortOrder: sortOption.direction,
      isNextPage
    });
  }

  getSortOption() {
    return MEDIA_SORT_OPTIONS[this.state.sortKey];
  }

  handleSearchChange(term) {
    this.setState({
      searchTerm: term
    });
  }

  handleSortUpdate(value) {
    this.setState({
      sortKey: value
    });
  }

  handleKeyDown(event) {
    const { searchTerm: term, selectedFolderId: folderId } = this.state;
    const sortOption = this.getSortOption();

    switch (event.keyCode) {
      case keyCodes.enterKey: {
        this.props.setSearchTerm(term);
        this.props.onSearchAssets({
          term,
          folderId,
          sortBy: sortOption.sortKey,
          sortOrder: sortOption.direction
        });
        break;
      }
      default:
    }
  }

  handleClearInput() {
    this.setState({
      searchTerm: ""
    });
    this.props.handleClearInput();
    this.containerRef.current.querySelector("input").focus();
  }

  handleScroll(event) {
    const { target } = event;
    const scrollBottom =
      target.scrollHeight - target.clientHeight - target.scrollTop;
    if (scrollBottom <= 0 && !this.props.term) {
      const isNextPage = true;
      this.handleFetchAssets(isNextPage);
    }
  }

  getAssets() {
    const { selectedFolderId } = this.state;

    if (selectedFolderId) {
      return this.getAssetsFromFolder(selectedFolderId);
    }

    if (!this.props.rootAssets) {
      this.handleFetchAssets();
    }

    this.setState({
      assets: this.props.rootAssets || []
    });
  }

  getAssetsFromFolder(folderId) {
    const { folders } = this.props;

    const selectedFolder = folders.find(folder => folder.id === folderId);

    let assets = selectedFolder.images?.length > 0 ? selectedFolder.images : [];
    if (!(selectedFolder && selectedFolder.images)) {
      const sortOption = this.getSortOption();
      this.props.onFetchAssetsInFolder({
        folderId,
        sortBy: sortOption.sortKey,
        sortOrder: sortOption.direction
      });
    }

    if (this.props.term) {
      const searchResults = getPath(
        selectedFolder,
        `terms.${this.props.term}`,
        {}
      );
      const searchResultIds = searchResults?.ids || [];
      assets = searchResultIds.map(imageId => searchResults.images[imageId]);
    }

    this.setState({
      assets
    });
  }

  getModalButtons() {
    return [
      {
        name: "Cancel",
        theme: "gray",
        onClick: this.props.onClose
      },
      {
        name: "Confirm",
        theme: "blueSolid",
        disabled: !this.state.selectedAssetId,
        onClick: () =>
          this.props.updateSmartImage({
            smartImage: {
              ...this.props.smartImage,
              mediaId: this.state.selectedAssetId
            },
            onSuccess: () => {
              this.props.onClose();
              // show success toast
            }
          }),
        isLoading: this.props.isSaving
      }
    ];
  }

  getSelectedFolder() {
    if (!this.props.folders) return;

    return this.props.folders.find(
      folder => folder.id === this.state.selectedFolderId
    );
  }

  renderFoldersSection() {
    if (this.props.term) return null;

    if (this.state.selectedFolderId) {
      const selectedFolder = this.getSelectedFolder();

      if (!selectedFolder) return null;

      const path = [
        {
          text: this.props.transferRootLocation,
          onClick: () => this.setState({ selectedFolderId: null }),
          className: style.tranferRootPathClass
        },
        {
          text: selectedFolder.name,
          className: style.folderPathClass
        }
      ];

      return (
        <BreadCrumbList path={path} wrapperClassName={style.breadCrumbList} />
      );
    }

    if (!this.props.folders.length) return null;

    return (
      <section className={style.foldersSection}>
        {this.props.folders.map(folder => (
          <FolderButtonContainer
            key={folder.id}
            folder={folder}
            onClick={() => this.setState({ selectedFolderId: folder.id })}
            buttonClassName={style.folderButton}
            location={this.props.location}
            titleClassName={style.folderButtonTitle}
          />
        ))}
      </section>
    );
  }

  getIsLoading() {
    const { isFetchingFirstPage, isSearching } = this.props;
    const { selectedFolderId } = this.state;

    if (!!selectedFolderId) {
      const selectedFolder = this.props.folders.find(
        folder => folder.id === this.state.selectedFolderId
      );
      if (this.props.term)
        return (
          selectedFolder.terms[this.props.term]?.isFetching ||
          selectedFolder.isFetching
        );
      return !selectedFolder.images || selectedFolder.isFetching;
    }
    return isFetchingFirstPage || isSearching;
  }

  renderGrid() {
    if (this.getIsLoading()) {
      return (
        <div className={style.emptyGrid}>
          <Loading />
        </div>
      );
    }

    if (!this.state.assets.length) {
      return (
        <div className={style.emptyGrid}>
          <DesignsEmptyState
            resource={"/designs/workspace/uploads/images"}
            isImageFolder={!!this.state.selectedFolderId}
            isImageSearch={!!this.props.term}
            imageSearchTerm={this.props.term}
            isSmartImageTransfer
          />
        </div>
      );
    }

    const assetMediaId = asset => asset.mediaId || asset.media.id;
    const isDisabled = asset =>
      Object.keys(this.props.smartAssets).includes(assetMediaId(asset));
    const handleOnClick = asset => {
      if (isDisabled(asset)) return;
      this.setState({ selectedAssetId: assetMediaId(asset) });
    };

    const dynamicStyle = {
      marginTop: this.state.selectedFolderId && !this.props.term ? "24px" : ""
    };
    return (
      <div className={style.assetCards} style={dynamicStyle}>
        {this.state.assets.map(asset => (
          <AssetResultCard
            asset={asset}
            onClick={asset => handleOnClick(asset)}
            isSelected={assetMediaId(asset) === this.state.selectedAssetId}
            isDisabled={isDisabled(asset)}
            smartAssets={this.props.smartAssets}
          />
        ))}
      </div>
    );
  }

  render() {
    const customStyles = {
      marginTop:
        this.state.selectedFolderId || this.props.term ? "24px" : "8px",
      height: this.state.selectedFolderId ? "294px" : "310px"
    };
    const transferModalClassName = this.state.isUploadingAsset
      ? style.uploadingTransferSmartAssetModal
      : style.transferSmartImageModal;

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onClose}
        contentLabel={"Transfer ID"}
        header={"Transfer ID"}
        className={transferModalClassName}
        headerTitleClassName={style.smartImageModalHeader}
        buttonsClassName={style.modalButtons}
        buttons={this.getModalButtons()}
      >
        <div className={style.transferModalWrapper} ref={this.containerRef}>
          <div className={style.topSection}>
            <SearchInput
              className={style.search}
              placeholder={`Search for ${this.props.transferRootLocation} by name`}
              value={this.state.searchTerm}
              onChange={this.handleSearchChange}
              inputClassName={style.searchInput}
              onKeyDown={this.handleKeyDown}
              isFocussed={this.state.isSearchFocused}
              clearInput={this.handleClearInput}
            />
            <div style={{ marginLeft: "12px" }}>
              <BrandKitSortButton
                sortKey={this.state.sortKey}
                onSortUpdate={this.handleSortUpdate}
              />
            </div>
            <ImagesUploadButton
              isSmartImageTransfer
              toggleSmartImageUpload={() =>
                this.setState({
                  isUploadingAsset: !this.state.isUploadingAsset
                })
              }
              handleCloseTransferModal={this.props.onClose}
              isBrandKitAssets={!!PATHS.isBrandKit(window.location.pathname)}
              smartImage={this.props.smartImage}
              asset={this.props.asset}
              isUploadingAsset={this.state.isUploadingAsset}
            />
          </div>
          <Scrollable
            onScroll={e => this.handleScroll(e)}
            scrollContentClassName={style.tansferScrollSection}
            customStyles={customStyles}
            scrollYClassName={style.scrollbar}
          >
            {this.renderFoldersSection()}
            {this.renderGrid()}
          </Scrollable>
        </div>
      </Modal>
    );
  }
}

export default SmartImageTransferModal;
