import React, { Component } from "react";
import style from "./style.module.css";
import { Button } from "views/components";
import ExitStandardisedIcon from "views/components/icons/ExitStandardisedIcon";
import ImagesUploadModalContainer from "./ImagesUploadModalContainer";
import AnimationsUploadModalContainer from "./AnimationsUploadModalContainer";
import TeamAnimationsUploadModalContainer from "views/containers/DesignsPageContainer/Workspace/ImagesButtons/TeamAnimationsUploadModalContainer";
import BrandAssetUploadModalContainer from "views/containers/BrandAssetUploadModalContainer/BrandAssetUploadModalContainer";
import { noop } from "lib";

export class ImagesUploadButton extends Component {
  constructor(props) {
    super(props);

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);

    this.state = {
      isModalOpen: false,
      UploadModalContainer: ImagesUploadModalContainer
    };
  }

  handleOpenModal() {
    const { isModalOpen } = this.state;

    if (!isModalOpen) {
      this.setState({
        isModalOpen: true
      });
    }
  }

  handleCloseModal({ onSuccess = noop }) {
    const { isModalOpen } = this.state;

    if (isModalOpen) {
      this.setState({
        isModalOpen: false
      });
    }

    onSuccess();
  }

  handleOnClick() {
    const {
      canAccessBrandKit,
      isTeamAnimations,
      showUpgradeModal = noop,
      isSmartImageTransfer = false,
      toggleSmartImageUpload = noop,
      isUploadingAsset = false
    } = this.props;

    if (isSmartImageTransfer && !isUploadingAsset) toggleSmartImageUpload();

    if (isTeamAnimations && !canAccessBrandKit) {
      showUpgradeModal();
      return;
    }

    this.handleOpenModal();
  }

  componentDidMount() {
    if (this.props.isAnimations) {
      this.setState({
        UploadModalContainer: AnimationsUploadModalContainer
      });
    } else if (this.props.isTeamAnimations) {
      this.setState({
        UploadModalContainer: TeamAnimationsUploadModalContainer
      });
    } else if (this.props.isBrandKitAssets) {
      this.setState({
        UploadModalContainer: BrandAssetUploadModalContainer
      });
    } else {
      // exit safely and leave default modal
    }
  }

  render() {
    const {
      asset,
      folderId,
      className,
      isSmartImageTransfer,
      handleCloseTransferModal,
      toggleSmartImageUpload,
      smartImage
    } = this.props;
    const { isModalOpen, UploadModalContainer } = this.state;

    return (
      <Button
        onClick={this.handleOnClick}
        theme="blueSolidNoBackgroundNoBorder"
        className={`${style.headerButton} ${className}`}
        id="upload-image-button"
      >
        <div className={style.iconBoundingBox} data-is-upload={true}>
          <ExitStandardisedIcon
            direction="up"
            color="#3184fc"
            size="18"
            title="Add Folder"
          />
        </div>
        <span>Upload</span>
        <UploadModalContainer
          isOpen={isModalOpen}
          onClose={this.handleCloseModal}
          folderId={folderId}
          sortOptions={this.props.sortOptions}
          isSmartImageTransfer={isSmartImageTransfer}
          handleCloseTransferModal={handleCloseTransferModal}
          handleSmartImageUploadClose={toggleSmartImageUpload}
          smartImage={smartImage}
          asset={asset}
        />
      </Button>
    );
  }
}

export default ImagesUploadButton;
