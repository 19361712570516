import React from "react";
import BrandKitSearchInput from "../BrandKitSearch/BrandKitSearchInput";
import BrandKitSearchSpacer from "../BrandKitSearch/BrandKitSearchSpacer";
import BrandKitSearchTextButton from "../BrandKitSearch/BrandKitSearchTextButton";
import PlusIcon from "views/components/icons/PlusIcon";
import ExitStandardisedIcon from "views/components/icons/ExitStandardisedIcon";
import BrandKitSortButton from "../BrandKitSortButton";
import { AddImageButton } from "views/components";
import { STATIC_ASSET_FILE_TYPES } from "lib/constants";
import common from "../common.module.css";
import BrandKitImageOps from "./BrandKitImageOps";
import { keyCodes } from "lib";

export class BrandKitImagesSearch extends React.Component {
  constructor(props) {
    super(props);

    this.setQueryString = BrandKitImageOps.setQueryString.bind(this);
    this.handleSortUpdate = BrandKitImageOps.handleSortUpdate.bind(this);
    this.handleAddImage = BrandKitImageOps.handleAddImage.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onAddImageButtonClick = BrandKitImageOps.onAddImageButtonClick.bind(
      this
    );
    this.state = {
      searchTerm: ""
    };
  }

  handleKeyDown(event) {
    if (event.keyCode === keyCodes.enterKey) {
      this.setQueryString({ term: this.props.searchTerm });
    }
  }

  render() {
    const { isBrandManager, qsFolderId, sortKey } = this.props;

    return (
      <>
        <BrandKitSearchInput
          onChange={term => {
            this.props.setBrandKitState({ searchTerm: term }, "images");
          }}
          onClear={() => {
            this.props.setBrandKitState({ searchTerm: null }, "images");
            this.setQueryString(null);
          }}
          placeholder="Search for Brand Images by name"
          value={this.props.searchTerm || ""}
          onKeyDown={this.handleKeyDown}
        />

        {isBrandManager && (
          <>
            <BrandKitSortButton
              sortKey={sortKey}
              onSortUpdate={this.handleSortUpdate}
              filterSmartImages={this.props.filterSmartImages}
              toggleSmartImageFilter={() => {
                this.props.setBrandKitState(
                  { filterSmartImages: !this.props.filterSmartImages },
                  "images"
                );
              }}
              smartImageFilter={true}
            />

            {qsFolderId === null && (
              <>
                <BrandKitSearchTextButton
                  Icon={() => (
                    <PlusIcon
                      color="#3184fc"
                      size="18px"
                      style={{ transform: "rotate(-90deg)" }}
                    />
                  )}
                  onClick={() =>
                    this.props.setBrandKitState(
                      { isAddingFolder: true },
                      "images"
                    )
                  }
                  title="New Folder"
                />

                <BrandKitSearchSpacer />
              </>
            )}

            <AddImageButton
              acceptedFileTypes={STATIC_ASSET_FILE_TYPES}
              className={common.uploadButtonPrependError}
              onChange={({ file, onSuccess }) =>
                this.handleAddImage(file, onSuccess)
              }
              onClick={this.onAddImageButtonClick}
              image={
                <BrandKitSearchTextButton
                  Icon={() => (
                    <ExitStandardisedIcon
                      color="#3184fc"
                      size="18px"
                      style={{ transform: "rotate(-90deg)" }}
                    />
                  )}
                  title="Upload"
                  onClick={this.onAddImageButtonClick}
                />
              }
              isMulti
            />
          </>
        )}
      </>
    );
  }
}

export default BrandKitImagesSearch;
