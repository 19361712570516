import React from "react";
import { connect } from "react-redux";
import { processDesignRemix } from "state/ui/designRemix/designRemixActions";
import {
  fetchNextPageOfTeamsIfNeeded,
  fetchNextPageOfSubTeamsIfNeeded,
  fetchTeamsCount
} from "state/entities/teams/teamsActions";
import { teamsTotalCountSelector } from "state/entities/teams/teamsSelectors";
import {
  isFetchingTeamsSelector,
  selectedTeamSelector,
  teamsSelector
} from "state/ui/header/TeamMenu/TeamMenuSelectors";
import {
  clearSearch,
  navigateDown,
  navigateUp,
  searchTeams
} from "state/ui/header/TeamMenu/TeamMenuActions";
import DesignRemixWrapper from "views/components/DesignRemix/DesignRemixWrapper";

export const DesignRemixContainer = props => <DesignRemixWrapper {...props} />;

const mapStateToProps = state => {
  const selectedTeam = selectedTeamSelector(state);
  const teams = teamsSelector(state, selectedTeam);
  const teamCount = teamsTotalCountSelector(state);
  const isFetchingTeams = isFetchingTeamsSelector(state);

  return {
    currentUser: state.currentUser,
    currentTeam: state.ui.currentTeam,
    teams,
    teamCount,
    isFetchingTeams,
    selectedTeam
  };
};

const mapDispatchToProps = dispatch => {
  return {
    processDesignRemix: (...args) => dispatch(processDesignRemix(...args)),
    fetchTeamsIfNeeded: args => dispatch(fetchNextPageOfTeamsIfNeeded(args)),
    fetchNextPageOfSubTeamsIfNeeded: args =>
      dispatch(fetchNextPageOfSubTeamsIfNeeded(args)),
    fetchTeamsCount: args => dispatch(fetchTeamsCount(args)),
    navigateDown: selectedTeam => dispatch(navigateDown(selectedTeam)),
    navigateUp: selectedTeam => dispatch(navigateUp(selectedTeam)),
    onSearchTeams: args => dispatch(searchTeams(args)),
    onClearSearch: () => dispatch(clearSearch())
  };
};

DesignRemixContainer.displayName = "DesignRemixContainer";
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesignRemixContainer);
