import React from "react";
import { Modal } from "views/components";
import style from "./style.module.css";
import Tooltip from "views/components/Tooltip/Tooltip";
import InfoIcon from "views/components/icons/InfoIcon";

export class DeleteSmartImageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getTransferIdButtonLabel() {
    return (
      <div className={style.transferIdButton}>
        Transfer ID
        <div
          className={style.transferIcon}
          data-tip="Transfer this Smart Image ID to a new image"
          data-for="transfer-smart-image-id-tooltip"
        >
          <InfoIcon size="14px" />
        </div>
        <Tooltip id="transfer-smart-image-id-tooltip" place="bottom" />
      </div>
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onClose}
        contentLabel={"Delete Image?"}
        header={"Delete Image?"}
        className={style.deleteSmartImageModal}
        headerTitleClassName={style.smartImageModalHeader}
        buttonsClassName={style.modalButtons}
        buttons={[
          {
            name: "Cancel",
            theme: "gray",
            onClick: this.props.onClose
          },
          {
            name: this.getTransferIdButtonLabel(),
            theme: "grayBackgroundXDarkGrayFont",
            onClick: this.props.onTransferClick
          },
          {
            name: "Delete Anyway",
            theme: "redSolid",
            onClick: this.props.onHardDelete
          }
        ]}
        crossClassName={style.modalCross}
      >
        <div className={style.deleteContentWrapper}>
          Are you sure you want to delete this Image? There is a Smart ID
          currently assigned to this image. Any designs using this Smart ID will
          no longer be linked to this image.
        </div>
      </Modal>
    );
  }
}

export default DeleteSmartImageModal;
