import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllTeamAssetFolders } from "state/entities/folders/foldersSelectors";
import BrandAssetsUploadModal from "views/components/BrandAssetUploadModal/BrandAssetUploadModal";
import { STATIC_ASSET_FILE_TYPES } from "lib/constants";
import {
  handleTeamSmartImageUploadTransfer,
  handleTeamSmartLogoUploadTransfer
} from "state/entities/teamSmartImages/teamSmartImagesActions";

export class BrandAssetUploadModalContainer extends Component {
  render() {
    return (
      <BrandAssetsUploadModal
        {...this.props}
        acceptedFileTypes={STATIC_ASSET_FILE_TYPES}
        destinationName={`Brand ${
          this.props.asset === "images" ? "Images" : "Logos"
        }`}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const assetFolders = getAllTeamAssetFolders(state, ownProps.asset);
  return {
    assetFolders
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const uploadTransferAction =
    ownProps.asset === "images"
      ? handleTeamSmartImageUploadTransfer
      : handleTeamSmartLogoUploadTransfer;
  return {
    handleTeamSmartAssetUploadTransfer: args =>
      dispatch(uploadTransferAction(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandAssetUploadModalContainer);
