import React, { Component } from "react";
import { connect } from "react-redux";
import SmartImageTransferModal from "views/components/SmartImageTransferModal/SmartImageTransferModal";
import { userTeamSmartImagesEntitiesSelector } from "state/entities/userTeamSmartImages/userTeamSmartImagesSelectors";
import { teamSmartImagesEntitiesSelector } from "state/entities/teamSmartImages/teamSmartImagesSelectors";
import { userTeamSmartImagesApiSavingSelector } from "state/api/userTeamSmartImages/userTeamSmartImagesApiSelectors";
import { getAllImagesFolders } from "state/entities/imageFolders/imageFoldersSelectors";
import {
  userTeamImagesPageSelector,
  myImagesPersonalStateSelector
} from "state/ui/MyImages/MyImagesSelectors";
import {
  getUserImageNextPage,
  getUserImageInFolderNextPage,
  refetchUserTeamImages
} from "state/entities/userTeamImages/userTeamImagesActions";
import { transferUserTeamSmartImage } from "state/entities/userTeamSmartImages/userTeamSmartImageActions";
import { searchImages } from "state/ui/MyImages/MyImagesActions";
import { userTeamImagesFirstPageIsFetching } from "state/api/userTeamImages/userTeamImagesApiSelector";
import { userTeamImagesEntitiesSelector } from "state/entities/userTeamImages/userTeamImagesSelectors";

export class TransferUserSmartImageModalContainer extends Component {
  constructor(props) {
    super(props);
    this.handleClearInput = this.handleClearInput.bind(this);
    this.setSearchTerm = this.setSearchTerm.bind(this);

    this.state = {
      term: ""
    };
  }

  handleClearInput() {
    this.setState({ term: "" });
  }

  setSearchTerm(term) {
    this.setState({ term });
  }

  render() {
    return (
      <SmartImageTransferModal
        {...this.props}
        transferRootLocation="My Images"
        setSearchTerm={this.setSearchTerm}
        handleClearInput={this.handleClearInput}
        rootAssets={userTeamImagesPageSelector({
          state: this.props.state,
          term: this.state.term
        })}
        term={this.state.term}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const userSmartImages = userTeamSmartImagesEntitiesSelector(state);
  const teamSmartImages = teamSmartImagesEntitiesSelector(state);
  const smartImages = {
    ...userSmartImages,
    ...teamSmartImages
  };
  const isSaving = userTeamSmartImagesApiSavingSelector(state);

  const myImagesState = myImagesPersonalStateSelector(state);
  let isSearching = false;
  if (myImagesState && !!myImagesState.currentTerm) {
    isSearching = myImagesState.terms[myImagesState.currentTerm]?.isFetching;
  }

  return {
    folders: getAllImagesFolders(state),
    isSaving,
    smartAssets: smartImages,
    state,
    isFetchingFirstPage: userTeamImagesFirstPageIsFetching(state),
    isSearching,
    assetEntities: userTeamImagesEntitiesSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchAssets: args => dispatch(getUserImageNextPage(args)),
    onFetchAssetsInFolder: args => dispatch(getUserImageInFolderNextPage(args)),
    updateSmartImage: args => dispatch(transferUserTeamSmartImage(args)),
    onSearchAssets: args => dispatch(searchImages(args)),
    refetchAssets: args => dispatch(refetchUserTeamImages(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferUserSmartImageModalContainer);
