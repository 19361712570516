import { pick, cloneDeep, clamp, getPath, noop, isEmpty } from "lib/lodash";
import { jsonStringEqual } from "./equalityUtils";
import { caseInsensitiveStringCompare } from "./string/string";
import {
  indentNodes,
  unIndentNodes,
  removeIndentation
} from "lib/richText/IndentationUtils";
import { omitUnchangedComparison } from "lib/lodashExtentions";
import {
  getCurrentSelectionPosition,
  setCurrentSelectionPosition
} from "lib/cursorUtils";
import FontToolBox from "lib/FontToolBox";
import { parseLinkUrl } from "lib/parseLinkUrl";
import { DOM_NODE_TYPES } from "lib/textUtils";
import { isNil } from "lib";
import { getCurrentRangeValues, setRangeForTarget } from "lib/selectionUtils";

const stylableNodeNames = ["SPAN", "A", "DIV", "UL", "SUP"];

/**
 * @desc uses the current selection range to find the closest parent node which is contentEditable
 * @returns the closest dom node parent to the current selection which has the attribute contentEditable true
 */
export const getNearestContentEditableParentFromSelection = () => {
  const range = getRange();

  if (!range) {
    return;
  }

  let contentEditableNode = range.commonAncestorContainer;
  const checkNode = nodeToCheck => {
    if (!nodeToCheck) return;
    if (nodeToCheck.contentEditable === "true") {
      contentEditableNode = nodeToCheck;
      return;
    }
    checkNode(nodeToCheck.parentNode);
  };
  checkNode(contentEditableNode);
  return contentEditableNode;
};

// our important styles and their font styling uses
export const importantStylesMap = {
  "font-style": ["italic"],
  "font-weight": ["bold"],
  "font-family": ["bold", "italic", "font"],
  color: ["color"],
  "text-align": ["text-align"],
  width: ["text-align"],
  "text-decoration-line": ["underline"],
  "text-decoration-color": ["underline"],
  "font-size": ["font-size"]
};

export const applicableClasses = ["tab"];

/**
 * @desc traverses through parent nodes to find the closest node which has any siblings
 * @param {HTMLElement} node - the node that we want to start from
 * @param {HTMLElement} cutoffNode - the node that should be our maximum height for traversal
 * @returns {HTMLElement} the closest parent node with siblings or whose parent is the cutoff node
 */
export const getClosestNodeWithSiblings = (node, cutoffNode) => {
  if (node === cutoffNode) {
    // this is as high as we can go so return
    return node;
  }

  let closestNode = node;

  const checkNode = nodeToCheck => {
    const isNodeOnlyChild =
      !nodeToCheck.nextSibling && !nodeToCheck.previousSibling;
    const isParentNodeCutoff =
      !nodeToCheck.parentNode || nodeToCheck.parentNode === cutoffNode;
    if (!isNodeOnlyChild) {
      closestNode = nodeToCheck;
    } else if (isParentNodeCutoff) {
      closestNode = nodeToCheck;
    } else {
      checkNode(nodeToCheck.parentNode);
    }
  };

  checkNode(node);

  return closestNode;
};

/**
 * @desc traverses downwards through nodes returning an array of all nodes underneath the given parent node
 * @param {HTMLElement} parentNode - the node to get all children for
 * @returns {Array<HTMLElement>} all children of the given parent node in an array
 */
export const getAllChildrenForNode = parentNode => {
  const nodes = [];

  const parseNodeChildren = node => {
    nodes.push(node);
    const childNodes = node?.childNodes ? Array.from(node.childNodes) : [];
    childNodes.forEach(parseNodeChildren);
  };

  parseNodeChildren(parentNode);

  return nodes;
};

export const getNodeRangeForSelection = element => {
  if (!element || !element.firstChild || !element.lastChild) return;
  const range = getRange();

  // if (!range || range.startContainer === element) {
  //   // only way this is possible is when whole textbox is selected
  //   return {
  //     startNode: element.firstChild,
  //     endNode: element.lastChild,
  //     startOffset: 0,
  //     endOffset: element.lastChild.length
  //   };
  // }

  return {
    startNode: range.startContainer,
    endNode: range.endContainer,
    startOffset: range.startOffset,
    endOffset: range.endOffset
  };
};

/**
 * @desc - parses the next node sibling from currentNode and return lists of middle nodes with the new node(s) appended
 * @param {HTMLElement} currentNode - the node to start traversing siblings from
 * @param {[HTMLElement]} middleNodes - a list of top level HTML nodes to extend upon with the current nodes next sibling
 * @param {[HTMLElement]} middleNodesSpread  - a list of HTML nodes to extend upon with the current node next sibling and its children
 * @param {Boolean} isStartNodeSameAsEndNode - whether or not the start node is the same as the end node
 * @param {HTMLElement} endClosestNodeWithSiblings - the node for our traversal to end at
 * @returns {Object} {middleNodes, middleNodesSpread}
 */
const parseNextNodeSibling = ({
  currentNode,
  middleNodes = [],
  middleNodesSpread = [],
  isStartNodeSameAsEndNode,
  endClosestNodeWithSiblings
}) => {
  const nextSibling = currentNode.nextSibling;

  // make local clones of the node arrays so we don't mutate input values
  const _middleNodes = cloneDeep(middleNodes);
  const _middleNodesSpread = cloneDeep(middleNodesSpread);

  // check that there is a sibling and it isn't the last node
  if (
    nextSibling &&
    !isStartNodeSameAsEndNode &&
    nextSibling !== endClosestNodeWithSiblings
  ) {
    // add the node to the middle nodes
    _middleNodes.push(nextSibling);
    // add the node and all its children to the spread middle nodes
    _middleNodesSpread.push(...getAllChildrenForNode(nextSibling));

    return parseNextNodeSibling({
      currentNode: nextSibling,
      middleNodes: _middleNodes,
      middleNodesSpread: _middleNodesSpread,
      isStartNodeSameAsEndNode,
      endClosestNodeWithSiblings
    });
  }
  // reached the end of our parsing so we can return the node arrays
  return {
    middleNodes,
    middleNodesSpread
  };
};

/**
 * @desc - parses the next node sibling from currentNode and return lists of middle nodes with the new node(s) appended
 * @param {HTMLElement} boundaryElement - the boundary node used to limit the maximum height traversed
 * @returns {Object} {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 */
export const getNodesInRange = boundaryElement => {
  if (
    !boundaryElement ||
    !boundaryElement.childNodes ||
    !boundaryElement.childNodes.length
  ) {
    return;
  }

  let { startNode, endNode, startOffset, endOffset } = getNodeRangeForSelection(
    boundaryElement
  );

  if (startNode === boundaryElement) {
    startNode = endNode;
    startOffset = 0;
  }

  if (endNode === boundaryElement) {
    endNode = startNode;
    endOffset = endNode.textContent.length;
  }

  const isStartNodeSameAsEndNode = startNode === endNode;

  // check if all of startNode selected
  const isAllStartSelected =
    startOffset === 0 &&
    (!isStartNodeSameAsEndNode || endOffset === endNode.length);
  const isAllEndSelected =
    endOffset === endNode.length &&
    (!isStartNodeSameAsEndNode || startOffset === 0);

  // are we just working in the same node?
  if (isStartNodeSameAsEndNode) {
    // check if the whole node is selected
    const isWholeNodeSelected =
      startOffset === 0 && endOffset === startNode.length;

    if (isWholeNodeSelected) {
      // when the whole node is selected we should try to find the nearest parent with siblings
      const nearestNodeWithSiblings = getClosestNodeWithSiblings(
        startNode,
        boundaryElement
      );

      // return all children for the node
      return {
        startNode: startNode,
        middleNodes: [startNode],
        endNode: endNode,
        allNodes: getAllChildrenForNode(nearestNodeWithSiblings),
        startOffset: startOffset,
        endOffset: endOffset
      };
    }
  }

  // selection spans multiple nodes
  const startClosestNodeWithSiblings = getClosestNodeWithSiblings(
    startNode,
    boundaryElement
  );

  const endClosestNodeWithSiblings = getClosestNodeWithSiblings(
    endNode,
    boundaryElement
  );

  let { middleNodes, middleNodesSpread } = parseNextNodeSibling({
    currentNode: startClosestNodeWithSiblings,
    isStartNodeSameAsEndNode,
    endClosestNodeWithSiblings
  });

  if (isAllStartSelected && !isStartNodeSameAsEndNode) {
    // when all of start is selected we should include it in middle nodes
    middleNodes = [startClosestNodeWithSiblings, ...middleNodes];
  }

  if (isAllEndSelected && !isStartNodeSameAsEndNode) {
    // when all of end is selected we should include it in middle nodes
    middleNodes.push(endClosestNodeWithSiblings);
  }

  const startNodeWithChildren = getAllChildrenForNode(
    startClosestNodeWithSiblings
  );
  const endNodeWithChildren = isStartNodeSameAsEndNode
    ? []
    : getAllChildrenForNode(endClosestNodeWithSiblings);

  return {
    startNode: startNode,
    middleNodes,
    endNode: endNode,
    allNodes: [
      ...startNodeWithChildren,
      ...middleNodesSpread,
      ...endNodeWithChildren
    ],
    startOffset: startOffset,
    endOffset: endOffset
  };
};

/**
 * @desc - forms a unique array from the given nodes array using HTMLElement === HTMLElement
 * @param {[HTMLElement]} nodes
 * @returns {[HTMLElement]} unique array of HTMLElements
 */
export const nodesUnique = nodes => {
  const uniqueArray = [];
  nodes.forEach(node => {
    // check if the node already exists in the array
    if (!uniqueArray.some(uniqueNode => uniqueNode === node)) {
      // it doesn't so we can add it
      uniqueArray.push(node);
    }
  });
  return uniqueArray;
};

/**
 * @desc gets a list of HTMLElements from the given nodes which conform to the given style object
 * @param {[HTMLElement]} nodes - a list of nodes to check for the given styles
 * @param {Object} styles - an object of type {styleKey: styleValue} which nodes need to match to be returned
 * @returns {[HTMLElement]} - an array of nodes which fit the style requirements provided
 */
export const getAllContentWithStyles = (nodes, styles) => {
  if (!nodes.length) return [];

  const nodesWithStyling = [];

  nodes.forEach(node => {
    if (node.nodeName === "#text") return;
    const stylesMatching = Object.keys(styles).map(styleKey => {
      const styleValue = styles[styleKey];
      if (styleKey === "font-family") {
        // need to perform a contains here since font-family name may contain italic or bold version
        return (
          !!node.style &&
          !!node.style[styleKey] &&
          node.style[styleKey].includes(styleValue)
        );
      }
      if (
        styleValue === "*" &&
        (styleKey === "color" || styleKey === "font-size")
      ) {
        // need to ignore the content as long as there is any
        return !!node.style && !!node.style[styleKey];
      }

      // check if the node has styleKey: styleValue in styles
      return (
        !!node.style &&
        !!node.style[styleKey] &&
        node.style[styleKey] === styleValue
      );
    });

    if (stylesMatching.every(match => match)) {
      nodesWithStyling.push(node);
    }
  });

  return nodesWithStyling;
};

/**
 * @desc gets a list of HTMLElements from the given nodes which are anchor nodes
 * @param {[HTMLElement]} nodes - a list of nodes to check for anchor nodes
 * @returns {[HTMLElement]} - an array of nodes which are anchor nodes
 */

export const getAllLinkedContent = nodes => {
  const linkedNodes = nodes.filter(node => node && node.nodeName === "A");
  return nodesUnique(linkedNodes);
};

/**
 * @desc gets a list of HTMLElements from the given nodes which are mark nodes
 * @param {[HTMLElement]} nodes - a list of nodes to check for anchor nodes
 * @returns {[HTMLElement]} - an array of nodes which are anchor nodes
 */

export const getAllMarkContent = nodes => {
  const markNodes = nodes.filter(node => node && node.nodeName === "MARK");
  return nodesUnique(markNodes);
};

/**
 * @desc gets a list of HTMLElements from the given nodes which are superscript nodes
 * @param {[HTMLElement]} nodes - a list of nodes to check for anchor nodes
 * @returns {[HTMLElement]} - an array of nodes which are anchor nodes
 */

export const getAllSuperscriptContent = nodes => {
  const superscriptNodes = nodes.filter(
    node => node && node.nodeName === "SUP"
  );
  return nodesUnique(superscriptNodes);
};

/**
 * @desc gets a list of HTMLElements from the given nodes which have color styling
 * @param {[HTMLElement]} nodes - a list of nodes to check for the given styles
 * @returns {[HTMLElement]} - an array of nodes which have color styling
 */
export const getAllColoredContent = nodes => {
  return nodesUnique([
    ...getAllContentWithStyles(nodes, {
      color: "*"
    })
  ]);
};

/**
 * @desc gets a list of HTMLElements from the given nodes which have font-size styling
 * @param {[HTMLElement]} nodes - a list of nodes to check for the given styles
 * @returns {[HTMLElement]} - an array of nodes which have font-size styling
 */
export const getAllFontSizeContent = nodes => {
  return nodesUnique([
    ...getAllContentWithStyles(nodes, {
      "font-size": "*"
    })
  ]);
};

/**
 * @desc gets a list of HTMLElements from the given nodes which have font-family styling
 * @param {[HTMLElement]} nodes - a list of nodes to check for the given styles
 * @returns {[HTMLElement]} - an array of nodes which have font-family styling
 */
export const getAllFontFamilyContent = nodes => {
  return nodesUnique([
    ...getAllContentWithStyles(nodes, {
      "font-family": ""
    })
  ]);
};

/**
 * @desc gets a list of HTMLElements from the given nodes which have underline styling
 * @param {[HTMLElement]} nodes - a list of nodes to check for the given styles
 * @returns {[HTMLElement]} - an array of nodes which have underline styling
 */
export const getAllUnderlineContent = nodes => {
  return nodesUnique([
    ...getAllContentWithStyles(nodes, {
      "text-decoration-line": "underline"
    })
  ]);
};

/**
 * @desc gets a list of HTMLElements from the given nodes which have bold styling
 * @param {[HTMLElement]} nodes - a list of nodes to check for the given styles
 * @returns {[HTMLElement]} - an array of nodes which have bold styling
 */
export const getAllBoldContent = nodes => {
  return nodesUnique([
    ...getAllContentWithStyles(nodes, {
      "font-weight": "bold"
    }),
    ...getAllContentWithStyles(nodes, {
      "font-family": "-Bold"
    })
  ]);
};

/**
 * @desc gets a list of HTMLElements from the given nodes which have italic styling
 * @param {[HTMLElement]} nodes - a list of nodes to check for the given styles
 * @returns {[HTMLElement]} - an array of nodes which have italic styling
 */
export const getAllItalicContent = nodes => {
  return nodesUnique([
    ...getAllContentWithStyles(nodes, {
      "font-style": "italic"
    }),
    ...getAllContentWithStyles(nodes, {
      "font-family": "-Italic"
    }),
    ...getAllContentWithStyles(nodes, {
      "font-family": "-BoldItalic"
    })
  ]);
};

/**
 * @desc - traverses the node tree downwards to find the closest text node child
 * @param {HTMLElement} node - a HTMLElement to use as the starting point for our tree traversal
 * @returns {HTMLElement} - the closest text node downwards from the starting node
 */
export const getClosestTextChild = node => {
  if (node.nodeName === "#text") return node;
  if (!node.firstChild) return null;

  let textChild;

  const getNextChildNode = parentNode => {
    if (parentNode.firstChild && parentNode.firstChild.nodeName === "#text") {
      return (textChild = parentNode.firstChild);
    }
    getNextChildNode(parentNode.firstChild);
  };
  getNextChildNode(node);

  return textChild;
};

export const getLastTextChild = node => {
  if (node.nodeName === "#text") return node;
  if (!node.lastChild) return null;

  let textChild;

  const getPreviousChildNode = parentNode => {
    if (parentNode.lastChild && parentNode.lastChild.nodeName === "#text") {
      return (textChild = parentNode.lastChild);
    }
    getPreviousChildNode(parentNode.lastChild);
  };
  getPreviousChildNode(node);

  return textChild;
};

/**
 * @desc checks a nodes styling to confirm whether it has any styling not in our ignored keys list
 * @param {HTMLElement} node - the node to check for default styling
 * @param {[String]} ignoredKeys - the style keys we should ignore during the comparison
 * @returns {Boolean} - true if the node has no styling not in our ignored keys list
 */
export const getIsSpanDefaultStyles = (node, ignoredKeys = []) => {
  const nodeClone = node.cloneNode();
  ignoredKeys.forEach(key => {
    nodeClone.style[key] = null;
  });
  return isNodeStylesEmpty(nodeClone);
};

/**
 * @desc remove font-family styling from given selection
 * @param {Object} nodeDefinition - arguments for function
 * @param {HTMLElement[]} nodeDefinition.styledNodes - the nodes that we are wanting to remove font-family from
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 */
export const removeFontFamilyFromSelection = ({
  styledNodes,
  nodesInRange
}) => {
  return removeStyleFromSelection({
    styledNodes,
    nodesInRange,
    targetStyle: { "font-family": "" },
    styleName: "font-family"
  });
};

/**
 * @desc remove underline styling from given selection
 * @param {Object} nodeDefinition - arguments for function
 * @param {HTMLElement[]} nodeDefinition.styledNodes - the nodes that we are wanting to remove underline from
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 */
export const removeUnderlineFromSelection = ({ styledNodes, nodesInRange }) => {
  return removeStyleFromSelection({
    styledNodes,
    nodesInRange,
    targetStyle: { "text-decoration-line": "underline" },
    styleName: "underline"
  });
};

/**
 * @desc remove bold styling from given selection
 * @param {Object} nodeDefinition - arguments for function
 * @param {HTMLElement[]} nodeDefinition.styledNodes - the nodes that we are wanting to remove bold from
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 */
export const removeBoldFromSelection = ({ styledNodes, nodesInRange }) => {
  return removeStyleFromSelection({
    styledNodes,
    nodesInRange,
    targetStyle: { "font-weight": "bold" },
    styleName: "bold"
  });
};

/**
 * @desc remove italic styling from given selection
 * @param {Object} nodeDefinition - arguments for function
 * @param {HTMLElement[]} nodeDefinition.styledNodes - the nodes that we are wanting to remove italic from
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 */
export const removeItalicFromSelection = ({ styledNodes, nodesInRange }) => {
  return removeStyleFromSelection({
    styledNodes,
    nodesInRange,
    targetStyle: { "font-style": "italic" },
    styleName: "italic"
  });
};

/**
 * @desc remove font-size styling from given selection
 * @param {Object} nodeDefinition - arguments for function
 * @param {HTMLElement[]} nodeDefinition.styledNodes - the nodes that we are wanting to remove bold from
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 */
export const removeFontSizeFromSelection = ({ styledNodes, nodesInRange }) => {
  return removeStyleFromSelection({
    styledNodes,
    nodesInRange,
    targetStyle: { "font-size": "" },
    styleName: "font-size"
  });
};

/**
 * @desc remove color styling from given selection
 * @param {Object} nodeDefinition - arguments for function
 * @param {HTMLElement[]} nodeDefinition.styledNodes - the nodes that we are wanting to remove bold from
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 */
export const removeColorFromSelection = ({ styledNodes, nodesInRange }) => {
  return removeStyleFromSelection({
    styledNodes,
    nodesInRange,
    targetStyle: { color: "" },
    styleName: "color"
  });
};

export const convertAnchorToSpan = linkedNode => {
  // we have a fully selected anchor node (all linked nodes should be anchor nodes)
  // when a node is fully selected we should convert it to a span and remove the href
  const importantStylesOnNode = pick(
    linkedNode.style,
    Object.keys(importantStylesMap)
  );

  if (Object.values(importantStylesOnNode).join("") === "") {
    // there are no styles left on the node, extract contents intead of converting it
    return extractContentFromSpan(linkedNode);
  }

  // create out wrapper span and add the styling from the anchor node
  const wrapperNode = document.createElement("span");
  Object.keys(importantStylesOnNode).forEach(styleKey => {
    wrapperNode.style[styleKey] = importantStylesOnNode[styleKey];
  });

  // get our linked nodes children and move them to our wrapperNode
  const anchorChildren = Array.from(linkedNode.childNodes);

  anchorChildren.forEach(node => {
    wrapperNode.appendChild(node);
  });

  // add the wrapper node in front of the linked node
  linkedNode.parentNode.insertBefore(wrapperNode, linkedNode);

  // remove the linked node
  linkedNode.outerHTML = "";

  return wrapperNode;
};

export const convertMarkToStylableNode = linkedNode => {
  // we have a fully selected mark node
  // when a node is fully selected we should convert it to a span or an anchor tag
  // based on if a href was provided
  const importantStylesOnNode = pick(
    linkedNode.style,
    Object.keys(importantStylesMap)
  );

  if (Object.values(importantStylesOnNode).join("") === "") {
    // there are no styles left on the node, extract contents intead of converting it
    return extractContentFromSpan(linkedNode);
  }

  const linkedHref = linkedNode.getAttribute("href");

  // create out wrapper span and add the styling from the anchor node
  const wrapperNode = linkedHref
    ? document.createElement("a")
    : document.createElement("span");
  Object.keys(importantStylesOnNode).forEach(styleKey => {
    wrapperNode.style[styleKey] = importantStylesOnNode[styleKey];
  });

  if (linkedHref) {
    wrapperNode.setAttribute("href", linkedNode.getAttribute("href"));
  }

  // get our linked nodes children and move them to our wrapperNode
  const anchorChildren = Array.from(linkedNode.childNodes);

  anchorChildren.forEach(node => {
    wrapperNode.appendChild(node);
  });

  // add the wrapper node in front of the linked node
  linkedNode.parentNode.insertBefore(wrapperNode, linkedNode);

  // remove the linked node
  linkedNode.outerHTML = "";

  return wrapperNode;
};

/**
 * @desc remove given styling styling from given selection
 * @param {Object} nodeDefinition - arguments for function
 * @param {HTMLElement[]} nodeDefinition.styledNodes - the nodes that we are wanting to remove bold from
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 * @param {Object} nodeDefinition.targetStyle - an object defining the styles to remove from the selection
 * @param {string} nodeDefinition.styleName - the name for the change our style target represents
 */
export const removeStyleFromSelection = ({
  styledNodes,
  nodesInRange,
  targetStyle = {},
  styleName
}) => {
  const getStripFunctionFromStyleName = (styleName, styledNode) => {
    switch (styleName) {
      case "anchor": {
        return convertAnchorToSpan;
      }
      case "superscript":
      case "mark": {
        return convertMarkToStylableNode;
      }
      case "bold": {
        const element = getNearestContentEditableParentFromSelection();
        // get the current font-family for the element or selection
        const closestNodeWithId = getClosestParentWithIdAttribute(element);

        let currentElementFontData = {};

        if (closestNodeWithId) {
          const elementDataId = closestNodeWithId.id;
          const elementData =
            window.easil.editor.designData.elements[elementDataId];
          const elementFontFamily = elementData.fontFamily;
          // get the font family data for the current textbox font
          currentElementFontData = FontToolBox.getFontData(elementFontFamily);
        }

        if (
          !!styledNode.style &&
          styledNode.style["font-family"] &&
          styledNode.style["font-family"].includes("-Bold")
        ) {
          if (styledNode.style["font-family"].includes("-BoldItalic")) {
            const fontFamily = FontToolBox.getFontFamilyFor({
              fontName: styledNode.style["font-family"],
              isItalicVersion: true,
              isBoldVersion: true
            });
            const fontData = FontToolBox.getFontData(fontFamily);

            // the node is a font family bold-italic, we should just set it back to italic font of same family family
            return node => {
              node.style["font-family"] = fontData.italicFontFamilyName;
              return node;
            };
          }
          const fontFamily = FontToolBox.getFontFamilyFor({
            fontName: styledNode.style["font-family"],
            isItalicVersion: false,
            isBoldVersion: true
          });
          const fontData = FontToolBox.getFontData(fontFamily);

          if (
            currentElementFontData.fontFamilyName === fontData.fontFamilyName
          ) {
            // from the current element font family so we should just remove font family style
            return node => {
              node.style["font-family"] = null;
              return node;
            };
          } else {
            // the node is a font family bold, we should just set it back to regular font of same family family
            return node => {
              node.style["font-family"] = fontData.fontFamilyName;
              return node;
            };
          }
        }
        return removeStyleFromNode;
      }
      case "italic": {
        const element = getNearestContentEditableParentFromSelection();
        // get the current font-family for the element or selection
        const closestNodeWithId = getClosestParentWithIdAttribute(element);

        let currentElementFontData = {};

        if (closestNodeWithId) {
          const elementDataId = closestNodeWithId.id;
          const elementData =
            window.easil.editor.designData.elements[elementDataId];
          const elementFontFamily = elementData.fontFamily;
          // get the font family data for the current textbox font
          currentElementFontData = FontToolBox.getFontData(elementFontFamily);
        }

        if (
          !!styledNode.style &&
          styledNode.style["font-family"] &&
          (styledNode.style["font-family"].includes("-Italic") ||
            styledNode.style["font-family"].includes("-BoldItalic"))
        ) {
          if (styledNode.style["font-family"].includes("-BoldItalic")) {
            const fontFamily = FontToolBox.getFontFamilyFor({
              fontName: styledNode.style["font-family"],
              isItalicVersion: true,
              isBoldVersion: true
            });
            const fontData = FontToolBox.getFontData(fontFamily);
            // the node is a font family bold-italic, we should just set it back to bold font of same family family
            return node => {
              node.style["font-family"] = fontData.boldFontFamilyName;
              return node;
            };
          }
          const fontFamily = FontToolBox.getFontFamilyFor({
            fontName: styledNode.style["font-family"],
            isItalicVersion: true,
            isBoldVersion: false
          });
          const fontData = FontToolBox.getFontData(fontFamily);

          if (
            currentElementFontData.fontFamilyName === fontData.fontFamilyName
          ) {
            // from the current element font family so we should just remove font family style
            return node => {
              node.style["font-family"] = null;
              return node;
            };
          } else {
            // the node is a font family bold, we should just set it back to regular font of same family family
            return node => {
              node.style["font-family"] = fontData.fontFamilyName;
              return node;
            };
          }
        }
        return removeStyleFromNode;
      }
      default:
        return removeStyleFromNode;
    }
  };

  styledNodes.forEach(styledNode => {
    let stripFunction = getStripFunctionFromStyleName(styleName, styledNode);

    const closestTextNode = getClosestTextChild(styledNode);
    if (!closestTextNode) {
      styledNode.outerHTML = "";
      return;
    }

    const isNodeOnlyTargetStyle = getIsSpanDefaultStyles(
      styledNode,
      Object.keys(targetStyle)
    );

    // check if the node is fully within the selection
    const isNodeAMiddleNode = !!nodesInRange.middleNodes.find(
      middleNode => styledNode === middleNode
    );

    const isStartOfNodeInSelection =
      // is not the start node
      nodesInRange.startNode !== closestTextNode ||
      // the start offset is not 0
      nodesInRange.startOffset === 0;

    const isEndOfNodeInSelection =
      // it is not the end node
      nodesInRange.endNode !== closestTextNode ||
      // the end offset is not the end node length
      nodesInRange.endOffset === nodesInRange.endNode.length;

    const isRangeNodeFullySelected =
      isStartOfNodeInSelection && isEndOfNodeInSelection;

    const isNodeFullySelected = isNodeAMiddleNode || isRangeNodeFullySelected;

    if (isNodeFullySelected) {
      // when a node is fully selected we can just remove the target styling from it
      stripFunction(styledNode, styleName);
    } else {
      let nextNode = closestTextNode;

      if (!isStartOfNodeInSelection) {
        // there is a section of text before out selection starts
        // clone the current span for the start section
        const startCloneWrapper = styledNode.cloneNode();
        // create a range for the start of the section if it is needed
        const startRange = createRangeAndSet(
          nextNode,
          nextNode,
          0,
          nodesInRange.startOffset
        );

        // wrap the start in our clone
        startRange.surroundContents(startCloneWrapper);
        nextNode = startCloneWrapper.nextSibling;
      }

      const middleSectionEndOffset =
        nodesInRange.endOffset -
        (isStartOfNodeInSelection ? 0 : nodesInRange.startOffset);

      if (!isNodeOnlyTargetStyle) {
        // when the node has anything but target style on it we wrap the selected area in a clone of the original span without target style
        // clone the current span for the selected section and remove the target style from it
        let selectionCloneWrapper = styledNode.cloneNode();

        selectionCloneWrapper = stripFunction(selectionCloneWrapper, styleName);
        // create a range for the middle of the section
        // this will always start at 0 since the start would have been stripped out by now if there was one
        const middleRange = createRangeAndSet(
          nextNode,
          nextNode,
          0,
          middleSectionEndOffset
        );

        // wrap the start in our clone
        middleRange.surroundContents(selectionCloneWrapper);
        nextNode = selectionCloneWrapper.nextSibling;
      }

      if (!isEndOfNodeInSelection) {
        // there is a section of text ater out selection ends
        // clone the current span for the end section
        const endCloneWrapper = styledNode.cloneNode();
        // create a range for the start of the section if it is needed
        const endRange = createRangeAndSet(
          nextNode,
          nextNode,
          isNodeOnlyTargetStyle ? middleSectionEndOffset : 0,
          nextNode.length
        );

        // wrap the start in our clone
        endRange.surroundContents(endCloneWrapper);
      }

      // pull the children out and remove the span
      extractContentFromSpan(styledNode);
    }
  });
};

/**
 * @desc add given styling styling to given selection
 * @param {Object} nodeDefinition - arguments for function
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 * @param {HTMLElement} nodeDefinition.element - an object defining the boundary element for our selection to stay within
 * @param {string} nodeDefinition.attributesToApply - an object defining the styling we should apply to nodes within the given selection
 */
export const applyAttributesToSelection = ({
  nodesInRange,
  element,
  attributesToApply,
  applyAttributes = setStylesOnNodes
}) => {
  if (!nodesInRange) return;

  let {
    startNode,
    endNode,
    middleNodes,
    startOffset,
    endOffset
  } = nodesInRange;

  if (startNode === element) {
    startNode = endNode;
    startOffset = 0;
  }

  if (endNode === element) {
    endNode = startNode;
    endOffset = endNode.textContent.length;
  }

  // check if we need to handle start and end nodes seperately
  const isEndAndStartSameNode = endNode === startNode;

  let nodesToApplyAttributesTo = middleNodes;

  if (isEndAndStartSameNode && startOffset === endOffset) {
    return applyAttributes([startNode], {}, element);
  }

  const isStartNodeFullySelected =
    startOffset === 0 &&
    (!isEndAndStartSameNode || !endNode.length || endOffset === endNode.length);

  const isEndNodeFullySelected =
    (isEndAndStartSameNode && isStartNodeFullySelected) ||
    endOffset === endNode.length ||
    !endNode.length;

  if (!isStartNodeFullySelected) {
    // we need to handle wrapping the start nodes selected content
    // just wrap the part we want in a span and add it to nodesToStyle
    let _startNode = startNode;
    if (_startNode.nodeName !== "#text") {
      _startNode = getClosestTextChild(startNode);
    }

    const rangeStart = startOffset;
    const rangeEnd = isEndAndStartSameNode
      ? endOffset
      : _startNode.textContent.length;

    const startTop = getClosestNodeWithSiblings(_startNode, element);
    const endSectionText = _startNode.splitText(
      clamp(rangeEnd, 0, _startNode.textContent.length)
    );
    const selectedSectionTarget =
      (endSectionText && endSectionText.previousSibling) || _startNode;
    let selectedSectionText = selectedSectionTarget.splitText(
      clamp(rangeStart, 0, selectedSectionTarget.textContent.length)
    );
    const startSectionText = selectedSectionText.previousSibling;

    const textNodes = [startSectionText, selectedSectionText, endSectionText];

    if (stylableNodeNames.includes(startTop.nodeName)) {
      // there is a span wrapping this so we should clone it across the text nodes and then remove it
      const wrappedTextNodes = textNodes.map(textNode => {
        if (textNode.length === 0) return null;
        const wrapperNode = startTop.cloneNode();
        wrapperNode.innerHTML = "";

        wrapNodeContentInNode(textNode, wrapperNode);

        // move span out of the original
        startTop.parentNode.insertBefore(wrapperNode, startTop);

        return wrapperNode;
      });

      selectedSectionText = wrappedTextNodes[1];

      // get rid of the original span
      startTop.outerHTML = "";
    }
    // add the selectedSectionText to our middle nodes to get its styling
    nodesToApplyAttributesTo = [
      selectedSectionText,
      ...nodesToApplyAttributesTo
    ];
  }
  if (!isEndNodeFullySelected && !isEndAndStartSameNode) {
    // we need to handle wrapping the end nodes selected content
    // just wrap the part we want in a span and add it to nodesToApplyAttributesTo
    const rangeStart = 0;
    let rangeEnd = endOffset;

    let _endNode = endNode;
    if (endNode.nodeName !== "#text") {
      _endNode = getLastTextChild(endNode);
      rangeEnd = _endNode.textContent.length;
    }

    const endTop = getClosestNodeWithSiblings(_endNode, element);

    const endSectionText = _endNode.splitText(
      clamp(rangeEnd, 0, _endNode.textContent.length)
    );
    const selectedSectionTarget =
      (endSectionText && endSectionText.previousSibling) || _endNode;
    let selectedSectionText = selectedSectionTarget.splitText(
      clamp(rangeStart, 0, selectedSectionTarget.textContent.length)
    );
    const startSectionText = selectedSectionText.previousSibling;

    const textNodes = [startSectionText, selectedSectionText, endSectionText];

    if (stylableNodeNames.includes(endTop.nodeName)) {
      // there is a span wrapping this so we should clone it across the text nodes and then remove it
      const wrappedTextNodes = textNodes.map(textNode => {
        if (textNode.length === 0) return null;
        const wrapperNode = endTop.cloneNode();
        wrapperNode.innerHTML = "";

        wrapNodeContentInNode(textNode, wrapperNode);

        // move span out of the original
        endTop.parentNode.insertBefore(wrapperNode, endTop);

        return wrapperNode;
      });

      selectedSectionText = wrappedTextNodes[1];

      // get rid of the original span
      endTop.outerHTML = "";
    }
    // add the selectedSectionText to our middle nodes to get its styling
    nodesToApplyAttributesTo = [
      selectedSectionText,
      ...nodesToApplyAttributesTo
    ];
  }

  if (
    isStartNodeFullySelected &&
    isEndNodeFullySelected &&
    isEndAndStartSameNode &&
    startNode === element
  ) {
    return applyAttributes(
      Array.from(startNode.childNodes),
      attributesToApply,
      element
    );
  }

  return applyAttributes(
    nodesToApplyAttributesTo.filter(x => x),
    attributesToApply,
    element
  );
};

/**
 * @desc Wrap the given selection in a link.
 * @param {Object} nodeDefinition - arguments for function
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 * @param {HTMLElement} nodeDefinition.element - an object defining the boundary element for our selection to stay within
 * @param {string} nodeDefinition.href - the link destination
 */
export const applyLinkToSelection = ({ nodesInRange, element, href }) => {
  const parsedURL = parseLinkUrl(href);

  return applyAttributesToSelection({
    nodesInRange,
    element,
    attributesToApply: {
      href: parsedURL,
      rel: "noopener noreferrer",
      target: "_blank"
    },
    applyAttributes: (...args) => setAttributesOnNodes(...args, "a")
  });
};

/**
 * @desc wrap a selection in mark tags
 * @param {Object} nodeDefinition - arguments for function
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 * @param {HTMLElement} nodeDefinition.element - an object defining the boundary element for our selection to stay within
 * @param {string} nodeDefinition.color - the color we wish to apply to the selection
 */
export const applyMarkToSelection = (element, nodesInRange) => {
  if (
    window.getSelection().anchorNode === null ||
    window.getSelection().anchorNode === document
  )
    return;
  return applyAttributesToSelection({
    nodesInRange,
    element,
    attributesToApply: [],
    applyAttributes: (...args) => setAttributesOnNodes(...args, "mark")
  });
};

/**
 * @desc wrap a selection in superscript tags
 * @param {Object} nodeDefinition - arguments for function
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 * @param {HTMLElement} nodeDefinition.element - an object defining the boundary element for our selection to stay within
 * @param {string} nodeDefinition.color - the color we wish to apply to the selection
 */
export const applySuperscriptToSelection = ({ element, nodesInRange }) => {
  if (
    window.getSelection().anchorNode === null ||
    window.getSelection().anchorNode === document
  )
    return;
  return applyAttributesToSelection({
    nodesInRange,
    element,
    attributesToApply: [],
    applyAttributes: (...args) => setAttributesOnNodes(...args, "SUP")
  });
};

/**
 * @desc add given font-size to given selection
 * @param {Object} nodeDefinition - arguments for function
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 * @param {HTMLElement} nodeDefinition.element - an object defining the boundary element for our selection to stay within
 * @param {string} nodeDefinition.fontSize - the fontSize we wish to apply to the selection
 */
export const applyFontSizeToSelection = ({
  nodesInRange,
  element,
  fontSize
}) => {
  return applyAttributesToSelection({
    nodesInRange,
    element,
    attributesToApply: {
      "font-size": fontSize
    }
  });
};

/**
 * @desc add given font-family to given selection
 * @param {Object} nodeDefinition - arguments for function
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 * @param {HTMLElement} nodeDefinition.element - an object defining the boundary element for our selection to stay within
 * @param {string} nodeDefinition.fontFamily - the fontFamily we wish to apply to the selection
 */
export const applyFontFamilyToSelection = ({
  nodesInRange,
  element,
  fontFamily
}) => {
  return applyAttributesToSelection({
    nodesInRange,
    element,
    attributesToApply: {
      "font-family": fontFamily
    }
  });
};

/**
 * @desc add given color to given selection
 * @param {Object} nodeDefinition - arguments for function
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 * @param {HTMLElement} nodeDefinition.element - an object defining the boundary element for our selection to stay within
 * @param {string} nodeDefinition.color - the color we wish to apply to the selection
 */
export const applyColorToSelection = ({ nodesInRange, element, color }) => {
  return applyAttributesToSelection({
    nodesInRange,
    element,
    attributesToApply: {
      color: color
    }
  });
};

/**
 * @desc add underline to given selection
 * @param {Object} nodeDefinition - arguments for function
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 * @param {HTMLElement} nodeDefinition.element - an object defining the boundary element for our selection to stay within
 */
export const applyUnderlineToSelection = ({ nodesInRange, element }) => {
  return applyAttributesToSelection({
    nodesInRange,
    element,
    attributesToApply: { "text-decoration-line": "underline" }
  });
};

/**
 * @desc add bold to given selection
 * @param {Object} nodeDefinition - arguments for function
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 * @param {HTMLElement} nodeDefinition.element - an object defining the boundary element for our selection to stay within
 */
export const applyBoldToSelection = ({ nodesInRange, element }) => {
  // get the current font-family for the element or selection
  const closestNodeWithId = getClosestParentWithIdAttribute(element);

  let currentElementFontData = {};

  if (closestNodeWithId) {
    const elementDataId = closestNodeWithId.id;
    const elementData = window.easil.editor.designData.elements[elementDataId];
    const elementFontFamily = elementData.fontFamily;
    // get the font family data for the current textbox font
    currentElementFontData = FontToolBox.getFontData(elementFontFamily);
  }

  return applyAttributesToSelection({
    nodesInRange,
    element,
    attributesToApply: { "font-weight": "bold" },
    applyAttributes: (nodes, styles, element) =>
      setBoldOnNodes(nodes, styles, element, currentElementFontData)
  });
};

const applyIndentNodeSelection = nodes => {
  if (nodes.length === 1 && nodes[0].textContent === "") {
    // we have an empty selection, we should find nodes between last break and next break
    const node = nodes[0];
    const breakContents = [node];
    let searchNode = node;
    while (
      searchNode.previousSibling &&
      searchNode.previousSibling.nodeName !== "BR"
    ) {
      breakContents.push(searchNode.previousSibling);
      searchNode = searchNode.previousSibling;
    }
    while (searchNode.nextSibling && searchNode.nextSibling.nodeName !== "BR") {
      breakContents.push(searchNode.nextSibling);
      searchNode = searchNode.nextSibling;
    }

    return breakContents.filter(contentNode => contentNode.textContent !== "");
  }
  if (!nodes || !nodes.length) {
    return [];
  }
  const parentNode = nodes[0].parentNode;
  if (parentNode.nodeName !== "SPAN") {
    return nodes;
  }
  if (
    parentNode.childNodes.length === nodes.length &&
    nodes.every(node => parentNode.contains(node))
  ) {
    // all nodes are in the same parent
    return [parentNode];
  }
  return nodes;
};

export const applyIndentationToSelection = ({
  nodesInRange,
  element,
  markerType
}) => {
  const nodes = applyAttributesToSelection({
    nodesInRange,
    element,
    attributesToApply: {},
    applyAttributes: applyIndentNodeSelection
  });

  const indentedNodes = indentNodes({
    nodes,
    isReplace: true,
    markerType
  });

  return indentedNodes;
};

export const removeIndentationFromSelection = ({ nodesInRange, element }) => {
  return unIndentNodes({
    nodes: [nodesInRange.startNode]
  });
};

export const unindentSelection = ({ nodesInRange, element }) => {
  return unIndentNodes({
    nodes: nodesInRange.allNodes,
    isReplace: true,
    rootNode: getNearestContentEditableParentFromSelection()
  });
};

/**
 * @desc add italic to given selection
 * @param {Object} nodeDefinition - arguments for function
 * @param {Object} nodeDefinition.nodesInRange - Object of node range output in the form: {
 *  startNode, - the selection range starting node
    middleNodes, - all fully selected nodes between the startNode and endNode node
    endNode, - the selection range ending node
    allNodes, - all nodes including those nested between the startNode and endNode
    startOffset, - the offset for our selection in the startNode
    endOffset: endOffset - the offset for our selection in the endNode
 * }
 * @param {HTMLElement} nodeDefinition.element - an object defining the boundary element for our selection to stay within
 */
export const applyItalicToSelection = ({ nodesInRange, element }) => {
  // get the current font-family for the element or selection
  const closestNodeWithId = getClosestParentWithIdAttribute(element);
  const elementDataId = closestNodeWithId.id;
  const elementData = window.easil.editor.designData.elements[elementDataId];
  const elementFontFamily = elementData.fontFamily;
  // get the font family data for the current textbox font
  const currentElementFontData = FontToolBox.getFontData(elementFontFamily);

  return applyAttributesToSelection({
    nodesInRange,
    element,
    attributesToApply: { "font-style": "italic" },
    applyAttributes: (nodes, styles, element) =>
      setItalicOnNodes(nodes, styles, element, currentElementFontData)
  });
};

/**
 * @desc sets the given attributes on the given nodes wrapping them in spans or divs if required
 * @param {HTMLElement[]} nodes - an array of HTMLElement nodes to apply attributes to
 * @param {Object} attributes - an object of attributes to apply to given nodes
 * @param {HTMLElement} element - the boundary node for our application of attributes to stay within
 * @param {string} wrapperNodeType - the type of node to use as a wrapper e.g. "span" defaults with "span"
 */
export const setAttributesOnNodes = (
  nodes,
  attributes,
  element,
  wrapperNodeType = "span"
) => {
  // map through nodes
  nodes.forEach(node => {
    const currentNode = getClosestNodeWithSiblings(node, element);
    if (currentNode.nodeName === "BR") {
      // do nothing to breaks since they don't need mark tags
      return;
    }
    if (["LI", "SUP"].includes(currentNode.nodeName)) {
      // create a DOMElement for the wrapper
      const wrappingNode = document.createElement(wrapperNodeType);
      // add given attributes to wrapper
      Object.keys(attributes).forEach(attributeKey => {
        wrappingNode[attributeKey] = attributes[attributeKey];
      });

      const children = Array.from(currentNode.childNodes);
      children.forEach(child => {
        wrappingNode.appendChild(child);
      });

      currentNode.appendChild(wrappingNode);
      return;
    }

    if (currentNode.nodeName === "#text") {
      // create a DOMElement for the wrapper
      const wrappingNode = document.createElement(wrapperNodeType);
      // add given attributes to span
      Object.keys(attributes).forEach(attributeKey => {
        wrappingNode[attributeKey] = attributes[attributeKey];
      });

      // create a new range to wrap
      const wrappingRange = createRangeAndSet(
        currentNode,
        currentNode,
        0,
        currentNode.length
      );

      wrappingRange.surroundContents(wrappingNode);
      return;
    }

    if (!caseInsensitiveStringCompare(currentNode.nodeName, wrapperNodeType)) {
      // create a DOMElement for the wrapper
      const wrappingNode = document.createElement(wrapperNodeType);

      const importantStylesForNode = pick(
        currentNode.style,
        Object.keys(importantStylesMap)
      );

      // add already present styles to node
      Object.keys(importantStylesForNode).forEach(styleKey => {
        wrappingNode.style[styleKey] = importantStylesForNode[styleKey];
      });

      const currentNodeHref = currentNode.getAttribute("href");
      if (currentNodeHref) {
        wrappingNode.setAttribute("href", currentNodeHref);
      }

      // add given attributes to span
      Object.keys(attributes).forEach(attributeKey => {
        wrappingNode[attributeKey] = attributes[attributeKey];
      });

      // create a new range to wrap
      const wrappingRange = document.createRange();
      wrappingRange.selectNodeContents(currentNode);

      wrappingRange.surroundContents(wrappingNode);

      if (currentNode.nodeName !== "#text") {
        extractContentFromSpan(currentNode);
      }
      return;
    }

    // set given attributes on currentNode
    Object.keys(attributes).forEach(attributeKey => {
      currentNode[attributeKey] = attributes[attributeKey];
    });
  });
};

/** updates the given style property on a node */
export const updateStyleForNode = (node, styleKey, styleValue) => {
  if (styleKey === "font-family") {
    node.style[styleKey] = `'${styleValue}'`;
  } else {
    node.style[styleKey] = styleValue;
  }
};

/**
 * @desc sets the given styles on the given nodes wrapping them in spans or divs if required
 * @param {HTMLElement[]} nodes - an array of HTMLElement nodes to apply styles to
 * @param {Object} styles - an object of styles to apply to given nodes
 * @param {HTMLElement} element - the boundary node for our application of styles to stay within
 */
export const setStylesOnNodes = (nodes, styles, element) => {
  if (isEmpty(styles)) {
    return nodes;
  }
  // map through nodes
  nodes.forEach(node => {
    const currentNode = getClosestNodeWithSiblings(node, element);
    switch (currentNode.nodeName) {
      case "#text": {
        // just wrap in a styled span
        // create a span
        const wrappingSpan = document.createElement("span");
        // add given styles to span
        Object.keys(styles).forEach(styleKey =>
          updateStyleForNode(wrappingSpan, styleKey, styles[styleKey])
        );

        // create a new range to wrap
        const wrappingRange = createRangeAndSet(
          currentNode,
          currentNode,
          0,
          currentNode.length
        );

        wrappingRange.surroundContents(wrappingSpan);
        break;
      }

      // handle B and I nodes
      case "B": {
        let defaultStyles = { "font-weight": "bold" };
        const childNodes = extractContentFromSpan(node);
        const lastChildNode = childNodes[childNodes.length - 1];
        const range = createRangeAndSet(
          childNodes[0],
          lastChildNode,
          0,
          lastChildNode.length
        );

        const wrapperNode = document.createElement("span");
        Object.keys(defaultStyles).forEach(key => {
          wrapperNode.style[key] = defaultStyles[key];
        });
        Object.keys(styles).forEach(styleKey =>
          updateStyleForNode(wrapperNode, styleKey, styles[styleKey])
        );

        range.surroundContents(wrapperNode);
        break;
      }

      case "I": {
        const defaultStyles = { "font-style": "italic" };
        const childNodes = extractContentFromSpan(node);
        const lastChildNode = childNodes[childNodes.length - 1];
        const range = createRangeAndSet(
          childNodes[0],
          lastChildNode,
          0,
          lastChildNode.length
        );

        const wrapperNode = document.createElement("span");
        Object.keys(defaultStyles).forEach(key => {
          wrapperNode.style[key] = defaultStyles[key];
        });
        Object.keys(styles).forEach(styleKey =>
          updateStyleForNode(wrapperNode, styleKey, styles[styleKey])
        );

        range.surroundContents(wrapperNode);
        break;
      }
      // end b and i node handling
      // set given styles on currentNode
      case "A":
      case "SUP":
      case "SPAN":
      case "LI": {
        // set given styles on currentNode
        Object.keys(styles).forEach(styleKey =>
          updateStyleForNode(currentNode, styleKey, styles[styleKey])
        );
        break;
      }
      default: {
        console.log(
          `Node of type ${currentNode.nodeName} is not a valid target`
        );
        break;
      }
    }
  });
};

/**
 * @desc sets the given styles on the given nodes wrapping them in spans or divs if required
 * @param {HTMLElement[]} nodes - an array of HTMLElement nodes to apply styles to
 * @param {Object} styles - an object of styles to apply to given nodes
 * @param {HTMLElement} element - the boundary node for our application of styles to stay within
 */
export const setBoldOnNodes = (nodes, styles, element, elementFontData) => {
  const elementFontFamilyNames = [
    elementFontData.fontFamilyName,
    elementFontData.boldFontFamilyName,
    elementFontData.italicFontFamilyName,
    elementFontData.boldItalicFontFamilyName
  ];

  // map through nodes
  nodes.forEach(node => {
    const currentNode = getClosestNodeWithSiblings(node, element);
    let styleToApply = styles;
    if (
      !!currentNode.style &&
      !!currentNode.style["font-family"] &&
      !elementFontFamilyNames.includes(currentNode.style["font-family"])
    ) {
      const nodeFontFamily = currentNode.style["font-family"];
      // this node has a font-family but not from the elements current font-family
      if (nodeFontFamily.includes("-Italic")) {
        // the font is an italic variation so we should check for italic font family
        const nodeFontFamilyName = FontToolBox.getFontFamilyFor({
          fontName: nodeFontFamily,
          isItalicVersion: true,
          isBoldVersion: false
        });

        const nodeFontData = FontToolBox.getFontData(nodeFontFamilyName);

        if (!!nodeFontData && !!nodeFontData.boldItalicFontFamilyName) {
          styleToApply = {
            "font-family": nodeFontData.boldItalicFontFamilyName
          };
        } else {
          styleToApply = {
            "font-weight": "bold"
          };
        }
      } else if (nodeFontFamily.includes("-BoldItalic")) {
        // this should not be possible here
        console.error(
          "setBoldOnNodes should not be run on nodes which are already font-family bold"
        );
      } else if (nodeFontFamily.includes("-Bold")) {
        // this should not be possible here
        console.error(
          "setBoldOnNodes should not be run on nodes which are already font-family bold"
        );
      } else {
        // the font is neither italic or bold version already
        const nodeFontFamilyName = FontToolBox.getFontFamilyFor({
          fontName: nodeFontFamily,
          isItalicVersion: false,
          isBoldVersion: false
        });

        const nodeFontData = FontToolBox.getFontData(nodeFontFamilyName);

        if (!!nodeFontData && !!nodeFontData.boldFontFamilyName) {
          styleToApply = {
            "font-family": nodeFontData.boldFontFamilyName
          };
        } else {
          styleToApply = {
            "font-weight": "bold"
          };
        }
      }
    } else if (!!elementFontData.boldItalicFontFamilyName) {
      if (
        !!currentNode.style &&
        !!currentNode.style["font-family"] &&
        currentNode.style["font-family"] ===
          elementFontData.italicFontFamilyName &&
        !!elementFontData.boldItalicFontFamilyName
      ) {
        // is the italic version of the font family so set to boldItalic
        styleToApply = {
          "font-family": elementFontData.boldItalicFontFamilyName
        };
      } else {
        // just apply the bold version of the font
        styleToApply = {
          "font-family": elementFontData.boldFontFamilyName
        };
      }
    }

    switch (currentNode.nodeName) {
      case "#text": {
        // just wrap in a styled span
        // create a span
        const wrappingSpan = document.createElement("span");
        // add given styles to span
        Object.keys(styleToApply).forEach(styleKey => {
          wrappingSpan.style[styleKey] = styleToApply[styleKey];
        });

        // create a new range to wrap
        const wrappingRange = createRangeAndSet(
          currentNode,
          currentNode,
          0,
          currentNode.length
        );

        wrappingRange.surroundContents(wrappingSpan);
        break;
      }

      // handle B and I nodes
      case "B": {
        let defaultStyles = { "font-weight": "bold" };
        const childNodes = extractContentFromSpan(node);
        const lastChildNode = childNodes[childNodes.length - 1];
        const range = createRangeAndSet(
          childNodes[0],
          lastChildNode,
          0,
          lastChildNode.length
        );

        const wrapperNode = document.createElement("span");
        Object.keys(defaultStyles).forEach(key => {
          wrapperNode.style[key] = defaultStyles[key];
        });
        Object.keys(styleToApply).forEach(styleKey => {
          wrapperNode.style[styleKey] = styleToApply[styleKey];
        });

        range.surroundContents(wrapperNode);
        break;
      }

      case "I": {
        const defaultStyles = { "font-style": "italic" };
        const childNodes = extractContentFromSpan(node);
        const lastChildNode = childNodes[childNodes.length - 1];
        const range = createRangeAndSet(
          childNodes[0],
          lastChildNode,
          0,
          lastChildNode.length
        );

        const wrapperNode = document.createElement("span");
        Object.keys(defaultStyles).forEach(key => {
          wrapperNode.style[key] = defaultStyles[key];
        });
        Object.keys(styleToApply).forEach(styleKey => {
          wrapperNode.style[styleKey] = styleToApply[styleKey];
        });

        range.surroundContents(wrapperNode);
        break;
      }
      // end b and i node handling
      case "A": {
        // set given styles on currentNode
        Object.keys(styleToApply).forEach(styleKey => {
          currentNode.style[styleKey] = styleToApply[styleKey];
        });
        break;
      }
      case "SUP":
      case "SPAN":
      case "LI": {
        // set given styles on currentNode
        Object.keys(styleToApply).forEach(styleKey => {
          currentNode.style[styleKey] = styleToApply[styleKey];
        });
        break;
      }
      default: {
        console.log(
          `Node of type ${currentNode.nodeName} is not a valid target`
        );
        break;
      }
    }
  });
};

/**
 * @desc sets the given styles on the given nodes wrapping them in spans or divs if required
 * @param {HTMLElement[]} nodes - an array of HTMLElement nodes to apply styles to
 * @param {Object} styles - an object of styles to apply to given nodes
 * @param {HTMLElement} element - the boundary node for our application of styles to stay within
 */
export const setItalicOnNodes = (nodes, styles, element, elementFontData) => {
  const elementFontFamilyNames = [
    elementFontData.fontFamilyName,
    elementFontData.boldFontFamilyName,
    elementFontData.italicFontFamilyName,
    elementFontData.boldItalicFontFamilyName
  ];

  // map through nodes
  nodes.forEach(node => {
    const currentNode = getClosestNodeWithSiblings(node, element);
    let styleToApply = styles;

    if (
      !!currentNode.style &&
      !!currentNode.style["font-family"] &&
      !elementFontFamilyNames.includes(currentNode.style["font-family"])
    ) {
      const nodeFontFamily = currentNode.style["font-family"];
      // this node has a font-family but not from the elements current font-family
      if (nodeFontFamily.includes("-Bold")) {
        // the font is an bold variation so we should check for bold font family
        const nodeFontFamilyName = FontToolBox.getFontFamilyFor({
          fontName: nodeFontFamily,
          isItalicVersion: false,
          isBoldVersion: true
        });

        const nodeFontData = FontToolBox.getFontData(nodeFontFamilyName);

        if (!!nodeFontData && !!nodeFontData.boldItalicFontFamilyName) {
          styleToApply = {
            "font-family": nodeFontData.boldItalicFontFamilyName
          };
        } else {
          styleToApply = {
            "font-style": "italic"
          };
        }
      } else if (nodeFontFamily.includes("-BoldItalic")) {
        // this should not be possible here
        console.error(
          "setItalicOnNodes should not be run on nodes which are already font-family italic"
        );
      } else if (nodeFontFamily.includes("-Bold")) {
        // this should not be possible here
        console.error(
          "setItalicOnNodes should not be run on nodes which are already font-family italic"
        );
      } else {
        // the font is neither italic or bold version already
        const nodeFontFamilyName = FontToolBox.getFontFamilyFor({
          fontName: nodeFontFamily,
          isItalicVersion: false,
          isBoldVersion: false
        });

        const nodeFontData = FontToolBox.getFontData(nodeFontFamilyName);

        if (!!nodeFontData && !!nodeFontData.italicFontFamilyName) {
          styleToApply = {
            "font-family": nodeFontData.italicFontFamilyName
          };
        } else {
          styleToApply = {
            "font-style": "italic"
          };
        }
      }
    } else if (!!elementFontData.italicFontFamilyName) {
      if (
        !!currentNode.style &&
        !!currentNode.style["font-family"] &&
        currentNode.style["font-family"] ===
          elementFontData.boldFontFamilyName &&
        !!elementFontData.boldItalicFontFamilyName
      ) {
        // is the italic version of the font family so set to boldItalic
        styleToApply = {
          "font-family": elementFontData.boldItalicFontFamilyName
        };
      } else {
        // just apply the italic version of the font
        styleToApply = {
          "font-family": elementFontData.italicFontFamilyName
        };
      }
    }

    switch (currentNode.nodeName) {
      case "#text": {
        // just wrap in a styled span
        // create a span
        const wrappingSpan = document.createElement("span");
        // add given styles to span
        Object.keys(styleToApply).forEach(styleKey => {
          wrappingSpan.style[styleKey] = styleToApply[styleKey];
        });

        // create a new range to wrap
        const wrappingRange = createRangeAndSet(
          currentNode,
          currentNode,
          0,
          currentNode.length
        );

        wrappingRange.surroundContents(wrappingSpan);
        break;
      }

      // handle B and I nodes
      case "B": {
        let defaultStyles = { "font-weight": "bold" };
        const childNodes = extractContentFromSpan(node);
        const lastChildNode = childNodes[childNodes.length - 1];
        const range = createRangeAndSet(
          childNodes[0],
          lastChildNode,
          0,
          lastChildNode.length
        );

        const wrapperNode = document.createElement("span");
        Object.keys(defaultStyles).forEach(key => {
          wrapperNode.style[key] = defaultStyles[key];
        });
        Object.keys(styleToApply).forEach(styleKey => {
          wrapperNode.style[styleKey] = styleToApply[styleKey];
        });

        range.surroundContents(wrapperNode);
        break;
      }

      case "I": {
        const defaultStyles = { "font-style": "italic" };
        const childNodes = extractContentFromSpan(node);
        const lastChildNode = childNodes[childNodes.length - 1];
        const range = createRangeAndSet(
          childNodes[0],
          lastChildNode,
          0,
          lastChildNode.length
        );

        const wrapperNode = document.createElement("span");
        Object.keys(defaultStyles).forEach(key => {
          wrapperNode.style[key] = defaultStyles[key];
        });
        Object.keys(styleToApply).forEach(styleKey => {
          wrapperNode.style[styleKey] = styleToApply[styleKey];
        });

        range.surroundContents(wrapperNode);
        break;
      }
      // end b and i node handling
      case "A": {
        // set given styles on currentNode
        Object.keys(styleToApply).forEach(styleKey => {
          currentNode.style[styleKey] = styleToApply[styleKey];
        });
        break;
      }
      case "SUP":
      case "SPAN":
      case "LI": {
        // set given styles on currentNode
        Object.keys(styleToApply).forEach(styleKey => {
          currentNode.style[styleKey] = styleToApply[styleKey];
        });
        break;
      }
      default: {
        console.log(
          `Node of type ${currentNode.nodeName} is not a valid target`
        );
        break;
      }
    }
  });
};

/**
 * @desc when given a href applies this as an anchor node to the current selection, else removes all links from the current selection
 * @param {HTMLElement} element - the element boundary for our function to stay within while performing its modifications
 * @param {string} href - a url to add as the link href on selection
 */
export const toggleLinksOnSelection = (element, href) => {
  if (
    window.getSelection().anchorNode === null ||
    window.getSelection().anchorNode === document
  )
    return;
  const nodesInRange = getNodesInRange(element);

  const initialTextSelectionIndexes = getTextSelectionIndexes(element);

  if (!href) {
    // no href was provided so we must be wanting to remove the links from node range
    const linkedNodes = getAllLinkedContent(nodesInRange.allNodes);
    if (linkedNodes.length > 0) {
      removeStyleFromSelection({
        element,
        styledNodes: linkedNodes,
        nodesInRange,
        styleName: "anchor"
      });
    }
  } else {
    applyLinkToSelection({ element, nodesInRange, href });
  }

  // strip out empty text nodes
  Array.from(element.childNodes).forEach(node => {
    if (node.nodeName === "#text" && !node.textContent) {
      element.removeChild(node);
    }
  });

  normalizeElement(element);

  applySelectionFromTextIndexes(initialTextSelectionIndexes, element);
};

/**
 * @desc check whether selection contains a mark the current selection, else removes all marks from the current selection
 * @param {HTMLElement} element - the element boundary for our function to stay within while performing its modifications
 */
export const toggleMarkOnSelection = element => {
  const nodesInRange = getNodesInRange(element);

  if (
    window.getSelection().anchorNode === null ||
    window.getSelection().anchorNode === document ||
    !nodesInRange
  )
    return;

  const markedNodes = getAllMarkContent(nodesInRange.allNodes);

  if (markedNodes.length > 0) {
    removeStyleFromSelection({
      element,
      styledNodes: markedNodes,
      nodesInRange,
      styleName: "mark"
    });
  } else {
    applyMarkToSelection(element, nodesInRange);
  }

  element.normalize();
};

/**
 * @desc when selection contains no underline applies this to the current selection, else removes underline from the current selection
 * @param {HTMLElement} element - the element boundary for our function to stay within while performing its modifications
 */
export const toggleUnderlineOnSelection = element => {
  if (
    window.getSelection().anchorNode === null ||
    window.getSelection().anchorNode === document
  )
    return;
  const nodesInRange = getNodesInRange(element);

  // check if we already have underline content
  const underlineNodes = getAllUnderlineContent(nodesInRange.allNodes);

  const initialSelectionPosition = getCurrentSelectionPosition(element);

  if (underlineNodes.length > 0) {
    // some content is already underline so we want to remove the underline on that content
    removeUnderlineFromSelection({
      element,
      styledNodes: underlineNodes,
      nodesInRange
    });
  } else {
    // no underline so we are setting underline
    applyUnderlineToSelection({ element, nodesInRange });
  }

  // strip out empty text nodes
  Array.from(element.childNodes).forEach(node => {
    if (node.nodeName === "#text" && !node.textContent) {
      element.removeChild(node);
    }
  });

  normalizeElement(element);

  setCurrentSelectionPosition(initialSelectionPosition, element);
};

/**
 * @desc when given a font size applies this to the current selection, else removes all font size from the current selection
 * @param {HTMLElement} element - the element boundary for our function to stay within while performing its modifications
 * @param {string} size - the font size apply to the current selection
 */
export const toggleFontSizeOnSelection = (element, fontSize) => {
  const nodesInRange = getNodesInRange(element);

  const initialSelectionPosition = getCurrentSelectionPosition(element);

  if (!fontSize) {
    // no fontSize was provided so we must be wanting to remove the color from node range
    const fontSizeNodes = getAllFontSizeContent(nodesInRange.allNodes);
    if (fontSizeNodes.length > 0) {
      removeFontSizeFromSelection({
        element: element,
        styledNodes: fontSizeNodes,
        nodesInRange
      });
    }
  } else {
    applyFontSizeToSelection({ element: element, nodesInRange, fontSize });
  }

  // strip out empty text nodes
  Array.from(element.childNodes).forEach(node => {
    if (node.nodeName === "#text" && !node.textContent) {
      element.removeChild(node);
    }
  });

  normalizeElement(element);

  setCurrentSelectionPosition(initialSelectionPosition, element);
};

/**
 * @desc when given a font family applies this to the current selection, else removes all font family from the current selection
 * @param {HTMLElement} element - the element boundary for our function to stay within while performing its modifications
 * @param {string} fontFamily - the font family apply to the current selection
 */
export const toggleFontFamilyOnSelection = (element, fontFamily) => {
  const nodesInRange = getNodesInRange(element);

  const initialSelectionPosition = getCurrentSelectionPosition(element);

  if (!fontFamily) {
    // no fontFamily was provided so we must be wanting to remove the color from node range
    const fontFamilyNodes = getAllFontFamilyContent(nodesInRange.allNodes);
    if (fontFamilyNodes.length > 0) {
      removeFontFamilyFromSelection({
        element: element,
        styledNodes: fontFamilyNodes,
        nodesInRange
      });
    }
  } else {
    applyFontFamilyToSelection({ element: element, nodesInRange, fontFamily });
  }

  // strip out empty text nodes
  Array.from(element.childNodes).forEach(node => {
    if (node.nodeName === "#text" && !node.textContent) {
      element.removeChild(node);
    }
  });

  normalizeElement(element);

  setCurrentSelectionPosition(initialSelectionPosition, element);
};

/**
 * @desc when given a color applies this to the current selection, else removes all color from the current selection
 * @param {HTMLElement} element - the element boundary for our function to stay within while performing its modifications
 * @param {string} color - a hex color to apply to the current selection
 */
export const toggleColorOnSelection = (element, color, originalColor) => {
  if (
    window.getSelection().anchorNode === null ||
    window.getSelection().anchorNode === document
  )
    return;
  let _element = element;
  if (!_element || _element.contentEditable !== "true") {
    applySavedSelection();
    _element = getNearestContentEditableParentFromSelection();
  }

  const nodesInRange = getNodesInRange(_element);

  const initialSelectionPosition = getCurrentSelectionPosition(_element);

  if (!color) {
    // no color was provided so we must be wanting to remove the color from node range
    const coloredNodes = getAllColoredContent(nodesInRange.allNodes);
    if (coloredNodes.length > 0) {
      removeColorFromSelection({
        element: _element,
        styledNodes: coloredNodes,
        nodesInRange
      });
    }
  } else {
    applyColorToSelection({ element: _element, nodesInRange, color });
  }

  // strip out empty text nodes
  Array.from(_element.childNodes).forEach(node => {
    if (node.nodeName === "#text" && !node.textContent) {
      _element.removeChild(node);
    }
  });

  normalizeElement(_element);

  setCurrentSelectionPosition(initialSelectionPosition, _element);
};

export const isListContentInSelection = () => {
  const node = getNearestContentEditableParentFromSelection();

  if (!node) return false;

  const nodesInRange = getNodesInRange(node);

  if (!nodesInRange) return false;

  const ulNodes = nodesInRange.allNodes.filter(
    node => node.nodeName === "UL" || node.nodeName === "LI"
  );

  return ulNodes.length > 0;
};

export const isBoldContentInSelection = () => {
  const node = getNearestContentEditableParentFromSelection();

  if (!node) return false;

  const nodesInRange = getNodesInRange(node);

  if (!nodesInRange) return false;

  // check if we already have bold content
  const boldNodes = getAllBoldContent(nodesInRange.allNodes);

  return boldNodes.length > 0;
};

export const isSuperscriptContentInSelection = () => {
  const node = getNearestContentEditableParentFromSelection();

  if (!node) return false;

  const nodesInRange = getNodesInRange(node);

  if (!nodesInRange) return false;

  // check if we already have superscript content
  const superscriptNodes = getAllSuperscriptContent(nodesInRange.allNodes);

  return superscriptNodes.length > 0;
};

export const isItalicContentInSelection = () => {
  const node = getNearestContentEditableParentFromSelection();

  if (!node) return false;

  const nodesInRange = getNodesInRange(node);

  if (!nodesInRange) return false;

  // check if we already have italic content
  const italicNodes = getAllItalicContent(nodesInRange.allNodes);

  return italicNodes.length > 0;
};

export const isUnderlineContentInSelection = () => {
  const node = getNearestContentEditableParentFromSelection();

  if (!node) return false;

  const nodesInRange = getNodesInRange(node);

  if (!nodesInRange) return false;

  // check if we already have underline content
  const underlineNodes = getAllUnderlineContent(nodesInRange.allNodes);

  return underlineNodes.length > 0;
};

export const isMarkContentInSelection = () => {
  const node = getNearestContentEditableParentFromSelection();

  if (!node) return false;

  const nodesInRange = getNodesInRange(node);

  if (!nodesInRange) return false;

  // check if we already have underline content
  const markNodes = getAllMarkContent(nodesInRange.allNodes);

  return markNodes.length > 0;
};

/**
 * @desc toggles bold on the selection, when the selection contains any bold content removes all bold from selection, else applies bold to current selection
 * @param {HTMLElement} element - the element boundary for our function to stay within while performing its modifications
 */
export const toggleBoldOnSelection = element => {
  const nodesInRange = getNodesInRange(element);

  if (
    window.getSelection().anchorNode === null ||
    window.getSelection().anchorNode === document ||
    !nodesInRange
  )
    return;

  // check if we already have bold content
  const boldNodes = getAllBoldContent(nodesInRange.allNodes);

  const initialSelectionPosition = getCurrentSelectionPosition(element);

  if (boldNodes.length > 0) {
    // some content is already bold so we want to remove the bold on that content
    removeBoldFromSelection({ element, styledNodes: boldNodes, nodesInRange });
  } else {
    // no bold so we are setting bold
    applyBoldToSelection({ element, nodesInRange });
  }

  // strip out empty text nodes
  Array.from(element.childNodes).forEach(node => {
    if (node.nodeName === "#text" && !node.textContent) {
      element.removeChild(node);
    }
  });

  normalizeElement(element);

  setCurrentSelectionPosition(initialSelectionPosition, element);
};

/**
 * @desc toggles superscript on the selection, when the selection contains any superscript content removes all superscript from selection, else applies superscript to current selection
 * @param {HTMLElement} element - the element boundary for our function to stay within while performing its modifications
 */
export const toggleSuperscriptOnSelection = element => {
  const nodesInRange = getNodesInRange(element);

  if (
    window.getSelection().anchorNode === null ||
    window.getSelection().anchorNode === document ||
    !nodesInRange
  )
    return;

  // check if we already have superscript content
  const superscriptNodes = getAllSuperscriptContent(nodesInRange.allNodes);

  const initialSelectionPosition = getCurrentSelectionPosition(element);

  if (superscriptNodes.length > 0) {
    // some content is already superscript so we want to remove the superscript on that content
    removeStyleFromSelection({
      element,
      styledNodes: superscriptNodes,
      nodesInRange,
      styleName: "superscript"
    });
  } else {
    // no superscript so we are setting superscript
    applySuperscriptToSelection({ element, nodesInRange });
  }

  // strip out empty text nodes
  Array.from(element.childNodes).forEach(node => {
    if (node.nodeName === "#text" && !node.textContent) {
      element.removeChild(node);
    }
  });

  normalizeElement(element);

  setCurrentSelectionPosition(initialSelectionPosition, element);
};

export const toggleListOnSelection = (element, markerType) => {
  const nodesInRange = getNodesInRange(element);

  if (
    window.getSelection().anchorNode === null ||
    window.getSelection().anchorNode === document ||
    !nodesInRange
  )
    return;

  const ulNodes = nodesInRange.allNodes.filter(
    node => node.nodeName === "UL" || node.nodeName === "LI"
  );

  const initialSelectionPosition = getCurrentSelectionPosition(element);

  if (ulNodes.length > 0) {
    // already a list in the content, remove lists from content
    removeIndentation(nodesInRange.allNodes, element);
  } else {
    applyIndentationToSelection({ element, nodesInRange, markerType });
  }

  // strip out empty text nodes
  Array.from(element.childNodes).forEach(node => {
    if (node.nodeName === "#text" && !node.textContent) {
      element.removeChild(node);
    }
  });

  normalizeElement(element);

  setCurrentSelectionPosition(initialSelectionPosition, element);
};

/**
 * @desc applies indentation on the selection
 * @param {HTMLElement} element - the element boundary for our function to stay within while performing its modifications
 */
export const indentSelection = (element, markerType) => {
  const nodesInRange = getNodesInRange(element);

  if (
    window.getSelection().anchorNode === null ||
    window.getSelection().anchorNode === document ||
    !nodesInRange
  )
    return;

  const initialSelectionPosition = getCurrentSelectionPosition(element);

  applyIndentationToSelection({ element, nodesInRange, markerType });

  // strip out empty text nodes
  Array.from(element.childNodes).forEach(node => {
    if (node.nodeName === "#text" && !node.textContent) {
      element.removeChild(node);
    }
  });

  normalizeElement(element);

  setCurrentSelectionPosition(initialSelectionPosition, element);
};

export const unIndentSelection = element => {
  const nodesInRange = getNodesInRange(element);

  if (
    window.getSelection().anchorNode === null ||
    window.getSelection().anchorNode === document ||
    !nodesInRange
  )
    return;

  const initialSelectionPosition = getCurrentSelectionPosition(element);

  removeIndentationFromSelection({ element, nodesInRange });

  // strip out empty text nodes
  Array.from(element.childNodes).forEach(node => {
    if (node.nodeName === "#text" && !node.textContent) {
      element.removeChild(node);
    }
  });

  normalizeElement(element);

  setCurrentSelectionPosition(initialSelectionPosition, element);
};

/**
 * @desc toggles italic on the selection, when the selection contains any italic content removes all italic from selection, else applies italic to current selection
 * @param {HTMLElement} element - the element boundary for our function to stay within while performing its modifications
 */
export const toggleItalicOnSelection = element => {
  if (
    window.getSelection().anchorNode === null ||
    window.getSelection().anchorNode === document
  )
    return;
  const nodesInRange = getNodesInRange(element);

  // check if we already have italic content
  const italicNodes = getAllItalicContent(nodesInRange.allNodes);

  const initialSelectionPosition = getCurrentSelectionPosition(element);

  if (italicNodes.length > 0) {
    // some content is already italic so we want to remove the italic on that content
    removeItalicFromSelection({
      element,
      styledNodes: italicNodes,
      nodesInRange
    });
  } else {
    // no italic so we are setting italic
    applyItalicToSelection({ element, nodesInRange });
  }

  // strip out empty text nodes
  Array.from(element.childNodes).forEach(node => {
    if (node.nodeName === "#text" && !node.textContent) {
      element.removeChild(node);
    }
  });

  normalizeElement(element);

  setCurrentSelectionPosition(initialSelectionPosition, element);
};

/**
 * @desc normalizes a HTMLElement, merging together adjacent nodes with the same styling and removing empty nodes
 * @param {HTMLElement} element the element we want to normalize
 */
export const normalizeElement = (element, isSavingRange, callback = noop) => {
  let childNodes = Array.from(element.childNodes);
  element.normalize();
  for (let childIndex = 0; !!childNodes[childIndex]; ) {
    const childNode = childNodes[childIndex];
    if (childNode.nodeName === "FONT") {
      convertMarkToStylableNode(childNode);

      childNodes = Array.from(element.childNodes);
    } else if (
      childNode.nodeName === "SPAN" &&
      isNodeStylesEmpty(childNode) &&
      isNodeClassesEmpty(childNode)
    ) {
      // we need to strip this span
      extractContentFromSpan(childNode);

      childNodes = Array.from(element.childNodes);
    } else if (
      childNode.nodeName === "SPAN" &&
      Array.from(childNode.childNodes).some(node => node.nodeName === "BR")
    ) {
      // handle spans with break tags inside
      const spanChildren = Array.from(childNode.childNodes);

      spanChildren.forEach(spanChild => {
        if (spanChild.nodeName === "BR") return;
        // clone the span for all its styles
        const wrapperNode = childNode.cloneNode();
        // wrap the child in span clone
        wrapNodeContentInNode(spanChild, wrapperNode);
      });

      // pull out the content and remove the span
      const content = extractContentFromSpan(childNode);

      const firstBreak = content.find(node => node.nodeName === "BR");

      if (firstBreak && isSavingRange) {
        const range = document.createRange();
        range.selectNode(firstBreak);
        range.collapse(false);
        window.easil.savedBreakCursorRange = range.cloneRange();
      }

      // repeat index since we changed the structure from this index
      childNodes = Array.from(element.childNodes);
    } else if (childNode.nodeName === "LI") {
      let isDOMChanged = false;
      const firstChild = childNode.childNodes[0];
      if (!!firstChild && firstChild.nodeName === "UL") {
        const textNode = document.createTextNode(String.fromCharCode(160));
        childNode.insertBefore(textNode, firstChild);
        isDOMChanged = true;
      }
      const parent = childNode.parentNode;
      if (parent.nodeName === "DIV") {
        // the parent being a div means we have a list item without a list
        const children = Array.from(childNode.childNodes);
        children.forEach(child => {
          parent.insertBefore(child, childNode);
        });
        childNode.remove();
        isDOMChanged = true;
      }
      if (!isDOMChanged) {
        // the DOM has not been updated so we can continue to children and the next index
        if (childNode.childNodes) {
          normalizeElement(childNode);
        }
        childIndex++;
      } else {
        // DOM was changed, update our array and repeat index
        childNodes = Array.from(element.childNodes);
      }
    } else {
      let isDOMChanged = false;
      if (!!childNode.nextSibling) {
        isDOMChanged = mergeNodesIfPossible(childNode, childNode.nextSibling);
      }

      if (!isDOMChanged) {
        // the DOM has not been updated so we can continue to children and the next index
        if (childNode.childNodes) {
          normalizeElement(childNode);
        }
        childIndex++;
      } else {
        // DOM was changed, update our array and repeat index
        childNodes = Array.from(element.childNodes);
      }
    }
  }
  callback();
};

export const normalizeTextboxValue = value => {
  const div = document.createElement("DIV");
  div.innerHTML = value;
  normalizeElement(div);
  return div.innerHTML;
};

/**
 * @desc takes 2 HTMLElements and merges them together if they share the same styling
 * @param {HTMLElement} startNode - the HTMLElement to compare and merge with endNode if possible
 * @param {HTMLElement} endNode  - the HTMLElement to compare and merge with startNode if possible
 * @returns {Boolean} - true if changes were possible for the given input
 */
export const mergeNodesIfPossible = (startNode, endNode) => {
  // break if nodes are not same type
  if (startNode.nodeName !== endNode.nodeName) return;
  const nodeType = startNode.nodeName;

  switch (nodeType) {
    case "#text": {
      startNode.parentNode.normalize();
      return true;
    }
    case "SUP":
    case "SPAN": {
      if (nodesHaveSameStyles(startNode, endNode)) {
        // move content from end into start
        Array.from(endNode.childNodes).forEach(childNode => {
          startNode.appendChild(childNode);
        });

        // remove the end node
        endNode.outerHTML = "";

        return true;
      }

      // no changes were possible
      return false;
    }
    case "UL": {
      if (startNode.getAttribute("class") === endNode.getAttribute("class")) {
        // classes match so we can merge
        Array.from(endNode.childNodes).forEach(childNode => {
          startNode.appendChild(childNode);
        });

        endNode.remove();

        return true;
      }
      return false;
    }

    default: {
      // could not perform merge on this node type
      return false;
    }
  }
};

/**
 * @desc takes a flattened selection range and duplicates it for the new node tree structure
 * @param {Object} originalIndexes - the original flattened indexes we want to duplicate on the new node tree
 * @param {HTMLElement} element - the wrapper for our node tree to apply the selection to
 */
export const applySelectionFromTextIndexes = (originalIndexes, element) => {
  const childNodes = Array.from(element.childNodes);

  const currentIndexes = getTextSelectionIndexes(element);

  if (!currentIndexes) return;

  const startNodeOffset = currentIndexes.rangeIndexesSummed
    .filter(index => index <= originalIndexes.startTextPosition)
    .pop();
  const endNodeOffset = currentIndexes.rangeIndexesSummed
    .filter(index => index <= originalIndexes.endTextPosition)
    .pop();

  currentIndexes.rangeIndexesSummed.reverse();

  const startNodeIndex =
    currentIndexes.rangeIndexesSummed.length -
    currentIndexes.rangeIndexesSummed.findIndex(
      index => index === startNodeOffset
    ) -
    1;
  const endNodeIndex =
    currentIndexes.rangeIndexesSummed.length -
    currentIndexes.rangeIndexesSummed.findIndex(
      index => index === endNodeOffset
    ) -
    1;

  const range = document.createRange();

  if (
    startNodeIndex === endNodeIndex &&
    childNodes[startNodeIndex] &&
    childNodes[startNodeIndex].nodeName !== "#text" &&
    !childNodes[startNodeIndex].firstChild
  ) {
    // this is a span without any text node inside it, we need to make one for our selection
    // const emptyTextNode = document.createTextNode("");
    range.selectNodeContents(childNodes[startNodeIndex]);
  } else {
    childNodes[startNodeIndex].normalize();
    childNodes[endNodeIndex].normalize();

    const newStartNode = getClosestTextChild(childNodes[startNodeIndex]);
    const newEndNode = getClosestTextChild(childNodes[endNodeIndex]);

    if (!newStartNode || !newEndNode) return;

    const newStartOffset = originalIndexes.startTextPosition - startNodeOffset;
    const newEndOffset = originalIndexes.endTextPosition - endNodeOffset;

    range.setStart(newStartNode, newStartOffset);
    range.setEnd(newEndNode, newEndOffset);
  }
  const selection = getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
};

/**
 * @desc takes the current selection within the given element and calculates a flattened version of
 * it which can be reapplied to another tree structure at a later time
 * @param {HTMLElement} element - the wrapper for our node tree to get the selection from
 */
export const getTextSelectionIndexes = element => {
  const childNodes = Array.from(element.childNodes).filter(
    node => node.nodeName !== "BR"
  );

  // strip out any break tags since these can't contain cursor

  // get the content lengths split into an array
  const rangeIndexes = childNodes.map(node => node.textContent.length);

  const range = getRange();
  if (!range) return;

  const startTop = getClosestNodeWithSiblings(range.startContainer, element);
  const endTop = getClosestNodeWithSiblings(range.endContainer, element);

  // get the position of the start and end container in our childNodes
  const startContainerIndex = childNodes.findIndex(node => startTop === node);
  const endContainerIndex = childNodes.findIndex(node => endTop === node);

  // set up a sum for the range indexes
  const rangeIndexesSummed = [];
  rangeIndexes.reduce((latestSum, indexValue, currentIndex) => {
    rangeIndexesSummed[currentIndex] = latestSum;
    return latestSum + indexValue;
  }, 0);

  // get the sum of all lengths before the start and end containers as its text offset
  const startContainerOffset = rangeIndexesSummed[startContainerIndex];
  const endContainerOffset = rangeIndexesSummed[endContainerIndex];

  // start and end text positions is the sum of their offset and their containers offset
  const startTextPosition = range.startOffset + startContainerOffset;
  const endTextPosition = range.endOffset + endContainerOffset;

  return {
    startTextPosition: startTextPosition || 0,
    endTextPosition: endTextPosition || 0,
    rangeIndexesSummed
  };
};

/**
 * @desc checks a given node for important styles which are not set to the default
 * @param {HTMLElement} node - the node to check for empty styles on
 */
export const isNodeStylesEmpty = node => {
  if (!node || !stylableNodeNames.includes(node.nodeName)) return false;
  const importantStylesForNode = pick(
    node.style,
    Object.keys(importantStylesMap)
  );
  const styleValueStrings = Object.values(importantStylesForNode);
  const styleValueStringCombined = styleValueStrings.reduce(
    (previousString, currentNodeStyleValue) =>
      previousString + currentNodeStyleValue,
    ""
  );
  return styleValueStringCombined === "";
};

/**
 * @desc checks a given node for classes which are not set to the default
 * @param {HTMLElement} node - the node to check for empty classes on
 */
export const isNodeClassesEmpty = node => {
  if (!node || !stylableNodeNames.includes(node.nodeName)) return false;

  const applicableClassesForNode = applicableClasses.filter(className =>
    node.classList.contains(className)
  );
  return applicableClassesForNode?.length === 0;
};

/**
 * @desc create a new span with the given color applied to it
 * @param {string} color - a hex color string to apply to the span
 * @returns {HTMLElement} - a span node with the given color applied to it
 */
export const createNewColorSpan = color => {
  const span = document.createElement("span");
  addColorToNode(span, color);
  return span;
};

/**
 * @desc create a new span with italic styling applied to it
 * @returns {HTMLElement} - a span node with italic applied to it
 */
export const createNewItalicSpan = () => {
  const span = document.createElement("span");
  addItalicToNode(span);
  return span;
};

/**
 * @desc create a new span with bold styling applied to it
 * @returns {HTMLElement} - a span node with bold applied to it
 */
export const createNewBoldSpan = () => {
  const span = document.createElement("span");
  addBoldToNode(span);
  return span;
};

/**
 * @desc wraps the targetNode in the wrapperNode
 * @param {HTMLElement} targetNode - the node to wrap
 * @param {HTMLElement} wrapperNode - the node which will wrap targetNode
 */
export const wrapNodeContentInNode = (targetNode, wrapperNode) => {
  const range = document.createRange();

  range.selectNodeContents(targetNode);

  range.surroundContents(wrapperNode);
};

/**
 * @desc gets the style differences between two given nodes
 * @param {HTMLElement} node1 - a node to compare styles with node2
 * @param {HTMLElement} node2 - a node to compare styles with node1
 * @returns {Object} and object of key value pairs which are different between the 2 nodes styles
 */
export const getNodeStyleDifferences = (node1, node2) => {
  const importantStyleKeys = Object.keys(importantStylesMap);
  return omitUnchangedComparison(
    pick(node1, importantStyleKeys),
    pick(node2, importantStyleKeys)
  );
};

/**
 * @desc checks if two nodes have the same styles
 * @param {HTMLElement} firstNode - a node to compare styles with secondNode
 * @param {HTMLElement} secondNode  - a node to compare styles with firstNode
 * @returns {Boolean} true if both nodes have the same styles
 */
export const nodesHaveSameStyles = (firstNode, secondNode) => {
  const importantStyleKeys = Object.keys(importantStylesMap);
  return jsonStringEqual(
    pick(firstNode.style, importantStyleKeys),
    pick(secondNode.style, importantStyleKeys)
  );
};

/**
 * @desc checks if a node has bold styles on it
 * @param {HTMLElement} node - the node to check for bold styles on
 * @returns {Boolean} true if node has bold styles
 */
export const isNodeBold = node => {
  return node.style["font-weight"] === "bold";
};

/**
 * @desc checks if a node has italic styles on it
 * @param {HTMLElement} node - the node to check for italic styles on
 * @returns {Boolean} true if node has italic styles
 */
export const isNodeItalic = node => {
  return node.style["font-style"] === "italic";
};

/**
 * @desc applies the given color to the given node
 * @param {HTMLElement} node - the node to apply color styling to
 * @param {string} color - a hex color string to use as the color applied to given node
 */
export const addColorToNode = (node, color) => {
  node.style["color"] = color;
};

/**
 * @desc applies bold styling to the given node
 * @param {HTMLElement} node - the node to apply bold styling to
 */
export const addBoldToNode = node => {
  node.style["font-weight"] = "bold";
};

/**
 * @desc applies italic styling to the given node
 * @param {HTMLElement} node - the node to apply italic styling to
 */
export const addItalicToNode = node => {
  node.style["font-style"] = "italic";
};

/**
 * @desc removes the given style type from the given node
 * @param {HTMLElement} node - the node to remove styling from
 * @param {string} styleName - the name of the style to remove from the given element
 */
export const removeStyleFromNode = (node, styleName) => {
  switch (styleName) {
    case "bold": {
      return removeBoldFromNode(node);
    }
    case "italic": {
      return removeItalicFromNode(node);
    }
    case "color": {
      return removeColorFromNode(node);
    }
    case "font-size": {
      return removeFontSizeFromNode(node);
    }
    case "font-family": {
      return removeFontFamilyFromNode(node);
    }
    case "underline": {
      return removeUnderlineFromNode(node);
    }
    default: {
      console.log(`Style '${styleName}' is not a valid style to remove`);
      return node;
    }
  }
};

/**
 * @desc removes the underline styling from a node and if that leaves its styles empty extracts its contents and removes the node
 * @param {HTMLElement} node - the node to remove underline styling from
 */
export const removeUnderlineFromNode = node => {
  node.style["text-decoration-line"] = null;
  if (isNodeStylesEmpty(node)) {
    // should remove the span
    extractContentFromSpan(node);
  }
  return node;
};

/**
 * @desc removes the font-family styling from a node and if that leaves its styles empty extracts its contents and removes the node
 * @param {HTMLElement} node - the node to remove font-family styling from
 */
export const removeFontFamilyFromNode = node => {
  node.style["font-family"] = null;
  if (isNodeStylesEmpty(node)) {
    // should remove the span
    extractContentFromSpan(node);
  }
  return node;
};

/**
 * @desc removes the font-size styling from a node and if that leaves its styles empty extracts its contents and removes the node
 * @param {HTMLElement} node - the node to remove font-size styling from
 */
export const removeFontSizeFromNode = node => {
  node.style["font-size"] = null;
  // if an anchor tag, we want to leave the node
  if (isNodeStylesEmpty(node) && node.nodeName !== "A") {
    // should remove the span
    extractContentFromSpan(node);
  }
  return node;
};

/**
 * @desc removes the color styling from a node and if that leaves its styles empty extracts its contents and removes the node
 * @param {HTMLElement} node - the node to remove color styling from
 */
export const removeColorFromNode = node => {
  node.style["color"] = null;
  // if an anchor tag, we want to leave the node
  if (isNodeStylesEmpty(node) && node.nodeName !== "A") {
    // should remove the span
    extractContentFromSpan(node);
  }
  return node;
};

/**
 * @desc removes the bold styling from a node and if that leaves its styles empty extracts its contents and removes the node
 * @param {HTMLElement} node - the node to remove bold styling from
 */
export const removeBoldFromNode = node => {
  node.style["font-weight"] = null;
  if (isNodeStylesEmpty(node)) {
    // should remove the span
    extractContentFromSpan(node);
  }
  return node;
};

/**
 * @desc removes the italic styling from a node and if that leaves its styles empty extracts its contents and removes the node
 * @param {HTMLElement} node - the node to remove italic styling from
 */
export const removeItalicFromNode = node => {
  node.style["font-style"] = null;
  if (isNodeStylesEmpty(node)) {
    // should remove the span
    extractContentFromSpan(node);
  }
  return node;
};

/**
 * @desc takes the children from a node and places them outside it before removing the given node
 * @param {HTMLElement} node the node to extract content from
 * @returns {HTMLElement[]} the node children extracted from the given node
 */
export const extractContentFromSpan = node => {
  const nodeChildren = Array.from(node.childNodes);

  nodeChildren.forEach(childNode => {
    node.parentNode.insertBefore(childNode, node);
  });

  node.outerHTML = "";

  return nodeChildren;
};

/**
 * @desc gets the current window selection
 * @returns {Selection} the current window selection
 */

export const getSelection = () => {
  const win = document.defaultView || document.parentWindow;
  return win.getSelection();
};

/**
 * @desc get the first of current window selection ranges
 * @returns {Range} the first of the current window selection ranges
 */
export const getRange = () => {
  const selection = getSelection();
  if (!selection.rangeCount) return;
  return selection.getRangeAt(0);
};

/**
 * @desc creates a new Range with the given nodes and offsets
 * @param {HTMLElement} startNode - the node for the new range to start at
 * @param {HTMLElement} endNode - the node for the new range to end at
 * @param {number} startOffset - the offset for the new range to start at
 * @param {number} endOffset - the offset for the new range to end at
 * @returns {Range} the range defined by the given input
 */
export const createRangeAndSet = (
  startNode,
  endNode,
  startOffset,
  endOffset
) => {
  const range = document.createRange();
  range.setStart(startNode, startOffset);
  range.setEnd(endNode, endOffset);
  return range;
};

export const applySavedSelection = () => {
  const savedSelection = window.easil.savedSelection;
  if (!savedSelection) {
    return;
  }

  setCurrentSelectionPosition(savedSelection.position, savedSelection.node);
};

export const saveCurrentRange = () => {
  const range = getRange();
  if (range) {
    const node = getNearestContentEditableParentFromSelection();
    const currentSelectionPosition = getCurrentSelectionPosition(node);
    window.easil.savedSelection = { position: currentSelectionPosition, node };
  }
};

export const getHrefInSelection = element => {
  const nodesInRange = getNodesInRange(element);
  const linkedNodes = nodesInRange.allNodes.filter(
    node => node && node.nodeName === "A"
  );
  const uniqueLinkedNodes = nodesUnique(linkedNodes);

  if (!uniqueLinkedNodes.length || !uniqueLinkedNodes[0].attributes.href)
    return;

  return uniqueLinkedNodes[0].attributes.href.value;
};

export const getClosestParentWithIdAttribute = node => {
  let currentNode = node;

  while (currentNode) {
    if (currentNode.id) {
      const isContentEditableId = currentNode.id.startsWith("UCE-");
      const isTable2Id = currentNode.id.startsWith("table2-element-");
      const isTable2CellId = currentNode.id.startsWith("table-cell-");
      const isTable2TextfieldId = currentNode.id.startsWith("cell-");
      if (
        !(
          isContentEditableId ||
          isTable2Id ||
          isTable2CellId ||
          isTable2TextfieldId
        )
      )
        break;
    }
    currentNode = currentNode.parentNode;
  }

  return currentNode;
};

export const scaleFontSizeContent = ({
  textboxElement,
  styledNodes,
  scale
}) => {
  // get the font size nodes in the current selection

  // map through font size nodes and set their font-size style value to scaled value
  styledNodes.forEach(node => {
    const fontSizeAsString = node.style["font-size"].split("px").shift();
    const fontSizeAsNumber = parseFloat(fontSizeAsString);
    node.style["font-size"] = `${fontSizeAsNumber * scale}px`;
  });
};

/**
 * @desc gets the computedStyle font-size for a given node or its closest stylable parent
 * @param {HTMLElement} node - the node to get the computed font size for
 * @returns {String} the value of the nodes computed font-size trimming 'px' from the end
 */
export const getComputedFontSizeForNode = (node, selectionContentEditable) => {
  let stylableNode = node;

  // make sure we arn't attempting to get styles for a text node
  if (node.nodeName === "#text") {
    stylableNode = getClosestNodeWithSiblings(node);
    // ensure the node we got back isn't still a text node (top level text nodes will do this)
    if (stylableNode.nodeName === "#text") {
      // this is a top level text node so get the computed styles from the element
      stylableNode = selectionContentEditable;
    }
  }

  const computedStyles = getComputedStyle(stylableNode);

  const fontSizeText = getPath(computedStyles, "font-size", "");

  // return the value but trim off the px first
  return fontSizeText.split("px").shift();
};

/**
 * @desc gets the computedStyle font-family for a given node or its closest stylable parent
 * @param {HTMLElement} node - the node to get the computed font family for
 * @returns {String} the value of the nodes computed font-family trimming 'px' from the end
 */
export const getComputedFontFamilyForNode = (
  node,
  selectionContentEditable
) => {
  let stylableNode = node;

  // make sure we arn't attempting to get styles for a text node
  if (node.nodeName === "#text") {
    stylableNode = getClosestNodeWithSiblings(node);
    // ensure the node we got back isn't still a text node (top level text nodes will do this)
    if (stylableNode.nodeName === "#text") {
      // this is a top level text node so get the computed styles from the element
      stylableNode = selectionContentEditable;
    }
  }

  const computedStyles = getComputedStyle(stylableNode);

  return getPath(computedStyles, "font-family", "");
};

export const waitForElement = (selector, parentNode = document) => {
  return new Promise(resolve => {
    if (parentNode.querySelector(selector)) {
      return resolve(parentNode.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (parentNode.querySelector(selector)) {
        resolve(parentNode.querySelector(selector));
        observer.disconnect();
      }
    });

    const observeSource = parentNode.body || parentNode;

    observer.observe(observeSource, {
      childList: true,
      subtree: true
    });
  });
};

export const getPreviousSiblings = node => {
  let currentNode = node;
  if (!currentNode.previousSibling) return [];
  const previousSiblings = [];
  do {
    currentNode = currentNode.previousSibling;
    previousSiblings.push(currentNode);
  } while (!!currentNode.previousSibling);
  return previousSiblings;
};

export const getNextSiblings = node => {
  let currentNode = node;
  if (!currentNode.nextSibling) return [];
  const nextSiblings = [];
  do {
    currentNode = currentNode.nextSibling;
    nextSiblings.push(currentNode);
  } while (!!currentNode.nextSibling);
  return nextSiblings;
};

/**
 * @desc splits a list item node into two at the given index
 * @param {HTMLNode} textNode - the text node to apply the split index on
 * @param {HTMLNode} liNode - the list item node that is being split
 * @param {Number} splitIndex - the index at which the textNode should be split
 */
export const splitListItem = (textNode, liNode, splitIndex) => {
  const liParent = liNode.parentNode;

  // get the previous and next siblings for the current textNode
  const previousSiblings = getPreviousSiblings(textNode);
  const nextSiblings = getNextSiblings(textNode);

  // split the current text node at the given index
  const endSectionText = textNode.splitText(splitIndex);
  const startSectionText = endSectionText.previousSibling;

  // make two clones of the list item
  const firstLiNode = liNode.cloneNode();
  const secondLiNode = liNode.cloneNode();

  // place the previous siblings in the first list item
  previousSiblings.reverse().forEach(siblingNode => {
    firstLiNode.appendChild(siblingNode);
  });
  // add the start section to the end of the list item
  firstLiNode.appendChild(startSectionText);

  // place the end section in the second list item
  secondLiNode.appendChild(endSectionText);
  // add the next siblings onto the second list item
  nextSiblings.forEach(siblingNode => {
    secondLiNode.appendChild(siblingNode);
  });

  // inset both new list items before the old one
  liParent.insertBefore(firstLiNode, liNode);
  liParent.insertBefore(secondLiNode, liNode);

  let selectionTarget = endSectionText;

  if (endSectionText.textContent === "") {
    secondLiNode.innerHTML = "<br>";
    selectionTarget = secondLiNode.firstChild;
  }

  // move the selection to the start of our second new node
  const range = new Range();
  range.setStart(selectionTarget, 0);
  range.collapse(true);
  document.getSelection().removeAllRanges();
  document.getSelection().addRange(range);

  // remove the old list item node
  liNode.remove();
};

export const isNodeWithinSelectionRange = node => {
  const selection = window.getSelection(); // Get the current selection object
  if (selection.rangeCount === 0) {
    return false; // No selection range
  }

  const range = selection.getRangeAt(0); // Get the first range of the selection

  // Check if the node is within the range's start and end containers
  const nodeRange = document.createRange();
  nodeRange.selectNode(node);
  const isNodeBeforeRange =
    range.compareBoundaryPoints(range.START_TO_START, nodeRange) === 1;
  const isNodeAfterRange =
    range.compareBoundaryPoints(range.END_TO_END, nodeRange) === -1;

  return !isNodeBeforeRange && !isNodeAfterRange;
};

export const forceUpdateHTML = (
  value,
  rangeStart,
  rangeEnd,
  rangeTargetIndex = 0,
  target
) => {
  let _rangeStart = rangeStart;
  let _rangeEnd = rangeEnd;
  let _rangeTargetIndex = rangeTargetIndex;

  if (isNil(_rangeStart) || isNil(!_rangeEnd)) {
    // get current selection range
    const currentRangeValues = getCurrentRangeValues(target);
    _rangeTargetIndex = currentRangeValues.rangeTargetIndex;
    _rangeStart = currentRangeValues.rangeStart;
    _rangeEnd = currentRangeValues.rangeEnd;
  }

  // update innerHTML
  target.innerHTML = value;

  const targetChildNodes = Array.from(target.childNodes);
  let rangeTargetNode = targetChildNodes[_rangeTargetIndex];

  // compensate for if a range target is in a new node that has been stripped
  if (targetChildNodes.length - 1 < _rangeTargetIndex) {
    const filteredTargetChildNodes = targetChildNodes.filter(
      node => node.nodeName === DOM_NODE_TYPES.text
    );
    rangeTargetNode =
      filteredTargetChildNodes[filteredTargetChildNodes.length - 1];
    if (isNil(rangeTargetNode)) {
      // in the case the selection is then empty we want to escape
      return;
    }
    _rangeStart = rangeTargetNode.data.length;
    _rangeEnd = rangeTargetNode.data.length;
  }

  // set the new range on the rangeTarget child which should be a text node
  setRangeForTarget({
    target: rangeTargetNode,
    startOffset: _rangeStart,
    endOffset: _rangeEnd
  });
};

export const setTextInCurrentContentEditablePosition = text => {
  let selection, range;
  if (window.getSelection) {
    // IE9 and non-IE
    selection = window.getSelection();
    if (selection.getRangeAt && selection.rangeCount) {
      range = selection.getRangeAt(0);
      range.deleteContents();

      let el = document.createElement("div");
      el.innerHTML = text;
      let frag = document.createDocumentFragment(),
        node,
        lastNode;
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      range.insertNode(frag);

      // Preserve the selection
      if (lastNode) {
        range = range.cloneRange();
        range.setStartAfter(lastNode);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  } else if (document.selection && document.selection.type !== "Control") {
    // IE < 9
    document.selection.createRange().pasteHTML(text);
  }
};

export const setCaretAfterTextInContentEditable = (
  text,
  contentEditableNode
) => {
  if (!contentEditableNode) return;
  // place caret at end of recently inserted placeholder in contentEditableDiv
  const startIndex = contentEditableNode.textContent.indexOf(text);
  if (startIndex !== -1) {
    const range = document.createRange();
    const startNode = contentEditableNode.firstChild; // Assuming the text node is the first child
    const startOffset = startIndex + text.length; // Set the start offset after the text
    range.setStart(startNode, startOffset);
    range.collapse(true);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    contentEditableNode.focus();
  }
};

export const scaleRichTextFontSizeValue = (value, scale) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = value;

  const spans = tempDiv.querySelectorAll("span");

  spans.forEach(span => {
    const currentFontSize = parseFloat(span.style.fontSize);
    const newFontSize = currentFontSize * scale + "px";

    span.style.fontSize = newFontSize;
  });
  return tempDiv.innerHTML;
};
