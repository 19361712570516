import React from "react";
import { Input, Modal } from "views/components";
import style from "./style.module.css";
import { stripHTML } from "lib/textUtils";
import Tooltip from "views/components/Tooltip/Tooltip";
import InfoIcon from "views/components/icons/InfoIcon";
import { isVector } from "lib/isSvg";
import PATHS from "routes/paths";

const USER_PREPEND = "user.";

export class SmartImageModal extends React.Component {
  constructor(props) {
    super(props);
    this.canSave = this.canSave.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.getButtons = this.getButtons.bind(this);
    this.getInputLabel = this.getInputLabel.bind(this);

    this.state = {
      label: this.getInputLabel(),
      error: null
    };
  }

  getInputLabel() {
    if (this.props.smartImage) {
      const isUserTeamUploads = PATHS.isWorkspaceUploadsImages(
        window.location.pathname
      );
      return isUserTeamUploads
        ? this.props.smartImage.label.split(".")[1]
        : this.props.smartImage.label;
    }

    return null;
  }

  handleCreate() {
    const { label } = this.state;
    const { smartImage, image } = this.props;
    const isUserTeamUploads = PATHS.isWorkspaceUploadsImages(
      window.location.pathname
    );
    const updatedLabel = isUserTeamUploads ? USER_PREPEND + label : label;

    const cleanValue = stripHTML(updatedLabel);
    switch (this.props.type) {
      case "create": {
        this.props.createUserTeamSmartImage({
          label: cleanValue,
          mediaId: image.mediaId,
          onSuccess: () => this.props.onClose()
        });
        break;
      }
      case "teamCreate": {
        this.props.createTeamSmartImage({
          label: cleanValue,
          mediaId: image.mediaId,
          onSuccess: () => this.props.onClose()
        });
        break;
      }
      case "update": {
        this.props.updateUserTeamSmartImage({
          smartImage: {
            ...smartImage,
            label: cleanValue
          },
          onSuccess: () => this.props.onClose()
        });
        break;
      }
      case "teamUpdate": {
        this.props.updateTeamSmartImage({
          smartImage: {
            ...smartImage,
            label: cleanValue
          },
          onSuccess: () => this.props.onClose()
        });
        break;
      }
      default:
        return;
    }
  }

  handleUpdate(value) {
    this.setState({
      label: value
    });
  }

  handleBlur() {
    const { label } = this.state;
    const { smartImages } = this.props;
    const isUserTeamUploads = PATHS.isWorkspaceUploadsImages(
      window.location.pathname
    );
    const updatedLabel = isUserTeamUploads ? USER_PREPEND + label : label;

    let error;

    const isSmartImageLabelInUse = () => {
      let smartImageLabelInUse = false;
      smartImageLabelInUse = Object.keys(smartImages).find(
        smartImage =>
          smartImages[smartImage].label.toLowerCase() ===
          updatedLabel.toLowerCase()
      );
      return !!smartImageLabelInUse;
    };

    switch (true) {
      case !label:
        error = <span>Enter a smart text ID.</span>;
        this.setState({ error: error });
        break;
      case isSmartImageLabelInUse():
        error = <span>Already in use.</span>;
        this.setState({ error: error });
        break;
      case label && !updatedLabel.match(/^[-a-zA-Z0-9._]+$/):
        error = <span>Cannot contain invalid characters.</span>;
        this.setState({ error: error });
        break;
      case label &&
        !updatedLabel.match(/^(?!.*[._-]{2})[a-zA-Z0-9]+[._-]?[a-zA-Z0-9]+$/):
        error = <span>Cannot contain more than one separator.</span>;
        this.setState({ error: error });
        break;
      case !isUserTeamUploads && label && updatedLabel.startsWith("user."):
        error = <span>Brand kit ID must not start with user.</span>;
        this.setState({ error: error });
        break;
      default:
        this.setState({ error: null });
        return;
    }
  }

  handleKeyDown(event) {
    if (event.code === "Space") event.preventDefault();
  }

  canSave() {
    const { label, error } = this.state;

    if (label === null) {
      return false;
    }

    if (label !== null && label === "") {
      return false;
    }

    if (error && error !== null) {
      return false;
    }

    return true;
  }

  getTransferIdButtonLabel() {
    return (
      <div className={style.transferIdButton}>
        Transfer ID
        <div
          className={style.transferIcon}
          data-tip="Transfer this Smart Image ID to a new image"
          data-for="transfer-smart-image-id-tooltip"
        >
          <InfoIcon size="14px" />
        </div>
        <Tooltip id="transfer-smart-image-id-tooltip" place="bottom" />
      </div>
    );
  }

  getButtons() {
    const buttons = [
      {
        name: "Cancel",
        theme: "gray",
        onClick: this.props.onClose
      },
      {
        name: "Save",
        theme: "blueSolid",
        disabled: !this.canSave(),
        onClick: () => this.handleCreate(),
        isLoading: this.props.isSaving || this.props.isSavingTeam
      }
    ];

    if (this.props.type === "update" || this.props.type === "teamUpdate") {
      buttons.splice(1, 0, {
        name: this.getTransferIdButtonLabel(),
        theme: "grayBackgroundXDarkGrayFont",
        onClick: this.props.onTransferClick
      });
    }

    return buttons;
  }

  render() {
    const { error } = this.state;
    const { image } = this.props;

    const getInputFieldError = () => error;

    const getHeaderLabel = () =>
      this.props.type === "update" || this.props.type === "teamUpdate"
        ? "Update Smart Image"
        : "Assign as Smart Image";

    const labelErrorColor = {
      color: "#c01818",
      marginBottom: "8px"
    };

    const labelColor = {
      color: "#7a7d81",
      marginBottom: "8px"
    };
    const hasIputError = !!getInputFieldError();
    const isUserTeamUploads = PATHS.isWorkspaceUploadsImages(
      window.location.pathname
    );

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onClose}
        contentLabel={getHeaderLabel()}
        header={getHeaderLabel()}
        className={style.smartImageModal}
        headerTitleClassName={style.smartImageModalHeader}
        buttonsClassName={style.modalButtons}
        buttons={this.getButtons()}
        crossClassName={style.modalCross}
      >
        <div className={style.contentWrapper}>
          <div className={style.modalImageWrapper}>
            <img
              alt="smartImage"
              className={style.modalImage}
              src={isVector(image.url) ? image.url : image.previewUrl}
            />
          </div>
          <div className={style.modalForm}>
            <div className={style.modalFormGroup}>
              <div style={error ? labelErrorColor : labelColor}>
                <span
                  style={
                    error
                      ? { fontWeight: 500, paddingRight: "4px" }
                      : { fontWeight: "normal", paddingRight: "4px" }
                  }
                >
                  Smart Image ID
                </span>
                {getInputFieldError()}
              </div>
              <div className={style.modalFormField} data-error={hasIputError}>
                {isUserTeamUploads && (
                  <div className={style.userPrepend}>{USER_PREPEND}</div>
                )}
                <Input
                  value={this.state.label}
                  onChange={event => this.handleUpdate(event.target.value)}
                  onBlur={this.handleBlur}
                  placeholder="Enter Smart Image ID"
                  className={`${style.modalFormInput}`}
                  style={{
                    borderRadius: isUserTeamUploads ? "0px 4px 4px 0px" : "4px"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default SmartImageModal;
