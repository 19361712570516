export const designSmartTextSelector = state =>
  state.entities.smartAssets.design?.smartText;

export const designSmartImagesSelector = state => {
  const smartImages = state.entities.smartAssets.design?.smartImages;
  if (!smartImages) return null;
  return Object.values(smartImages).reduce((acc, element) => {
    acc[element.mediaId] = element;
    return acc;
  }, {});
};
