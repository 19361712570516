import { confirm } from "lib/ConfirmationModal/ConfirmationModal";

import style from "./style.module.css";

const confirmArchiveDesign = ({ onOk, onCancel }) => {
  const header = "Archive folder";
  const text = `Are you sure you want to archive your folder? Your designs will still
                be recoverable from the Archived page.`;
  const buttonsDetails = {
    ok: {
      theme: "redSolid",
      name: "Archive"
    },
    cancel: {
      theme: "gray"
    }
  };

  return confirm({
    header,
    text,
    onOk,
    onCancel,
    buttonsDetails,
    modalClassName: style.modal
  });
};

export default confirmArchiveDesign;
