import Element from "state/ui/editor/elements/Element";

class QrCodeElement extends Element {
  static RESTRICTIONS = [
    "removable",
    "visibility",
    "opacity",
    "angle",
    "duplicate",
    "sizeAndPosition",
    "color",
    "qrvalue"
  ];

  static DEFAULT_PROPS = {
    value: "about.easil.com"
  };

  getRestrictionsMap() {
    const restrictions = {};

    let availableRestrictions = [...this.constructor.RESTRICTIONS];

    availableRestrictions.forEach(
      restriction => (restrictions[restriction] = false)
    );

    this.restrictions.forEach(restriction => {
      restrictions[restriction] = true;
    });

    return restrictions;
  }

  changeFillColor({ domId, color }) {
    const fillColorsUpdated = [];

    this.fillColors.forEach(fillColor => {
      if (!fillColor.domId.includes(domId)) {
        fillColorsUpdated.push(fillColor);
      } else {
        fillColorsUpdated.push({
          ...fillColor,
          color
        });
      }
    });

    return new this.constructor({
      ...this,
      fillColors: fillColorsUpdated
    });
  }

  get minWidth() {
    return this.resizableX ? this.srcWidth * (2 / 3) * this.scale : 1;
  }

  get minHeight() {
    return this.resizableY ? this.srcHeight * (2 / 3) * this.scale : 1;
  }

  updateAttributes(attributes) {
    if (window.easil.context.isCroppingVector) {
      return new this.constructor({
        ...this,
        ...attributes
      });
    }

    const _attributes = super.ensureMinimalValues(attributes);

    const sideEffects = {};

    if (!this.resizableX && _attributes.hasOwnProperty("width")) {
      const scaleFactor = _attributes.width / this.width;

      sideEffects.height = this.height * scaleFactor;
      sideEffects.scale = this.scale * scaleFactor;
    }

    if (!this.resizableY && _attributes.hasOwnProperty("height")) {
      const scaleFactor = _attributes.height / this.height;

      sideEffects.width = this.width * scaleFactor;
      sideEffects.scale = this.scale * scaleFactor;
    }

    return new this.constructor({
      ...this,
      ..._attributes,
      ...sideEffects
    });
  }
}

export default QrCodeElement;
