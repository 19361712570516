import React from "react";
import style from "./style.module.css";
import { isVector } from "lib/isSvg";
import { formatFileSize } from "lib/files/fileUtils";
import { formatDateString } from "lib/temporal/dateUtils";
import { getImageSize } from "lib/getImageDetails";
import ScrollingOverflowText from "views/components/ScrollingOverflowText/ScrollingOverflowText";

const AssetResultCard = ({
  asset,
  onClick,
  isSelected,
  isDisabled,
  assetStyle,
  smartAssets
}) => {
  const assetMediaId = asset => asset.mediaId || asset.media.id;
  const updatedDateClean = asset =>
    formatDateString(asset.createdAt || asset.media.createdAt, "MMM DD YYYY");
  const imageSize = asset => formatFileSize(getImageSize(asset), 1);
  const getSmartAssetLabel = asset => {
    const smartAssetLabel = smartAssets[assetMediaId(asset)].label;
    return (
      <>
        <div className={style.smartAssetLabel}>{smartAssetLabel}</div>
        <ScrollingOverflowText className={style.smartAssetName}>
          {asset.media.name}
        </ScrollingOverflowText>
      </>
    );
  };
  return (
    <div
      className={style.assetCard}
      onClick={() => onClick(asset)}
      data-is-selected={isSelected}
      data-is-disabled={isDisabled}
    >
      <div className={style.asset}>
        <img
          src={isVector(asset.url) ? asset.url : asset.previewUrl}
          alt=""
          id={asset.mediaId}
          style={assetStyle}
        />
      </div>
      <div className={style.assetInfo}>
        {isDisabled ? (
          getSmartAssetLabel(asset)
        ) : (
          <>
            <ScrollingOverflowText className={style.assetName}>
              {asset.media.name}
            </ScrollingOverflowText>
            <div className={style.info}>
              {imageSize(asset)}, {updatedDateClean(asset)}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AssetResultCard;
