import * as types from "state/entities/smartAssets/smartAssetsTypes";
import * as smartTextTypes from "state/entities/smartText/smartTextTypes";

export const initState = {
  design: {}
};

const smartAssetsReducers = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_DESIGN_SMART_IMAGES_SUCCESS: {
      const {
        response: { entities, ids }
      } = action;
      const responseSmartImages = entities.smartAssets[ids].smartImages;
      const designSmartImages = Object.keys(responseSmartImages).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...responseSmartImages[key],
            teamId: entities.smartAssets[ids].teamId
          }
        }),
        {}
      );

      return {
        ...state,
        design: {
          ...state.design,
          userId: entities.smartAssets[ids].userId,
          smartImages: designSmartImages
        }
      };
    }

    case smartTextTypes.FETCH_DESIGN_SMART_TEXT_SUCCESS: {
      const {
        response: { entities, ids }
      } = action;
      const responseSmartText = entities.smartText[ids].smartFields;
      const designSmartImages = Object.keys(responseSmartText).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...responseSmartText[key],
            teamId: entities.smartText[ids].teamId
          }
        }),
        {}
      );

      return {
        ...state,
        design: {
          ...state.design,
          smartText: designSmartImages
        }
      };
    }

    case types.UPDATE_DESIGN_SMART_ASSET_OWNER_REQUEST_SUCCESS: {
      const { userId } = action.response;
      return {
        ...state,
        design: {
          ...state.design,
          userId
        }
      };
    }

    default:
      return state;
  }
};

export default smartAssetsReducers;
