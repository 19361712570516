import { userTeamImagesSelector } from "state/entities/userTeamImages/userTeamImagesSelectors";
import {
  getEditorUserImagesSearch,
  editorImageSearchPersonalSelector
} from "state/ui/editorImageSearch/editorImageSearchSelectors";
import { isEmpty } from "lib";
import { getImageSize, getImageCreatedAt } from "lib/getImageDetails";

export const formatSearchResultList = searchResults => {
  return searchResults.map(searchResult => ({
    ...searchResult,
    createdAt: getImageCreatedAt(searchResult),
    media: {
      id: searchResult.mediaId,
      name: searchResult.name,
      priority: null,
      createdAt: null,
      updatedAt: null,
      status: "ACTIVE",
      thumbnail: searchResult.thumbnailUrl.split("/").pop(),
      asset: searchResult.url.split("/").pop(),
      preview: searchResult.previewUrl.split("/").pop(),
      type: searchResult.type,
      size: getImageSize(searchResult) || 0,
      height: null,
      width: null
    }
  }));
};

export const userTeamImagesPageSelector = ({ state, term }) => {
  const editorPersonalSearchState = editorImageSearchPersonalSelector(state);
  if (editorPersonalSearchState.currentTerm !== null && term && term !== "") {
    const resultImages = getEditorUserImagesSearch(state);
    if (!isEmpty(resultImages)) {
      if (!isEmpty(resultImages[term])) {
        // return the current search bar result if it is present
        return formatSearchResultList(resultImages[term]);
      }
      if (!isEmpty(resultImages[editorPersonalSearchState.currentTerm])) {
        // return the last search result if there is one
        return formatSearchResultList(
          resultImages[editorPersonalSearchState.currentTerm]
        );
      }
    }
    return [];
  }
  return userTeamImagesSelector(state);
};
