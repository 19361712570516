import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const AZDownIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => {
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 20}
      height={height || size || 20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
    >
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          stroke={color || easilGrayXDark}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m18 14-3 3-3-3m-3 3H3l6-5.5H3m12-9V17M3 8.5l3-6 3 6M4 7h4"
        />
      </g>
    </svg>
  );
};

AZDownIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default AZDownIcon;
