import React, { Component } from "react";
export class Table2CellDragHandle extends Component {
  render() {
    const {
      position,
      handleResizeMouseDown,
      zoom,
      cellHeight,
      cellWidth,
      isExtremeBorder
    } = this.props;
    const isColumnResizeHandle = ["left", "right"].includes(position);

    // 10% of cells width/height, 20px max handle width zoom adjusted
    const handleWidth = 0.1 * (isColumnResizeHandle ? cellWidth : cellHeight);
    let scaledWidth = Math.min(handleWidth, 20) * zoom;

    // below represents origin for resize handle positioning
    // may change in the future when table cell border widths become adjustable
    // as current border width is hard coded at 2px
    let resizeHandleOrigin = "-1px";

    // double resize handle width when matching the outside border of the table
    // adjusted origin for full overlap with border
    if (isExtremeBorder) {
      scaledWidth = scaledWidth * 2;
      resizeHandleOrigin = `-${scaledWidth / 2}px`;
    }

    return (
      <div
        style={{
          [position]: resizeHandleOrigin,
          height: isColumnResizeHandle ? "100%" : `${scaledWidth}px`,
          width: isColumnResizeHandle ? `${scaledWidth}px` : "100%",
          position: "absolute",
          zIndex: 2147483647,
          cursor: isColumnResizeHandle ? "ew-resize" : "ns-resize"
        }}
        data-border={position}
        onMouseDown={handleResizeMouseDown}
      />
    );
  }
}

export default Table2CellDragHandle;
