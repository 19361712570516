import AlignRectCenterIcon from "views/components/icons/AlignRectCenterIcon";
import BackgroundColorIcon from "views/components/icons/BackgroundColorIcon";
import BorderIcon from "views/components/icons/BorderIcon";
import CopyIcon from "views/components/icons/CopyIcon";
import CropIcon from "views/components/icons/CropIcon";
import CurvedTextIcon from "views/components/icons/CurvedTextIcon";
import EyeIcon from "views/components/icons/EyeIcon";
import FlipIcon from "views/components/icons/FlipIcon";
import FontFamilyIcon from "views/components/icons/FontFamilyIcon";
import FontSizeIcon from "views/components/icons/FontSizeIcon";
import ImageReplaceIcon from "views/components/icons/ImageReplaceIcon";
import LineHeightIcon from "views/components/icons/LineHeightIcon";
import MaskIcon from "views/components/icons/MaskIcon";
import OpacityIcon from "views/components/icons/OpacityIcon";
import OutlineNoPaddingIcon from "views/components/icons/OutlineNoPaddingIcon";
import PaletteIcon from "views/components/icons/PaletteIcon";
import RotateIcon from "views/components/icons/RotateIcon";
import ShadowIcon from "views/components/icons/ShadowIcon";
import SizeIcon from "views/components/icons/SizeIcon";
import SparklesIcon from "views/components/icons/SparklesIcon";
import TextAlignCenterIcon from "views/components/icons/TextAlignCenterIcon";
import TextDecorationIcon from "views/components/icons/TextDecorationIcon";
import TextEditIcon from "views/components/icons/TextEditIcon";
import TextSpaceIcon from "views/components/icons/TextSpaceIcon";
import TInBoxIcon from "views/components/icons/TInBoxIcon";
import TrashCanIcon from "views/components/icons/TrashCanIcon";
import ColumnsIcon from "views/components/icons/ColumnsIcon";
import RowsIcon from "views/components/icons/RowsIcon";
import PencilDottedStyleIcon from "views/components/icons/PencilDottedStyleIcon";
import { QRIcon } from "views/components/icons";

const elementsRestrictionsMap = {
  removable: { name: "Removable", key: "removable", icon: TrashCanIcon },
  visibility: { name: "Visibility", key: "visibility", icon: EyeIcon },
  opacity: { name: "Opacity", key: "opacity", icon: OpacityIcon },
  angle: { name: "Angle", key: "angle", icon: RotateIcon },
  duplicate: { name: "Duplicate", key: "duplicate", icon: CopyIcon },
  textEdit: { name: "Text Edit", key: "textEdit", icon: TextEditIcon },
  sizeAndPosition: {
    name: "Size And Position",
    key: "sizeAndPosition",
    icon: SizeIcon
  },
  imageUpload: {
    name: "Image Replace",
    key: "imageUpload",
    icon: ImageReplaceIcon
  },
  position: { name: "Position", key: "position", icon: SizeIcon },
  filters: { name: "Filters", key: "filters", icon: SparklesIcon },
  outline: { name: "Outline", key: "outline", icon: OutlineNoPaddingIcon },
  flipping: { name: "Flipping", key: "flipping", icon: FlipIcon },
  cropping: { name: "Cropping", key: "cropping", icon: CropIcon },
  color: { name: "Color", key: "color", icon: PaletteIcon },
  setAsBackground: {
    name: "Set As Background",
    key: "setAsBackground",
    icon: BackgroundColorIcon
  },
  fontFamily: { name: "Font Family", key: "fontFamily", icon: FontFamilyIcon },
  fontSize: { name: "Font Size", key: "fontSize", icon: FontSizeIcon },
  textDecoration: {
    name: "Text Decoration",
    key: "textDecoration",
    icon: TextDecorationIcon
  },
  textShadow: { name: "Text Shadow", key: "textShadow", icon: ShadowIcon },
  textCurve: { name: "Curve Text", key: "textCurve", icon: CurvedTextIcon },
  textMask: { name: "Text Mask", key: "textMask", icon: MaskIcon },
  letterSpacing: {
    name: "Letter Spacing",
    key: "letterSpacing",
    icon: TextSpaceIcon
  },
  lineHeight: { name: "Line Height", key: "lineHeight", icon: LineHeightIcon },
  border: { name: "Border", key: "border", icon: BorderIcon },
  textAlign: {
    name: "Text Align",
    key: "textAlign",
    icon: TextAlignCenterIcon
  },
  textBoundary: {
    name: "Text Boundary",
    key: "textBoundary",
    icon: TInBoxIcon,
    sideEffects: [
      "position",
      "fontFamily",
      "fontSize",
      "lineHeight",
      "letterSpacing",
      "textAlign"
    ],
    infoText: {
      isShownOnActive: true,
      isShownOnInactive: true,
      text: "Input text is limited to the set boundary area."
    }
  },
  table2TextBoundary: {
    name: "Text Boundary",
    key: "table2TextBoundary",
    icon: TInBoxIcon,
    sideEffects: [
      "fontFamily",
      "fontSize",
      "lineHeight",
      "letterSpacing",
      "editRowLayout",
      "editColumnLayout",
      "sizeAndPosition"
    ],
    infoText: {
      isShownOnActive: true,
      isShownOnInactive: true,
      text: "Input text is limited to the cell boundaries."
    }
  },
  elementAlign: {
    name: "Element Align",
    key: "elementAlign",
    icon: AlignRectCenterIcon
  },
  cellStyle: {
    name: "Cell Style",
    key: "cellStyle",
    icon: PencilDottedStyleIcon
  },
  editRowLayout: {
    name: "Edit Row Layout",
    key: "editRowLayout",
    icon: RowsIcon
  },
  editColumnLayout: {
    name: "Edit Column Layout",
    key: "editColumnLayout",
    icon: ColumnsIcon
  },
  qrvalue: {
    name: "Edit QR Code Content",
    key: "qrvalue",
    icon: QRIcon
  }
};

export default elementsRestrictionsMap;
