import { cloneDeep } from "lodash";
import Element from "state/ui/editor/elements/Element";

class VectorElement extends Element {
  static RESTRICTIONS = [
    "removable",
    "visibility",
    "opacity",
    "angle",
    "duplicate",
    "sizeAndPosition",
    "color",
    "filters",
    "flipping",
    "cropping",
    "imageUpload",
    "outline"
  ];

  static DEFAULT_PROPS = {
    imageInstructions: []
  };

  getRestrictionsMap() {
    const restrictions = {};

    let availableRestrictions = [...this.constructor.RESTRICTIONS];

    if (this.imageInstructions.length === 0) {
      availableRestrictions = availableRestrictions.filter(
        restriction => restriction !== "imageUpload"
      );
    }

    availableRestrictions.forEach(
      restriction => (restrictions[restriction] = false)
    );

    this.restrictions.forEach(restriction => {
      restrictions[restriction] = true;
    });

    return restrictions;
  }

  changeFillColor({ domId, color }) {
    const fillColorsUpdated = [];

    this.fillColors.forEach(fillColor => {
      if (!fillColor.domId.includes(domId)) {
        fillColorsUpdated.push(fillColor);
      } else {
        fillColorsUpdated.push({
          ...fillColor,
          color
        });
      }
    });

    return new this.constructor({
      ...this,
      fillColors: fillColorsUpdated
    });
  }

  get minWidth() {
    return this.resizableX ? this.srcWidth * (2 / 3) * this.scale : 1;
  }

  get minHeight() {
    return this.resizableY ? this.srcHeight * (2 / 3) * this.scale : 1;
  }

  updateAttributes(attributes) {
    if (window.easil.context.isCroppingVector) {
      return new this.constructor({
        ...this,
        ...attributes
      });
    }

    const _attributes = super.ensureMinimalValues(attributes);

    const sideEffects = {};

    if (!this.resizableX && _attributes.hasOwnProperty("width")) {
      const scaleFactor = _attributes.width / this.width;

      sideEffects.height = this.height * scaleFactor;
      sideEffects.scale = this.scale * scaleFactor;
    }

    if (!this.resizableY && _attributes.hasOwnProperty("height")) {
      const scaleFactor = _attributes.height / this.height;

      sideEffects.width = this.width * scaleFactor;
      sideEffects.scale = this.scale * scaleFactor;
    }

    if (_attributes.hasOwnProperty("imageInstructions")) {
      const attributeImageInstructionIds = _attributes.imageInstructions.map(
        instruction => instruction.id
      );
      // make sure that we are just replacing the selected instructions
      _attributes.imageInstructions = [
        ...this.imageInstructions.filter(
          instruction => !attributeImageInstructionIds.includes(instruction.id)
        ),
        ..._attributes.imageInstructions
      ];
    }

    return new this.constructor({
      ...this,
      ..._attributes,
      ...sideEffects
    });
  }

  updateImageInstructions({ imageInstructions }) {
    const instructionMap = {};
    imageInstructions.forEach(
      imageInstruction =>
        (instructionMap[imageInstruction.domId] = imageInstruction)
    );

    const updatedInstructions = this.imageInstructions.map(imageInstruction => {
      if (instructionMap[imageInstruction.domId]) {
        // Use the updated image instruction.
        return instructionMap[imageInstruction.domId];
      }
      return imageInstruction;
    });

    return new this.constructor({
      ...this,
      imageInstructions: updatedInstructions
    });
  }

  getImageColors() {
    const colors = {};

    this.imageInstructions.forEach(imageInstruction => {
      this.getImageInstructionColors(imageInstruction, colors);
    });

    return colors;
  }

  getImageInstructionColors(imageInstruction, colors = {}) {
    if (!imageInstruction.id) {
      return;
    }

    return (colors[imageInstruction.id] = {
      previewUrl: imageInstruction.previewSrc || this.src,
      colors: imageInstruction.palette
    });
  }

  disconnectSmartImage(domId) {
    const imageInstructionsClone = cloneDeep(this.imageInstructions);
    const selectedImageInstruction = imageInstructionsClone.find(
      instruction => instruction.domId === domId
    );

    if (selectedImageInstruction) {
      delete selectedImageInstruction.label;
    }

    return new this.constructor({
      ...this,
      imageInstructions: imageInstructionsClone
    });
  }
}

export default VectorElement;
