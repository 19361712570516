import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ClockArrowAntiClockwiseIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => {
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 22}
      height={height || size || 22}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      ariaLabelledby="title"
      fill={color || easilGrayXDark}
    >
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M4.379 6.01A7 7 0 1 1 3 10.182"
          stroke={color || easilGrayXDark}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          stroke={color || easilGrayXDark}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.5 3v3.5h3M10 7v4l2 1"
        />
      </g>
    </svg>
  );
};

ClockArrowAntiClockwiseIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ClockArrowAntiClockwiseIcon;
