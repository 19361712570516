export const FETCH_DESIGN_SMART_IMAGES_REQUEST =
  "API/ENTITIES/DESIGN_SMART_IMAGES_REQUEST";
export const FETCH_DESIGN_SMART_IMAGES_SUCCESS =
  "API/ENTITIES/DESIGN_SMART_IMAGES_SUCCESS";
export const FETCH_DESIGN_SMART_IMAGES_FAILURE =
  "API/ENTITIES/DESIGN_SMART_IMAGES_FAILURE";

export const UPDATE_DESIGN_SMART_ASSET_OWNER_REQUEST =
  "API/ENTITIES/UPDATE_DESIGN_SMART_ASSET_OWNER_REQUEST";
export const UPDATE_DESIGN_SMART_ASSET_OWNER_REQUEST_SUCCESS =
  "API/ENTITIES/UPDATE_DESIGN_SMART_ASSET_OWNER_REQUEST_SUCCESS";
export const UPDATE_DESIGN_SMART_ASSET_OWNER_REQUEST_FAILURE =
  "API/ENTITIES/UPDATE_DESIGN_SMART_ASSET_OWNER_REQUEST_FAILURE";
