import React from "react";
import style from "./style.module.css";
import PATHS from "routes/paths";

import { Button } from "views/components";
import LightBulbPencilAndPaperIcon from "views/components/icons/LightBulbPencilAndPaperIcon";

import getStateValues from "./stateValues";

const getEmptyStateForDisplaying = ({
  filters,
  resource,
  isDesigner,
  teamAllowEasilTemplatesSetting,
  changeQuery,
  buttonOnClickProp,
  isImageFolder,
  isSmartImageTransfer,
  isImageSearch,
  imageSearchTerm,
  hasTeamDesignsInFolders
}) => {
  const stateValues = getStateValues();
  /* using the props passed in determine the title, content and if the easil templates button should be present */
  if (PATHS.isWorkspaceFolder(resource)) {
    return stateValues.workspaceFolder;
  }
  if (PATHS.isCatalogueFolder(resource)) {
    return stateValues.catalogueFolder;
  }

  if (PATHS.isDesignSearch(resource)) {
    /* is empty search result */
    return stateValues.search({
      term: filters.term.replace(/\+/g, " "),
      changeQuery
    });
  }

  if (resource === PATHS.workspaceArchived) {
    /* is empty archived section */
    if (!isDesigner && !teamAllowEasilTemplatesSetting) {
      return stateValues.archivedWorkspaceWithoutEasil;
    }
    return stateValues.archivedWorkspaceWithEasil;
  }

  if (resource === PATHS.catalogueArchived) {
    /* is empty archived section */
    if (!isDesigner && !teamAllowEasilTemplatesSetting) {
      return stateValues.archivedTemplatesWithoutEasil;
    }
    return stateValues.archivedTemplatesWithEasil;
  }

  if (PATHS.isWorkspaceUploadsImages(resource)) {
    if (isImageFolder) {
      if (isSmartImageTransfer) {
        if (isImageSearch && imageSearchTerm)
          return stateValues.imageSearch(buttonOnClickProp, imageSearchTerm);
        return stateValues.smartImageEmpty();
      }
      return stateValues.imagesFolder(buttonOnClickProp);
    }
    if (isImageSearch && imageSearchTerm) {
      return stateValues.imageSearch(buttonOnClickProp, imageSearchTerm);
    }
    return stateValues.images(buttonOnClickProp);
  }

  if (PATHS.isWorkspaceUploadsAnimations(resource)) {
    if (isImageFolder) {
      return stateValues.animationsFolder(buttonOnClickProp);
    }
    if (isImageSearch && imageSearchTerm) {
      return stateValues.animationsSearch(buttonOnClickProp, imageSearchTerm);
    }
    return stateValues.animations(buttonOnClickProp);
  }

  if (resource === PATHS.brandAnimations) {
    // TODO: Implement empty states for folders and search
    if (isImageFolder && !isImageSearch) {
      return stateValues.animationsFolder(buttonOnClickProp);
    }
    if (isImageSearch && imageSearchTerm) {
      return stateValues.brandAnimationsSearch(imageSearchTerm);
    }
    return stateValues.brandAnimations(buttonOnClickProp);
  }

  /* is empty workspace */
  if (resource === PATHS.workspaceDrafts) {
    return {
      ...stateValues.emptyWorkSpace,
      showButton: teamAllowEasilTemplatesSetting
    };
  }

  /* in case the team has no easil templates and user is not a designer */
  if (!isDesigner && !teamAllowEasilTemplatesSetting) {
    return stateValues.emptyTeamTemplatesWithoutEasil;
  }

  if (PATHS.isTeamTemplates(resource)) {
    // when this value is unset we want to show an empty component
    // this avoids sudden text change
    if (hasTeamDesignsInFolders === null) {
      return stateValues.empty;
    }
    if (hasTeamDesignsInFolders) {
      return stateValues.emptyTeamTemplatesDesignsInFolder;
    } else {
      return stateValues.emptyTeamTemplatesWithEasil;
    }
  }

  /* empty drafts page */
  return stateValues.drafts;
};

const DesignsEmptyState = ({
  changePage,
  changeQuery,
  filters,
  resource,
  isDesigner,
  teamAllowEasilTemplatesSetting,
  buttonOnClick: buttonOnClickProp,
  isImageFolder,
  isSmartImageTransfer,
  isImageSearch,
  imageSearchTerm,
  hasTeamDesignsInFolders
}) => {
  let {
    title,
    titleStyle = {},
    content,
    contentStyle = {},
    showButton,
    button: {
      buttonText = "View Easil Templates",
      buttonLocation = PATHS.catalogueEasil,
      buttonTheme = "grayBackgroundXDarkGrayFont",
      buttonOnClick,
      buttonClassName
    } = {},
    icon: { IconComponent = LightBulbPencilAndPaperIcon, iconStyle = {} } = {},
    containerClassName
  } = getEmptyStateForDisplaying({
    filters,
    resource,
    isDesigner,
    teamAllowEasilTemplatesSetting,
    changeQuery,
    buttonOnClickProp,
    isImageFolder,
    isSmartImageTransfer,
    isImageSearch,
    imageSearchTerm,
    hasTeamDesignsInFolders
  });

  if (!buttonOnClick) buttonOnClick = () => changePage(buttonLocation);

  return (
    <div className={`${style.emptyStateContainer} ${containerClassName}`}>
      <div className={style.emptyStateIcons} style={iconStyle}>
        <IconComponent color={style.easilBlack} />
      </div>
      <div className={style.emptyStateTitle} style={titleStyle}>
        {title}
      </div>
      <div className={style.emptyStateDescription} style={contentStyle}>
        {content}
      </div>
      <div>
        {showButton && (
          <Button
            onClick={buttonOnClick}
            theme={buttonTheme}
            className={buttonClassName}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default DesignsEmptyState;
