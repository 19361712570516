import { omit } from "lib/lodash";
import { insertItem, moveItem, removeItem } from "lib/array/array";
import { getAnimationData } from "lib/animatedElementUtils";
import { cloneDeep } from "lodash";
import { isQuickInputElement } from "lib/elements";

const DesignLayerOps = {
  /**
   * @desc - moves an array of layers around the canvas
   * @param {array} elements - an array of the layer elements to move
   * @param {number} pageIndex - the index of the page the layers are being moved to
   * @param {string} groupId - the Id of the group that the layers are being nested under
   * @param {number} baseNewIndex - the first element index that the layers are being moved to
   * @return {designData} - returns a designData object which can be applied to a setStateAndSave call to change the editor state
   */
  moveLayers({ elements, pageIndex, groupId, baseNewIndex }) {
    /* define target for dropping layers */
    const toDetails = {
      pageIndex,
      groupId,
      index: baseNewIndex
    };

    let newPages = this.pages;
    let newElements = this.elements;

    /* iterate through all the layer elements to move */
    elements.forEach((element, index) => {
      // need to break here if the element is a group being moved to a group (itself or any other)
      if (
        (element.type === "group" && toDetails.groupId) ||
        ((element.restrictions?.position === true ||
          element.restrictions?.sizeAndPosition === true) &&
          element.pageIndex !== toDetails.pageIndex)
      ) {
        return;
      }

      const fromPageId = element.pageId;

      /* get the length of the elements list */
      const elementsLength = newPages[fromPageId].elementsOrder.length - 1;
      const groupElementsLength = element.groupId
        ? newElements[element.groupId].elementsOrder.length - 1
        : undefined;

      /* take the current index of the element from the number of elements to get the reverse element index (gets applied to a reversed array) */
      let elementIndex;
      if (!element.groupId) {
        elementIndex =
          elementsLength -
          newPages[fromPageId].elementsOrder.indexOf(element.itemId);
      } else {
        const offset = toDetails.groupId && index > 0 ? 1 * index : 0;
        elementIndex =
          groupElementsLength -
          newElements[element.groupId].elementsOrder.indexOf(element.itemId) +
          offset;
      }

      /* put together an object specifying where the element came from */
      const fromDetails = {
        pageIndex: element.pageIndex,
        groupId: element.groupId,
        elementIndex
      };

      /* get the result of moving the element */
      const {
        pagesUpdated: newPagesUpdated,
        elementsUpdated: newElementsUpdated
      } = this.moveLayer({
        elementId: element.itemId,
        fromDetails,
        toDetails,
        returnConstructed: false,
        newElements,
        newPages
      });

      /* apply the results globally to ensure they can be accessed in next loop */
      newPages = newPagesUpdated || newPages || this.pages;
      newElements = newElementsUpdated || newElements || this.elements;
    });

    /* build a new designData version containing all the changes to the layers */
    const designData = new this.constructor({
      ...this,
      pages: newPages,
      elements: newElements,
      version: this.version + 1
    });

    return designData;
  },

  /**
   * @desc - moves a single layer in the canvas
   * @param {string} elementId - the id for the element to move
   * @param {object} fromDetails - an object representing the location the element layer is moving from
   * @param {number} fromDetails.pageIndex - the index of the page the element is currently on
   * @param {string} fromDetails.groupId - the id of the group the element is currently in
   * @param {number} fromDetails.elementIndex - the index of the element in the page/group it comes from
   * @param {object} toDetails - an object representing the location to move an element layer to
   * @param {number} toDetails.pageIndex - the index of the page to move the element to
   * @param {string} toDetails.groupId - the id of the group the element is to be moved to
   * @param {number} toDetails.elementIndex - the index of the element in the page/group it is to be moved to
   * @param {boolean} [returnConstructed=true] - whether to return a constructed designData object or raw update data
   */
  moveLayer({
    elementId,
    fromDetails,
    toDetails,
    returnConstructed = true,
    newElements,
    newPages
  }) {
    const isMoveWithinGroup =
      fromDetails.groupId && fromDetails.groupId === toDetails.groupId;

    if (isMoveWithinGroup) {
      /* move the layer order within a group */
      return this.moveLayerWithinGroup({
        elementId,
        fromDetails,
        toDetails,
        returnConstructed,
        newElements
      });
    }

    const isMoveAcrossGroups =
      fromDetails.groupId &&
      toDetails.groupId &&
      fromDetails.groupId !== toDetails.groupId;

    if (isMoveAcrossGroups) {
      /* move the layer from one group to another group */
      return this.moveLayerAcrossGroups({
        elementId,
        fromDetails,
        toDetails,
        returnConstructed,
        newElements
      });
    }

    const isMoveFromGroupToPage = fromDetails.groupId && !toDetails.groupId;

    if (isMoveFromGroupToPage) {
      /* move the layer out of a group */
      return this.moveLayerFromGroupToPage({
        elementId,
        fromDetails,
        toDetails,
        returnConstructed,
        newElements,
        newPages
      });
    }

    const isMoveFromPageToGroup = !fromDetails.groupId && toDetails.groupId;

    if (isMoveFromPageToGroup) {
      /* move the layer into a group */
      return this.moveLayerFromPageToGroup({
        elementId,
        fromDetails,
        toDetails,
        returnConstructed,
        newElements,
        newPages
      });
    }

    const isMoveAcrossPages = fromDetails.pageIndex !== toDetails.pageIndex;

    if (isMoveAcrossPages) {
      /* move layer to different page */
      return this.moveLayerAcrossPages({
        elementId,
        fromDetails,
        toDetails,
        returnConstructed,
        newPages
      });
    }

    /* move the layer within the page */
    return this.moveLayerInPage({
      elementId,
      fromDetails,
      toDetails,
      returnConstructed,
      newPages
    });
  },

  /**
   * @desc - moves a single layer within a group
   * @param {object} fromDetails - an object representing the location the element layer is moving from
   * @param {number} fromDetails.pageIndex - the index of the page the element is currently on
   * @param {string} fromDetails.groupId - the id of the group the element is currently in
   * @param {number} fromDetails.elementIndex - the index of the element in the page/group it comes from
   * @param {object} toDetails - an object representing the location to move an element layer to
   * @param {number} toDetails.pageIndex - the index of the page to move the element to
   * @param {string} toDetails.groupId - the id of the group the element is to be moved to
   * @param {number} toDetails.elementIndex - the index of the element in the page/group it is to be moved to
   * @param {boolean} [returnConstructed=true] - whether to return a constructed designData object or raw update data
   */
  moveLayerWithinGroup({
    fromDetails,
    toDetails,
    returnConstructed,
    newElements
  }) {
    const group = this.getElement(fromDetails.groupId);

    const currentElements = newElements || this.elements;

    const elementsUpdated = {
      ...currentElements,
      [fromDetails.groupId]: group.moveItem(
        fromDetails.elementIndex,
        toDetails.index,
        // when constructing multiple changes pass updated element order
        !returnConstructed
          ? currentElements[fromDetails.groupId].elementsOrder
          : undefined
      )
    };

    /* just return the updated elements */
    if (!returnConstructed) return { elementsUpdated };

    /* return a full designData object */
    return new this.constructor({
      ...this,
      elements: elementsUpdated,
      version: this.version + 1
    });
  },

  /**
   * @desc - moves a single layer from one group to another
   * @param {string} elementId - the id for the element to move
   * @param {object} fromDetails - an object representing the location the element layer is moving from
   * @param {number} fromDetails.pageIndex - the index of the page the element is currently on
   * @param {string} fromDetails.groupId - the id of the group the element is currently in
   * @param {number} fromDetails.elementIndex - the index of the element in the page/group it comes from
   * @param {object} toDetails - an object representing the location to move an element layer to
   * @param {number} toDetails.pageIndex - the index of the page to move the element to
   * @param {string} toDetails.groupId - the id of the group the element is to be moved to
   * @param {number} toDetails.elementIndex - the index of the element in the page/group it is to be moved to
   * @param {boolean} [returnConstructed=true] - whether to return a constructed designData object or raw update data
   */
  moveLayerAcrossGroups({
    elementId,
    fromDetails,
    toDetails,
    returnConstructed,
    newElements
  }) {
    const toGroup = this.getElement(toDetails.groupId);
    const fromGroup = this.getElement(fromDetails.groupId);

    const currentElements = newElements || this.elements;

    const elementsUpdated = {
      ...currentElements,
      [fromDetails.groupId]: fromGroup.deleteItem(
        elementId,
        // when constructing multiple changes pass updated element order
        !returnConstructed
          ? currentElements[fromDetails.groupId].elementsOrder
          : undefined
      ),
      [toDetails.groupId]: toGroup.insertItem(
        elementId,
        toDetails.index,
        // when constructing multiple changes pass updated element order
        !returnConstructed
          ? currentElements[toDetails.groupId].elementsOrder
          : undefined
      )
    };

    /* just return the updated elements */
    if (!returnConstructed) return { elementsUpdated };

    /* return a full designData object */
    return new this.constructor({
      ...this,
      elements: elementsUpdated,
      version: this.version + 1
    });
  },

  /**
   * @desc - moves a single layer out of its containing group and into the page structure
   * @param {string} elementId - the id for the element to move
   * @param {object} fromDetails - an object representing the location the element layer is moving from
   * @param {number} fromDetails.pageIndex - the index of the page the element is currently on
   * @param {string} fromDetails.groupId - the id of the group the element is currently in
   * @param {number} fromDetails.elementIndex - the index of the element in the page/group it comes from
   * @param {object} toDetails - an object representing the location to move an element layer to
   * @param {number} toDetails.pageIndex - the index of the page to move the element to
   * @param {string} toDetails.groupId - the id of the group the element is to be moved to
   * @param {number} toDetails.elementIndex - the index of the element in the page/group it is to be moved to
   * @param {boolean} [returnConstructed=true] - whether to return a constructed designData object or raw update data
   */
  moveLayerFromGroupToPage({
    elementId,
    fromDetails,
    toDetails,
    returnConstructed,
    newElements,
    newPages
  }) {
    const group = this.getElement(fromDetails.groupId);
    const element = this.getElement(elementId).updateAttributes({
      groupId: null
    });

    const currentElements = newElements || this.elements;
    const currentPages = newPages || this.pages;

    const elementsUpdated = {
      ...currentElements,
      [elementId]: element,
      [fromDetails.groupId]: group.deleteItem(
        elementId,
        // when constructing multiple changes pass updated element order
        !returnConstructed
          ? currentElements[fromDetails.groupId].elementsOrder
          : undefined
      )
    };

    const pageId = this.pagesOrder[toDetails.pageIndex];
    const toPageElementsOrderUpdated = insertItem(
      currentPages[pageId].elementsOrder.slice().reverse(),
      toDetails.index,
      elementId
    ).reverse();

    const fromPageId = this.pagesOrder[fromDetails.pageIndex];
    const fromPageQuickInputOrder = [
      ...currentPages[fromPageId].quickInputOrder
    ];
    // remove element from previous page quick input order
    const updatedFromPageQuickInputOrder = fromPageQuickInputOrder.filter(
      id => id !== elementId
    );
    const updatedToPageQuickInputOrder = [
      ...currentPages[pageId].quickInputOrder
    ];
    // push element into new page quick input order
    if (isQuickInputElement(element.type) && fromPageId !== pageId)
      updatedToPageQuickInputOrder.push(elementId);

    const pagesUpdated = {
      ...currentPages,
      [fromPageId]: {
        ...currentPages[fromPageId],
        quickInputOrder: updatedFromPageQuickInputOrder
      },
      [pageId]: {
        ...currentPages[pageId],
        elementsOrder: toPageElementsOrderUpdated,
        quickInputOrder: updatedToPageQuickInputOrder
      }
    };

    /* just return the updated elements and updated pages */
    if (!returnConstructed) return { elementsUpdated, pagesUpdated };

    /* return a full designData object */
    return new this.constructor({
      ...this,
      elements: elementsUpdated,
      pages: pagesUpdated,
      version: this.version + 1
    });
  },

  /**
   * @desc - moves a single ungrouped layer into a given group
   * @param {string} elementId - the id for the element to move
   * @param {object} fromDetails - an object representing the location the element layer is moving from
   * @param {number} fromDetails.pageIndex - the index of the page the element is currently on
   * @param {string} fromDetails.groupId - the id of the group the element is currently in
   * @param {number} fromDetails.elementIndex - the index of the element in the page/group it comes from
   * @param {object} toDetails - an object representing the location to move an element layer to
   * @param {number} toDetails.pageIndex - the index of the page to move the element to
   * @param {string} toDetails.groupId - the id of the group the element is to be moved to
   * @param {number} toDetails.elementIndex - the index of the element in the page/group it is to be moved to
   * @param {boolean} [returnConstructed=true] - whether to return a constructed designData object or raw update data
   */
  moveLayerFromPageToGroup({
    elementId,
    fromDetails,
    toDetails,
    returnConstructed,
    newElements,
    newPages
  }) {
    const pageId = this.pagesOrder[fromDetails.pageIndex];

    const currentElements = newElements || this.elements;
    const currentPages = newPages || this.pages;
    const fromPageElementsOrderUpdated = removeItem(
      currentPages[pageId].elementsOrder.slice().reverse(),
      fromDetails.elementIndex
    ).reverse();

    const pagesUpdated = {
      ...currentPages,
      [pageId]: {
        ...currentPages[pageId],
        elementsOrder: fromPageElementsOrderUpdated
      }
    };

    const group = this.getElement(toDetails.groupId);

    const elementsUpdated = {
      ...currentElements,
      [toDetails.groupId]: group.insertItem(
        elementId,
        toDetails.index,
        // when constructing multiple changes pass updated element order
        !returnConstructed
          ? currentElements[toDetails.groupId].elementsOrder
          : undefined
      ),
      [elementId]: {
        ...currentElements[elementId],
        groupId: toDetails.groupId
      }
    };

    /* just return the updated elements and updated pages */
    if (!returnConstructed) return { elementsUpdated, pagesUpdated };

    /* return a full designData object */
    return new this.constructor({
      ...this,
      elements: elementsUpdated,
      pages: pagesUpdated,
      version: this.version + 1
    });
  },

  /**
   * @desc - moves a single layer from one page to another page
   * @param {string} elementId - the id for the element to move
   * @param {object} fromDetails - an object representing the location the element layer is moving from
   * @param {number} fromDetails.pageIndex - the index of the page the element is currently on
   * @param {string} fromDetails.groupId - the id of the group the element is currently in
   * @param {number} fromDetails.elementIndex - the index of the element in the page/group it comes from
   * @param {object} toDetails - an object representing the location to move an element layer to
   * @param {number} toDetails.pageIndex - the index of the page to move the element to
   * @param {string} toDetails.groupId - the id of the group the element is to be moved to
   * @param {number} toDetails.elementIndex - the index of the element in the page/group it is to be moved to
   * @param {boolean} [returnConstructed=true] - whether to return a constructed designData object or raw update data
   */
  moveLayerAcrossPages({
    elementId,
    fromDetails,
    toDetails,
    returnConstructed,
    newPages
  }) {
    const element = this.getElement(elementId);
    const toPageId = this.pagesOrder[toDetails.pageIndex];
    const fromPageId = this.pagesOrder[fromDetails.pageIndex];

    const currentPages = newPages || this.pages;

    const toPageElementsOrderUpdated = insertItem(
      currentPages[toPageId].elementsOrder.slice().reverse(),
      toDetails.index,
      elementId
    ).reverse();

    const fromPageElementsOrderUpdated = removeItem(
      currentPages[fromPageId].elementsOrder.slice().reverse(),
      fromDetails.elementIndex
    ).reverse();

    const toPageQuickInputOrderUpdated = [
      ...currentPages[toPageId].quickInputOrder
    ];
    let fromPageQuickInputUpdated = [
      ...currentPages[fromPageId].quickInputOrder
    ];
    if (isQuickInputElement(element.type)) {
      toPageQuickInputOrderUpdated.push(elementId);
      fromPageQuickInputUpdated = fromPageQuickInputUpdated.filter(
        id => id !== elementId
      );
    } else if (element.type === "group") {
      const textboxIds = element.elementsOrder.filter(id =>
        isQuickInputElement(this.getElement(id).type)
      );
      toPageQuickInputOrderUpdated.push(...textboxIds);
      fromPageQuickInputUpdated = fromPageQuickInputUpdated.filter(
        id => !textboxIds.includes(id)
      );
    }

    const pagesUpdated = {
      ...currentPages,
      [fromPageId]: {
        ...currentPages[fromPageId],
        elementsOrder: fromPageElementsOrderUpdated,
        quickInputOrder: fromPageQuickInputUpdated
      },
      [toPageId]: {
        ...currentPages[toPageId],
        elementsOrder: toPageElementsOrderUpdated,
        quickInputOrder: toPageQuickInputOrderUpdated
      }
    };

    if (
      element.duration ||
      (element.imageInstructions &&
        element.imageInstructions.some(instruction => !!instruction.duration))
    ) {
      const animationData = getAnimationData({ ...element, pageId: toPageId });
      // remove animatedElement from previous page
      pagesUpdated[fromPageId] = {
        ...pagesUpdated[fromPageId],
        animatedElements: omit(
          pagesUpdated[fromPageId].animatedElements,
          animationData.map(data => data.animationDataKey)
        )
      };
      // add animatedElement to new page
      pagesUpdated[toPageId] = {
        ...pagesUpdated[toPageId],
        animatedElements: {
          ...pagesUpdated[toPageId].animatedElements,
          ...animationData.reduce(
            (accumulator, dataPoint) => ({
              ...accumulator,
              [dataPoint.animationDataKey]: dataPoint
            }),
            {}
          )
        }
      };
    }

    /* just return the updated pages */
    if (!returnConstructed) return { pagesUpdated };

    /* return a full designData object */
    return new this.constructor({
      ...this,
      pages: pagesUpdated,
      version: this.version + 1
    });
  },

  /**
   * @desc - moves a single layer in the element order of a page
   * @param {object} fromDetails - an object representing the location the element layer is moving from
   * @param {number} fromDetails.pageIndex - the index of the page the element is currently on
   * @param {string} fromDetails.groupId - the id of the group the element is currently in
   * @param {number} fromDetails.elementIndex - the index of the element in the page/group it comes from
   * @param {object} toDetails - an object representing the location to move an element layer to
   * @param {number} toDetails.pageIndex - the index of the page to move the element to
   * @param {string} toDetails.groupId - the id of the group the element is to be moved to
   * @param {number} toDetails.elementIndex - the index of the element in the page/group it is to be moved to
   * @param {boolean} [returnConstructed=true] - whether to return a constructed designData object or raw update data
   */
  moveLayerInPage({ fromDetails, toDetails, returnConstructed, newPages }) {
    /* if the moved element is above the destiny position, the index has to be corrected
       to account for the element once it is remove from there */
    const correctIndex =
      fromDetails.elementIndex < toDetails.index
        ? toDetails.index - 1
        : toDetails.index;
    const pageId = this.pagesOrder[toDetails.pageIndex];

    const currentPages = newPages || this.pages;

    const pageElementsOrderUpdated = moveItem(
      currentPages[pageId].elementsOrder.slice().reverse(),
      fromDetails.elementIndex,
      correctIndex
    ).reverse();

    const pagesUpdated = {
      ...currentPages,
      [pageId]: {
        ...currentPages[pageId],
        elementsOrder: pageElementsOrderUpdated
      }
    };

    /* just return the updated pages */
    if (!returnConstructed) return { pagesUpdated };

    /* return a full designData object */
    return new this.constructor({
      ...this,
      pages: pagesUpdated,
      version: this.version + 1
    });
  },

  updateQuickInputOrder({ pageId, quickInputOrder }) {
    const currentPages = cloneDeep(this.pages);

    const pagesUpdated = {
      ...currentPages,
      [pageId]: {
        ...currentPages[pageId],
        quickInputOrder
      }
    };

    return new this.constructor({
      ...this,
      pages: pagesUpdated,
      version: this.version + 1
    });
  }
};

export default DesignLayerOps;
