import { createSelector } from "reselect";
import { isEmpty, flatten, isNil, getPath } from "lib";
import { getParameterByName } from "lib/queryStringUtils";
import { getImageSize, getImageCreatedAt } from "lib/getImageDetails";

const teamImagesFoldersSelector = state => state.api.userTeamImages.folders;
const userTeamImagesEntitiesSelector = state => state.entities.userTeamImages;
const userTeamFoldersSearchSelector = state =>
  getPath(state, "ui.myImages.personal.folders", {});

export const formatSearchResultList = searchResults => {
  return searchResults.map(searchResult => ({
    ...searchResult,
    createdAt: getImageCreatedAt(searchResult),
    media: {
      id: searchResult.mediaId,
      name: searchResult.name,
      priority: null,
      createdAt: null,
      updatedAt: null,
      status: "ACTIVE",
      thumbnail: searchResult.thumbnailUrl.split("/").pop(),
      asset: searchResult.url.split("/").pop(),
      preview: searchResult.previewUrl.split("/").pop(),
      type: searchResult.type,
      size: getImageSize(searchResult) || 0,
      height: null,
      width: null
    }
  }));
};

const getFolderImages = (folderStore, imageFolderId, userTeamImages) => {
  if (isEmpty(folderStore)) {
    return null;
  }

  if (isEmpty(folderStore[imageFolderId])) {
    return null;
  }

  if (isNil(folderStore[imageFolderId].pages[1].ids)) {
    return null;
  }

  let imagesIds = [];

  Object.values(folderStore[imageFolderId].pages).forEach(page => {
    if (page.ids) {
      imagesIds = imagesIds.concat(page.ids);
    }
  });

  return imagesIds
    .filter(imageId => userTeamImages[imageId])
    .map(imageId =>
      Object.assign({ canBeDeleted: true }, userTeamImages[imageId])
    );
};

const imageFoldersEntitiesSelector = state => state.entities.imageFolders;
const imageFoldersApiSelector = state => state.api.imageFolders;

const processGetAllImagesFolders = (
  imageFolders,
  imageFoldersApi,
  teamImagesFolders,
  userTeamImages,
  userTeamImagesFoldersSearch
) => {
  const pages = imageFoldersApi.pages;

  if (isEmpty(pages) || (pages[1].isFetching && isNil(pages[1].ids))) {
    return null;
  }

  const pageNumbers = Object.keys(pages).sort();

  let allImageFoldersIds = [];

  pageNumbers.forEach(
    pageNumber =>
      (allImageFoldersIds = allImageFoldersIds.concat(
        pages[pageNumber].ids || []
      ))
  );

  const allImagesFolders = allImageFoldersIds.map(imageFolderId => {
    const entity = Object.assign({}, imageFolders[imageFolderId]);

    entity.images = getFolderImages(
      teamImagesFolders,
      imageFolderId,
      userTeamImages
    );

    if (userTeamImagesFoldersSearch[imageFolderId]) {
      entity.terms = Object.keys(
        userTeamImagesFoldersSearch[imageFolderId].terms
      ).reduce((formattedTerms, currentTerm) => {
        const termState =
          userTeamImagesFoldersSearch[imageFolderId].terms[currentTerm];
        const termEntities = {};

        formatSearchResultList(Object.values(termState.images || {})).forEach(
          searchResultEntity => {
            termEntities[searchResultEntity.mediaId] = searchResultEntity;
          }
        );

        return {
          ...formattedTerms,
          [currentTerm]: {
            ...termState,
            images: termEntities
          }
        };
      }, {});
    }

    return entity;
  });

  return allImagesFolders;
};

export const getAllImagesFolders = createSelector(
  [
    imageFoldersEntitiesSelector,
    imageFoldersApiSelector,
    teamImagesFoldersSelector,
    userTeamImagesEntitiesSelector,
    userTeamFoldersSearchSelector
  ],
  processGetAllImagesFolders
);

const folderIdsFromPageList = list => {
  const pages = Object.values(list);

  const isFetchingPage = pages.some(page => page.isFetching);

  if (isFetchingPage) {
    return [];
  } else {
    const allIds = flatten(pages.map(page => page.ids));

    return allIds;
  }
};

const folderListByPages = (folders, pageList) => {
  const folderIds = folderIdsFromPageList(pageList);

  if (!folders) return [];

  return Object.values(folders).filter(folder => folderIds.includes(folder.id));
};

const processImageFoldersSelector = (imageFolders, imageFoldersApi) => {
  const imageFoldersPages = imageFoldersApi.pages;

  return folderListByPages(imageFolders, imageFoldersPages);
};

export const imageFoldersSelector = createSelector(
  [imageFoldersEntitiesSelector, imageFoldersApiSelector],
  processImageFoldersSelector
);

const locationSearchSelector = state => state.router.location.search;

const processFolderFromQueryString = (imageFolders, locationSearch) => {
  const folderId = getParameterByName("folderId", locationSearch);

  if (!imageFolders || !folderId) {
    return null;
  }

  return imageFolders[folderId];
};

export const folderFromQueryString = createSelector(
  [imageFoldersEntitiesSelector, locationSearchSelector],
  processFolderFromQueryString
);
