import { rotateRect } from "lib/transform";

/**
 * Calculate the extreme position (min or max) of an element
 * based on the axis (x or y) and its rotation.
 * @param {Object} element - The element whose position is being calculated.
 * @param {string} axis - The axis on which to calculate (either "x" or "y").
 * @param {boolean} isLowPosition - If true, calculates the minimum position, otherwise calculates the maximum position.
 * @returns {number} - The extreme position (min or max) of the element.
 */
export const getExtremePosition = (element, axis, isLowPosition) => {
  const rotatedPoints = rotateRect(
    element.left + element.width / 2, // Center x
    element.top + element.height / 2, // Center y
    element.width,
    element.height,
    element.angle
  );
  const mathExtremeFunction = isLowPosition ? Math.min : Math.max;
  return mathExtremeFunction(...rotatedPoints.map(point => point[axis]));
};

/**
 * Get the bounding box of a group of elements. The smallest and largest extreme positions
 * of all elements in the group along the specified axis.
 * @param {Array} groupElements - The array of elements that belong to the group.
 * @param {string} axis - The axis on which to calculate (either "x" or "y").
 * @returns {Object} - An object containing the min and max extreme positions of the group.
 */
export const getGroupBoundingBox = (groupElements, axis) => {
  let minExtreme = Infinity;
  let maxExtreme = -Infinity;

  groupElements.forEach(element => {
    const minPosition = getExtremePosition(element, axis, true);
    const maxPosition = getExtremePosition(element, axis, false);
    minExtreme = Math.min(minExtreme, minPosition);
    maxExtreme = Math.max(maxExtreme, maxPosition);
  });

  return { minExtreme, maxExtreme };
};

/**
 * Calculate the total size (bounding box size) of all selected elements along the specified axis.
 * This function calculates the total length covered by the elements.
 * @param {Array} elementsData - The array of element data.
 * @param {string} axis - The axis on which to calculate the size (either "x" or "y").
 * @returns {number} - The total size (length) of all elements along the specified axis.
 */
export const calculateTotalSize = (elementsData, axis) => {
  return elementsData.reduce((sum, element) => {
    const minPosition = getExtremePosition(element, axis, true);
    const maxPosition = getExtremePosition(element, axis, false);
    return sum + (maxPosition - minPosition);
  }, 0);
};

/**
 * Calculate the available space for distribution and the required spacing between elements.
 * This function determines how much space there is between the first and last element and divides that space
 * by the number of elements to calculate the spacing between them.
 * @param {Array} elementsData - The array of element data.
 * @param {number} totalSize - The total size (bounding box size) of all elements.
 * @param {string} axis - The axis on which to calculate the spacing (either "x" or "y").
 * @returns {number} - The spacing between each element after distribution.
 */
export const calculateSpacing = (elementsData, totalSize, axis) => {
  const firstElement = elementsData[0];
  const lastElement = elementsData[elementsData.length - 1];
  const firstExtreme = getExtremePosition(firstElement, axis, true);
  const lastExtreme = getExtremePosition(lastElement, axis, false);
  const totalSpacing = lastExtreme - firstExtreme;

  const remainingSpace = totalSpacing - totalSize;
  return remainingSpace / (elementsData.length - 1);
};
