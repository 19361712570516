import { rotatePoint } from "lib/geometry/rotation";
import { vectorBetweenPoints } from "lib/geometry/vectors";
import ScalingCalculationFactory from "lib/scalingTools/ScalingCalculation/ScalingCalculationFactory";
import ScalingCalculation from "lib/scalingTools/ScalingCalculation";
import { adjustTableFontSizes, adjustTableRowHeights } from "lib/tableUtils";
import {
  getNodesInRange,
  getAllFontSizeContent,
  scaleFontSizeContent,
  scaleRichTextFontSizeValue
} from "lib/DOMNodeUtils";
import { createDOMElementFromHTMLString } from "lib/tests/DOMNodeUtils/testSetupFunctions";
import {
  generateTable2MinHeight,
  table2MinWidthGenerator,
  table2HeightUpdater
} from "views/components/Editor/sidebar/tabs/shapes/Tables2Tab/helper";

export const distanceBetweenPoints = (point1, point2) => {
  return Math.sqrt(
    Math.pow(Math.abs(point2.x - point1.x), 2) +
      Math.pow(Math.abs(point2.y - point1.y), 2)
  );
};

export const calculateScalingFactor = ({
  anchorPoint,
  handlerInitialPosition,
  differenceFromInitialPosition
}) => {
  const distanceFromAnchorToHandler = distanceBetweenPoints(
    anchorPoint,
    handlerInitialPosition
  );

  const distanceFromAnchorToMouseCurrentPosition = distanceBetweenPoints(
    anchorPoint,
    {
      x: handlerInitialPosition.x + differenceFromInitialPosition.x,
      y: handlerInitialPosition.y + differenceFromInitialPosition.y
    }
  );

  return distanceFromAnchorToMouseCurrentPosition / distanceFromAnchorToHandler;
};

export const resizeTable2CellContent = (cells, scale, zoom) => {
  if (!cells) return;

  const scaledTable2Cells = {};
  const table2Cells = Object.values(cells);
  table2Cells.forEach(cell => {
    scaledTable2Cells[cell.uniqueId] = {
      ...cell,
      value: scaleRichTextFontSizeValue(cell.value, scale),
      displayValue: scaleRichTextFontSizeValue(cell.value, scale),
      fontSize: cell.fontSize * scale,
      height: cell.height * scale,
      width: cell.width * scale,
      padding: cell.padding * scale
    };
  });

  return scaledTable2Cells;
};

export const resizeTable2CellWidths = (cells, scale, zoom) => {
  if (!cells) return;

  const scaledTable2Cells = {};
  const table2Cells = Object.values(cells);
  table2Cells.forEach(cell => {
    scaledTable2Cells[cell.uniqueId] = {
      ...cell,
      width: cell.width * scale
    };
  });

  return scaledTable2Cells;
};

export const resizeTable2CellHeights = (cells, scale, zoom) => {
  if (!cells) return;
  const scaledTable2Cells = {};
  const table2Cells = Object.values(cells);
  table2Cells.forEach(cell => {
    let contentCellDomRect;
    let contentCellDom = document.getElementById(`cell-${cell.uniqueId}`);
    if (!contentCellDom) {
      scaledTable2Cells[cell.uniqueId] = {
        ...cell,
        height: Math.max(cell.height * scale)
      };
      return;
    }

    contentCellDomRect = contentCellDom.childNodes[0].getBoundingClientRect();

    const MIN_ROW_HEIGHT = contentCellDomRect.height / zoom;

    scaledTable2Cells[cell.uniqueId] = {
      ...cell,
      height: Math.max(cell.height * scale, MIN_ROW_HEIGHT)
    };
  });
  return scaledTable2Cells;
};

export const resizeTextboxRichContent = (element, scale) => {
  // return early when textbox contains no text content
  if (!element || !element.value) return;

  const textboxElement = createDOMElementFromHTMLString(element.displayValue);

  textboxElement.style["position"] = "absolute";
  textboxElement.contentEditable = "true";

  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(textboxElement);

  selection.removeAllRanges();
  selection.addRange(range);

  const nodesInRange = getNodesInRange(textboxElement);

  const fontSizeNodes = getAllFontSizeContent(nodesInRange.allNodes);

  // only scale font size styling if fontSizeNodes are present
  if (fontSizeNodes.length) {
    scaleFontSizeContent({
      textboxElement,
      styledNodes: fontSizeNodes,
      scale
    });
    // reassign the new stripped innerHTML value to the element preview
    element.value = scaleRichTextFontSizeValue(element.value, scale);
    element.displayValue = textboxElement.innerHTML;
  }

  textboxElement.remove();
};

export const scaleElements = ({
  elements,
  zoom,
  dragItem,
  differenceFromInitialOffset
}) => {
  const isScalingGrid = elements.length === 1 && elements[0].type === "grid";

  if (isScalingGrid) {
    return scaleGrid({
      element: elements[0],
      zoom,
      dragItem,
      differenceFromInitialOffset
    });
  }

  const scalingCalculation = ScalingCalculationFactory({
    dragItem,
    differenceFromInitialOffset
  });

  const { scale, snapPointX, snapPointY } = scalingCalculation.calculateScale();

  const elementsResized = scalingCalculation.scaleElements({
    elements,
    scale,
    zoom
  });

  return [elementsResized, snapPointX, snapPointY, scale];
};

export const scaleGrid = ({
  element,
  zoom,
  dragItem,
  differenceFromInitialOffset
}) => {
  const {
    anchorPoint,
    handlerInitialPosition,
    handlerCoordinateName
  } = dragItem;

  const gridElement = element;

  const diffRotated = rotatePoint(
    differenceFromInitialOffset.x,
    differenceFromInitialOffset.y,
    0,
    0,
    -gridElement.angle
  );

  const referenceFactor = {
    y: handlerCoordinateName.includes("N") ? -1 : 1,
    x: handlerCoordinateName.includes("W") ? -1 : 1
  };

  /* The mouse move have to be adjusted to the reference */
  diffRotated.y = diffRotated.y * referenceFactor.y;
  diffRotated.x = diffRotated.x * referenceFactor.x;

  const gridWidthZoomed = gridElement.width * zoom;
  const gridHeightZoomed = gridElement.height * zoom;

  const scaledWidth = gridWidthZoomed + diffRotated.x;
  const scaledHeight = gridHeightZoomed + diffRotated.y;

  const min = 50;

  let isMin = false;

  if (scaledWidth < min) {
    diffRotated.x = 50 - gridWidthZoomed;
    isMin = true;
  }

  if (scaledHeight < min) {
    diffRotated.y = 50 - gridHeightZoomed;
    isMin = true;
  }

  const differenceFromInitialOffsetLimitedByMin = rotatePoint(
    diffRotated.x * referenceFactor.x,
    diffRotated.y * referenceFactor.y,
    0,
    0,
    gridElement.angle
  );

  const currentPosition = {
    x: handlerInitialPosition.x + differenceFromInitialOffsetLimitedByMin.x,
    y: handlerInitialPosition.y + differenceFromInitialOffsetLimitedByMin.y
  };

  const [closestX, distanceToX] = ScalingCalculation.getClosestPoint(
    dragItem.snapSystem.snapPointsCorrected.x,
    currentPosition.x
  );
  const [closestY, distanceToY] = ScalingCalculation.getClosestPoint(
    dragItem.snapSystem.snapPointsCorrected.y,
    currentPosition.y
  );

  const shouldSnapToX =
    distanceToX !== Infinity && Math.abs(distanceToX) > 0.1 && !isMin;
  const shouldSnapToY =
    distanceToY !== Infinity && Math.abs(distanceToY) > 0.1 && !isMin;

  if (shouldSnapToX && shouldSnapToY) {
    return [
      scaleGrid({
        element,
        zoom,
        dragItem,
        differenceFromInitialOffset: {
          x: differenceFromInitialOffset.x + distanceToX,
          y: differenceFromInitialOffset.y + distanceToY
        }
      })[0],
      closestX,
      closestY
    ];
  }

  if (shouldSnapToX) {
    return [
      scaleGrid({
        element,
        zoom,
        dragItem,
        differenceFromInitialOffset: {
          x: differenceFromInitialOffset.x + distanceToX,
          y: differenceFromInitialOffset.y
        }
      })[0],
      closestX,
      null
    ];
  }

  if (shouldSnapToY) {
    return [
      scaleGrid({
        element,
        zoom,
        dragItem,
        differenceFromInitialOffset: {
          x: differenceFromInitialOffset.x,
          y: differenceFromInitialOffset.y + distanceToY
        }
      })[0],
      null,
      closestY
    ];
  }

  const vectorFromAnchorToCurrent = vectorBetweenPoints(
    currentPosition,
    anchorPoint
  );

  const scaledCenter = {
    x: anchorPoint.x + vectorFromAnchorToCurrent.x / 2,
    y: anchorPoint.y + vectorFromAnchorToCurrent.y / 2
  };

  const newCoords = rotatePoint(
    anchorPoint.x,
    anchorPoint.y,
    scaledCenter.x,
    scaledCenter.y,
    -gridElement.angle
  );

  const vectorFromAnchorToHandlerInitialPosition = vectorBetweenPoints(
    handlerInitialPosition,
    anchorPoint
  );

  const nonScaledCenter = {
    x: anchorPoint.x + vectorFromAnchorToHandlerInitialPosition.x / 2,
    y: anchorPoint.y + vectorFromAnchorToHandlerInitialPosition.y / 2
  };

  const initialCoords = rotatePoint(
    anchorPoint.x,
    anchorPoint.y,
    nonScaledCenter.x,
    nonScaledCenter.y,
    -gridElement.angle
  );

  const newHeight = gridHeightZoomed + diffRotated.y;
  const newWidth = gridWidthZoomed + diffRotated.x;

  /* The  coordinates have to be adjusted to the reference */
  if (handlerCoordinateName.includes("N")) {
    newCoords.y -= newHeight;
    initialCoords.y -= gridHeightZoomed;
  }

  if (handlerCoordinateName.includes("W")) {
    newCoords.x -= newWidth;
    initialCoords.x -= gridWidthZoomed;
  }

  const updatedGridElement = {
    ...gridElement,
    topIncrement: newCoords.y - initialCoords.y,
    leftIncrement: newCoords.x - initialCoords.x,
    top: newCoords.y,
    left: newCoords.x,
    width: gridWidthZoomed + diffRotated.x,
    height: gridHeightZoomed + diffRotated.y
  };

  const imageInstructions = calculateGridImageInstructions({
    imageInstructions: gridElement.imageInstructions,
    diffRotated,
    zoom,
    source: gridElement.source,
    gridElement
  });

  return [
    [
      {
        ...updatedGridElement,
        imageInstructions
      }
    ]
  ];
};

const calculateImageInstructionGridCellScale = (
  imageInstruction,
  source,
  callback
) => {
  const checkGridLocation = (location, xScale, yScale) => {
    let _xScale = xScale;
    let _yScale = yScale;

    if (location.domId && location.domId === imageInstruction.domId) {
      callback({
        xScale,
        yScale
      });
      return;
    }

    if (location.type === "row") {
      _xScale = xScale / location.children.length;
    }
    if (location.type === "column") {
      _yScale = yScale / location.children.length;
    }
    if (["row", "column"].includes(location.type) && location.children) {
      location.children.forEach(child =>
        checkGridLocation(child, _xScale, _yScale)
      );
    }
  };

  return checkGridLocation(source, 1, 1);
};

const calculateGridImageInstructions = ({
  imageInstructions,
  diffRotated,
  zoom,
  source,
  gridElement
}) => {
  const getImageScale = (imageInstruction, gridScale) => {
    const cellHeight = gridElement.height * gridScale.yScale;
    const cellWidth = gridElement.width * gridScale.xScale;

    const xAxisGridCellScale = cellWidth / imageInstruction.width;
    const yAxisGridCellScale = cellHeight / imageInstruction.height;

    const unzoomedXDiff =
      ((diffRotated.x / zoom) * gridScale.xScale) / xAxisGridCellScale;
    const unzoomedYDiff =
      ((diffRotated.y / zoom) * gridScale.yScale) / yAxisGridCellScale;

    const newXScale =
      (imageInstruction.width + unzoomedXDiff) / imageInstruction.width;

    const newYScale =
      (imageInstruction.height + unzoomedYDiff) / imageInstruction.height;

    return Math.max(newXScale, newYScale);
  };

  const imageInstructionsUpdated = [];

  imageInstructions.forEach(imageInstruction => {
    if (!imageInstruction.id) {
      imageInstructionsUpdated.push(imageInstruction);
      return;
    }

    calculateImageInstructionGridCellScale(
      imageInstruction,
      source,
      gridScale => {
        const newScale = getImageScale(imageInstruction, gridScale);

        const newHeight = imageInstruction.height * newScale;
        const newWidth = imageInstruction.width * newScale;
        imageInstructionsUpdated.push({
          ...imageInstruction,
          height: newHeight,
          width: newWidth,
          top: (imageInstruction.top / imageInstruction.height) * newHeight,
          left: (imageInstruction.left / imageInstruction.width) * newWidth
        });
      }
    );
  });

  return imageInstructionsUpdated;
};

export const resizeElementHorizontally = ({
  element,
  anchorPoint,
  handlerCoordinateName,
  scale,
  zoom,
  offset
}) => {
  const radians = (Math.PI / 180) * (360 - element.angle);
  const scaledWidth = element.width * scale;

  let minWidth =
    element.type === "vector" ? element.srcWidth * (2 / 3) * element.scale : 1;

  if (element.type === "table2") {
    const updatedTable2 = table2MinWidthGenerator({
      ...element,
      width: "auto"
    });
    minWidth = updatedTable2.width;
  }

  const newWidth = Math.max(scaledWidth, minWidth);

  const rotationCorrection = {
    top: 0,
    left: 0
  };

  const widthIncrement = newWidth - element.width;

  if (handlerCoordinateName === "E") {
    rotationCorrection.top = -widthIncrement * Math.sin(radians);
    rotationCorrection.left = -widthIncrement * Math.cos(radians);
  }

  const { topLeft: nonScaledTopLeftPoint } = element.getCornersPosition();
  const { topLeft: scaledTopLeftPoint } = element.getCornersPosition({
    width: newWidth
  });

  const { x: scaleDiffX, y: scaleDiffY } = vectorBetweenPoints(
    nonScaledTopLeftPoint,
    scaledTopLeftPoint
  );

  const topIncrement = scaleDiffY - rotationCorrection.top;
  const newTop = (element.top + topIncrement) * zoom + offset.y;

  const leftIncrement = scaleDiffX + rotationCorrection.left;
  const newLeft = (element.left + leftIncrement) * zoom + offset.x;

  const elementScaledDimensions = {
    newTop,
    newLeft,
    top: newTop,
    left: newLeft,
    topIncrement,
    leftIncrement,
    widthIncrement,
    height: element.height * zoom,
    width: newWidth * zoom
  };

  return {
    ...element,
    ...elementScaledDimensions
  };
};

export const resizeElementVertically = ({
  element,
  anchorPoint,
  handlerCoordinateName,
  scale,
  zoom,
  offset
}) => {
  const radians = (Math.PI / 180) * (360 - element.angle);
  const scaledHeight = element.height * scale;
  const minHeight =
    element.type === "vector" ? element.srcHeight * (2 / 3) * element.scale : 1;
  let newHeight = Math.max(scaledHeight, minHeight);

  if (element.type === "table2") {
    const tableMinHeight = generateTable2MinHeight(element);
    if (newHeight < tableMinHeight) {
      newHeight = tableMinHeight;
    }
  }

  if (element.type === "textbox") {
    const textboxParent = document.getElementById(element.uniqueId);
    const textboxMinHeight = textboxParent.firstChild.offsetHeight;
    if (newHeight < textboxMinHeight) {
      newHeight = textboxMinHeight;
    }
  }

  const rotationCorrection = {
    top: 0,
    left: 0
  };

  const heightIncrement = newHeight - element.height;

  if (handlerCoordinateName === "N") {
    rotationCorrection.top = -heightIncrement * Math.cos(radians);
    rotationCorrection.left = -heightIncrement * Math.sin(radians);
  }

  const { topLeft: nonScaledTopLeftPoint } = element.getCornersPosition();
  const { topLeft: scaledTopLeftPoint } = element.getCornersPosition({
    height: newHeight
  });

  const { x: scaleDiffX, y: scaleDiffY } = vectorBetweenPoints(
    nonScaledTopLeftPoint,
    scaledTopLeftPoint
  );

  const topIncrement = rotationCorrection.top + scaleDiffY;
  const newTop = (element.top + topIncrement) * zoom + offset.y;

  const leftIncrement = scaleDiffX + rotationCorrection.left;
  const newLeft = (element.left + leftIncrement) * zoom + offset.x;

  const elementScaledDimensions = {
    height: newHeight * zoom,
    width: element.width * zoom,
    heightIncrement,
    topIncrement,
    leftIncrement,
    newTop,
    newLeft,
    top: newTop,
    left: newLeft
  };

  if (element.type === "table2") {
    const updatedCells = resizeTable2CellHeights(
      element.cells,
      newHeight / element.height,
      zoom
    );
    elementScaledDimensions.cells = updatedCells;
    const { height } = table2HeightUpdater({
      ...element,
      cells: updatedCells,
      height: "auto"
    });
    elementScaledDimensions.height = height * zoom;
  }

  return {
    ...element,
    ...elementScaledDimensions
  };
};

export const resizeElementProportionally = ({
  element,
  anchorPoint,
  dragItem,
  scale,
  zoom,
  offset,
  contextMenuPaste
}) => {
  const newColumnsMetadata = adjustTableFontSizes({
    columnsMetadata: element.columnsMetadata,
    scale
  });
  const newRows = adjustTableRowHeights({ rows: element.rows, scale });

  const currentLeft = element.left * zoom + offset.x;
  const newLeft = contextMenuPaste
    ? anchorPoint.x + (currentLeft - anchorPoint.x)
    : anchorPoint.x + (currentLeft - anchorPoint.x) * scale;
  const leftIncrement = (newLeft - currentLeft) / zoom;

  const currentTop = element.top * zoom + offset.y;
  const newTop = contextMenuPaste
    ? anchorPoint.y + (currentTop - anchorPoint.y)
    : anchorPoint.y + (currentTop - anchorPoint.y) * scale;
  const topIncrement = (newTop - currentTop) / zoom;

  const _element = { value: element.value, displayValue: element.displayValue };

  resizeTextboxRichContent(_element, scale);

  const elementScaledDimensions = {
    topIncrement,
    leftIncrement,
    top: newTop,
    left: newLeft,
    width: element.width * scale * zoom,
    height: element.height * scale * zoom,
    fontSize: element.fontSize * scale,
    scale: element.scale * scale,
    columnsMetadata: newColumnsMetadata,
    rows: newRows,
    value: _element.value,
    displayValue: _element.displayValue
  };

  if (element.type === "table2") {
    elementScaledDimensions.cells = resizeTable2CellContent(
      element.cells,
      scale,
      zoom
    );
  }

  let imageInstructions;

  if (element.type === "grid" && dragItem) {
    let _differenceFromInitialOffset = {
      x: 0,
      y: 0
    };

    switch (dragItem.handlerCoordinateName) {
      case "NW": {
        const offset = Math.max(
          element.width * zoom - element.width * zoom * scale,
          element.height * zoom - element.height * zoom * scale
        );
        _differenceFromInitialOffset = {
          x: offset,
          y: offset
        };
        break;
      }
      case "SW": {
        const offset = Math.max(
          element.width * zoom - element.width * zoom * scale,
          element.height * zoom - element.height * zoom * scale
        );
        _differenceFromInitialOffset = {
          x: offset,
          y: -offset
        };
        break;
      }
      case "NE": {
        const offset = Math.max(
          element.width * zoom * scale - element.width * zoom,
          element.height * zoom * scale - element.height * zoom
        );
        _differenceFromInitialOffset = {
          x: offset,
          y: -offset
        };
        break;
      }
      case "SE":
      default: {
        offset = Math.max(
          element.width * zoom * scale - element.width * zoom,
          element.height * zoom * scale - element.height * zoom
        );
        _differenceFromInitialOffset = {
          x: offset,
          y: offset
        };
        break;
      }
    }

    const scaledDimensions = scaleGrid({
      element,
      zoom,
      dragItem,
      differenceFromInitialOffset: _differenceFromInitialOffset
    })[0][0];

    imageInstructions = scaledDimensions.imageInstructions;
  }

  if (imageInstructions) {
    elementScaledDimensions.imageInstructions = imageInstructions;
  }

  return {
    ...element,
    ...elementScaledDimensions
  };
};
