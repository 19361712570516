import { isEmpty, isNil } from "lib";
import { createSelector } from "reselect";
import { userTeamImagesSelector } from "state/entities/userTeamImages/userTeamImagesSelectors";
import { formatSearchResultList } from "state/ui/userTeamImagesPage/userTeamImagesPageSelectors";

export const myImagesPersonalStateSelector = state =>
  state.ui.myImages.personal;

const getImagesByTerm = ({ imagesStore, canBeDeleted = false }) => {
  const terms = Object.keys(imagesStore.terms);

  const imagesByTerm = {};

  terms.forEach(term => {
    const termObject = imagesStore.terms[term];

    const formatImage = imageId => {
      return {
        ...termObject.images[imageId],
        canBeDeleted
      };
    };

    if (isNil(termObject.ids)) {
      imagesByTerm[term] = null;
    } else {
      imagesByTerm[term] = termObject.ids.map(formatImage);
    }
  });

  return imagesByTerm;
};

// TODO: Duplicated from userTeamImagesPageSelectors.js but modified for MyImages state.
export const userTeamImagesPageSelector = ({ state, term }) => {
  if (term && term !== "") {
    const resultImages = getUserImagesSearch(state);
    if (!isEmpty(resultImages)) {
      if (!isEmpty(resultImages[term])) {
        // return the current search bar result if it is present
        return formatSearchResultList(resultImages[term]);
      }
    }
    return [];
  }
  return userTeamImagesSelector(state);
};

export const getUserImagesSearch = createSelector(
  [myImagesPersonalStateSelector],
  imagesStore => getImagesByTerm({ imagesStore, canBeDeleted: true })
);
