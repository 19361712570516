import { combineReducers } from "redux";

import { usersReducers as users } from "./users";
import { pendingUsersReducers as pendingUsers } from "./pendingUsers";
import { teamsReducers as teams } from "./teams";
import { smartTextReducers as smartText } from "./smartText";
import { smartAssetsReducers as smartAssets } from "./smartAssets";
import { designsReducers as designs } from "./designs";
import { designsDataReducers as designsData } from "./designsData";
import { publicFontsReducers as publicFonts } from "./publicFonts";
import { userTeamFontsReducers as userTeamFonts } from "./userTeamFonts";
import { userTeamImagesReducers as userTeamImages } from "./userTeamImages";
import { userTeamSmartImagesReducers as userTeamSmartImages } from "./userTeamSmartImages";
import { userTeamAnimationsReducers as userTeamAnimations } from "./userTeamAnimations";
import { teamAnimationsReducers as teamAnimations } from "./teamAnimations";
import { teamHierarchyReducers as teamHierarchy } from "./teamHierarchy";
import { teamImagesReducers as teamImages } from "./teamImages";
import { teamSmartImagesReducers as teamSmartImages } from "./teamSmartImages";
import { teamLogosReducers as teamLogos } from "./teamLogos";
import { subscriptionsReducers as subscriptions } from "./subscriptions";
import { subscriptionPlansReducers as subscriptionPlans } from "./subscriptionPlans";
import { cardsReducers as cards } from "./cards";
import { catalogueSizesReducers as catalogueSizes } from "./catalogueSizes";
import { workspaceSizesReducers as workspaceSizes } from "./workspaceSizes";
import { downloadsReducers as downloads } from "./downloads";
import { foldersReducers as folders } from "./folders";
import { designFoldersReducers as designFolders } from "./designFolders";
import { tagsReducers as tags } from "./tags";
import { designTagsReducers as designTags } from "./designTags";
import { userTeamFavoritesReducers as userTeamFavorites } from "./userTeamFavorites";
import { graphicsReducers as graphics } from "./graphics";
import { teamBillingReducers as teamBilling } from "./teamBilling";
import { shippingRatesReducers as shippingRates } from "./shippingRates";
import { ordersReducers as orders } from "./orders";
import { orderTransactionsReducers as orderTransactions } from "./orderTransactions";
import { printPricingReducers as printPricing } from "./printPricing";
import { printOptionsReducers as printOptions } from "./printOptions";
import { teamSettingsReducers as teamSettings } from "./teamSettings";
import { searchSuggestionsReducers as searchSuggestions } from "./searchSuggestions";
import { imageFoldersReducers as imageFolders } from "./imageFolders";
import { userTeamAnimationsFoldersReducers as userTeamAnimationsFolders } from "./userTeamAnimationsFolders";
import { teamAnimationsFoldersReducers as teamAnimationsFolders } from "./teamAnimationsFolders";
import { creditsReducers as credits } from "./credits";
import { collectionAllocationsReducers as collectionAllocations } from "./collectionAllocations";
import { designActionsReducers as designActions } from "./designActions";
import { invoicesReducers as invoices } from "./invoices";
import { billingDetailsReducers as billingDetails } from "./billingDetails";
import { shippingDetailsReducers as shippingDetails } from "./shippingDetails";
import { purchasedCatalogueSizesReducers as purchasedCatalogueSizes } from "./purchasedCatalogueSizes";
import { stockAnimationsReducers as stockAnimations } from "./stockAnimations";

import { CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS } from "state/currentUser/currentUserTypes";

const combinedReducers = combineReducers({
  billingDetails,
  cards,
  catalogueSizes,
  collectionAllocations,
  credits,
  designActions,
  designFolders,
  designs,
  designsData,
  designTags,
  downloads,
  folders,
  graphics,
  imageFolders,
  invoices,
  orders,
  orderTransactions,
  pendingUsers,
  printOptions,
  printPricing,
  publicFonts,
  purchasedCatalogueSizes,
  searchSuggestions,
  shippingDetails,
  shippingRates,
  smartText,
  smartAssets,
  stockAnimations,
  subscriptionPlans,
  subscriptions,
  tags,
  teamAnimations,
  teamAnimationsFolders,
  teamBilling,
  teamHierarchy,
  teamImages,
  teamSmartImages,
  teamLogos,
  teams,
  teamSettings,
  users,
  userTeamAnimations,
  userTeamAnimationsFolders,
  userTeamFavorites,
  userTeamFonts,
  userTeamImages,
  userTeamSmartImages,
  workspaceSizes
});

const combinedWithReset = (state, action) => {
  if (action.type === CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS) {
    state = undefined;
  }

  return combinedReducers(state, action);
};

export default combinedWithReset;
