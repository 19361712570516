class Table2Borders {
  static BORDER_DEFAULT_COLOR = "#E5E8EB";
  static BORDER_DEFAULT_SIZE = 2;
  static BORDER_DEFAULT_STYLE = "solid";
  static DEFAULT_PROPS = {
    borderBottom: {
      color: Table2Borders.BORDER_DEFAULT_COLOR,
      thickness: Table2Borders.BORDER_DEFAULT_SIZE,
      style: Table2Borders.BORDER_DEFAULT_STYLE
    },
    borderTop: {
      color: Table2Borders.BORDER_DEFAULT_COLOR,
      thickness: Table2Borders.BORDER_DEFAULT_SIZE,
      style: Table2Borders.BORDER_DEFAULT_STYLE
    },
    borderRight: {
      color: Table2Borders.BORDER_DEFAULT_COLOR,
      thickness: Table2Borders.BORDER_DEFAULT_SIZE,
      style: Table2Borders.BORDER_DEFAULT_STYLE
    },
    borderLeft: {
      color: Table2Borders.BORDER_DEFAULT_COLOR,
      thickness: Table2Borders.BORDER_DEFAULT_SIZE,
      style: Table2Borders.BORDER_DEFAULT_STYLE
    }
  };

  static BORDER_POSITIONS = [
    "borderBottom",
    "borderTop",
    "borderRight",
    "borderLeft"
  ];

  constructor(
    {
      borderBottom = {},
      borderTop = {},
      borderRight = {},
      borderLeft = {}
    } = Table2Borders.DEFAULT_PROPS
  ) {
    this.borderBottom = {
      ...Table2Borders.DEFAULT_PROPS.borderBottom,
      ...borderBottom
    };
    this.borderTop = {
      ...Table2Borders.DEFAULT_PROPS.borderTop,
      ...borderTop
    };
    this.borderRight = {
      ...Table2Borders.DEFAULT_PROPS.borderRight,
      ...borderRight
    };
    this.borderLeft = {
      ...Table2Borders.DEFAULT_PROPS.borderLeft,
      ...borderLeft
    };
  }

  removeAllCellBorders() {
    const borders = Object.keys(this);
    const updatedBorders = {};

    borders.forEach(border => {
      updatedBorders[border] = {
        ...Table2Borders.DEFAULT_PROPS[border],
        color: null
      };
    });

    return new this.constructor(updatedBorders);
  }

  changeAllCellBordersAttribute(attribute, value) {
    const borders = Object.keys(this);
    const updatedBorders = {};

    borders.forEach(border => {
      updatedBorders[border] = {
        ...this[border],
        [attribute]: value
      };
    });

    return new this.constructor(updatedBorders);
  }

  changeAllCellBordersAttributes(attributes) {
    const borders = Object.keys(this);
    const updatedBorders = {};

    borders.forEach(border => {
      updatedBorders[border] = {
        ...this[border],
        ...attributes
      };
    });

    return new this.constructor(updatedBorders);
  }

  changeSingleCellBorderAttributes(borderPosition, attributes) {
    const borderPositionUpdated = {
      ...this[borderPosition],
      ...attributes
    };

    return new this.constructor({
      ...this,
      [borderPosition]: borderPositionUpdated
    });
  }

  toggleCellBorder(borderPosition) {
    const hasBorderPosition = this.hasCellBorderPosition(borderPosition);

    const newColor = hasBorderPosition ? null : this.getCellBordersColor();

    const borderPositionUpdated = {
      ...this[borderPosition],
      color: newColor
    };

    return new this.constructor({
      ...this,
      [borderPosition]: borderPositionUpdated
    });
  }

  hasCellBorderPosition(position) {
    return Boolean(this[position].color);
  }

  hasNoCellBorder() {
    const hasNoCellBorder = Table2Borders.BORDER_POSITIONS.every(
      borderPosition => !this.hasCellBorderPosition(borderPosition)
    );

    return hasNoCellBorder;
  }

  getCellBordersColor() {
    if (this.hasNoCellBorder()) {
      return Table2Borders.BORDER_DEFAULT_COLOR;
    }

    const firstCellBorderWithColor = Table2Borders.BORDER_POSITIONS.find(
      borderPosition => this[borderPosition].color !== null
    );

    return this[firstCellBorderWithColor].color;
  }

  getAsCss(backgroundColor, isSelected) {
    const { borderBottom, borderTop, borderLeft, borderRight } = this;

    const getCellBorderPositionCss = ({ thickness, style, color }) => {
      if (isSelected && !color) {
        return `${Table2Borders.BORDER_DEFAULT_SIZE}px ${Table2Borders.BORDER_DEFAULT_STYLE} ${Table2Borders.BORDER_DEFAULT_COLOR}`;
      }
      const borderColor = color || backgroundColor;
      const borderStyle = style || "solid";
      return `${thickness}px ${borderStyle} ${borderColor}`;
    };

    return {
      borderBottom: getCellBorderPositionCss(borderBottom),
      borderTop: getCellBorderPositionCss(borderTop),
      borderLeft: getCellBorderPositionCss(borderLeft),
      borderRight: getCellBorderPositionCss(borderRight)
    };
  }
}

export default Table2Borders;
