import * as types from "./userTeamImagesTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { currentUserSelector } from "state/currentUser/currentUserSelectors";
import { getCurrentTeamId } from "state/ui/currentTeam/currentTeamSelectors";
import { addBulkImagesToFolder } from "state/entities/imageFolders/imageFoldersActions";
import { makeToast } from "state/ui/toaster/ToasterActions";
import { noop, isEmpty } from "lib";

/**
 * @desc refetches all image pages currently in the userTeamImages api state
 * this function overwrites the current pages with the new ones and should only be used
 * as a hard refresh of the userTeamImages
 */
export const refetchUserTeamImagesPages = () => (dispatch, getState) => {
  const state = getState();

  const userImagesStore = state.api.userTeamImages;

  const pages = userImagesStore.pages;

  const lastPage = Math.max(...Object.keys(pages));

  for (let i = 1; i <= lastPage; i++) {
    dispatch(fetchUserTeamImages(i));
  }
};

export const refetchUserTeamImagesPagesInFolder = (
  folderId,
  sortBy,
  sortOrder
) => (dispatch, getState) => {
  const state = getState();

  const foldersStore = state.api.userTeamImages.folders[folderId];

  const pages = foldersStore ? foldersStore.pages : { 1: {} };

  const lastPage = Math.max(...Object.keys(pages));

  for (let i = 1; i <= lastPage; i++) {
    dispatch(
      fetchUserTeamImagesInFolder({ page: i, folderId, sortBy, sortOrder })
    );
  }
};

const shouldFetchUserImagesNextPage = (state, page) => {
  const userTeamImagesPages = state.api.userTeamImages.pages;
  if (
    !userTeamImagesPages ||
    !userTeamImagesPages[page] ||
    userTeamImagesPages[page].didInvalidate
  ) {
    return true;
  }
  return false;
};

export const getUserImageNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchUserImagesNextPage(getState(), args.page)) {
    return dispatch(getUserImageNextPage(args));
  }
};

export const getUserImageNextPage = ({
  page = 1,
  folderId,
  sortBy,
  sortOrder
} = {}) => (dispatch, getState) => {
  const state = getState();

  const userImagesStore = state.api.userTeamImages;

  const pages = userImagesStore.pages;

  if (isEmpty(pages)) {
    dispatch(fetchUserTeamImages({ page: 1, sortBy, sortOrder }));
    return;
  }

  const lastPage = Math.max(...Object.keys(pages));

  if (!pages || !pages[lastPage]) {
    dispatch(fetchUserTeamImages({ page: 1, folderId, sortBy, sortOrder }));
    return;
  }

  if (
    pages[lastPage].isFetching ||
    pages[lastPage].ids.length < userImagesStore.pageSize
  ) {
    return;
  }

  dispatch(fetchUserTeamImages({ page: lastPage + 1, sortBy, sortOrder }));
};

export const getUserImageInFolderNextPage = ({
  folderId,
  sortBy,
  sortOrder,
  onSuccess
}) => (dispatch, getState) => {
  const state = getState();

  const userImagesFolderStore = state.api.userTeamImages.folders[folderId];

  if (isEmpty(userImagesFolderStore)) {
    dispatch(
      fetchUserTeamImagesInFolder({
        page: 1,
        folderId,
        sortBy,
        sortOrder,
        onSuccess
      })
    );
    return;
  }

  const pages = userImagesFolderStore.pages;

  const lastPage = Math.max(...Object.keys(pages));

  if (pages[lastPage].isFetching) {
    return;
  }

  let page = lastPage;

  /* refetch the last page if not complete, or get next page */
  if (pages[lastPage].ids.length >= state.api.userTeamImages.pageSize) {
    page = lastPage + 1;
  }

  dispatch(
    fetchUserTeamImagesInFolder({
      folderId,
      page,
      sortBy,
      sortOrder,
      onSuccess
    })
  );
};

export const fetchUserTeamImagesInFolder = ({
  page = 1,
  folderId,
  sortBy,
  sortOrder,
  onSuccess
}) => (dispatch, getState) => {
  const actionsTypes = [
    types.USER_TEAM_IMAGES_IN_FOLDER_REQUEST,
    types.USER_TEAM_IMAGES_IN_FOLDER_REQUEST_SUCCESS,
    types.USER_TEAM_IMAGES_IN_FOLDER_REQUEST_FAILURE
  ];

  dispatch(
    fetchUserTeamImages({
      types: actionsTypes,
      params: { folderId },
      page: page,
      sortBy,
      sortOrder,
      onSuccess
    })
  );
};

const userTeamImageTypes = [
  types.USER_TEAM_IMAGES_REQUEST,
  types.USER_TEAM_IMAGES_REQUEST_SUCCESS,
  types.USER_TEAM_IMAGES_REQUEST_FAILURE
];

export const clearUserTeamImageState = () => ({
  type: types.USER_TEAM_IMAGES_CLEAR
});

export const refetchUserTeamImages = args => dispatch => {
  if (!args.skipStateClear) {
    dispatch(clearUserTeamImageState());
  }
  args.folderId
    ? dispatch(getUserImageInFolderNextPage(args))
    : dispatch(fetchUserTeamImages(args));
};

export const fetchUserTeamImages = ({
  params,
  page = 1,
  types = userTeamImageTypes,
  sortBy = "updatedAt",
  sortOrder = "desc",
  onSuccess = noop
} = {}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  const _params = {
    ...params,
    type: "image",
    sortBy,
    sortOrder: sortOrder.toLowerCase()
  };

  dispatch({
    [CALL_API]: {
      types: types,
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/images`,
      request: {
        params: _params,
        page: page,
        pageSize: getState().api.userTeamImages.pageSize
      },
      schema: schemas.USER_TEAM_IMAGES_ARRAY,
      onSuccess: response => {}
    }
  });
};

export const deleteUserTeamImage = ({
  mediaId,
  folderId,
  onSuccess = noop,
  isSmartImage = false
}) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;
  const userId = getState().currentUser.id;

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      types: [
        types.USER_TEAM_IMAGE_DELETE_REQUEST,
        types.USER_TEAM_IMAGE_DELETE_REQUEST_SUCCESS,
        types.USER_TEAM_IMAGE_DELETE_REQUEST_FAILURE
      ],
      request: {
        mediaId,
        extra: {
          folderId
        }
      },
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/images/${mediaId}?forceDelete=${isSmartImage}`,
      schema: schemas.NONE,
      onSuccess: response => onSuccess(response)
    }
  });
};

export const updateUserTeamImage = ({
  mediaId,
  folderId,
  image,
  onSuccess = noop
}) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;
  const userId = getState().currentUser.id;

  dispatch({
    [CALL_API]: {
      method: "PUT",
      types: [
        types.USER_TEAM_IMAGE_UPDATE_REQUEST,
        types.USER_TEAM_IMAGE_UPDATE_REQUEST_SUCCESS,
        types.USER_TEAM_IMAGE_UPDATE_REQUEST_FAILURE
      ],
      request: {
        body: {
          mediaId,
          folderId,
          userId,
          teamId,
          ...image
        }
      },
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/images/${mediaId}`,
      schema: schemas.NONE,
      onSuccess: response => onSuccess(response)
    }
  });
};

/**
 * @desc take an image file and uploads it to the users team images
 * @param {Object} args
 * @param {Object} args.file - an object that describes the file to upload from the file picker
 * @param {Number} args.duration - the duration of a given GIF
 * @param {function} args.onSuccess - a function which runs when the process succeeds passed the result of the request
 */
export const uploadUserTeamImage = ({
  imageFile: file,
  onSuccess,
  duration,
  onPresignedUrlCreated = noop,
  noRefetch,
  placeholderURL
}) => async (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  // synchronously generate a pre signed url for the direct upload
  const preSignedUrl = await generatePreSignedUrlPromise({ file, teamId });
  onPresignedUrlCreated(preSignedUrl.id);
  // synchronously upload the image file to the pre signed url
  await uploadImageToPreSignedUrlPromise({
    file,
    preSignedUrl: preSignedUrl.url
  });

  // make update request to the api
  dispatch(
    uploadUserTeamImageApiCall({
      duration,
      fileId: preSignedUrl.id,
      filename: preSignedUrl.filename,
      noRefetch,
      onSuccess,
      placeholderURL
    })
  );

  /**
   * @desc taking a file object and makes a request for a pre signed image upload url and returns it on resolution
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @returns {Promise} results in a Promise that resolves when the pre signed url is retrieved containing a 'url' key
   */
  function generatePreSignedUrlPromise({ file }) {
    return new Promise((resolve, reject) => {
      dispatch(
        createUserImageUploadUrl({
          filename: file.name,
          responseContentType: file.type,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }

  /**
   * @desc taking a file and a pre signed url processes the upload of the file to the passed url
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @param {string} args.preSignedUrl - a pre signed image upload url to use for directly uploading the passed file
   * @returns {Promise} results in a Promise that resolves when the image has been successfully uploaded
   */
  function uploadImageToPreSignedUrlPromise({ file, preSignedUrl }) {
    return new Promise((resolve, reject) => {
      dispatch(
        uploadImageToPreSignedUrl({
          imageFile: file,
          preSignedUrl: preSignedUrl,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }
};

/**
 * @desc adds the details for an uploaded image to the api
 * @param {Object} args
 * @param {string} args.filename - the name of the file that will be uploaded
 * @param {string} args.fileId - the id for the file that is being uploaded
 * @param {Number} args.duration - the duration of a given GIF
 * @param {function} args.onSuccess - a function which is run when the request succeeds and is passed the results of the request
 */
export const uploadUserTeamImageApiCall = ({
  duration,
  filename,
  fileId,
  onSuccess = noop,
  noRefetch,
  placeholderURL
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  dispatch({
    [CALL_API]: {
      method: "POST",
      types: [
        types.USER_TEAM_IMAGE_UPLOAD_REQUEST,
        types.USER_TEAM_IMAGE_UPLOAD_REQUEST_SUCCESS,
        types.USER_TEAM_IMAGE_UPLOAD_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET_V2,
      endpoint: `/teams/${teamId}/users/${userId}/images`,
      schema: schemas.NONE,
      request: {
        body: {
          duration,
          id: fileId,
          filename,
          userId,
          teamId
        }
      },
      extra: {
        placeholderURL
      },
      onSuccess: response => {
        if (!noRefetch) {
          dispatch(refetchUserTeamImagesPages());
        }
        onSuccess(response);
      }
    }
  });
};

/**
 * @desc makes the api request for a pre signed url
 * @param {Object} args
 * @param {string} args.filename - the name of the file that will be uploaded
 * @param {string} args.responseContentType - the type extension for the file to be uploaded
 * @param {function} args.onSuccess - a function that runs when the request succeeds passed the result of the request
 * @param {function} args.onFailure - a function that runs when the request fails passed the results of the request
 */
export const createUserImageUploadUrl = ({
  filename,
  responseContentType,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.USER_IMAGE_UPLOAD_URL_REQUEST,
        types.USER_IMAGE_UPLOAD_URL_REQUEST_SUCCESS,
        types.USER_IMAGE_UPLOAD_URL_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/images/url`,
      request: {
        body: {
          userId,
          teamId,
          filename,
          responseContentType
        }
      },
      onSuccess: onSuccess,
      schema: schemas.NONE
    }
  });
};

/**
 * @desc using the pre signed url passed processes the upload of a file
 * @param {Object} args
 * @param {Object} args.imageFile - an object that describes the file selected by the user in file picker
 * @param {string} args.preSignedUrl - a pre signed image upload url to use for directly uploading the passed file
 * @param {function} args.onSuccess - a function that runs when the request succeeds passed the result of the request
 * @param {function} args.onFailure - a function that runs when the request fails passed the results of the request
 */
export const uploadImageToPreSignedUrl = ({
  imageFile,
  preSignedUrl,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  const headers = new Headers({
    "Content-Type": imageFile.type
  });

  const requestSpecs = {
    method: "PUT",
    headers,
    body: imageFile
  };

  fetch(preSignedUrl, requestSpecs)
    .then(function(response) {
      if (!response.ok) {
        throw Error(
          `Image could not be upload. Error Message: ${response.statusText}`
        );
      }
      return response;
    })
    .then(onSuccess)
    .catch(onFailure);
};

export const addUploadingImagesPlaceholders = ({
  mediaPlaceholders,
  folderId
}) => {
  return {
    type: types.ADD_USER_TEAM_IMAGES_PLACEHOLDERS,
    mediaPlaceholders,
    folderId
  };
};

export const handleAddBulkImagesToFolder = ({
  mediaIds,
  folderId,
  onSuccess = noop,
  onFailure = noop
}) => dispatch => {
  dispatch(
    addBulkImagesToFolder({
      mediaIds,
      folderId,
      onSuccess,
      onFailure: () => {
        dispatch(
          makeToast({
            textComponent: `Some selected images could not be moved.\nPlease contact support if the issue\npersists`,
            type: "noButton",
            icon: "warning"
          })
        );
        onFailure();
      }
    })
  );
};

export const deleteUserTeamImages = (response, folderId, onSuccess = noop) => (
  dispatch,
  getState
) => {
  const teamId = getState().ui.currentTeam.id;
  const userId = getState().currentUser.id;

  const deletionId = response.id;
  const mediaIds = response.mediaIds;

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      types: [
        types.USER_TEAM_IMAGES_DELETE_REQUEST,
        types.USER_TEAM_IMAGES_DELETE_REQUEST_SUCCESS,
        types.USER_TEAM_IMAGES_DELETE_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/images/deletions/${deletionId}`,
      schema: schemas.NONE,
      extra: {
        mediaIds,
        folderId
      },
      onSuccess: () => onSuccess()
    }
  });
};

export const createDeleteUserTeamImagesRequest = ({
  mediaIds,
  folderId,
  onSuccess = noop
}) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;
  const userId = getState().currentUser.id;

  dispatch({
    [CALL_API]: {
      method: "POST",
      types: [
        types.USER_TEAM_IMAGES_CREATE_DELETE_REQUEST,
        types.USER_TEAM_IMAGES_CREATE_DELETE_REQUEST_SUCCESS,
        types.USER_TEAM_IMAGES_CREATE_DELETE_REQUEST_FAILURE
      ],
      request: {
        body: {
          userId,
          teamId,
          mediaIds
        }
      },
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/images/deletions`,
      schema: schemas.NONE,
      onSuccess: response => {
        dispatch(deleteUserTeamImages(response, folderId, onSuccess));
      }
    }
  });
};
