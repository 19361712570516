import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FolderButton from "./FolderButton";
import {
  deleteWorkspaceFolder,
  updateWorkspaceFolder,
  updateTeamFolder,
  deleteTeamFolder
} from "state/entities/folders/foldersActions";
import { currentSubscriptionPlan } from "state/entities/subscriptions/subscriptionsSelectors";
import { userIsDesigner } from "state/currentUser/currentUserSelectors";
import Subscriptions from "lib/subscriptions";
import PATHS from "routes/paths";
import { folderTeamAllocationsSelector } from "state/entities/folders/foldersSelectors";

const FolderButtonContainer = ({
  allocations,
  folder,
  onClick,
  updateFolder,
  deleteFolder,
  buttonClassName,
  menuActions,
  canModifyFolder,
  wrapperClassName,
  titleClassName,
  openShareFolderModal
}) => {
  return (
    <FolderButton
      onClick={onClick}
      folder={folder}
      updateFolder={updateFolder}
      deleteFolder={deleteFolder}
      buttonClassName={buttonClassName}
      menuActions={menuActions}
      canModifyFolder={canModifyFolder}
      wrapperClassName={wrapperClassName}
      titleClassName={titleClassName}
      openShareFolderModal={openShareFolderModal}
      allocations={allocations}
    />
  );
};

FolderButtonContainer.displayName = "FolderButtonContainer";
/*   folder: FolderButton.folderType.isRequired, */
FolderButtonContainer.propTypes = {
  onClick: PropTypes.func.isRequired,
  updateFolder: PropTypes.func.isRequired,
  deleteFolder: PropTypes.func.isRequired
};

const getCanModifyFolder = (state, ownProps) => {
  const currentSubscription = currentSubscriptionPlan(state);
  const subscription = Subscriptions.get(currentSubscription.code);
  const canArchive = subscription.getCanCreateFolder();
  const isCatalogue = window.location.pathname.includes(PATHS.catalogue);
  if (canArchive && (!isCatalogue || userIsDesigner(state))) {
    return true;
  }
  return false;
};

const getFolderFunctions = ({ dispatch, location }) => {
  switch (true) {
    case location.pathname.includes(PATHS.catalogue): {
      return {
        updateFolder: folder => dispatch(updateTeamFolder({ folder })),
        deleteFolder: folderId => dispatch(deleteTeamFolder({ folderId }))
      };
    }
    case location.pathname.includes(PATHS.workspace):
    default: {
      return {
        updateFolder: folder => dispatch(updateWorkspaceFolder({ folder })),
        deleteFolder: folderId => dispatch(deleteWorkspaceFolder({ folderId }))
      };
    }
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const folderFunctions = getFolderFunctions({
    dispatch,
    location: window.location
  });

  return {
    ...folderFunctions
  };
};

const mapStateToProps = (state, ownProps) => ({
  canModifyFolder: getCanModifyFolder(state, ownProps),
  allocations: folderTeamAllocationsSelector(state, ownProps.folder.id)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FolderButtonContainer);
