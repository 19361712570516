import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import TeamList from "./TeamList";
import ArrowIcon from "views/components/icons/ArrowIcon";

import { Loading, SearchInput } from "views/components";

export class DesignRemix extends React.Component {
  static propTypes = {
    teams: PropTypes.object,
    teamsLoading: PropTypes.bool,
    handleProcessDesignRemix: PropTypes.func
  };

  render() {
    const {
      teams = {},
      teamsLoading,
      handleProcessDesignRemix,
      selectedTeam,
      selectTeam,
      navigateBack,
      onFetchMoreTeams,
      targetName = "design"
    } = this.props;

    return (
      <div className={style.wrapper}>
        <div className={style.title}>
          <h1>Add to team</h1>
        </div>
        <div className={style.description}>
          <p>
            Select a team for your {targetName} to be located. The {targetName}{" "}
            can only be located in one team as per our{" "}
            <a href="https://easil.com/terms-of-use">license agreement</a>.
          </p>
        </div>
        <div className={style.teamListTitle}>
          <p>Teams</p>
        </div>
        <div className={style.searchInput}>
          <SearchInput
            placeholder={"Search Teams"}
            onChange={this.props.onSearchChange}
            value={this.props.searchTerm}
            onKeyPress={this.props.onEnterTriggered}
          />
        </div>
        {selectedTeam && (
          <div
            className={style.returnLink}
            onClick={() => navigateBack(selectedTeam)}
          >
            <ArrowIcon
              className={style.returnLinkIcon}
              size="24"
              direction="left"
            />
            <div>{selectedTeam.name}</div>
          </div>
        )}
        {this.props.isFetchingTeams && <Loading size="24px" />}
        {teams.length === 0 && !this.props.isFetchingTeams && (
          <div style={{ textAlign: "center" }}>No teams found</div>
        )}
        <TeamList
          teams={teams}
          teamsLoading={teamsLoading}
          handleProcessDesignRemix={handleProcessDesignRemix}
          selectTeam={selectTeam}
          onFetchMoreTeams={onFetchMoreTeams}
        />
      </div>
    );
  }
}

DesignRemix.displayName = "DesignRemix";
export default DesignRemix;
