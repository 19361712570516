import React, { Component } from "react";
import { Link } from "react-router-dom";
import { default as AddFolderModal } from "../../DesignsPageSubHeader/AddFolderModalContainer";
import BrandKitEmpty from "../BrandKitEmpty/BrandKitEmpty";
import BrandKitImageRow from "../BrandKitImageRow";
import common from "../common.module.css";
import EmptyLogosIcon from "views/components/icons/EmptyLogosIcon";
import EmptySearchIcon from "views/components/icons/EmptySearchIcon";
import FileDragAndDrop from "../../FileDragAndDrop";
import Loading from "views/components/loading";
import PATHS from "routes/paths";
import PopoutItemMenu from "../../popout/PopoutItemMenu";
import Subscriptions from "lib/subscriptions";
import CaretIcon from "views/components/icons/CaretIcon";
import EllipsisIcon from "views/components/icons/EllipsisIcon";
import FolderWideIcon from "views/components/icons/FolderWideIcon";
import TrashCanIcon from "views/components/icons/TrashCanIcon";
import { AddImageButton } from "views/components";
import ScrollingOverflowText from "views/components/ScrollingOverflowText/ScrollingOverflowText";
import {
  MEDIA_SORT_OPTIONS,
  STATIC_ASSET_FILE_TYPES,
  DEFAULT_ACCEPTED_STATIC_FILE_TYPES
} from "lib/constants";
import mediaSearchAdapter from "lib/mediaSearchAdapter";
import BrandKitLogoOps from "./BrandKitLogoOps";
import FilterOption from "views/components/FilterOption/FilterOption";
import { getParameterByName } from "lib/queryStringUtils";

const TEAM_FOLDER_TYPE = "BRAND_LOGO";

export default class BrandKitLogos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditingFolder: false,
      menuFooterOpen: false,
      menuFooterOption: null,
      menuId: null,
      subscription: Subscriptions.get(props.currentSubscriptionCode),
      isTransferModalOpen: false
    };
    this.contentWrapper = document.getElementById("contentWrapper");
    this.handleScroll = this.handleScroll.bind(this);
    this.handleSortUpdate = BrandKitLogoOps.handleSortUpdate.bind(this);
    this.setQueryString = BrandKitLogoOps.setQueryString.bind(this);
    this.onAddImageButtonClick = BrandKitLogoOps.onAddImageButtonClick.bind(
      this
    );
    this.handleAddLogo = BrandKitLogoOps.handleAddLogo.bind(this);
  }

  componentDidMount() {
    const teamFoldersArr = this.getTeamFoldersArr();

    if (!teamFoldersArr.length) {
      this.props.fetchAllTeamFolders({ type: TEAM_FOLDER_TYPE });
    }

    if (!Object.keys(this.props.smartImages).length) {
      this.props.fetchTeamSmartImages();
    }

    this.contentWrapper.addEventListener("scroll", this.handleScroll);
    this.handleQueryString();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // prevent parent (underlying) component from updating when
    // team image API calls are made from transfer modal
    if (this.state.isTransferModalOpen && !nextState.isTransferModalOpen)
      return true;
    if (this.state.isTransferModalOpen) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location.search !== this.props.location.search ||
      prevProps.filterSmartImages !== this.props.filterSmartImages
    ) {
      this.handleQueryString();
    }
    const { qsFolderId } = this.props;
    if (
      this.props.sortKey !== prevProps.sortKey ||
      qsFolderId !== prevProps.qsFolderId
    ) {
      const { qsTerm } = this.props;
      const sortOption = MEDIA_SORT_OPTIONS[this.props.sortKey];
      // sort key was updated so we will need to refetch if we don't have all of the content already
      this.props.refetchBrandKitLogos({
        folderId: qsFolderId,
        term: qsTerm,
        sortBy: sortOption.sortKey,
        sortOrder: sortOption.direction
      });
    }

    // // when transfer modal closes we need to refetch current location assets
    if (prevState.isTransferModalOpen && !this.state.isTransferModalOpen) {
      const { qsTerm } = this.props;
      const folderId = getParameterByName("folderId", window.location.search);
      const sortOption = MEDIA_SORT_OPTIONS[this.props.sortKey];
      this.props.refetchBrandKitLogos({
        folderId,
        term: qsTerm,
        sortBy: sortOption.sortKey,
        sortOrder: sortOption.direction
      });
    }
  }

  componentWillUnmount() {
    this.contentWrapper.removeEventListener("scroll", this.handleScroll);
  }

  getLeftOffset() {
    const dropWrapper = document.querySelector(
      `div[class*="${common.fileDropWrapper}"]`
    );

    if (!dropWrapper) {
      return;
    }
    return dropWrapper.getBoundingClientRect().left;
  }

  getEntriesArr() {
    const {
      brandKitLogos,
      qsFolderId,
      qsTerm,
      filterSmartImages,
      smartImages
    } = this.props;
    if (
      brandKitLogos.entriesFolderId === qsFolderId &&
      brandKitLogos.entriesTerm === qsTerm
    ) {
      if (filterSmartImages) {
        const smartImageArray = Object.values(smartImages);
        const filteredSmartImageArray = brandKitLogos.entries.filter(imageObj =>
          smartImageArray.find(
            smartImageObj => smartImageObj.mediaId === imageObj.media.id
          )
        );

        return filteredSmartImageArray;
      }
      return mediaSearchAdapter(brandKitLogos.entries);
    }

    return [];
  }

  determineImageSrc(logo) {
    return logo.url.endsWith(".svg") ? logo.url : logo.thumbnailUrl;
  }

  getEntriesState() {
    const { qsFolderId, qsTerm } = this.props;
    const entriesArr = this.getEntriesArr();
    const isProcessing = this.getIsProcessing();
    const teamFoldersArr = this.getTeamFoldersArr();

    if (qsFolderId !== null && (isProcessing || entriesArr.length > 0)) {
      return "FOLDER_RESULTS";
    }

    if (qsFolderId !== null && entriesArr.length === 0) {
      return "FOLDER_EMPTY";
    }

    if (qsTerm !== null && (isProcessing || entriesArr.length > 0)) {
      return "SEARCH_RESULTS";
    }

    if (qsTerm !== null && entriesArr.length === 0) {
      return "SEARCH_EMPTY";
    }

    if (
      qsTerm === null &&
      entriesArr.length === 0 &&
      teamFoldersArr.length === 0 &&
      !isProcessing
    ) {
      return "NORMAL_EMPTY";
    }

    return "NORMAL_RESULTS";
  }

  getIsProcessing() {
    const { isProcessing } = this.props;
    const { brandKitLogos, teamFolders } = this.props;

    return (
      brandKitLogos.isProcessing || isProcessing || teamFolders.isProcessing
    );
  }

  getTeamFoldersArr() {
    const { teamFolders } = this.props;
    return Object.values(teamFolders.entries).filter(
      f => f.type === TEAM_FOLDER_TYPE
    );
  }

  async handleAddTeamFolder(name) {
    const resp = await this.props.createTeamFolder({
      name,
      type: TEAM_FOLDER_TYPE
    });

    if (!resp.error) {
      this.setQueryString(null);
      this.props.setBrandKitState({ isAddingFolder: false }, "logos");
    }
  }

  handleRemoveLogo({ mediaId: logoId, isSmartImage }) {
    const teamId = this.props.currentTeam.id;
    this.props.removeBrandKitLogo({ logoId, teamId, isSmartImage });
  }

  handleMenuShowHide(menuId) {
    if (menuId && menuId !== this.state.menuId) {
      this.setState({
        menuFooterOpen: false,
        menuFooterOption: null,
        menuId
      });
    } else {
      this.setState({ menuId: null });
    }
  }

  handleQueryString() {
    const { brandKitLogos, location } = this.props;
    const qs = new URLSearchParams(location.search);
    const qsFolderId = qs.get("folderId");
    const qsTerm = qs.get("term");
    const sortOption = MEDIA_SORT_OPTIONS[this.props.sortKey];

    if (qsTerm !== this.props.qsTerm) {
      window.clearTimeout(this.throttleTimeout);

      if (qsTerm === null) {
        this.props.setBrandKitState({ isProcessing: false }, "logos");
      } else {
        const throttleSearch = () => {
          this.props.searchBrandKitLogos({
            folderId: qsFolderId,
            term: qsTerm,
            sortBy: sortOption.sortKey,
            sortOrder: sortOption.direction,
            filterSmartImages: this.props.filterSmartImages
          });
          this.props.setBrandKitState({ isProcessing: false }, "logos");
        };
        this.throttleTimeout = window.setTimeout(throttleSearch, 500);
        this.props.setBrandKitState({ isProcessing: true }, "logos");
      }
    }

    if (
      !qsTerm &&
      (qsFolderId !== brandKitLogos.entriesFolderId ||
        qsTerm !== brandKitLogos.entriesTerm)
    ) {
      this.props.searchBrandKitLogos({
        folderId: qsFolderId,
        term: qsTerm,
        sortBy: sortOption.sortKey,
        sortOrder: sortOption.direction,
        filterSmartImages: this.props.filterSmartImages
      });
    }

    this.props.setBrandKitState({ qsTerm, qsFolderId }, "logos");
  }

  handleScroll(event) {
    const { brandKitLogos, qsFolderId, qsTerm, sortKey } = this.props;
    const { target } = event;
    const scrollBottom =
      target.scrollHeight - target.clientHeight - target.scrollTop;

    const sortOption = MEDIA_SORT_OPTIONS[sortKey];

    if (
      scrollBottom === 0 &&
      brandKitLogos.isMoreAvailable &&
      brandKitLogos.isProcessing === false
    ) {
      this.props.searchBrandKitLogos({
        folderId: qsFolderId,
        page: brandKitLogos.page + 1,
        term: qsTerm,
        sortBy: sortOption.sortKey,
        sortOrder: sortOption.direction
      });
    }
  }

  render() {
    const {
      isBrandManager,
      teamFolders,
      canAccessBrandKit,
      qsFolderId,
      searchTerm,
      qsTerm
    } = this.props;
    const entriesArr = this.getEntriesArr();
    const entriesState = this.getEntriesState();
    const isProcessing = this.getIsProcessing();
    const teamFolder = teamFolders.entries[qsFolderId];
    const teamFoldersArr = this.getTeamFoldersArr();

    return (
      <>
        {isBrandManager && canAccessBrandKit && (
          <FileDragAndDrop
            handleDrop={files => files.map(f => this.handleAddLogo(f))}
            label="Drop your logos here"
            acceptedFileTypes={DEFAULT_ACCEPTED_STATIC_FILE_TYPES}
            leftOffset={this.getLeftOffset()}
            isUsingDirectParent={true}
            className={common.fileDropWrapper}
          />
        )}

        {["FOLDER_EMPTY", "FOLDER_RESULTS"].includes(entriesState) && (
          <>
            <div className={common.contentTitle}>
              {teamFolder ? teamFolder.name : <>&nbsp;</>}
            </div>
            {this.props.filterSmartImages && qsTerm && (
              <div className={common.contentTitleForSearch}>
                <>
                  Showing {entriesArr.length}
                  <FilterOption
                    label={"Smart Image"}
                    onClick={() => {
                      this.props.setBrandKitState(
                        { filterSmartImages: false },
                        "logos"
                      );
                    }}
                  />
                  results for{" "}
                  <FilterOption
                    label={searchTerm}
                    onClick={() => {
                      this.props.setBrandKitState(
                        { searchTerm: null },
                        "logos"
                      );
                      this.setQueryString({ term: null });
                    }}
                  />
                </>
              </div>
            )}
            {this.props.filterSmartImages && !qsTerm && (
              <div className={common.contentTitleForSearch}>
                {
                  <>
                    Showing {entriesArr.length}
                    <FilterOption
                      label={"Smart Image"}
                      onClick={() => {
                        this.props.setBrandKitState(
                          { filterSmartImages: false },
                          "logos"
                        );
                      }}
                    />{" "}
                    results
                  </>
                }
              </div>
            )}
            {!this.props.filterSmartImages && qsTerm && (
              <div className={common.contentTitleForSearch}>
                {
                  <>
                    Showing {entriesArr.length} results for{" "}
                    <FilterOption
                      label={searchTerm}
                      onClick={() => {
                        this.props.setBrandKitState(
                          { searchTerm: null },
                          "logos"
                        );
                        this.setQueryString({ term: null });
                      }}
                    />
                  </>
                }
              </div>
            )}
          </>
        )}

        {entriesState === "NORMAL_RESULTS" && (
          <>
            <div className={common.contentTitle}>Brand Logos</div>
            {this.props.filterSmartImages && (
              <div className={common.contentTitleForSearch}>
                {
                  <>
                    Showing {entriesArr.length}
                    <FilterOption
                      label={"Smart Image"}
                      onClick={() => {
                        this.props.setBrandKitState(
                          { filterSmartImages: false },
                          "logos"
                        );
                      }}
                    />{" "}
                    results
                  </>
                }
              </div>
            )}
          </>
        )}

        {entriesState === "SEARCH_RESULTS" && (
          <>
            <div className={common.contentTitleForSearch}>
              {this.props.filterSmartImages ? (
                <>
                  Showing {entriesArr.length}
                  <FilterOption
                    label={"Smart Image"}
                    onClick={() => {
                      this.props.setBrandKitState(
                        { filterSmartImages: false },
                        "logos"
                      );
                    }}
                  />
                  results for{" "}
                  <FilterOption
                    label={searchTerm}
                    onClick={() => {
                      this.props.setBrandKitState(
                        { searchTerm: null },
                        "logos"
                      );
                      this.setQueryString({ term: null });
                    }}
                  />
                </>
              ) : (
                <>
                  Showing {entriesArr.length} results for{" "}
                  <FilterOption
                    label={searchTerm}
                    onClick={() => {
                      this.props.setBrandKitState(
                        { searchTerm: null },
                        "logos"
                      );
                      this.setQueryString({ term: null });
                    }}
                  />
                </>
              )}
            </div>
          </>
        )}

        {["FOLDER_EMPTY", "FOLDER_RESULTS"].includes(entriesState) && (
          <div className={common.breadcrumb}>
            <Link to={PATHS.brandLogos}>Brand Logos</Link>

            <div className={common.breadcrumbSpacer}>
              <CaretIcon
                color="#afb3b6"
                direction="right"
                size="14px"
                strokeWidth="1"
              />
            </div>

            {teamFolder && (
              <div className={common.breadcrumbMenu}>
                <div
                  className={common.breadcrumbMenuDropDown}
                  onClick={() =>
                    this.setState(s => ({
                      isEditingFolder: !s.isEditingFolder
                    }))
                  }
                >
                  {teamFolder.name}

                  <CaretIcon
                    color="#2c2e2f"
                    direction="down"
                    size="12px"
                    strokeWidth="1"
                  />
                </div>

                {this.state.isEditingFolder && (
                  <PopoutItemMenu
                    actions={[
                      {
                        Icon: () => <TrashCanIcon size="22px" />,
                        onClick: async () => {
                          this.setState({ isEditingFolder: false });
                          const resp = await this.props.deleteTeamFolder({
                            folderId: teamFolder.id,
                            type: TEAM_FOLDER_TYPE
                          });

                          if (!resp.error) {
                            this.setQueryString(null);
                          }
                        },
                        name: "Delete"
                      }
                    ]}
                    clasName={common.popoutItemMenu}
                    isProcessing={teamFolders.isProcessing}
                    itemName={teamFolder.name}
                    onClose={() => this.setState({ isEditingFolder: false })}
                    onRename={name => {
                      this.props.updateTeamFolder({
                        folder: { ...teamFolder, name }
                      });

                      this.setState({ isEditingFolder: false });
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}

        {["FOLDER_RESULTS", "NORMAL_RESULTS", "SEARCH_RESULTS"].includes(
          entriesState
        ) && (
          <>
            <div className={common.imageListTitles}>
              <div style={{ flex: "1 1 auto" }}>Title</div>
              <div className={common.imageListRowColumn}>Size</div>
              <div className={common.imageListRowColumn}>Uploaded</div>
              <div className={common.imageListRowMenu} />
            </div>

            <div className={common.imageList}>
              {entriesState === "NORMAL_RESULTS" &&
                teamFoldersArr.map(folder => (
                  <div
                    className={common.imageListFolderRow}
                    key={folder.id}
                    onClick={() => this.setQueryString({ folderId: folder.id })}
                  >
                    <div className={common.folderTile}>
                      <div className={common.logoListRowFolder}>
                        <FolderWideIcon
                          color="#9a9b9d"
                          size="20px"
                          strokeWidth="1.5"
                        />
                      </div>
                    </div>

                    <div className={common.imageListRowRight}>
                      <div className={common.imageListTitle}>
                        <ScrollingOverflowText
                          className={common.imageListRowTitle}
                          title={folder.name}
                        >
                          {folder.name}
                        </ScrollingOverflowText>
                      </div>
                      <div
                        className={common.imageListRowMenu}
                        onClick={e => e.stopPropagation()}
                      >
                        <div
                          className={common.imageListRowEllipse}
                          onClick={event => {
                            event.stopPropagation();
                            this.handleMenuShowHide(folder.id);
                          }}
                        >
                          <EllipsisIcon color="#999c9e" size="24px" />
                        </div>

                        {this.state.menuId === folder.id && (
                          <PopoutItemMenu
                            actions={[
                              {
                                Icon: () => <TrashCanIcon size="22px" />,
                                onClick: () => {
                                  this.props.deleteTeamFolder({
                                    folderId: folder.id,
                                    type: TEAM_FOLDER_TYPE
                                  });
                                  this.handleMenuShowHide();
                                },
                                name: "Delete"
                              }
                            ]}
                            className={common.popoutItemMenu}
                            isProcessing={teamFolders.isProcessing}
                            itemName={folder.name}
                            onClose={() => this.handleMenuShowHide()}
                            onRename={name => {
                              this.props.updateTeamFolder({
                                folder: { ...folder, name }
                              });
                              this.handleMenuShowHide();
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}

              {entriesArr.map(entry => (
                <BrandKitImageRow
                  key={entry.mediaId}
                  image={entry}
                  location={this.props.location}
                  currentTeam={this.props.currentTeam}
                  isBrandManager={this.props.isBrandManager}
                  teamFoldersArr={teamFoldersArr}
                  isProcessing={isProcessing}
                  qsFolderId={qsFolderId}
                  menuId={this.state.menuId}
                  removeContentFromTeamFolder={
                    this.props.removeContentFromTeamFolder
                  }
                  updateBrandKitImage={this.props.updateBrandKitLogo}
                  addContentToTeamFolder={this.props.addContentToTeamFolder}
                  handleMenuShowHide={args => this.handleMenuShowHide(args)}
                  handleRemoveImage={args => this.handleRemoveLogo(args)}
                  isSearching={!!this.props.qsTerm}
                  mediaInFolders={this.props.mediaInFolders}
                  getFolderForMedia={this.props.getFolderForMedia}
                  isLogo={true}
                  smartImage={this.props.smartImages[entry.media.id]}
                  onDeleteTeamSmartImage={this.props.onDeleteTeamSmartImage}
                  handleTransferModalToggle={bool =>
                    this.setState({ isTransferModalOpen: bool })
                  }
                />
              ))}
            </div>
          </>
        )}

        {isProcessing && <Loading />}

        {this.props.isAddingFolder && (
          <AddFolderModal
            errors={teamFolders.errors}
            isOpen
            submitting={teamFolders.isProcessing}
            onClose={() =>
              this.props.setBrandKitState({ isAddingFolder: false }, "logos")
            }
            onCreate={({ name }) => this.handleAddTeamFolder(name)}
          />
        )}

        {entriesState === "SEARCH_EMPTY" && (
          <BrandKitEmpty
            icon={<EmptySearchIcon height="43px" width="56px" />}
            subtitle="Try searching for another Logo, or clearing your search."
            title={
              <>
                No Logos could be found for '<b>{this.props.searchTerm}</b>'.
              </>
            }
          />
        )}

        {["FOLDER_EMPTY", "NORMAL_EMPTY"].includes(entriesState) && (
          <BrandKitEmpty
            button={
              isBrandManager && {
                children: (
                  <AddImageButton
                    acceptedFileTypes={STATIC_ASSET_FILE_TYPES}
                    onChange={({ file, onSuccess }) =>
                      this.handleAddLogo(file, onSuccess)
                    }
                    onClick={this.onAddImageButtonClick}
                    image="Upload Logos"
                    isMulti
                  />
                ),
                onClick: e => this.onAddImageButtonClick(e)
              }
            }
            icon={<EmptyLogosIcon height="44px" width="56px" />}
            subtitle={
              isBrandManager &&
              "Click the 'Upload Logos' button, or drag your logos onto this space from your computer.\nWe accept JPG, PNG or SVG file types."
            }
            title={
              {
                FOLDER_EMPTY: "This folder contains no Brand Logos",
                NORMAL_EMPTY:
                  "Upload your Logos to easily keep every design on brand!"
              }[entriesState]
            }
          />
        )}
      </>
    );
  }
}
