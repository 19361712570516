import React, { Component } from "react";
import style from "./style.module.css";
import UncontrolledContentEditable from "views/components/Editor/elements/UncontrolledContentEditable";
import {
  getDynamicCellStyling,
  isExceedingTable2CellBoundary,
  table2HeightUpdater
} from "views/components/Editor/sidebar/tabs/shapes/Tables2Tab/helper";
import { cloneDeep, keyCodes, inRange } from "lib";
import { jsonStringEqual } from "lib/equalityUtils";
import { normalizeElement } from "lib/DOMNodeUtils";
import {
  getContentCellDom,
  getExtremeCellIndexes,
  getTargetCellId,
  getTargetCellIdForLeftColumn,
  getTargetCellIdForTopRow,
  getTargetCellsInColumn,
  getTargetCellsInRow,
  stripAttributeFromCellValue
} from "lib/table2Utils";
import Table2CellDragHandle from "./Table2CellDragHandle/Table2CellDragHandle";
import { cssAttributesToCssString } from "lib/styling/css";
import { onNextTick } from "lib/temporal/render";
import { updateSmartTextPlaceholder } from "lib/textUtils";
import { ACTION_BAR_BUTTON_NAMES_MAP } from "lib/constants";
import { PadlockIcon } from "views/components/icons";

export default class Table2Cell extends Component {
  constructor(props) {
    super(props);
    this.selectTableCell = this.selectTableCell.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleResizeMouseDown = this.handleResizeMouseDown.bind(this);
    this.handleResizeMouseUp = this.handleResizeMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleColumnMouseMove = this.handleColumnMouseMove.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.setupColumnResize = this.setupColumnResize.bind(this);
    this.generateResizeHandlePositionArray = this.generateResizeHandlePositionArray.bind(
      this
    );
    this.handleRowMouseMove = this.handleRowMouseMove.bind(this);
    this.handleTableCellTab = this.handleTableCellTab.bind(this);
    this.getTableCellDynamicStyling = this.getTableCellDynamicStyling.bind(
      this
    );
    this.handleCellLockingAction = this.handleCellLockingAction.bind(this);
    this.generateTargetCellIdsForTabbing = this.generateTargetCellIdsForTabbing.bind(
      this
    );
    this.handleAutoCompleteStyling = this.handleAutoCompleteStyling.bind(this);
    this.isResizeHandleExtremeBorder = this.isResizeHandleExtremeBorder.bind(
      this
    );
    this.getLockingIndicatorStyling = this.getLockingIndicatorStyling.bind(
      this
    );
    this.contentEditableRef = React.createRef();
    this.cellRef = React.createRef();

    this.state = {
      isResizing: false,
      columnMinWidths: null,
      resizeBorder: null,
      previousTargetCellId: null,
      nextTargetCellId: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isCellEditable &&
      !this.props.isCellEditable &&
      jsonStringEqual(this.props.elementData, prevProps.elementData)
    ) {
      this.onBlur();
    }

    if (!prevState.isResizing && this.state.isResizing) {
      document.addEventListener("mousemove", this.handleMouseMove);
      document.addEventListener("mouseup", this.handleResizeMouseUp);
    } else if (prevState.isResizing && !this.state.isResizing) {
      document.removeEventListener("mousemove", this.handleMouseMove);
      document.removeEventListener("mouseup", this.handleResizeMouseUp);
    }

    if (!prevProps.isCellSelected && this.props.isCellSelected) {
      this.generateTargetCellIdsForTabbing(this.props.elementData.layout);
      document.addEventListener("keydown", this.handleTableCellTab);
    }

    if (prevProps.isCellSelected && !this.props.isCellSelected) {
      this.setState({
        previousTargetCellId: null,
        nextTargetCellId: null
      });
      document.removeEventListener("keydown", this.handleTableCellTab);
    }

    if (
      this.props.isCellSelected &&
      !jsonStringEqual(
        prevProps.elementData.layout,
        this.props.elementData.layout
      )
    ) {
      this.generateTargetCellIdsForTabbing(this.props.elementData.layout);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleTableCellTab);
  }

  handleSelectionChange() {
    const selectionEvent = new CustomEvent("textboxSelectionChange", {});
    document.dispatchEvent(selectionEvent);
  }

  getLockingIndicatorStyling(cellMetadata) {
    const { zoom } = this.props;
    const iconSize = 20 / zoom;
    const borderRadius = 4 / zoom;
    return {
      width: `${iconSize}px`,
      height: `${iconSize}px`,
      marginRight: `${cellMetadata.padding}px`,
      top: `calc(50% - ${iconSize / 2}px)`,
      borderRadius: `${borderRadius}px`
    };
  }

  generateTargetCellIdsForTabbing(layout) {
    if (!this.props.isCellSelected) return;

    const flattenedLayout = layout.flat();
    const currentIndex = flattenedLayout.findIndex(
      cell => cell.id === this.props.cellId
    );

    const findRenderedCellId = (startIndex, step) => {
      let targetCellIndex = startIndex;
      do {
        targetCellIndex =
          (targetCellIndex + step + flattenedLayout.length) %
          flattenedLayout.length;
        const targetCellId = flattenedLayout[targetCellIndex].id;
        // assign target cell if it exists in DOM and is not a locked cell
        if (
          document.querySelector(`#cell-${targetCellId}`) &&
          !this.props.elementData.lockedCellIds.includes(targetCellId)
        ) {
          return targetCellId;
        }
      } while (targetCellIndex !== startIndex);
      return null;
    };

    const previousTargetCellId = findRenderedCellId(currentIndex, -1);
    const nextTargetCellId = findRenderedCellId(currentIndex, 1);

    this.setState({
      previousTargetCellId,
      nextTargetCellId
    });
  }

  handleTableCellTab(event) {
    const { updateContextState, isCellEditable } = this.props;

    if (isCellEditable) return;

    if (event.keyCode === keyCodes.tabKey) {
      const targetCellId = event.shiftKey
        ? this.state.previousTargetCellId
        : this.state.nextTargetCellId;
      updateContextState({
        context: { selectedTable2CellIds: [targetCellId] }
      });
      event.preventDefault();
      event.stopPropagation();
    }
  }

  calculateCellMinWidth(cell) {
    const cssAttributes = {
      position: "absolute",
      "line-height": `${cell.lineHeight}em`,
      "letter-spacing": `${cell.letterSpacing / 1000}em`,
      "text-align": cell.textAlign,
      "font-size": `${cell.fontSize}px`,
      "font-family": cell.fontFamily,
      "font-weight": cell.bold,
      "text-decoration": cell.textDecoration,
      visibility: "hidden",
      width: "0px",
      display: "inline-block",
      "overflow-wrap": "anywhere",
      padding: "0px"
    };
    const cssString = cssAttributesToCssString(cssAttributes);
    const div = document.createElement("div");
    div.style.cssText = cssString;
    div.innerHTML = cell.value;
    document.body.appendChild(div);

    const minCellWidth = div.scrollWidth;

    document.body.removeChild(div);

    return minCellWidth + cell.padding * 2;
  }

  selectTableCell(cellId) {
    if (this.props.isCellLockingMode) {
      this.handleCellLockingAction(cellId);
      return;
    }

    if (this.props.isSelected && this.props.isOnlyElementSelected) {
      this.props.handleSelectedCells(cellId);
    }
  }

  onBlur() {
    if (this.props.isResizing) return;
    let _element = document.getElementById(this.props.cellId);
    if (!_element) {
      const tableCell = document.getElementById(`cell-${this.props.cellId}`);
      _element = tableCell.querySelector('div[class*="contentEditable"]');
    }

    normalizeElement(_element);

    this.props.onTable2TextFieldChange();
  }

  onKeyDown(event) {
    const { updateContextState } = this.props;

    // handle keydown tab event when in edit mode
    if (event.keyCode === keyCodes.tabKey) {
      const targetCellId = event.shiftKey
        ? this.state.previousTargetCellId
        : this.state.nextTargetCellId;
      const targetCell = document.querySelector(`#cell-${targetCellId}`);

      // first update the selected cell state
      updateContextState({
        context: { selectedTable2CellIds: [targetCellId] }
      });

      // trigger event on target cell to force second click for edit mode
      const clickEvent = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      });
      onNextTick(() => {
        targetCell.dispatchEvent(clickEvent);
      });
      event.preventDefault();
    }
  }

  onChange(innerHTML, event, setHTML) {
    event.stopPropagation();
    event.preventDefault();

    const hasTextBoundaryLock = this.props.elementData.restrictions.includes(
      "table2TextBoundary"
    );
    const isExceedingCellBoundary = isExceedingTable2CellBoundary(
      this.props.elementData,
      this.props.elementData.cells[this.props.cellId],
      innerHTML
    );
    if (!!innerHTML && hasTextBoundaryLock && isExceedingCellBoundary) {
      setHTML(this.props.elementData.cells[this.props.cellId].value);
      return;
    }

    const newDisplayValue = updateSmartTextPlaceholder(
      innerHTML,
      this.props.smartTextState
    );

    const cellMetadata = this.props.elementData.cells[this.props.cellId];
    if (
      cellMetadata.value === innerHTML &&
      cellMetadata.displayValue === newDisplayValue
    )
      return;

    this.props.handleTable2TextFieldPreview({
      value: innerHTML,
      displayValue: newDisplayValue
    });

    let _element = document.getElementById(this.props.cellId);
    if (!_element) {
      const tableCell = document.getElementById(`cell-${this.props.cellId}`);
      _element = tableCell.querySelector('div[class*="contentEditable"]');
    }
    normalizeElement(_element);
  }

  getContentDomRect(cellId) {
    const contentCellDom = getContentCellDom(cellId);
    return contentCellDom.getBoundingClientRect();
  }

  setupRowResize(border) {
    const {
      elementData,
      columnIndex,
      rowIndex,
      zoom,
      rowSpan,
      cellId
    } = this.props;
    const { layout } = elementData;

    const { highestRowIndex } = getExtremeCellIndexes({ elementData, cellId });
    const lastRowIndex = layout.length - 1;
    const isOuterBottom =
      lastRowIndex === highestRowIndex && border === "bottom";
    const isOuterTop = rowIndex === 0 && border === "top";

    let targetRowIndex;
    if (isOuterTop) {
      targetRowIndex = 0;
    } else if (isOuterBottom) {
      targetRowIndex = lastRowIndex;
    } else if (border === "top") {
      targetRowIndex = rowIndex - 1;
    } else if (border === "bottom") {
      targetRowIndex = rowIndex + (rowSpan - 1);
    } else {
      return false; // If no conditions met, not viable to continue
    }

    const targetCellId = isOuterTop
      ? getTargetCellIdForTopRow(elementData, cellId)
      : getTargetCellId(layout, targetRowIndex, columnIndex);

    const targetRow = getTargetCellsInRow(layout, targetRowIndex);
    const singleTargetRow = targetRow.filter(
      cell => cell[0] !== targetRowIndex
    );

    const cellsWithSharedBorder =
      layout.length === 1 || isOuterBottom
        ? singleTargetRow
        : singleTargetRow.filter((cell, columnIndex) => {
            const adjacentRowIndex = isOuterBottom
              ? targetRowIndex - 1
              : targetRowIndex + 1;
            const cellInAdajcentRow = layout[adjacentRowIndex][columnIndex];
            return cellInAdajcentRow.coordinates[0] === adjacentRowIndex;
          });

    const targetContentDom = getContentCellDom(targetCellId);
    const targetContentDomRect = targetContentDom.getBoundingClientRect();
    const targetDom = targetContentDom.parentNode;
    const targetDomRect = targetDom.getBoundingClientRect();

    const lowestTargetContent = cellsWithSharedBorder
      .map(cell => {
        const dom = getContentCellDom(cell.id);
        const rect = dom.getBoundingClientRect();
        return rect;
      })
      .sort((a, b) => (a.bottom < b.bottom ? 1 : -1))[0];

    const extraBorder = isOuterTop || isOuterBottom ? 2 : 4;
    const MIN_TARGET_CELL_HEIGHT = Math.ceil(
      (lowestTargetContent.bottom - targetContentDomRect.top) / zoom +
        extraBorder
    );

    const originalTargetCellTop = targetDomRect.top;
    const originalTargetCellHeight = Math.floor(targetDomRect.height / zoom);

    this.setState({
      isOuterTop,
      MIN_TARGET_CELL_HEIGHT,
      originalTargetCellHeight,
      originalTargetCellTop,
      targetCellId,
      targetDom,
      targetRow
    });
  }

  setupColumnResize(border) {
    const {
      cellId,
      colSpan,
      columnIndex,
      elementData,
      rowIndex,
      zoom
    } = this.props;
    const { layout } = elementData;

    const { highestColumnIndex } = getExtremeCellIndexes({
      elementData,
      cellId
    });
    const lastColumnIndex = layout[0].length - 1;
    const isOuterRight =
      lastColumnIndex === highestColumnIndex && border === "right";
    const isOuterLeft = columnIndex === 0 && border === "left";

    let targetColumnIndex;
    if (isOuterLeft) {
      targetColumnIndex = 0;
    } else if (isOuterRight) {
      targetColumnIndex = lastColumnIndex;
    } else if (border === "left") {
      targetColumnIndex = columnIndex - 1;
    } else if (border === "right") {
      targetColumnIndex = columnIndex + (colSpan - 1);
    } else {
      return false; // If no conditions met, not viable to continue
    }

    const targetCellId = isOuterLeft
      ? getTargetCellIdForLeftColumn(elementData, cellId)
      : getTargetCellId(layout, rowIndex, targetColumnIndex);

    const targetColumn = getTargetCellsInColumn(layout, targetColumnIndex);
    const singleTargetColumn = targetColumn.filter(
      cell => cell[1] !== targetColumnIndex
    );

    const cellsWithSharedBorder =
      layout[0].length === 1 || isOuterRight
        ? singleTargetColumn
        : singleTargetColumn.filter((cell, rowIndex) => {
            const adjacentColumnIndex = isOuterRight
              ? targetColumnIndex - 1
              : targetColumnIndex + 1;
            const cellInAdajcentRow = layout[rowIndex][adjacentColumnIndex];
            return cellInAdajcentRow.coordinates[1] === adjacentColumnIndex;
          });

    const targetContentDom = getContentCellDom(targetCellId);
    const targetDom = targetContentDom.parentNode;
    const targetDomRect = targetDom.getBoundingClientRect();

    const cellMinWidths = cellsWithSharedBorder.map(cell => {
      const elementCell = elementData.cells[cell.id];
      const cellRect = getContentCellDom(cell.id).getBoundingClientRect();
      const diffX = (cellRect.x - targetDomRect.x) / zoom;
      const minWidth = this.calculateCellMinWidth(elementCell);
      return diffX + minWidth;
    });
    const extraBorder = isOuterLeft || isOuterRight ? 2 : 4;
    const MIN_TARGET_CELL_WIDTH = Math.ceil(
      Math.max(...cellMinWidths) + extraBorder
    );

    const originalTargetCellLeft = targetDomRect.left;
    const originalTargetCellWidth = Math.floor(targetDomRect.width / zoom);
    const originalTableWidth = elementData.width;
    const originalTableLeft = elementData.left;

    this.setState({
      isOuterLeft,
      MIN_TARGET_CELL_WIDTH,
      originalTableLeft,
      originalTableWidth,
      originalTargetCellLeft,
      originalTargetCellWidth,
      cellsWithSharedBorder,
      targetCellId,
      targetColumn,
      targetDom
    });
  }

  handleResizeMouseDown(event) {
    if (!(this.props.isSelected && this.props.isOnlyElementSelected)) return;

    event.preventDefault();
    event.stopPropagation();

    const { border } = event.target.dataset;

    this.setState({
      isResizing: true,
      resizeBorder: border
    });

    if (["top", "bottom"].includes(border)) {
      this.setupRowResize(border);
    } else if (["left", "right"].includes(border)) {
      this.setupColumnResize(border);
    }
  }

  handleMouseMove(event) {
    switch (this.state.resizeBorder) {
      case "top":
      case "bottom": {
        this.handleRowMouseMove(event);
        break;
      }
      case "left":
      case "right": {
        this.handleColumnMouseMove(event);
        break;
      }
      default: {
        break;
      }
    }
  }

  handleResizeMouseUp() {
    this.setState({
      isResizing: false,
      resizeBorder: null
    });

    this.props.onElementPreviewPersist();
  }

  handleRowMouseMove(event) {
    const { elementData, zoom, lockedRowResizeCellIds = [] } = this.props;
    const {
      isOuterTop,
      MIN_TARGET_CELL_HEIGHT,
      originalTargetCellHeight,
      originalTargetCellTop,
      targetCellId,
      targetDom,
      targetRow
    } = this.state;

    // return early when target cell is included in restricted resize array
    // based on locked cell positions
    if (lockedRowResizeCellIds.includes(targetCellId)) return;

    let cursorRelativeToTarget = Math.ceil(
      (event.clientY - originalTargetCellTop) / zoom
    );
    let targetHeight;
    if (isOuterTop) {
      cursorRelativeToTarget = Math.ceil(
        originalTargetCellHeight - cursorRelativeToTarget
      );
    }
    targetHeight = cursorRelativeToTarget;

    // Ensure cell snaps to minimum row height if cursor is moving too quickly
    if (cursorRelativeToTarget <= MIN_TARGET_CELL_HEIGHT) {
      const targetCellHeight = Math.ceil(
        targetDom.getBoundingClientRect().height / zoom
      );
      if (targetCellHeight === MIN_TARGET_CELL_HEIGHT) {
        return; // already snapped in place, can return early
      }
      targetHeight = MIN_TARGET_CELL_HEIGHT;
    }

    // update height of cells
    const updatedCells = cloneDeep(elementData.cells);
    targetRow.forEach(cell => {
      updatedCells[cell.id].height = "auto";
    });
    updatedCells[targetCellId].height = targetHeight;

    const { height } = table2HeightUpdater({
      ...elementData,
      height: "auto",
      cells: updatedCells
    });

    const elementPreviewData = {
      cells: updatedCells,
      height
    };
    if (isOuterTop) {
      const diff = elementData.cells[targetCellId].height - targetHeight;
      elementPreviewData.top = elementData.top + diff;
    }

    this.props.onElementPreview(elementPreviewData);
  }

  handleColumnMouseMove(event) {
    const { elementData, zoom, lockedColumnResizeCellIds } = this.props;
    const {
      isOuterLeft,
      MIN_TARGET_CELL_WIDTH,
      originalTableLeft,
      originalTableWidth,
      originalTargetCellLeft,
      originalTargetCellWidth,
      targetCellId,
      targetColumn,
      targetDom
    } = this.state;

    // return early when target cell is included in restricted resize array
    // based on locked cell positions
    if (lockedColumnResizeCellIds.includes(targetCellId)) return;

    let cursorRelativeToTarget = Math.ceil(
      (event.clientX - originalTargetCellLeft) / zoom
    );

    let targetWidth;
    if (isOuterLeft) {
      cursorRelativeToTarget = Math.ceil(
        originalTargetCellWidth - cursorRelativeToTarget
      );
    }
    targetWidth = cursorRelativeToTarget;
    // Ensure cell snaps to minimum column width if cursor is moving too quickly
    if (cursorRelativeToTarget <= MIN_TARGET_CELL_WIDTH) {
      const targetCellWidth = Math.ceil(
        targetDom.getBoundingClientRect().width / zoom
      );
      if (targetCellWidth <= MIN_TARGET_CELL_WIDTH) {
        return; // already snapped in place, can return early
      }
      targetWidth = MIN_TARGET_CELL_WIDTH;
    }

    // update width of cells
    const updatedCells = cloneDeep(elementData.cells);
    targetColumn.forEach(cell => {
      updatedCells[cell.id].width = "auto";
    });
    updatedCells[targetCellId].width = targetWidth;

    const width = Math.round(
      originalTableWidth - (originalTargetCellWidth - targetWidth)
    );
    const { height } = table2HeightUpdater({
      ...elementData,
      cells: updatedCells,
      height: "auto",
      width
    });

    const elementPreviewData = {
      cells: updatedCells,
      width,
      height
    };
    if (isOuterLeft) {
      const diff = originalTargetCellWidth - targetWidth;
      elementPreviewData.left = originalTableLeft + diff;
    }

    this.props.onElementPreview(elementPreviewData);
  }

  generateResizeHandlePositionArray() {
    const {
      lockedRowResizeCellIds = [],
      lockedColumnResizeCellIds = [],
      cellId
    } = this.props;
    const { restrictions } = this.props.elementData;
    let positionArray = ["left", "right", "top", "bottom"];

    if (
      restrictions.includes("editRowLayout") ||
      lockedRowResizeCellIds.includes(cellId)
    ) {
      positionArray = positionArray.filter(
        pos => !["top", "bottom"].includes(pos)
      );
    }
    if (
      restrictions.includes("editColumnLayout") ||
      lockedColumnResizeCellIds.includes(cellId)
    ) {
      positionArray = positionArray.filter(
        pos => !["left", "right"].includes(pos)
      );
    }

    return positionArray;
  }

  handleAutoCompleteStyling() {
    const {
      elementData: { angle },
      cellId
    } = this.props;
    const tableCellRect = document
      .getElementById(`table-cell-${cellId}`)
      .getBoundingClientRect();
    const canvasRect = document
      .getElementById(`CANVAS-BACKGROUND-CONTENT-WRAPPER`)
      .getBoundingClientRect();

    const viewportBottom = window.scrollY + window.innerHeight;
    const hasSpaceForAutoCompleteBottom =
      viewportBottom - tableCellRect.bottom > 222;
    const hasSpaceForAutoCompleteTop = tableCellRect.top - canvasRect.top > 222;
    const hasSpaceForAutoCompleteLeft = tableCellRect.left > 225;
    const hasSpaceForAutoCompleteRight =
      canvasRect.right - tableCellRect.right > 225;

    const defaultStyle = {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      transform: `rotate(-${angle}deg) scale(1.8)`,
      pointerEvents: "all"
    };

    const generatePreferredPosition = positionArr => {
      if (hasSpaceForAutoCompleteBottom) return positionArr[0];
      if (hasSpaceForAutoCompleteTop) return positionArr[1];
      if (hasSpaceForAutoCompleteLeft) return positionArr[2];
      if (hasSpaceForAutoCompleteRight) return positionArr[3];
      return positionArr[0];
    };

    const degrees = Number(angle);
    if (degrees < 45 || degrees >= 315) {
      const position = generatePreferredPosition([
        "top",
        "bottom",
        "right",
        "left"
      ]);
      return {
        ...defaultStyle,
        [position]: "calc(100% + 100px)"
      };
    } else if (inRange(degrees, 45, 134)) {
      const position = generatePreferredPosition([
        "left",
        "right",
        "bottom",
        "top"
      ]);
      return {
        ...defaultStyle,
        [position]: "calc(100% + 20px)"
      };
    } else if (inRange(degrees, 135, 224)) {
      const position = generatePreferredPosition([
        "bottom",
        "top",
        "right",
        "left"
      ]);
      return {
        ...defaultStyle,
        [position]: "calc(100% + 30px)"
      };
    } else if (inRange(degrees, 225, 314)) {
      const position = generatePreferredPosition([
        "right",
        "left",
        "top",
        "bottom"
      ]);
      return {
        ...defaultStyle,
        [position]: "calc(100% + 40px)"
      };
    }
  }

  getTableCellDynamicStyling(cellMetadata) {
    let style = {
      cursor: "text",
      position: "relative",
      width: cellMetadata.width === "auto" ? "auto" : `${cellMetadata.width}px`,
      height:
        cellMetadata.height === "auto" ? "auto" : `${cellMetadata.height}px`,
      backgroundColor: cellMetadata.backgroundColor
        ? cellMetadata.backgroundColor
        : "",
      ...cellMetadata.borders.getAsCss(
        cellMetadata.backgroundColor,
        this.props.isSelected
      )
    };

    if (this.props.isCellLockingMode) {
      style = {
        ...style,
        backgroundColor: this.props.isCellLocked ? "#F2BFB4" : "#ffffff",
        borderTop: "2px solid #E5E8EB",
        borderRight: "2px solid #E5E8EB",
        borderBottom: "2px solid #E5E8EB",
        borderLeft: "2px solid #E5E8EB",
        opacity: this.props.isCellLocked ? 0.3 : 1
      };
    }

    return style;
  }

  handleCellLockingAction(cellId) {
    let updatedCells = [...this.props.elementData.lockedCellIds];

    if (updatedCells.includes(cellId)) {
      updatedCells = updatedCells.filter(id => id !== cellId);
    } else {
      updatedCells.push(cellId);
    }

    this.props.onElementPreview({
      lockedCellIds: updatedCells
    });
  }

  isResizeHandleExtremeBorder(border) {
    const { elementData, cellId, columnIndex, rowIndex } = this.props;
    const { highestRowIndex, highestColumnIndex } = getExtremeCellIndexes({
      elementData,
      cellId
    });
    const lastRowIndex = elementData.layout.length - 1;
    const lastColumnIndex = elementData.layout[0].length - 1;

    switch (border) {
      case "top": {
        return rowIndex === 0;
      }
      case "right": {
        return lastColumnIndex === highestColumnIndex;
      }
      case "bottom": {
        return lastRowIndex === highestRowIndex;
      }
      case "left": {
        return columnIndex === 0;
      }
      default:
        return;
    }
  }

  render() {
    const {
      colSpan,
      rowSpan,
      cellId,
      elementData,
      isCellSelected,
      isEditingCell,
      isCellLockingMode
    } = this.props;
    const cellMetadata = elementData.cells[cellId];
    const dynamicStyle = getDynamicCellStyling(cellMetadata);
    const isObservable = this.props.isCellSelected && this.props.isCellEditable;
    const dynamicWrapperStyle = this.getTableCellDynamicStyling(cellMetadata);

    if (isCellLockingMode) {
      dynamicStyle.color = "black";

      cellMetadata.displayValue = stripAttributeFromCellValue(
        cellMetadata.displayValue,
        "color"
      );
    }

    let customStyle = {
      position: "relative"
    };
    if (cellMetadata.textboxAnchor !== "top") {
      customStyle = {
        ...customStyle,
        display: "flex",
        alignItems: cellMetadata.textboxAnchor === "bottom" ? "end" : "center"
      };
    }
    const positionArray = this.generateResizeHandlePositionArray();
    const shouldAllowResize =
      this.props.isOnlyElementSelected && !isEditingCell && !isCellLockingMode;

    const isInEditMode =
      this.props.isCellEditable &&
      this.props.actionbar.buttonActive ===
        ACTION_BAR_BUTTON_NAMES_MAP.TABLE2_TEXTFIELD;

    const showLockIndicator =
      this.props.isSelected &&
      this.props.isCellLocked &&
      !this.props.isCellLockingMode &&
      !(
        this.props.actionbar.buttonActive ===
        ACTION_BAR_BUTTON_NAMES_MAP.TABLE2_TEXTFIELD
      );

    const lockingDynamicStyle = this.getLockingIndicatorStyling(cellMetadata);

    return (
      <td
        colSpan={colSpan}
        rowSpan={rowSpan}
        key={cellId}
        className={style.tableCellWrapper}
        style={dynamicWrapperStyle}
        id={`table-cell-${cellId}`}
      >
        {shouldAllowResize &&
          positionArray.map((position, index) => (
            <div key={`drag-handle-${index}`}>
              <Table2CellDragHandle
                cellId={cellId}
                zoom={this.props.zoom}
                position={position}
                handleResizeMouseDown={this.handleResizeMouseDown}
                width={dynamicWrapperStyle.width}
                cellHeight={cellMetadata.height}
                cellWidth={cellMetadata.width}
                isExtremeBorder={this.isResizeHandleExtremeBorder(position)}
              />
            </div>
          ))}
        <div
          ref={this.cellRef}
          id={`cell-${cellId}`}
          className={style.tableCell}
          data-testid="Table2Cell"
          data-is-selected={Boolean(isCellSelected)}
          onClick={() => this.selectTableCell(this.props.cellId)}
          style={customStyle}
        >
          {!this.props.isCellSelected ? (
            <div
              style={dynamicStyle}
              className={style.tableTextField}
              id={cellId}
              dangerouslySetInnerHTML={{
                __html: isInEditMode
                  ? cellMetadata.value || "&ZeroWidthSpace;"
                  : cellMetadata.displayValue || ""
              }}
            />
          ) : (
            <UncontrolledContentEditable
              style={dynamicStyle}
              onKeyDown={this.onKeyDown}
              className={style.tableTextField}
              onBlur={this.onBlur}
              text={
                isInEditMode
                  ? cellMetadata.value || ""
                  : cellMetadata.displayValue || ""
              }
              editable={this.props.isCellEditable}
              onChange={this.onChange}
              ref={this.contentEditableRef}
              isAutoCompleteOpen={this.props.isAutoCompleteOpen}
              isObservable={isObservable}
              disableInlineStyling
              selectAll
              elementId={cellMetadata.uniqueId}
              onMouseUp={this.handleSelectionChange}
            />
          )}
          {showLockIndicator && (
            <div className={style.lockingIndicator} style={lockingDynamicStyle}>
              <PadlockIcon size={`${16 / this.props.zoom}px`} />
            </div>
          )}
        </div>
      </td>
    );
  }
}
