import React, { Component } from "react";
import common from "./common.module.css";
import { Logger } from "lib";
import { formatFileSize } from "lib/files/fileUtils";
import { formatDateString } from "lib/temporal/dateUtils";
import { isGif } from "lib/isGif";
import EllipsisIcon from "views/components/icons/EllipsisIcon";
import FolderSquareIcon from "views/components/icons/FolderSquareIcon";
import TrashCanIcon from "views/components/icons/TrashCanIcon";
import { Loading } from "views/components";
import PopoutItemMenu from "../popout/PopoutItemMenu";
import { memoizedGetGifImageSource as getGifImageSource } from "lib/mediaSourceHelpers";
import ScrollingOverflowText from "views/components/ScrollingOverflowText/ScrollingOverflowText";
import { getParameterByName } from "lib/queryStringUtils";
import { SmartImageModalContainer } from "views/containers";
import SmartImageIcon from "views/components/icons/SmartImageIcon";
import EditSmartImageIcon from "views/components/icons/EditSmartImageIcon";
import UnassignSmartImageIcon from "views/components/icons/UnassignSmartImageIcon";
import SmartImageLabel from "../SmartImage/SmartImageLabel";
import confirmDeleteSmartImage from "views/components/SmartImage/confirmDeleteSmartImage";
import DeleteUserSmartImageModalContainer from "views/containers/DeleteUserSmartImageModalContainer/DeleteUserSmartImageModalContainer";
import TransferBrandSmartImageModalContainer from "views/containers/TransferBrandSmartImageModalContainer/TransferBrandSmartImageModalContainer";

const TEAM_FOLDER_TYPE = "BRAND_IMAGE";

export default class BrandKitImageRow extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      useFallback: false,
      imageSource: null,
      imgStyle: {
        width: "100%",
        height: "100%"
      },
      isSmartImageModalOpen: false,
      smartModalType: "create",
      isTransferUserSmartImageModalOpen: false
    };
    this.useFallbackImage = this.useFallbackImage.bind(this);
    this.footerSubmitAction = this.footerSubmitAction.bind(this);
    this.handleOnMouseOver = this.handleOnMouseOver.bind(this);
    this.handleRename = this.handleRename.bind(this);
    this.getMenuActions = this.getMenuActions.bind(this);
    this.openSmartImageModal = this.openSmartImageModal.bind(this);
    this.closeSmartImageModal = this.closeSmartImageModal.bind(this);
    this.openSmartImageModal = this.openSmartImageModal.bind(this);
    this.closeSmartImageModal = this.closeSmartImageModal.bind(this);
    this.handleDeleteSmartImage = this.handleDeleteSmartImage.bind(this);
    this.onClickDeleteSmartImage = this.onClickDeleteSmartImage.bind(this);
  }

  useFallbackImage() {
    this.setState({ useFallback: true });
  }

  async getImageUrl() {
    let imageSource = null;
    if (this.state.useFallback) {
      imageSource = this.props.image.url;
    } else {
      imageSource = this.props.image.thumbnailUrl;
    }

    if (isGif(this.props.image.url)) {
      imageSource = await getGifImageSource({
        isPlaying: this.state.isHovered,
        gifSource: imageSource
      });
    }

    if (imageSource !== this.state.imageSource) {
      this.setState({
        imageSource
      });
    }
  }

  handleOnMouseOver() {
    if (this.state.isHovered) {
      return;
    }

    this.setState({ isHovered: true });
  }

  componentDidMount() {
    this.getImageUrl();
  }

  componentDidUpdate(prevProps, prevState) {
    const { image, menuId, getFolderForMedia } = this.props;
    if (prevProps.image !== image) {
      // try to get the url again and remove the fallback if it was set
      if (this.state.useFallback) {
        this.setState({
          useFallback: false
        });
      }
      this.getImageUrl();
    }

    if (
      this.state.useFallback !== prevState.useFallback ||
      this.state.isHovered !== prevState.isHovered
    ) {
      // just get the url again since we want to preserve the fallback state we had
      this.getImageUrl();
    }
    if (
      menuId === image.mediaId &&
      prevProps.menuId !== prevProps.image.mediaId
    ) {
      getFolderForMedia({ mediaId: image.mediaId });
    }

    if (
      prevState.isTransferUserSmartImageModalOpen !==
      this.state.isTransferUserSmartImageModalOpen
    ) {
      this.props.handleTransferModalToggle(
        this.state.isTransferUserSmartImageModalOpen
      );
    }
  }

  handleRename(name) {
    const { image, handleMenuShowHide } = this.props;
    this.props.updateBrandKitImage({
      ...image,
      media: { ...image.media, name }
    });
    handleMenuShowHide();
  }

  async shouldComponentUpdate(nextProps) {
    if (nextProps.image.thumbnailUrl !== this.props.image.thumbnailUrl) {
      // the thumbnail url was updated so we want to ensure we load it
      // wait for the preview to be added to cache so we don't get a flicker on the canvas
      await new Promise(resolve => {
        let attempts = 0;
        const attemptLoad = () => {
          attempts++;
          Logger.info(
            `attempting a load for ${nextProps.image.name}: attempt ${attempts}`
          );
          const imagePreloaded = document.createElement("img");
          imagePreloaded.addEventListener("load", () => {
            Logger.info(
              `load succeeded for ${nextProps.image.name}: attempt ${attempts}`
            );
            resolve();
          });
          imagePreloaded.addEventListener("error", () => {
            if (attempts >= 5) {
              resolve();
            } else {
              Logger.info(
                `a load failed for ${nextProps.image.name}: attempt ${attempts}`
              );
              setTimeout(attemptLoad, 200);
            }
          });
          imagePreloaded.src = nextProps.image.thumbnailUrl;
        };
        attemptLoad();
      });

      return true;
    } else {
      return true;
    }
  }

  handleDeleteTeamImage(mediaId) {
    if (!!this.props.smartImage) {
      this.setState({
        isDeleteTeamSmartImageModalOpen: true
      });
      return;
    }

    this.props.handleRemoveImage({ mediaId });
  }

  getMenuActions() {
    const { image, handleMenuShowHide, mediaInFolders } = this.props;
    const getFolderActions = () => {
      const isInFolder = this.props.qsFolderId !== null;
      const isMediaInFolder = !!mediaInFolders[image.mediaId]?.folderId;
      const isSearchingMedia = getParameterByName(
        "term",
        window.location.search
      );
      if (mediaInFolders[image.mediaId]?.isFetching && !!isSearchingMedia) {
        return {
          Icon: () => <Loading size="24px" />
        };
      }
      if (isInFolder || isMediaInFolder) {
        return {
          Icon: () => <FolderSquareIcon size="24px" />,
          onClick: () => {
            this.props.removeContentFromTeamFolder({
              contentId: image.mediaId,
              folderId: mediaInFolders[image.mediaId].folderId,
              teamId: (this.props.currentTeam || {}).id
            });
            handleMenuShowHide();
          },
          name: "Remove from Folder"
        };
      }
      return {
        Icon: () => <FolderSquareIcon size="24px" />,
        onClick: () => this.setState({ menuFooterOpen: true }),
        name: "Move to Folder"
      };
    };

    const actions = [
      getFolderActions(),
      {
        Icon: () => <TrashCanIcon size="22px" />,
        onClick: () => {
          this.handleDeleteTeamImage(image.mediaId);
          handleMenuShowHide(image.media);
        },
        name: "Delete"
      }
    ];

    if (!this.props.smartImage) {
      actions.splice(1, 0, {
        name: "Assign Smart ID",
        Icon: props => <SmartImageIcon {...props} />,
        onClick: () => {
          // open smart image Modal
          this.openSmartImageModal("teamCreate");
        }
      });
    } else {
      // UPDATE SMART IMAGE BUTTON
      actions.splice(1, 0, {
        name: "Edit Smart ID",
        Icon: props => <EditSmartImageIcon {...props} />,
        onClick: () => {
          // open smart image Modal
          this.openSmartImageModal("teamUpdate");
        }
      });
      actions.splice(2, 0, {
        name: "Unassign Smart ID",
        Icon: props => <UnassignSmartImageIcon {...props} />,
        onClick: () => {
          // open smart image Modal
          this.handleDeleteSmartImage();
        }
      });
    }
    return actions;
  }

  footerSubmitAction(image) {
    const { menuFooterOption } = this.state;
    const folder = this.props.teamFoldersArr.find(
      f => f.name === menuFooterOption
    );

    this.props.addContentToTeamFolder({
      contentId: image.mediaId || image.id,
      folderId: folder.id,
      teamId: (this.props.currentTeam || {}).id,
      type: this.props.isLogo ? "BRAND_LOGO" : TEAM_FOLDER_TYPE
    });

    this.props.handleMenuShowHide();
  }

  openSmartImageModal(type) {
    if (this.state.isSmartImageModalOpen) return;
    this.setState({
      isSmartImageModalOpen: true,
      smartModalType: type
    });
  }
  closeSmartImageModal() {
    if (!this.state.isSmartImageModalOpen) return;
    this.setState({
      isSmartImageModalOpen: false,
      smartModalType: null
    });
  }

  onClickDeleteSmartImage() {
    this.props.onDeleteTeamSmartImage({
      id: this.props.smartImage.id,
      mediaId: this.props.smartImage.mediaId
    });
  }

  handleDeleteSmartImage() {
    confirmDeleteSmartImage(this.onClickDeleteSmartImage);
  }

  render() {
    const { image, handleMenuShowHide, smartImage, qsFolderId } = this.props;
    const { imageSource } = this.state;
    const submitButtonLabel = qsFolderId ? "Remove" : "Add";

    return (
      <div
        className={common.imageListRow}
        key={image.mediaId}
        ref={this.wrapperRef}
      >
        <div className={common.imageListTile}>
          <div
            className={common.imageListWrapper}
            onMouseEnter={() => this.setState({ isHovered: true })}
            onMouseLeave={() => this.setState({ isHovered: false })}
            onMouseOver={this.handleOnMouseOver}
          >
            {!!imageSource ? (
              <img
                src={imageSource}
                className={common.imageListRowImage}
                alt=""
                onError={this.useFallbackImage}
                id={image.mediaId}
                style={this.state.imgStyle}
              />
            ) : (
              <Loading />
            )}
          </div>
        </div>

        <div className={common.imageListRowRight}>
          <div
            className={common.imageRowTitle}
            style={{ flexWrap: smartImage ? "wrap" : "nowrap" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {smartImage && <SmartImageLabel label={smartImage.label} />}
            <ScrollingOverflowText
              className={common.imageListRowTitle}
              title={(image.media || {}).name || image.name}
              customStyle={{ paddingLeft: smartImage ? "4px" : "0" }}
            >
              {(image.media || {}).name || image.name}
            </ScrollingOverflowText>
          </div>

          <div className={common.imageRowRightWrapper}>
            <div className={common.imageListRowColumn}>
              {formatFileSize((image.media || {}).size)}
            </div>

            <div className={common.imageListRowColumn}>
              {formatDateString(image.createdAt)}
            </div>

            <div className={common.imageListRowMenu}>
              {this.props.isBrandManager && (
                <>
                  <div
                    className={common.imageListRowEllipse}
                    onClick={event => {
                      event.stopPropagation();
                      handleMenuShowHide(image.mediaId);
                    }}
                  >
                    <EllipsisIcon color="#999c9e" size="24px" />
                  </div>

                  {this.props.menuId === image.mediaId && (
                    <PopoutItemMenu
                      actions={this.getMenuActions()}
                      className={common.popoutItemMenu}
                      footerInfo={{
                        footerInputValue: this.state.menuFooterOption,
                        footerSubmitAction: () =>
                          this.footerSubmitAction(image),
                        footerValueOptions: this.props.teamFoldersArr.map(
                          folder => ({
                            key: folder.name,
                            label: folder.name
                          })
                        ),
                        handleFooterFieldChange: folderName => {
                          this.setState({
                            menuFooterOption: folderName
                          });
                        },
                        isFooterSubmitting: false,
                        type: "dropdown",
                        submitButtonLabel
                      }}
                      footerOpen={this.state.menuFooterOpen}
                      isProcessing={this.props.isProcessing}
                      itemName={image.media?.name}
                      onClose={() => handleMenuShowHide()}
                      onRename={name => this.handleRename(name)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {this.state.isSmartImageModalOpen && (
          <SmartImageModalContainer
            isOpen={this.state.isSmartImageModalOpen}
            onOpen={this.openSmartImageModal}
            onClose={this.closeSmartImageModal}
            image={image}
            smartImage={this.props.smartImage}
            type={this.state.smartModalType}
            onTransferClick={() =>
              this.setState({
                isSmartImageModalOpen: false,
                isTransferUserSmartImageModalOpen: true
              })
            }
          />
        )}
        {this.state.isDeleteTeamSmartImageModalOpen && (
          <DeleteUserSmartImageModalContainer
            isOpen={this.state.isDeleteTeamSmartImageModalOpen}
            onHardDelete={() =>
              this.props.handleRemoveImage({
                mediaId: image.mediaId,
                isSmartImage: !!this.props.smartImage
              })
            }
            onClose={() =>
              this.setState({ isDeleteTeamSmartImageModalOpen: false })
            }
            onTransferClick={() =>
              this.setState({
                isDeleteTeamSmartImageModalOpen: false,
                isTransferUserSmartImageModalOpen: true
              })
            }
          />
        )}
        {this.state.isTransferUserSmartImageModalOpen && (
          <TransferBrandSmartImageModalContainer
            isOpen={this.state.isTransferUserSmartImageModalOpen}
            onClose={() => {
              this.setState({ isTransferUserSmartImageModalOpen: false });
              this.props.handleTransferModalToggle(false);
            }}
            smartImage={this.props.smartImage}
            isLogo={this.props.isLogo}
          />
        )}
      </div>
    );
  }
}
