import React, { Component } from "react";
import style from "./style.module.css";
import { XIcon } from "../icons";
class FilterOption extends Component {
  render() {
    const { label, onClick } = this.props;
    return (
      <div className={style.optionWrapper}>
        <div className={style.option}>{label}</div>
        <div className={style.icon} onClick={onClick}>
          <XIcon width={"8"} height={"8"} color={"#afb3b6"} />
        </div>
      </div>
    );
  }
}
export default FilterOption;
