import React, { Component } from "react";
import { connect } from "react-redux";
import { createImageFolder } from "state/entities/imageFolders/imageFoldersActions";
import ImagesNewFolderButton from "./ImagesNewFolderButton";
import { myImagesPersonalStateSelector } from "state/ui/MyImages/MyImagesSelectors";

export class ImagesNewFolderButtonContainer extends Component {
  render() {
    return <ImagesNewFolderButton {...this.props} />;
  }
}

const mapStateToProps = state => {
  const createImageFolderState = state.ui.createImageFolder;
  const imageSearchPersonalState = myImagesPersonalStateSelector(state);
  return {
    createImageFolderState,
    isInSearchContext: imageSearchPersonalState.currentTerm !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createImageFolder: args => dispatch(createImageFolder(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImagesNewFolderButtonContainer);
