import { cloneDeep, flatten, isNil, omit, pick, getPath } from "lib/lodash";
import { uuid } from "lib/uuid";
import { domParser } from "lib/defaultDomParser";
import { arrayOfObjectsToObject } from "lib/array/array";
import {
  sixDigitHexColor,
  getFillColorsForElements
} from "lib/Colors/colorUtils";
import { insertItem, moveItem, removeItem } from "lib/array/array";
import { rotatePoint } from "lib/geometry/rotation";
import { getPaletteFromSrc } from "lib/Colors/colorUtils";
import { getImageDimensionsFromElement } from "lib/imageHelpers";
import {
  getVideoHasAudioFromSrc,
  getVideoDimensionsFromElement
} from "lib/videoHelpers";
import { isVideo } from "lib/isVideo";
import { isGif } from "lib/isGif";
import { getSourceTypeFromAsset } from "lib/elementTypeUtils";
import { getElementsBox } from "lib/elements";
import { getTableColors, getCellsWithFont } from "lib/tableUtils";
import { getTable2Colors } from "lib/table2Utils";
import {
  getElementsLeftValues,
  getElementsRightValues,
  getElementsTopValues,
  getElementsBottomValues,
  alignElementsAtMinimumOrigin,
  alignElementsAtCenterOrigin,
  alignElementsAtMaximumOrigin
} from "state/ui/editor/DesignUtils";
import {
  DesignLayerOps,
  DesignPositionOps,
  DesignCopyOps,
  DesignPageOps,
  DesignRestrictionsOps,
  DesignTextMaskingOps,
  DesignImageInstructionsOps,
  DesignColorsOps,
  DesignTableOps
} from "./designOps";
import { fetchSvgs } from "lib/svg/svg";
import { elementAlignmentValues } from "lib/constants";
import { canvasMargin } from "views/components/Editor/canvas/canvasConstants";
import {
  fetchSvgDataFromUrl,
  resizableOptions,
  fillColors,
  fillColorsFromUpload
} from "lib/parseSvgToElement";

import ElementFactory from "./elements/ElementFactory";
import designAdapter from "state/ui/editor/designAdapter";

import {
  calculateImageReplacePreview,
  getUpdatedImageFromPreview
} from "lib/Renderer/ImageUtil";

import { getImageSize, getImageMediaId } from "lib/getImageDetails";

import {
  getMaskSizeForVector,
  calculateUpdatedVectorImageInstructions
} from "views/components/Editor/elements/vector/vectorUtils";

import { calculateUpdatedGridImageInstructions } from "views/components/Editor/elements/grid/gridImageUtils";

import { getDefaultImageInstructionTarget } from "views/components/Editor/elements/grid/imageInstructionUtils";

import { getAllColoredContent } from "lib/DOMNodeUtils";

import { createDOMElementFromHTMLString } from "lib/tests/DOMNodeUtils/testSetupFunctions";

import * as imageColorExtractor from "node-vibrant";

import { isMp4 } from "lib/isMp4";

import { getAnimationData } from "lib/animatedElementUtils";

import {
  ANIMATED_DESIGN_PAGE_DEFAULT_DURATION,
  EDITOR_ELEMENTS_MAP
} from "lib/constants";

import {
  getFontFamiliesFromRichText,
  getFontFamilyMatchesInRichText
} from "lib/string/string";
import { getFontByName } from "lib/fonts";

import { isTextElement, isQuickInputElement } from "lib/elements";
import {
  updateSmartTextPlaceholder,
  hasSmartTextPlaceholders
} from "lib/textUtils";
import { element } from "prop-types";
import {
  isDesignOpenQuickInput,
  updateDurationForDesign
} from "lib/designUtils";
import {
  table2CellDimensionUpdater,
  table2HeightUpdater
} from "views/components/Editor/sidebar/tabs/shapes/Tables2Tab/helper";
import {
  getExtremePosition,
  calculateTotalSize,
  calculateSpacing,
  getGroupBoundingBox
} from "views/components/Editor/actionbar/buttons/elementAlign/utils";

class Design {
  constructor({
    pagesOrder,
    bleed,
    width,
    height,
    pages,
    elements,
    fonts,
    version,
    restrictions,
    id,
    templateCode,
    designId,
    createdAt,
    updatedAt,
    designDataId,
    ordered,
    animatedElements
  }) {
    this.bleed = bleed;
    this.createdAt = createdAt;
    this.designDataId = designDataId;
    this.designId = designId;
    this.elements = this.EnhancedElements(elements);
    this.fonts = fonts;
    this.height = height;
    this.id = id;
    this.ordered = ordered;
    this.pages = pages;
    this.pagesOrder = pagesOrder;
    this.restrictions = restrictions || [];
    this.templateCode = templateCode;
    this.updatedAt = updatedAt;
    this.version = version || 1;
    this.width = width;
    this.animatedElements = animatedElements;

    Object.assign(
      this,
      DesignColorsOps,
      DesignCopyOps,
      DesignImageInstructionsOps,
      DesignLayerOps,
      DesignPageOps,
      DesignPositionOps,
      DesignRestrictionsOps,
      DesignTableOps,
      DesignTextMaskingOps
    );

    this.createElement = this.createElement.bind(this);
    this.ImageElementBuilder = this.ImageElementBuilder.bind(this);
    this.VideoElementBuilder = this.VideoElementBuilder.bind(this);
    this.vectorElementBuilder = this.vectorElementBuilder.bind(this);

    this.getAllElementsWithFontFamily = this.getAllElementsWithFontFamily.bind(
      this
    );
  }

  getPage({ pageId }) {
    return this.pages[pageId];
  }

  getPageIdForElement(uniqueId) {
    let _uniqueId = uniqueId;
    const elementData = this.getElement(_uniqueId);

    if (elementData.groupId && elementData.groupId !== uniqueId) {
      _uniqueId = elementData.groupId;
    }

    for (const pageId in this.pages) {
      if (this.pages[pageId].elementsOrder.includes(_uniqueId)) {
        return pageId;
      }
    }
    return null; // Return null if the uniqueId is not found in any elementsOrder
  }

  getSnapPoints({ pageId, excludeIds = [] }) {
    const page = this.getPage({ pageId });

    // page snap points
    const xCoordinates = new Set([
      this.bleed,
      this.width / 2,
      this.width - this.bleed
    ]);
    const yCoordinates = new Set([
      this.bleed,
      this.height / 2,
      this.height - this.bleed
    ]);

    const showBleed = getPath(window, "easil.editor.state.showBleed");

    if (showBleed) {
      xCoordinates.add(0);
      xCoordinates.add(this.width);
      xCoordinates.add(this.bleed * 2);
      xCoordinates.add(this.width - this.bleed * 2);

      yCoordinates.add(0);
      yCoordinates.add(this.height);
      yCoordinates.add(this.bleed * 2);
      yCoordinates.add(this.height - this.bleed * 2);
    }

    page.elementsOrder
      .map(elementId => this.getElement(elementId))
      .filter(element => {
        return !excludeIds.includes(element.uniqueId) && !element.isHidden;
      })
      .forEach(element => {
        const {
          x: { left, center: xCenter, right },
          y: { top, center: yCenter, bottom }
        } = element.getSnapPoints();

        [left, xCenter, right].forEach(x => xCoordinates.add(x));
        [top, yCenter, bottom].forEach(y => yCoordinates.add(y));
      });

    // add the pages guides to the list of snapping coordinates
    (page.guides || []).forEach(guide => {
      if (guide.axis === "x") {
        // ensure to account for the bleed offset
        xCoordinates.add(guide.value + this.bleed);
      }
      if (guide.axis === "y") {
        // ensure to account for the bleed offset
        yCoordinates.add(guide.value + this.bleed);
      }
    });

    return {
      x: Array.from(xCoordinates),
      y: Array.from(yCoordinates)
    };
  }

  updateAttributes(attributes) {
    return new this.constructor({
      ...this,
      ...attributes
    });
  }

  createElement(element) {
    return ElementFactory.create({
      ...element,
      getElement: this.getElement.bind(this)
    });
  }

  async ImageElementBuilder(imageElementData) {
    const imageElement = this.createElement({
      previewSrc: imageElementData.previewUrl,
      ...imageElementData,
      type: "image"
    });

    // get the dimensions for the image being built
    const imageSize = await getImageDimensionsFromElement(imageElement);

    const designHeight = this.height;
    const designWidth = this.width;

    const canvasRatio = 0.75;

    const scale = Math.min(
      (designHeight * canvasRatio) / imageSize.height,
      (designWidth * canvasRatio) / imageSize.width,
      1
    );

    imageElement.scale = scale;

    imageElement.srcWidth = imageSize.width;
    imageElement.width = imageSize.width * scale;

    imageElement.srcHeight = imageSize.height;
    imageElement.height = imageSize.height * scale;
    // handle incoming asset in the form of GIF
    imageElement.duration = imageElementData.duration;

    if (
      !imageElement.duration &&
      imageElementData.media &&
      imageElementData.media.type === "ANIMATION"
    ) {
      imageElement.duration = imageElementData.media.duration;
    }

    return imageElement;
  }

  async VideoElementBuilder(videoElementData) {
    const videoElement = this.createElement({
      ...videoElementData,
      type: "video"
    });

    const videoSize = await getVideoDimensionsFromElement(videoElement);

    const designHeight = this.height;
    const designWidth = this.width;

    const canvasRatio = 0.75;

    const scale = Math.min(
      (designHeight * canvasRatio) / videoSize.height,
      (designWidth * canvasRatio) / videoSize.width,
      1
    );

    videoElement.scale = scale;

    videoElement.srcWidth = videoSize.width;
    videoElement.width = videoSize.width * scale;

    videoElement.srcHeight = videoSize.height;
    videoElement.height = videoSize.height * scale;

    // handle incoming asset in the form of GIF
    videoElement.duration = videoElementData.duration;
    if (
      videoElementData.media &&
      videoElementData.media.type === "VIDEO" &&
      videoElementData.media.duration
    ) {
      videoElement.duration = videoElement.media.duration;
    }

    videoElement.hasAudio = await getVideoHasAudioFromSrc(
      videoElement.previewSrc
    );

    return videoElement;
  }

  vectorElementBuilder(vectorElementData) {
    const vectorElement = this.createElement({
      ...vectorElementData,
      type: "vector"
    });

    const filePromise = fetchSvgDataFromUrl(vectorElementData.src);

    filePromise.then(svgFile => {
      const svg = domParser
        .parseFromString(svgFile, "image/svg+xml")
        .querySelector("svg");

      Object.assign(vectorElement, resizableOptions(svg));

      const designHeight = this.height;
      const designWidth = this.width;

      const box = svg.viewBox.baseVal;

      const srcHeight = box.height;
      const srcWidth = box.width;

      const canvasRatio = 0.25;

      const scale = Math.min(
        (designHeight * canvasRatio) / srcHeight,
        (designWidth * canvasRatio) / srcWidth
      );

      // set the scale on the element so that scaled width and height match the scale
      vectorElement.scale = scale;

      vectorElement.srcWidth = srcWidth;
      vectorElement.width = srcWidth * scale;

      vectorElement.srcHeight = srcHeight;
      vectorElement.height = srcHeight * scale;

      const changeIdFillColors = fillColors(svg);

      if (changeIdFillColors.length > 0) {
        vectorElement.fillColors = changeIdFillColors;
      } else {
        // automatically get the fill colors from the svg source
        vectorElement.fillColors = fillColorsFromUpload(svg);
      }

      const gTags = Array.from(svg.querySelectorAll("svg > g"));

      gTags.forEach(gTag => {
        // only one image tag should exist here
        const svgImageTag = gTag.querySelector("[clip-path] > image");

        if (!svgImageTag) {
          // if there is no image tag here then this is not a frame
          // do not add image instruction for it
          return;
        }

        // back track to closest clip-path tag
        const clipPath = svgImageTag.closest("[clip-path]");

        const getDimensionSource = () => {
          const clipPath = gTag.querySelector("clipPath");
          const rect = clipPath.querySelector("rect");
          const circle = clipPath.querySelector("circle");
          const path = clipPath.querySelector("path");
          const ellipse = clipPath.querySelector("ellipse");
          const polygon = clipPath.querySelector("polygon");
          return rect || circle || path || ellipse || polygon || svgImageTag;
        };

        // define the object to get dimensions for dropzone from
        const dimensionSource = getDimensionSource();

        if (!vectorElement.imageInstructions) {
          vectorElement.imageInstructions = [];
        }

        vectorElement.imageInstructions.push({
          domId: clipPath.id,
          width: dimensionSource.getAttribute("width"),
          height: dimensionSource.getAttribute("height")
        });
      });
    });

    return new Promise((resolve, reject) => {
      filePromise.then(() => {
        resolve(vectorElement);
      });
    });
  }

  EnhancedElements(elements) {
    const elementsEnhanced = {};

    for (let elementId in elements) {
      elementsEnhanced[elementId] = this.createElement(elements[elementId]);
    }

    return elementsEnhanced;
  }

  resizeGrid({
    dragItem,
    selectedItems,
    anchorPoint,
    offset,
    scale,
    zoom,
    scaleDirection,
    differenceFromInitialOffset
  }) {
    const gridElement = this.getElement(selectedItems[0].itemId);

    const gridElementResized = gridElement.stretch({
      anchorPoint,
      dragItem,
      offset,
      scale,
      zoom,
      scaleDirection,
      differenceFromInitialOffset
    });

    return new this.constructor({
      ...this,
      elements: {
        ...this.elements,
        [gridElement.uniqueId]: gridElementResized
      },
      version: this.version + 1
    });
  }

  resizeElements({
    selectedItems,
    dragItem,
    anchorPoint,
    offset,
    scale,
    zoom,
    scaleDirection,
    differenceFromInitialOffset,
    handlerInitialPosition
  }) {
    if (
      selectedItems.length === 1 &&
      this.getElement(selectedItems[0].itemId).type === "grid"
    ) {
      return this.resizeGrid({
        selectedItems,
        dragItem,
        offset,
        zoom,
        differenceFromInitialOffset,
        handlerInitialPosition
      });
    }

    let elementsUpdatedArray = [];
    selectedItems.forEach(item => {
      const elementResized = this.getElement(item.itemId).resize({
        anchorPoint,
        offset,
        scale,
        zoom,
        scaleDirection,
        differenceFromInitialOffset,
        dragItem
      });

      elementsUpdatedArray = elementsUpdatedArray.concat(elementResized);
    });

    const elementsUpdated = arrayOfObjectsToObject(
      elementsUpdatedArray,
      "uniqueId"
    );

    return new this.constructor({
      ...this,
      elements: {
        ...this.elements,
        ...elementsUpdated
      },
      version: this.version + 1
    });
  }

  getElement(elementId) {
    const element = this.elements[elementId];

    if (!element) {
      return null;
    }

    if (element.type === "group") {
      const groupBox = element.getSizeAndPosition();

      element.top = groupBox.top;
      element.left = groupBox.left;
      element.width = groupBox.width;
      element.height = groupBox.height;
    }

    return element;
  }

  getElementWithRestrictions(item) {
    const element = this.getElement(item.uniqueId);
    if (!element) return item;
    return {
      ...item,
      restrictions: element.getRestrictionsMap(),
      type: element.type,
      value: element.value
    };
  }

  getElementPositionActions({ elementId, groupId, pageId }) {
    if (groupId) {
      return this.getElementPositionActionsInGroup({
        elementId,
        groupId,
        pageId
      });
    }

    const page = this.pages[pageId];

    const elementIndexInPage = page.elementsOrder.indexOf(elementId);
    const pageLength = page.elementsOrder.length;

    let positionActions = [];

    if (elementIndexInPage + 1 !== pageLength) {
      positionActions.push("moveToTop", "moveUp");
    }

    if (elementIndexInPage !== 0) {
      positionActions.push("moveDown", "moveToBottom");
    }

    return positionActions;
  }

  getElementPositionActionsInGroup({ elementId, groupId, pageId }) {
    const group = this.getElement(groupId);
    const elementIndexInGroup = group.elementsOrder.indexOf(elementId);
    const groupLength = group.elementsOrder.length;

    let positionActions = [];

    if (elementIndexInGroup + 1 !== groupLength) {
      positionActions.push("moveToTop", "moveUp");
    }

    if (elementIndexInGroup !== 0) {
      positionActions.push("moveDown", "moveToBottom");
    }

    return positionActions;
  }

  getElementsColors() {
    const colors = new Set();

    Object.keys(this.elements).forEach(elementId => {
      var element = this.getElement(elementId);

      if (element.fillColors) {
        element.fillColors.forEach(fillColor =>
          colors.add(sixDigitHexColor(fillColor.color))
        );
      }

      if (element.color) {
        colors.add(sixDigitHexColor(element.color));
      }

      // find the colors for tables
      if (element.type === EDITOR_ELEMENTS_MAP.TABLE) {
        const tableColors = getTableColors(element);
        tableColors.forEach(color => {
          colors.add(sixDigitHexColor(color));
        });
      }

      // find the colors for table2 elements
      if (element.type === EDITOR_ELEMENTS_MAP.TABLE2) {
        const table2Colors = getTable2Colors(element);
        table2Colors.forEach(color => {
          colors.add(sixDigitHexColor(color));
        });
      }

      if (!!element.outline) {
        colors.add(sixDigitHexColor(element.outline.color));
      }

      // check if element is a textbox with rich text color
      if (
        element.type === EDITOR_ELEMENTS_MAP.TEXTBOX &&
        element.value.includes("color:")
      ) {
        const textboxElement = createDOMElementFromHTMLString(element.value);

        textboxElement.style["position"] = "absolute";
        textboxElement.contentEditable = "true";

        const allNodes = [];

        const addChildrenToNodeList = node => {
          // don't push the textbox element
          if (node !== textboxElement) {
            allNodes.push(node);
          }
          if (node.childNodes && node.childNodes.length) {
            [...node.childNodes].forEach(addChildrenToNodeList);
          }
        };

        addChildrenToNodeList(textboxElement);

        const coloredNodes = getAllColoredContent(allNodes);

        const nodeColors = coloredNodes.map(node => {
          // split rgb color into array of 3 numbers
          // return the color as a hex value in object
          const colorInRGB = node.style["color"]
            .replace("rgb(", "")
            .replace(")", "")
            .split(",")
            .map(color => parseInt(color));
          const colorInHex = imageColorExtractor.Util.rgbToHex(...colorInRGB);
          return colorInHex;
        });

        // add all colors found in the rich text to the list of colors
        nodeColors.forEach(nodeColor =>
          colors.add(sixDigitHexColor(nodeColor))
        );

        textboxElement.remove();
      }
    });

    this.pagesOrder.forEach(pageId => {
      const pageBgColor = this.pages[pageId].backgroundColor || "#ffffff";

      if (pageBgColor !== "transparent") {
        colors.add(sixDigitHexColor(pageBgColor));
      }
    });

    return Array.from(colors).sort();
  }

  getFillColors() {
    const elements = Object.keys(this.elements).map(elementId =>
      this.getElement(elementId)
    );

    return getFillColorsForElements(elements);
  }

  getLayers() {
    return this.pagesOrder.map(pageId => this.getPageLayers({ pageId }));
  }

  getPageLayers({ pageId }) {
    const elementsOrderReversed = this.pages[pageId].elementsOrder
      .slice()
      .reverse();

    return elementsOrderReversed.map(elementId => {
      const element = this.getElement(elementId);
      return element.getLayerTree({});
    });
  }

  getAssetElements() {
    const elements = {};

    for (let elementId in this.elements) {
      const element = this.getElement(elementId);

      if (["image", "background", "video"].includes(element.type)) {
        elements[element.id] = element;
      }

      if (element.type === "textbox" && element.maskImage) {
        elements[element.maskImage.id] = element.maskImage;
      }

      if (element.type === "grid") {
        element.imageInstructions.forEach(imageInstruction => {
          if (imageInstruction.id) {
            elements[imageInstruction.id] = imageInstruction;
          }
        });
      }

      if (element.type === "vector") {
        element.imageInstructions &&
          element.imageInstructions.forEach(imageInstruction => {
            if (imageInstruction.id) {
              elements[imageInstruction.id] = imageInstruction;
            }
          });
      }
    }

    const images = {};
    const videos = {};
    const animations = {};

    Object.values(elements).forEach(element => {
      const isVideoElement = isVideo(element.src);
      const isGifElement = isGif(element.src);
      if (isVideoElement) {
        videos[element.id] = element;
      } else if (isGifElement) {
        animations[element.id] = element;
      } else {
        images[element.id] = element;
      }
    });

    return {
      images,
      videos,
      animations
    };
  }

  getAnimationElements() {
    const { animations, videos } = this.getAssetElements();

    return { animations, videos };
  }

  getImages() {
    const { images } = this.getAssetElements();

    return images;
  }

  getAssets() {
    const assets = {
      vectors: {},
      images: {}
    };

    for (let elementId in this.elements) {
      const element = this.getElement(elementId);
      const elementTypesWithImage = ["background", "image"];

      if (elementTypesWithImage.includes(element.type)) {
        assets.images[element.id] = element;
      }

      if (element.type === "vector") {
        assets.vectors[element.id] = element;
      }
    }

    return assets;
  }

  toggleHideElementsInHiddenGroup = ({ currentElement }) => {
    // make the group element visible
    return {
      ...this.elements,
      [currentElement.groupId]: {
        ...this.elements[currentElement.groupId],
        isHidden: false
      },
      [currentElement.uniqueId]: {
        ...currentElement,
        isHidden: false
      }
    };
  };

  toggleHideGroup = ({ currentElement }) => {
    // get the elements in the group
    const groupedElements = Object.values(this.elements).filter(
      element => element.groupId === currentElement.uniqueId
    );

    // make them all hidden
    const updatedGroupedElements = groupedElements.map(element => ({
      [element.uniqueId]: {
        ...this.elements[element.uniqueId],
        isHidden: !currentElement.isHidden
      }
    }));

    return Object.assign(
      {},
      this.elements,
      {
        [currentElement.uniqueId]: {
          ...currentElement,
          isHidden: !currentElement.isHidden
        }
      },
      ...updatedGroupedElements
    );
  };

  toggleHideElement = ({ elementId, isGroupHidden }) => {
    let elementsUpdated;
    const currentElement = this.elements[elementId];
    if (currentElement.type === "group") {
      //perform group visibility update
      elementsUpdated = this.toggleHideGroup({ currentElement });
      // we are left with the grouped elements inheriting the isHidden of the parent
    } else if (isGroupHidden) {
      // is an element in a hidden group
      elementsUpdated = this.toggleHideElementsInHiddenGroup({
        currentElement
      });
      // we are left with the group element and the element selected visible with the rest of the group hidden
    } else {
      // is just an element on its own, toggle it!
      elementsUpdated = {
        ...this.elements,
        [elementId]: {
          ...currentElement,
          isHidden: !currentElement.isHidden
        }
      };
      // leaving all other element visibility and only toggling the given element
    }

    return new Design({
      ...this,
      elements: elementsUpdated,
      version: this.version + 1
    });
  };

  toggleFitToFrame = ({ elementId }) => {
    let elementsUpdated;
    const currentElement = this.elements[elementId];
    if (currentElement.type !== "image") {
      return;
    }
    elementsUpdated = {
      ...this.elements,
      [elementId]: {
        ...currentElement,
        fitToFrame: !currentElement.fitToFrame
      }
    };

    return new Design({
      ...this,
      elements: elementsUpdated,
      version: this.version + 1
    });
  };

  setFitToFrame = (elementId, isFitToFrame) => {
    let elementsUpdated;
    const currentElement = this.elements[elementId];
    if (
      currentElement.type !== "image" ||
      element.fitToFrame === isFitToFrame
    ) {
      return;
    }
    elementsUpdated = {
      ...this.elements,
      [elementId]: {
        ...currentElement,
        fitToFrame: isFitToFrame
      }
    };

    return new Design({
      ...this,
      elements: elementsUpdated,
      version: this.version + 1
    });
  };

  deleteElement({ elementId, groupId, pageId }) {
    if (groupId) {
      return this.deleteElementFromGroup({ elementId, groupId });
    }

    const page = cloneDeep(this.pages[pageId]);
    const elementIndexOnPage = page.elementsOrder.indexOf(elementId);
    const elementQuickInputOrderIndexOnPage = page.quickInputOrder.indexOf(
      elementId
    );

    const pagesUpdated = {
      ...this.pages,
      [pageId]: {
        ...page,
        elementsOrder: removeItem(page.elementsOrder, elementIndexOnPage),
        quickInputOrder: removeItem(
          page.quickInputOrder,
          elementQuickInputOrderIndexOnPage
        )
      }
    };

    const currentElement = this.getElement(elementId);
    // handle adding an animated element to the design
    if (currentElement.duration) {
      // this is an animated element
      const animationData = getAnimationData({ ...currentElement, pageId });
      animationData.forEach(dataPoint => {
        delete pagesUpdated[pageId].animatedElements[
          dataPoint.animationDataKey
        ];
      });
    }

    return new this.constructor({
      ...this,
      pages: pagesUpdated,
      elements: omit(this.elements, elementId),
      version: this.version + 1
    });
  }

  handleVideoDrop(videoData, pageId) {
    const designData = cloneDeep(this);
    const currentElement = this.getElement(videoData.uniqueId);
    const page = cloneDeep(this.pages[pageId]);
    const pagesUpdated = {
      ...this.pages,
      [pageId]: page
    };
    if (currentElement.duration && !videoData.duration) {
      // this is an animated element
      const animationData = getAnimationData({ ...currentElement, pageId });
      animationData.forEach(dataPoint => {
        delete pagesUpdated[pageId].animatedElements[
          dataPoint.animationDataKey
        ];
      });
    }
    const updatedDesignData = new this.constructor({
      ...this,
      pages: pagesUpdated
    });
    const { pages } = updateDurationForDesign(updatedDesignData, designData);
    const updatedElements = {
      ...this.elements,
      [videoData.uniqueId]: currentElement.updateAttributes(videoData)
    };

    return new this.constructor({
      ...this,
      pages,
      elements: updatedElements,
      version: this.version + 1
    });
  }

  deleteElementFromGroup({ elementId, groupId }) {
    const group = this.getElement(groupId);

    const elementsUpdated = {
      ...omit(this.elements, elementId),
      [groupId]: group.deleteItem(elementId)
    };

    return new this.constructor({
      ...this,
      elements: elementsUpdated,
      version: this.version + 1
    });
  }

  moveElement({ elementId, left, top }) {
    const elementsUpdated = {
      ...this.elements,
      [elementId]: {
        ...this.elements[elementId],
        left: left,
        top: top
      }
    };

    return new Design({
      ...this,
      elements: elementsUpdated,
      version: this.version + 1
    });
  }

  distributeElements(selectedElements, axis) {
    const selectedElementsData = selectedElements
      .map(element => this.getElement(element.itemId))
      // filter out empty groups
      .filter(
        element =>
          !(element.type === "group" && element.elementsOrder.length < 1)
      );
    const positionProperty = axis === "x" ? "left" : "top";

    // Sort elements by their extreme position along the axis
    selectedElementsData.sort(
      (a, b) => a[positionProperty] - b[positionProperty]
    );

    // Calculate total size of all elements and the spacing
    const totalSize = calculateTotalSize(selectedElementsData, axis);
    const spacing = calculateSpacing(selectedElementsData, totalSize, axis);

    // Initialize the current position and object for storing repositioned elements
    let currentPosition = getExtremePosition(
      selectedElementsData[0],
      axis,
      true
    );
    const repositionedElements = {};

    // Distribute elements
    selectedElementsData.forEach(element => {
      if (element.type === "group") {
        const groupElements = element.elementsOrder.map(id =>
          this.getElement(id)
        );
        const { minExtreme, maxExtreme } = getGroupBoundingBox(
          groupElements,
          axis
        );
        const groupSize = maxExtreme - minExtreme;
        const groupOffset = currentPosition - element[positionProperty];

        groupElements.forEach(groupElement => {
          groupElement[positionProperty] += groupOffset;
          repositionedElements[groupElement.uniqueId] = groupElement;
        });

        currentPosition += groupSize + spacing;
      } else {
        const minPosition = getExtremePosition(element, axis, true);
        const maxPosition = getExtremePosition(element, axis, false);
        const elementSize = maxPosition - minPosition;

        element[positionProperty] =
          currentPosition - minPosition + element[positionProperty];
        repositionedElements[element.uniqueId] = element;

        currentPosition += elementSize + spacing;
      }
    });

    // Update the elements object with repositioned elements
    const updatedElements = {
      ...this.elements,
      ...repositionedElements
    };

    return new Design({
      ...this,
      elements: updatedElements,
      version: this.version + 1
    });
  }

  alignElements(selectedElements, alignment) {
    let updatedElements = cloneDeep(this.elements);
    const _selectedElements = selectedElements.map(element =>
      this.getElement(element.itemId)
    );
    const { LEFT, CENTER, RIGHT, TOP, MIDDLE, BOTTOM } = elementAlignmentValues;
    const isXAxisAlignment = [LEFT, CENTER, RIGHT].includes(alignment);
    // define the lowest value and the highest value based on the alignment axis
    // to determine the overall width of the selected elements
    let lowestValuesArr, highestValuesArr;
    if (isXAxisAlignment) {
      lowestValuesArr = getElementsLeftValues(_selectedElements);
      highestValuesArr = getElementsRightValues(_selectedElements);
    } else {
      lowestValuesArr = getElementsTopValues(_selectedElements);
      highestValuesArr = getElementsBottomValues(_selectedElements);
    }
    const lowestValue = Math.min(...lowestValuesArr);
    const highestValue = Math.max(...highestValuesArr);

    const elementsExtremeValues = {
      lowestValue,
      highestValue
    };

    // perform alignment update on each selected element
    // return updatedElements with existing elements, as well as updated selected elements with
    // new positional values
    selectedElements.forEach(element => {
      let updatedElementObjects;
      switch (alignment) {
        case TOP:
        case LEFT: {
          updatedElementObjects = alignElementsAtMinimumOrigin(
            this,
            element.itemId,
            lowestValue,
            alignment
          );
          break;
        }
        case MIDDLE:
        case CENTER: {
          updatedElementObjects = alignElementsAtCenterOrigin(
            this,
            element.itemId,
            elementsExtremeValues,
            alignment
          );
          break;
        }
        case BOTTOM:
        case RIGHT: {
          updatedElementObjects = alignElementsAtMaximumOrigin(
            this,
            element.itemId,
            elementsExtremeValues,
            alignment
          );
          break;
        }
        default:
          break;
      }
      updatedElements = {
        ...updatedElements,
        ...updatedElementObjects
      };
    });

    return new Design({
      ...this,
      elements: updatedElements,
      version: this.version + 1
    });
  }

  rotateElementsAroundCenter({ selectedElements, angle }) {
    let updatedElements = cloneDeep(this.elements);
    const _selectedElements = selectedElements.map(element =>
      this.getElement(element.itemId)
    );

    const selectionBounding = getElementsBox(_selectedElements);
    const selectionBoundingCenter = {
      x: selectionBounding.left + selectionBounding.width / 2,
      y: selectionBounding.top + selectionBounding.height / 2
    };

    _selectedElements.forEach(element => {
      const elementCenter = {
        x: element.left + element.width / 2,
        y: element.top + element.height / 2
      };

      const rotatedElementCenter = rotatePoint(
        elementCenter.x,
        elementCenter.y,
        selectionBoundingCenter.x,
        selectionBoundingCenter.y,
        angle || 0
      );

      updatedElements[element.uniqueId] = {
        ...updatedElements[element.uniqueId],
        ...{
          left: rotatedElementCenter.x - element.width / 2,
          top: rotatedElementCenter.y - element.height / 2,
          angle: angle * -1
        }
      };
    });
  }

  moveGroup(selectedGroup, alignmentChange = 0, alignmentOrigin = "left") {
    const selectedGroupUpdated = {};

    selectedGroup.forEach(element => {
      selectedGroupUpdated[element.uniqueId] = {
        ...element,
        [alignmentOrigin]: element[alignmentOrigin] + alignmentChange
      };
    });

    return selectedGroupUpdated;
  }

  moveElementFromGroupToPage({ elementId, left, top, fromPage, toPage }) {
    const element = this.elements[elementId];
    const groupId = element.groupId;

    const groupElement = this.elements[groupId];

    const elementsUpdated = {
      ...this.elements,
      [elementId]: {
        ...this.elements[elementId],
        left: left,
        top: top,
        groupId: null
      },
      [groupId]: groupElement.deleteItem(elementId)
    };

    const toPageElementsUpdated = this.pages[toPage].elementsOrder.concat(
      elementId
    );
    const quickInputIndex = this.pages[fromPage].quickInputOrder.indexOf(
      elementId
    );
    const fromPageElementsUpdated = removeItem(
      this.pages[fromPage].quickInputOrder,
      quickInputIndex
    );
    const toPageQuickInputOrderUpdated = this.pages[
      toPage
    ].quickInputOrder.concat(elementId);

    const pagesUpdated = {
      ...this.pages,
      [fromPage]: {
        ...this.pages[fromPage],
        quickInputOrder: fromPageElementsUpdated
      },
      [toPage]: {
        ...this.pages[toPage],
        elementsOrder: toPageElementsUpdated,
        quickInputOrder: toPageQuickInputOrderUpdated
      }
    };

    return new Design({
      ...this,
      pages: pagesUpdated,
      elements: elementsUpdated,
      version: this.version + 1
    });
  }

  moveSelectionAcrossPages(args) {
    const gutterZoomed = canvasMargin * 2 * args.zoom;
    const pageAndGutterZoomedSize = args.canvasHeight + gutterZoomed;

    const referenceValue = getElementsBox(args.selectedElements).top;

    let top = referenceValue * args.zoom + args.delta.y;

    const numberOfPagesMoved = Math.abs(args.fromPageIndex - args.toPageIndex);

    const pagesMovedOffet = pageAndGutterZoomedSize * numberOfPagesMoved;

    const directionFactor =
      referenceValue * args.zoom + args.verticalScrollDiff + args.delta.y < 0
        ? 1
        : -1;

    const moveTotalDistance =
      top + args.verticalScrollDiff + pagesMovedOffet * directionFactor;

    const topDiff = moveTotalDistance / args.zoom - referenceValue;

    /* Update the elements */

    const selectedElementsUpdated = {};

    const allElements = flatten(
      args.selectedElements.map(element =>
        element.type === "group" ? element.elementsOrder : element.uniqueId
      )
    );

    allElements.forEach(elementId => {
      const elementObj = this.getElement(elementId);

      selectedElementsUpdated[elementId] = elementObj.move({
        top: topDiff,
        left: args.left
      });
    });

    const pagesCache = {};
    const groupsCache = {};

    const getPage = pageId => {
      pagesCache[pageId] = pagesCache[pageId] || this.pages[pageId];

      return pagesCache[pageId];
    };

    const getGroup = groupId => {
      groupsCache[groupId] = groupsCache[groupId] || this.getElement(groupId);

      return groupsCache[groupId];
    };

    /*  remove elements from page/group */
    args.selectedElements.forEach(element => {
      if (element.groupId) {
        const group = getGroup(element.groupId);

        groupsCache[group.uniqueId] = group.deleteItem(element.uniqueId);

        if (isQuickInputElement(element.type)) {
          const page = getPage(args.fromPageId);
          const quickInputIndex = page.quickInputOrder.indexOf(
            element.uniqueId
          );
          const quickInputOrderUpdated = removeItem(
            page.quickInputOrder,
            quickInputIndex
          );
          pagesCache[args.fromPageId] = {
            ...page,
            quickInputOrder: quickInputOrderUpdated
          };
        }
      } else {
        const page = getPage(args.fromPageId);
        const elementIndex = page.elementsOrder.indexOf(element.uniqueId);
        const elementsUpdated = removeItem(page.elementsOrder, elementIndex);

        let quickInputOrderUpdated = page.quickInputOrder.filter(
          id => id !== element.uniqueId
        );
        if (element.type === "group") {
          const groupElementIds = element.elementsOrder;
          quickInputOrderUpdated = quickInputOrderUpdated.filter(
            id => !groupElementIds.includes(id)
          );
        }

        pagesCache[args.fromPageId] = {
          ...page,
          elementsOrder: elementsUpdated,
          quickInputOrder: quickInputOrderUpdated
        };
      }
    });

    /* insert elements in toPage */
    args.selectedElements.forEach(element => {
      const page = getPage(args.toPageId);

      const elementsUpdated = insertItem(
        page.elementsOrder,
        page.elementsOrder.length,
        element.uniqueId
      );

      let quickInputOrderUpdated = [...page.quickInputOrder];

      if (isQuickInputElement(element.type))
        quickInputOrderUpdated.push(element.uniqueId);

      if (element.type === "group") {
        const textboxElementIds = element.elementsOrder.filter(id =>
          isQuickInputElement(this.elements[id].type)
        );
        quickInputOrderUpdated = [
          ...quickInputOrderUpdated,
          ...textboxElementIds
        ];
      }

      pagesCache[args.toPageId] = {
        ...page,
        elementsOrder: elementsUpdated,
        quickInputOrder: quickInputOrderUpdated
      };
    });

    Object.values(selectedElementsUpdated).forEach(elementSelected => {
      const animationData = getAnimationData({
        ...elementSelected,
        pageId: args.toPageId
      });
      // add these new animationData points to the page
      animationData.forEach(dataPoint => {
        pagesCache[args.fromPageId] = {
          ...pagesCache[args.fromPageId],
          animatedElements: omit(
            pagesCache[args.fromPageId].animatedElements,
            dataPoint.animationDataKey
          )
        };

        pagesCache[args.toPageId] = {
          ...pagesCache[args.toPageId],
          animatedElements: {
            ...pagesCache[args.toPageId].animatedElements,
            ...animationData.reduce(
              (accumulator, dataPoint) => ({
                ...accumulator,
                [dataPoint.animationDataKey]: dataPoint
              }),
              {}
            )
          }
        };
      });
    });

    return new Design({
      ...this,
      pages: { ...this.pages, ...pagesCache },
      elements: {
        ...this.elements,
        ...selectedElementsUpdated,
        ...groupsCache
      },
      version: this.version + 1
    });
  }

  moveSelection(moveDetails) {
    const { fromPageId, toPageId, selectedElements, top, left } = moveDetails;

    if (fromPageId !== toPageId) {
      return this.moveSelectionAcrossPages(moveDetails);
    }

    const selectedElementsUpdated = {};

    /* replaces group with its elements */
    const allElements = flatten(
      selectedElements.map(element =>
        element.type === "group" ? element.elementsOrder : element.uniqueId
      )
    );

    allElements.forEach(elementId => {
      const elementObj = this.getElement(elementId);

      selectedElementsUpdated[elementId] = elementObj.move({ top, left });
    });

    return new Design({
      ...this,
      elements: {
        ...this.elements,
        ...selectedElementsUpdated
      },
      version: this.version + 1
    });
  }

  moveElementToPage({ elementId, left, top, fromPage, toPage }) {
    const element = this.elements[elementId];
    if (element.groupId) {
      return this.moveElementFromGroupToPage({
        elementId,
        left,
        top,
        fromPage,
        toPage
      });
    }

    // handle switching ids of page elementsOrder and quickInputOrder
    const handleMoveIdBetweenPages = (id, arr) => {
      const index = this.pages[fromPage][arr].indexOf(id);

      if (index === -1) {
        return [this.pages[fromPage][arr], this.pages[toPage][arr]];
      }

      const fromPageElementsUpdated = removeItem(
        this.pages[fromPage][arr],
        index
      );
      const toPageElementsUpdated = this.pages[toPage][arr].concat(id);

      return [fromPageElementsUpdated, toPageElementsUpdated];
    };

    const [fromPageUpdated, toPageUpdated] = handleMoveIdBetweenPages(
      elementId,
      "elementsOrder"
    );
    const [
      fromPageQuickInputUpdated,
      toPageQuickInputUpdated
    ] = handleMoveIdBetweenPages(elementId, "quickInputOrder");

    const pagesUpdated = {
      ...this.pages,
      [fromPage]: {
        ...this.pages[fromPage],
        elementsOrder: fromPageUpdated,
        quickInputOrder: fromPageQuickInputUpdated
      },
      [toPage]: {
        ...this.pages[toPage],
        elementsOrder: toPageUpdated,
        quickInputOrder: toPageQuickInputUpdated
      }
    };

    if (
      element.duration ||
      (element.imageInstructions &&
        element.imageInstructions.some(instruction => !!instruction.duration))
    ) {
      const animationData = getAnimationData({ ...element, pageId: toPage });
      // remove animatedElement from previous page
      pagesUpdated[fromPage] = {
        ...pagesUpdated[fromPage],
        animatedElements: omit(
          pagesUpdated[fromPage].animatedElements,
          animationData.map(data => data.animationDataKey)
        )
      };
      // add animatedElement to new page
      pagesUpdated[toPage] = {
        ...pagesUpdated[toPage],
        animatedElements: {
          ...pagesUpdated[toPage].animatedElements,
          ...animationData.reduce(
            (accumulator, dataPoint) => ({
              ...accumulator,
              [dataPoint.animationDataKey]: dataPoint
            }),
            {}
          )
        }
      };
    }

    const elementsUpdated = {
      ...this.elements,
      [elementId]: {
        ...this.elements[elementId],
        left: left,
        top: top,
        groupId: null
      }
    };

    return new Design({
      ...this,
      pages: pagesUpdated,
      elements: elementsUpdated,
      version: this.version + 1
    });
  }

  deletePage(pageIndex) {
    const pageId = this.pagesOrder[pageIndex];

    let pageElements = [];
    const updatedPages = omit(cloneDeep(this.pages), pageId);

    // In case one of the page elements is a group,
    // also get the group elements ids
    this.pages[pageId].elementsOrder.forEach(elementId => {
      pageElements.push(elementId);

      const element = this.getElement(elementId);

      if (element.type === "group") {
        pageElements = pageElements.concat(element.elementsOrder);
      }
    });

    const isDeletedPageAnimated =
      this.pages[pageId].animatedElements &&
      Object.keys(this.pages[pageId].animatedElements).length > 0;
    const isAnyOtherPageAnimated = Object.values(updatedPages).some(
      page =>
        page.animatedElements && Object.keys(page.animatedElements).length > 0
    );

    // check if we are deleting the last animated page
    if (isDeletedPageAnimated && !isAnyOtherPageAnimated) {
      // remove the duration properties from all other pages
      Object.keys(updatedPages).forEach(pageKey => {
        updatedPages[pageKey] = omit(updatedPages[pageKey], "duration");
      });
    }

    return new Design({
      ...this,
      pages: updatedPages,
      elements: omit(this.elements, pageElements),
      pagesOrder: removeItem(this.pagesOrder, pageIndex),
      version: this.version + 1
    });
  }

  /* TODO: REFACTOR ASAP */
  async addPage(pages) {
    return new Promise(async (resolve, _reject) => {
      let svgUrls = [];
      let newElements = {};
      let newPageData = {};
      let pagesOrder = this.pagesOrder;

      let isNewPagesAnimated = false;

      const processPage = async (designData, index) => {
        const pageIndex =
          Object.keys(this.pages).map(id => this.pages[id]).length +
          (index + 1);
        const newPageId = designData.pageId;

        const elements = Object.keys(designData.elements).map(
          id => designData.elements[id]
        );
        svgUrls = svgUrls.concat(
          elements
            .filter(element => element.type === "vector")
            .map(element => element.src)
        );

        elements.forEach(element => {
          newElements[element.uniqueId] = element;
        });

        // when a page is animated set flag
        if (designData.duration) isNewPagesAnimated = true;

        newPageData[newPageId] = {
          backgroundColor: designData.backgroundColor || "#ffffff",
          elementsOrder: designData.elementsOrder,
          animatedElements: designData.animatedElements || {},
          duration: designData.duration,
          quickInputOrder: designData.quickInputOrder,
          openQuickInput: isDesignOpenQuickInput(this)
        };

        pagesOrder = insertItem(pagesOrder, pageIndex, newPageId);
      };

      const designProcessed = await designAdapter({
        pages: pages.map(pageData => pageData.data),
        restrictions: [],
        template: { code: null }
      });

      await Promise.all(
        pages.map(async (pageData, index) => {
          const newPageId = uuid();
          const designData = designProcessed.clonePage(index, newPageId);
          await processPage(designData, index);
        })
      );

      // when a new page is added that has a duration we want to ensure all pages get a duration
      if (isNewPagesAnimated) {
        Object.keys(this.pages).forEach(pageId => {
          const pageClone = { ...this.pages[pageId] };
          if (!pageClone.duration) {
            // add a duration to the page since this is now an animated design
            pageClone.duration = ANIMATED_DESIGN_PAGE_DEFAULT_DURATION;
            newPageData[pageId] = pageClone;
          }
        });
      }

      const design = new Design({
        ...this,
        pages: { ...this.pages, ...newPageData },
        elements: { ...this.elements, ...newElements },
        pagesOrder,
        version: this.version + 1
      });

      Promise.all(fetchSvgs(svgUrls)).then(_response => resolve(design));
    });
  }

  /* TODO: REFACTOR ASAP */
  replacePage(pages) {
    return new Promise(async (resolve, _reject) => {
      let svgUrls = [];

      const designProcessed = await designAdapter({
        pages: pages.map(page => page.data),
        restrictions: [],
        template: { code: null }
      });

      Object.keys(designProcessed.elements).forEach(elementId => {
        const element = designProcessed.elements[elementId];
        if (element.type === "vector") {
          svgUrls.push(element.src);
        }
      });

      let pagesOrder = Object.keys(designProcessed.pages);
      if (pages.length < pagesOrder.length) {
        // Remove any additional pages outside the number of supplied pages
        pagesOrder.forEach(pageId => {
          const index = pagesOrder.indexOf(pageId);
          if (index < pages.length) return;

          this.deletePage(index);
          pagesOrder = pagesOrder.filter(id => id !== pageId);
        });
      }

      const design = new Design({
        ...this,
        pages: designProcessed.pages,
        elements: designProcessed.elements,
        pagesOrder,
        version: this.version + 1
      });

      Promise.all(fetchSvgs(svgUrls)).then(_response => resolve(design));
    });
  }

  copyPage(pageIndex) {
    const pageToBeCopiedId = this.pagesOrder[pageIndex];
    const pageToBeCopied = this.pages[pageToBeCopiedId];

    const newPageId = uuid();

    const clonedElements = pageToBeCopied.elementsOrder.map(e =>
      this.getElement(e).clone()
    );

    const elementsOrder = clonedElements.map(
      clonedElement => clonedElement[0].uniqueId
    );

    const newElements = {};

    const flattenElements = flatten(clonedElements);

    const newPageQuickInputOrder = [];

    flattenElements.forEach(clonedElement => {
      if (isQuickInputElement(clonedElement.type)) {
        newPageQuickInputOrder.push(clonedElement.uniqueId);
      }
      newElements[clonedElement.uniqueId] = clonedElement;
    });

    const newPage = {
      backgroundColor: pageToBeCopied.backgroundColor,
      elementsOrder: elementsOrder,
      duration: pageToBeCopied.duration,
      animatedElements: pageToBeCopied.animatedElements || {},
      quickInputOrder: newPageQuickInputOrder || [],
      openQuickInput: pageToBeCopied.openQuickInput
    };

    Object.values(newElements).forEach(element => {
      const animationData = getAnimationData({ ...element, pageId: newPageId });
      // add these new animationData points to the page
      newPage.animatedElements = {
        ...(newPage.animatedElements || {}),
        ...animationData.reduce(
          (accumulator, dataPoint) => ({
            ...accumulator,
            [dataPoint.animationDataKey]: dataPoint
          }),
          {}
        )
      };
    });

    return new Design({
      ...this,
      pages: { ...this.pages, [newPageId]: newPage },
      elements: { ...this.elements, ...newElements },
      pagesOrder: insertItem(this.pagesOrder, pageIndex + 1, newPageId),
      version: this.version + 1
    });
  }

  clonePage(pageIndex, newPageId) {
    const pageToBeCopiedId = this.pagesOrder[pageIndex];
    const pageToBeCopied = this.pages[pageToBeCopiedId];

    const clonedElements = pageToBeCopied.elementsOrder.map(elementId =>
      this.getElement(elementId).clone()
    );

    const elementsOrder = clonedElements.map(
      clonedElement => clonedElement[0].uniqueId
    );

    const quickInputElementIds = [];

    clonedElements.forEach(elementArray => {
      elementArray.forEach(el => {
        if (isQuickInputElement(el?.type)) {
          quickInputElementIds.push(el.uniqueId);
        }
      });
    });

    const newElements = {};

    const flattenElements = flatten(clonedElements);

    flattenElements.forEach(clonedElement => {
      newElements[clonedElement.uniqueId] = clonedElement;
    });

    const animatedElements = {};

    flattenElements.forEach(element => {
      // ensure we get all animation data mapped out for cloned elements
      const animationData = getAnimationData(element, newPageId);
      if (animationData) {
        animationData.forEach(data => {
          animatedElements[data.animationDataKey] = data;
        });
      }
    });

    return {
      backgroundColor: pageToBeCopied.backgroundColor,
      elements: newElements,
      elementsOrder: elementsOrder,
      animatedElements,
      duration: pageToBeCopied.duration,
      pageId: newPageId,
      quickInputOrder: quickInputElementIds
    };
  }

  updateElementAttribute({ elementId, attribute, value }) {
    const element = this.getElement(elementId);

    return new Design({
      ...this,
      elements: {
        ...this.elements,
        [element.uniqueId]: {
          ...element,
          [attribute]: value
        }
      },
      version: this.version + 1
    });
  }

  /**
   * @desc Updates multiple elements with the same attribute updates in one version increase (mass update action)
   * @param {Object} elementUpdates - an object of form { elementId: { attributeKey: attributeValue } } used to update multiple elements at the same time
   * @returns Design
   */
  updateElementsAttribute({ elementsId, attributes }) {
    const elements = elementsId.map(elementId => this.getElement(elementId));

    const elementsUpdated = {};

    elements.forEach(
      element =>
        (elementsUpdated[element.uniqueId] = element.updateAttributes(
          attributes
        ))
    );

    return new Design({
      ...this,
      elements: {
        ...this.elements,
        ...elementsUpdated
      },
      version: this.version + 1
    });
  }

  updateTextboxDisplayValues(smartTextState) {
    const designData = cloneDeep(this);

    const shouldUpdateElement = (element, smartTextState) => {
      if (element.type === "table2") return shouldUpdateTable2Element(element);

      if (
        !isTextElement(element.type) ||
        !hasSmartTextPlaceholders(element.value)
      ) {
        return false;
      }

      const updatedValue = updateSmartTextPlaceholder(
        element.value,
        smartTextState
      );
      return updatedValue !== element.displayValue;
    };
    const shouldUpdateTable2Element = element => {
      return Object.values(element.cells).some(
        cell =>
          hasSmartTextPlaceholders(cell.value) &&
          updateSmartTextPlaceholder(cell.value, smartTextState) !==
            cell.displayValue
      );
    };

    const getElementsToUpdate = (designData, smartTextState) => {
      const elementsToUpdate = [];

      Object.keys(designData.pages).forEach(pageId => {
        const pageElements = designData.pages[pageId].elementsOrder;

        if (!(pageElements && pageElements.length)) return;

        pageElements.forEach(elmtId => {
          const elementData = designData.elements[elmtId];

          if (elementData?.type === "group") {
            elementData.elementsOrder.forEach(elementId => {
              const groupElementData = designData.elements[elementId];
              if (shouldUpdateElement(groupElementData, smartTextState)) {
                elementsToUpdate.push({ ...groupElementData, pageId });
              }
            });
          } else if (
            elementData &&
            shouldUpdateElement(elementData, smartTextState)
          ) {
            elementsToUpdate.push({ ...elementData, pageId });
          }
        });
      });

      return elementsToUpdate;
    };

    const elementsToUpdate = getElementsToUpdate(designData, smartTextState);
    const updatedElements = {};

    if (elementsToUpdate.length) {
      elementsToUpdate.forEach(element => {
        if (element.type === "table2") {
          const updatedCells = cloneDeep(element.cells);
          Object.values(element.cells).forEach(cell => {
            if (hasSmartTextPlaceholders(cell.value)) {
              updatedCells[cell.uniqueId] = {
                ...updatedCells[cell.uniqueId],
                displayValue: updateSmartTextPlaceholder(
                  cell.value,
                  smartTextState
                )
              };
            }
          });

          const updatedTable = table2CellDimensionUpdater({
            ...element,
            cells: updatedCells
          });
          const { height, cells } = table2HeightUpdater(updatedTable);

          updatedElements[element.uniqueId] = {
            ...element,
            cells,
            height
          };
        } else {
          updatedElements[element.uniqueId] = {
            ...element,
            displayValue: updateSmartTextPlaceholder(
              element.value,
              smartTextState
            )
          };
        }
      });
    }

    const updatedDesign = new Design({
      ...this,
      elements: {
        ...this.elements,
        ...updatedElements
      },
      version: this.version + 1
    });

    return {
      updatedDesign,
      updatedElements
    };
  }

  /**
   * @desc Updates multiple elements with different attribute updates in one version increase (mass individual update action)
   * @param {Object} elementUpdates - an object of form { elementId: { attributeKey: attributeValue } } used to update multiple elements at the same time
   * @returns Design
   */
  updateElementsAttributes({ elementUpdates }) {
    if (!elementUpdates || Object.keys(elementUpdates).length <= 0) return;

    const elementsUpdated = {};

    // process each element update
    Object.keys(elementUpdates).forEach(elementId => {
      const elementUpdate = elementUpdates[elementId];
      const element = this.getElement(elementId);
      if (!element) return;
      elementsUpdated[element.uniqueId] = element.updateAttributes(
        elementUpdate
      );
    });

    return new Design({
      ...this,
      elements: {
        ...this.elements,
        ...elementsUpdated
      },
      version: this.version + 1
    });
  }

  deleteElements(elements) {
    const pagesCache = {};
    const groupsCache = {};
    let elementsToBeDeleted = [];

    const getGroup = groupId => {
      groupsCache[groupId] = groupsCache[groupId] || this.getElement(groupId);

      return groupsCache[groupId];
    };

    const getPage = pageId => {
      pagesCache[pageId] = pagesCache[pageId] || this.pages[pageId];

      return pagesCache[pageId];
    };

    elements.forEach(elementSelected => {
      elementsToBeDeleted.push(elementSelected.itemId);
      const element = this.getElement(elementSelected.itemId);
      const page = getPage(elementSelected.pageId);

      if (elementSelected.groupId) {
        const group = getGroup(elementSelected.groupId);
        groupsCache[group.uniqueId] = group.deleteItem(elementSelected.itemId);
        pagesCache[elementSelected.pageId] = { ...page };
      } else {
        const elementIndex = page.elementsOrder.indexOf(elementSelected.itemId);
        const elementsUpdated = removeItem(page.elementsOrder, elementIndex);

        pagesCache[elementSelected.pageId] = {
          ...page,
          elementsOrder: elementsUpdated
        };
      }

      if (element.type === "group") {
        elementsToBeDeleted = elementsToBeDeleted.concat(element.elementsOrder);
        // remove group textbox elements from page quickInputOrder
        const groupTextboxIds = element.elementsOrder.filter(id =>
          isQuickInputElement(this.getElement(id).type)
        );
        const pageQuickInputOrder = [
          ...pagesCache[elementSelected.pageId].quickInputOrder
        ];
        const updatedQuickInputOrder = pageQuickInputOrder.filter(
          id => !groupTextboxIds.includes(id)
        );
        pagesCache[
          elementSelected.pageId
        ].quickInputOrder = updatedQuickInputOrder;
      }

      // remove individual elements from page quickInputOrder
      if (isQuickInputElement(element.type)) {
        const elementQuickInputOrderIndex = page.quickInputOrder.indexOf(
          elementSelected.itemId
        );
        const updatedQuickInputOrder = removeItem(
          page.quickInputOrder,
          elementQuickInputOrderIndex
        );
        pagesCache[
          elementSelected.pageId
        ].quickInputOrder = updatedQuickInputOrder;
      }

      // handle adding an animated element to the design
      if (
        element.duration ||
        (element.imageInstructions &&
          element.imageInstructions.some(
            instruction => !!instruction.duration
          )) ||
        (element.maskImage && element.maskImage.duration)
      ) {
        // this is an animated element
        const animationData = getAnimationData({
          ...element,
          pageId: elementSelected.pageId
        });
        animationData.forEach(dataPoint => {
          pagesCache[elementSelected.pageId] = {
            ...pagesCache[elementSelected.pageId],
            animatedElements: omit(
              pagesCache[elementSelected.pageId].animatedElements,
              dataPoint.animationDataKey
            )
          };
        });
      }
    });

    const elementsUpdated = omit(this.elements, elementsToBeDeleted);

    return new Design({
      ...this,
      pages: { ...this.pages, ...pagesCache },
      elements: {
        ...elementsUpdated,
        ...groupsCache
      },
      version: this.version + 1
    });
  }

  insertElement(elementData, suggestedId = uuid(), pageId) {
    const element = this.createElement(elementData);
    const elementCopy = element.clone(suggestedId)[0];

    const top = () => {
      if (isNil(elementCopy.top)) {
        return (this.height - elementCopy.height) / 2; // will center the element
      } else {
        return elementCopy.top;
      }
    };

    const left = () => {
      if (isNil(elementCopy.left)) {
        return (this.width - elementCopy.width) / 2; // will center the element
      } else {
        return elementCopy.left;
      }
    };

    elementCopy.top = top();
    elementCopy.left = left();
    elementCopy.angle = 0;
    elementCopy.opacity = 1;
    elementCopy.scaleY = 1;
    elementCopy.scaleX = 1;
    elementCopy.filter = null;

    const page = this.pages[pageId];

    const pagesUpdated = {
      ...this.pages,
      [pageId]: {
        ...page,
        elementsOrder: insertItem(
          page.elementsOrder,
          page.elementsOrder.length,
          suggestedId
        )
      }
    };

    // handling quickInputOrder updates
    if (isTextElement(elementData.type) || elementData.type === "qrcode") {
      const updatedQuickInputOrder = insertItem(
        page.quickInputOrder,
        page.quickInputOrder.length,
        suggestedId
      );
      pagesUpdated[pageId].quickInputOrder = updatedQuickInputOrder;
    }

    // handle adding an animated element to the design
    if (elementCopy.duration) {
      // this is an animated element
      const animationData = getAnimationData({ ...elementCopy, pageId });
      animationData.forEach(dataPoint => {
        pagesUpdated[pageId].animatedElements[
          dataPoint.animationDataKey
        ] = dataPoint;
      });
    }

    return new Design({
      ...this,
      pages: pagesUpdated,
      elements: {
        ...this.elements,
        [suggestedId]: elementCopy
      },
      version: this.version + 1
    });
  }

  setElementAsBackground(elementId) {
    const element = this.getElement(elementId);
    const pageId = this.getElementPageId(element.uniqueId);
    const page = this.pages[pageId];

    const elementsUpdated = {};

    const pageHeight = this.height;
    const pageWidth = this.width;

    /* We first check if the page already has a background */
    const pageBackgroundElement = this.getPageBackgroundElement(pageId);

    if (pageBackgroundElement) {
      elementsUpdated[
        pageBackgroundElement.uniqueId
      ] = pageBackgroundElement.convertToAssetTypeAndCenter({
        pageHeight,
        pageWidth
      });
    }

    /* convert the element to background and scale to cover the whole page */
    elementsUpdated[element.uniqueId] = element.convertToBackgroundType({
      pageHeight,
      pageWidth
    });

    /* Move element to bottom */
    const elementIndex = page.elementsOrder.indexOf(element.uniqueId);
    const pageUpdated = {
      ...page,
      elementsOrder: moveItem(page.elementsOrder, elementIndex, 0)
    };

    return new Design({
      ...this,
      elements: {
        ...this.elements,
        ...elementsUpdated
      },
      pages: {
        ...this.pages,
        [pageId]: pageUpdated
      },
      version: this.version + 1
    });
  }

  setElementAsForeground(elementId) {
    const element = this.getElement(elementId);
    const pageId = this.getElementPageId(element.uniqueId);
    const page = this.pages[pageId];

    const elementsUpdated = {};

    const pageHeight = this.height;
    const pageWidth = this.width;

    /* move element to top */
    const elementIndex = page.elementsOrder.indexOf(element.uniqueId);
    const pageUpdated = {
      ...page,
      elementsOrder: moveItem(
        page.elementsOrder,
        elementIndex,
        page.elementsOrder.length
      )
    };

    elementsUpdated[element.uniqueId] = element.convertToAssetTypeAndCenter({
      pageHeight,
      pageWidth
    });

    return new Design({
      ...this,
      elements: {
        ...this.elements,
        ...elementsUpdated
      },
      pages: {
        ...this.pages,
        [pageId]: pageUpdated
      },
      version: this.version + 1
    });
  }

  getElementPageId(elementId) {
    let elementPageId = undefined;
    for (let pageId in this.pages) {
      if (this.pages[pageId].elementsOrder.includes(elementId)) {
        elementPageId = pageId;
      } else {
        const pageElements = this.pages[
          pageId
        ].elementsOrder.map(pageElementId => this.getElement(pageElementId));
        const pageGroups = pageElements.filter(
          pageElement => pageElement.type === "group"
        );
        if (pageGroups.length) {
          // eslint-disable-next-line no-loop-func
          pageGroups.forEach(pageGroup => {
            if (pageGroup.elementsOrder.includes(elementId)) {
              elementPageId = pageId;
            }
          });
        }
      }
    }
    return elementPageId;
  }

  getPageBackgroundElement(pageId) {
    const page = this.pages[pageId];

    if (!page.elementsOrder.length) {
      return null;
    }

    const bottomElement = this.getElement(page.elementsOrder[0]);

    if (bottomElement.type !== "background") {
      return null;
    }

    return bottomElement;
  }

  isLockedToBrandImagesOnly() {
    /* Due to the evolution of the app, this naming 'addPhotoElement',
     * doesn't make much sense for the functionality it provides. But the
     * attribute is embedded in the all designs json, and changing it now
     * would require a backend migration through all the designs. */
    return this.restrictions.includes("addPhotoElement");
  }

  unassignSmartImages({ selectedElements, domId }) {
    const elementsUpdated = {};

    selectedElements.forEach(({ itemId }) => {
      const element = this.getElement(itemId);

      if (!element) {
        console.warn(`Element with itemId ${itemId} not found.`);
        return;
      }

      elementsUpdated[element.uniqueId] = element.disconnectSmartImage(domId);
    });

    return new Design({
      ...this,
      elements: {
        ...this.elements,
        ...elementsUpdated
      },
      version: this.version + 1
    });
  }

  updatePageGuides(guides, pageId, isNotIncrementingVersion) {
    // take a list for guides and add them to the assigned page

    const updatedPage = {
      ...this.pages[pageId],
      guides
    };

    return new Design({
      ...this,
      pages: {
        ...this.pages,
        [pageId]: updatedPage
      },
      version: this.version + (isNotIncrementingVersion ? 0 : 1)
    });
  }

  // replace image elements for all given elements
  async replaceImages({ selectedElements, assetElement, context }) {
    // get the elements to perform the action on
    const elements = selectedElements.map(selectedElement =>
      this.getElement(selectedElement.itemId)
    );

    // check if the target is a single grid or frame
    const isGridOrFrame =
      elements.length === 1 && ["grid", "vector"].includes(elements[0].type);

    // only allow vector and grid when they are lone results
    const typeFilter = isGridOrFrame
      ? ["image", "video", "background", "vector", "grid"]
      : ["image", "video", "background"];

    // filter out any elements that are not able to have an image replace applied to them
    const filteredElements = elements.filter(selectedElement =>
      typeFilter.includes(selectedElement.type)
    );

    if (!filteredElements.length) return this;

    const processingPromise = new Promise(resolve => {
      // loop through the elements to perform the replacement on
      const processImageReplace = async (
        elementData = filteredElements[0],
        currentDesignData = this,
        index = 0
      ) => {
        // check the element and document restrictions
        if (
          // image replace element restriction
          elementData.restrictions.includes("imageUpload") ||
          // brand images only document restriction
          (this.restrictions.includes("addPhotoElement") &&
            !(origin === "teamImage" || origin === "teamLogo"))
        ) {
          // if the action is restricted just return the current version of designData
          // check if this is the last element
          if (index < filteredElements.length - 1) {
            // call the process function again if not last element
            processImageReplace(
              filteredElements[index + 1],
              currentDesignData,
              index + 1
            );
          }
        }

        // Determine which replacement function to run depending on the element type
        let replaceFunction;
        switch (elementData.type) {
          case "grid": {
            replaceFunction = "replaceImageForGridElement";
            break;
          }
          case "vector": {
            replaceFunction = "replaceImageForVectorElement";
            break;
          }
          case "image":
          case "video":
          default: {
            replaceFunction = "replaceImageForImageElement";
            break;
          }
        }

        const designDataAfterReplace = await currentDesignData[replaceFunction](
          {
            elementId: elementData.uniqueId,
            assetElement: {
              ...assetElement,
              thumbnailUrl:
                assetElement.preservedThumbUrl || assetElement.thumbnailUrl,
              thumbSrc:
                assetElement.preservedThumbUrl || assetElement.thumbnailUrl
            },
            domId: context.selectedGridCellId || context.selectedPhotoInFrameId
          }
        );

        // check if this is the last element
        if (index < filteredElements.length - 1) {
          // call the process function again if not last element
          processImageReplace(
            filteredElements[index + 1],
            designDataAfterReplace,
            index + 1
          );
        } else {
          // is the last element, lets resolve with the designData
          return resolve(designDataAfterReplace);
        }
      };
      // do the first image replace function
      processImageReplace();
    });

    const updatedDesignData = await processingPromise;

    // check if the source is animated
    const isAnimatedSrc = isGif(assetElement.url) || isMp4(assetElement.url);

    const updatedPages = cloneDeep(this.pages);

    if (isAnimatedSrc) {
      // get the animatedElements and add them to the list
      // likely a gif being added over an image
      const elementAnimationData = filteredElements.reduce(
        (elementAccumulator, currentElement) => {
          const updatedElement = updatedDesignData.getElement(
            currentElement.uniqueId
          );
          const pageId = updatedDesignData.getElementPageId(
            currentElement.uniqueId
          );
          const animationData = getAnimationData({ ...updatedElement, pageId });
          return [...elementAccumulator, ...animationData];
        },
        []
      );

      Object.keys(this.pages).forEach(pageId => {
        const page = this.pages[pageId];
        const updatedAnimatedElements = cloneDeep(page.animatedElements);
        elementAnimationData.forEach(dataPoint => {
          if (dataPoint.pageId === pageId) {
            updatedAnimatedElements[dataPoint.animationDataKey] = dataPoint;
          }
        });

        updatedPages[pageId] = {
          ...page,
          animatedElements: updatedAnimatedElements
        };
      });
    } else {
      // run through the selectedElements and
      // check if any of them were animated previously
      let previousAnimated = [];
      selectedElements.forEach(selectedElement => {
        const _selectedElement = selectedElement.uniqueId
          ? selectedElement
          : this.getElement(selectedElement.itemId);
        const pageId = this.getElementPageId(_selectedElement.uniqueId);
        const animationData = getAnimationData({ ..._selectedElement, pageId });
        if (!!animationData) {
          let filteredAnimationData = animationData;
          const domId =
            context.selectedGridCellId || context.selectedPhotoInFrameId;
          if (domId) {
            // ensure when replacing within a grid or frame to only affect the selected cell
            filteredAnimationData = animationData.filter(
              dataPoint => dataPoint.domId === domId
            );
          }
          previousAnimated = previousAnimated.concat(filteredAnimationData);
        }
      });

      Object.keys(this.pages).forEach(pageId => {
        const page = this.pages[pageId];
        const updatedAnimatedElements = cloneDeep(page.animatedElements);
        previousAnimated.forEach(dataPoint => {
          if (dataPoint.pageId === pageId) {
            delete updatedAnimatedElements[dataPoint.animationDataKey];
          }
        });
        updatedPages[pageId] = {
          ...page,
          animatedElements: updatedAnimatedElements
        };
      });
    }
    // use a new Design as return to avoid multiple version updates
    return new Design({
      ...this,
      elements: {
        ...updatedDesignData.elements
      },
      pages: updatedPages,
      version: this.version + 1
    });
  }

  getElementBuilder(assetElement) {
    const assetType = getSourceTypeFromAsset(assetElement);
    switch (assetType) {
      case "video": {
        return this.VideoElementBuilder;
      }
      case "image":
      default: {
        return this.ImageElementBuilder;
      }
    }
  }

  // replace an image source for an image element
  async replaceImageForImageElement({ elementId, assetElement }) {
    const elementData = { ...this.getElement(elementId) };

    const ElementBuilder = this.getElementBuilder(assetElement);

    // build the image element for the action with design dimensions
    const imageElementBuilderPromise = ElementBuilder(assetElement);

    // calculate the preview values for imageReplace using assetElement
    const previewImageData = await calculateImageReplacePreview({
      imageInstruction: {
        imageElementBuilderPromise
      },
      elementData,
      pageDimensions: pick(this, ["height", "width"])
    });

    let assetType = getSourceTypeFromAsset(assetElement);

    // when the target element is a background type we should replace with a background type
    if (elementData.type === "background" && assetType === "image") {
      assetType = "background";
    }

    // build the updated element based on the preview data we just made
    const updatedElement = getUpdatedImageFromPreview({
      elementData,
      previewImageData
    });

    // update the element in the design and return the design
    return this.updateElementsAttribute({
      elementsId: [elementData.uniqueId],
      attributes: {
        ...updatedElement,
        type: assetType,
        startOffset: undefined,
        trimDuration: undefined
      }
    });
  }

  // replace an image source for a vector photo frame element
  async replaceImageForVectorElement({
    elementId,
    assetElement,
    domId: inputDomId,
    maskSize: inputMaskSize
  }) {
    const elementData = { ...this.getElement(elementId) };

    const domId =
      inputDomId || getDefaultImageInstructionTarget(elementData).domId;

    const maskSize = inputMaskSize || getMaskSizeForVector(elementData, domId);

    const ElementBuilder = this.getElementBuilder(assetElement);

    // build the image element for the action with design dimensions
    const imageElementBuilderPromise = await ElementBuilder(assetElement);

    // get the imageInstruction to update
    const originalImageInstruction = elementData.imageInstructions.find(
      imageInstruction => imageInstruction.domId === domId
    );

    const imageElement = {
      id: assetElement.id || assetElement.mediaId,
      originalSrc: assetElement.url,
      thumbSrc: assetElement.thumbnailUrl,
      previewSrc: assetElement.previewUrl,
      src: assetElement.previewUrl,
      price: assetElement.price,
      // Note: Image provider, Easil or Getty, not image src.
      source: assetElement.source,
      size: getImageSize(assetElement),
      mediaId: getImageMediaId(assetElement),
      imageElementBuilderPromise,
      ...imageElementBuilderPromise
    };

    // calculate the new imageInstruction object
    const updatedImageInstruction = await calculateUpdatedVectorImageInstructions(
      {
        imageInstruction: {
          ...imageElement,
          startOffset: undefined,
          trimDuration: undefined
        },
        originalImageInstruction,
        maskSize
      }
    );

    // remove smart image label when being replaced with not-so-smart image
    if (!assetElement.label) {
      delete updatedImageInstruction.label;
      delete originalImageInstruction.label;
    }

    // calculate the palette again since the src was changed
    const palette = await getPaletteFromSrc(
      updatedImageInstruction.thumbnailUrl
    );

    // build the updated image instructions array
    const updatedImageInstructions = elementData.imageInstructions.map(
      imgInstruction => {
        if (imgInstruction.domId === updatedImageInstruction.domId) {
          return {
            scaleX: 1,
            scaleY: 1,
            ...originalImageInstruction,
            ...updatedImageInstruction,
            palette
          };
        }

        return imgInstruction;
      }
    );

    return this.updateImageInstructions({
      elementId: elementData.uniqueId,
      imageInstructions: updatedImageInstructions
    });
  }

  // replace image source for grid element
  async replaceImageForGridElement({
    elementId,
    assetElement,
    domId: inputDomId
  }) {
    const elementData = { ...this.getElement(elementId) };

    const domId =
      inputDomId || getDefaultImageInstructionTarget(elementData).domId;

    const ElementBuilder = this.getElementBuilder(assetElement);

    // build the image element for the action with design dimensions
    const imageElementBuilderPromise = await ElementBuilder(assetElement);

    // get the imageInstruction to update
    const originalImageInstruction = elementData.imageInstructions.find(
      imageInstruction => imageInstruction.domId === domId
    );

    const imageElement = {
      id: assetElement.id || assetElement.mediaId,
      thumbSrc: assetElement.thumbnailUrl,
      previewSrc: assetElement.previewUrl,
      originalSrc: assetElement.url,
      src: assetElement.previewUrl,
      price: assetElement.price,
      // Note: Image provider, Easil or Getty, not image src.
      source: assetElement.source,
      size: getImageSize(assetElement),
      mediaId: getImageMediaId(assetElement),
      imageElementBuilderPromise,
      ...imageElementBuilderPromise,
      // needs the domId here to calculate the instructions correctly
      domId
    };

    // calculate the new imageInstruction object
    const updatedImageInstruction = await calculateUpdatedGridImageInstructions(
      {
        imageInstruction: {
          ...imageElement,
          startOffset: undefined,
          trimDuration: undefined
        },
        element: elementData,
        originalImageInstruction
      }
    );

    // remove smart image label when being replaced with not-so-smart image
    if (!assetElement.label) {
      delete updatedImageInstruction.label;
      delete originalImageInstruction.label;
    }

    // calculate the palette again since the src was changed
    const palette = await getPaletteFromSrc(updatedImageInstruction.previewUrl);

    // build the updated image instructions array
    const updatedImageInstructions = elementData.imageInstructions.map(
      imgInstruction => {
        if (imgInstruction.domId === updatedImageInstruction.domId) {
          return {
            scaleX: 1,
            scaleY: 1,
            ...originalImageInstruction,
            ...updatedImageInstruction,
            palette
          };
        }

        return imgInstruction;
      }
    );

    return this.updateImageInstructions({
      elementId: elementData.uniqueId,
      imageInstructions: updatedImageInstructions
    });
  }

  // replace video elements for all given elements
  async replaceVideos({ selectedElements, assetElement, context }) {
    // get the elements to perform the action on
    const elements = selectedElements.map(selectedElement =>
      this.getElement(selectedElement.itemId)
    );

    // check if the target is a single grid or frame
    const isGridOrFrame =
      elements.length === 1 && ["grid", "vector"].includes(elements[0].type);

    // only allow vector and grid when they are lone results
    const typeFilter = isGridOrFrame
      ? ["video", "background", "image", "vector", "grid"]
      : ["video", "background", "image"];

    // filter out any elements that are not able to have an image replace applied to them
    const filteredElements = elements.filter(selectedElement =>
      typeFilter.includes(selectedElement.type)
    );

    if (!filteredElements.length) return this;

    const processingPromise = new Promise(resolve => {
      // loop through the elements to perform the replacement on
      const processVideoReplace = async (
        elementData = filteredElements[0],
        currentDesignData = this,
        index = 0
      ) => {
        // check the element and document restrictions
        if (
          // image replace element restriction
          elementData.restrictions.includes("imageUpload") ||
          // brand images only document restriction
          (this.restrictions.includes("addPhotoElement") &&
            !(origin === "teamImage" || origin === "teamLogo"))
        ) {
          // if the action is restricted just return the current version of designData
          // check if this is the last element
          if (index < filteredElements.length - 1) {
            // call the process function again if not last element
            processVideoReplace(
              filteredElements[index + 1],
              currentDesignData,
              index + 1
            );
          }
        }

        // Determine which function to use for the replacement logic
        let replaceFunction;
        switch (elementData.type) {
          case "grid": {
            replaceFunction = "replaceImageForGridElement";
            break;
          }
          case "vector": {
            replaceFunction = "replaceImageForVectorElement";
            break;
          }
          case "video":
          case "image":
          default: {
            replaceFunction = "replaceImageForImageElement";
            break;
          }
        }

        const designDataAfterReplace = await currentDesignData[replaceFunction](
          {
            elementId: elementData.uniqueId,
            assetElement: {
              ...assetElement,
              thumbnailUrl:
                assetElement.preservedThumbUrl || assetElement.thumbnailUrl,
              thumbSrc:
                assetElement.preservedThumbUrl || assetElement.thumbnailUrl,
              previewUrl:
                assetElement.preservedPreviewUrl || assetElement.previewUrl,
              previewSrc:
                assetElement.preservedPreviewUrl || assetElement.previewUrl
            },
            domId: context.selectedGridCellId || context.selectedPhotoInFrameId
          }
        );

        // check if this is the last element
        if (index < filteredElements.length - 1) {
          // call the process function again if not last element
          processVideoReplace(
            filteredElements[index + 1],
            designDataAfterReplace,
            index + 1
          );
        } else {
          // is the last element, lets resolve with the designData
          return resolve(designDataAfterReplace);
        }
      };
      // do the first image replace function
      processVideoReplace();
    });

    // const updatedAnimatedElements = this.addAnimatedElements(filteredElements);
    const updatedDesignData = await processingPromise;

    const elementAnimationData = filteredElements.reduce(
      (elementAccumulator, currentElement) => {
        const updatedElement = updatedDesignData.getElement(
          currentElement.uniqueId
        );
        const pageId = updatedDesignData.getElementPageId(
          currentElement.uniqueId
        );
        const animationData = getAnimationData({ ...updatedElement, pageId });
        return [...elementAccumulator, ...animationData];
      },
      []
    );

    const updatedPages = {};
    Object.keys(this.pages).forEach(pageId => {
      const page = this.pages[pageId];
      const updatedAnimatedElements = cloneDeep(page.animatedElements);
      elementAnimationData.forEach(dataPoint => {
        if (dataPoint.pageId === pageId) {
          updatedAnimatedElements[dataPoint.animationDataKey] = dataPoint;
        }
      });

      updatedPages[pageId] = {
        ...page,
        animatedElements: updatedAnimatedElements
      };
    });

    // use a new Design as return to avoid multiple version updates
    return new Design({
      ...this,
      elements: {
        ...updatedDesignData.elements
      },
      pages: updatedPages,
      version: this.version + 1
    });
  }

  addAnimatedElements(animatedElements) {
    const newAnimatedElements = {};
    animatedElements.forEach(element => {
      newAnimatedElements[element.animationDataKey] = {
        uniqueId: element.uniqueId,
        animationDataKey: element.animationDataKey,
        duration: element.duration,
        pageId: element.pageId,
        groupId: element.groupId
      };
    });

    return {
      ...(this.animatedElements || {}),
      ...newAnimatedElements
    };
  }

  removeAnimatedElements(elementsToRemove) {
    const removeElementIds = elementsToRemove.map(element => element.id);
    const updatedAnimatedElements = omit(
      this.animatedELements,
      removeElementIds
    );
    return new Design({
      ...this,
      animatedElements: updatedAnimatedElements
    });
  }

  getAllFontFamilies() {
    const fontFamilies = new Set();
    // grab all fontFamily applicable elements
    Object.values(this.elements).forEach(element => {
      if (
        [EDITOR_ELEMENTS_MAP.TEXTBOX, EDITOR_ELEMENTS_MAP.VECTOR_TEXT].includes(
          element.type
        )
      ) {
        // this is a font family applicable element
        if (
          element.hasOwnProperty("fontFamily") &&
          !isNil(element.fontFamily)
        ) {
          // we have an element level fontFamily, add it to the set
          fontFamilies.add(element.fontFamily);
        }
        const richTextFonts = getFontFamiliesFromRichText(element.value);
        richTextFonts.forEach(rtFont => fontFamilies.add(rtFont));
      }
    });

    return Array.from(fontFamilies).map(getFontByName);
  }

  getAllElementsWithFontFamily(fontFamily) {
    const matchingElements = [];

    Object.values(this.elements).forEach(element => {
      if (element.restrictions.includes("fontFamily")) return;
      if (
        [EDITOR_ELEMENTS_MAP.TEXTBOX, EDITOR_ELEMENTS_MAP.VECTOR_TEXT].includes(
          element.type
        )
      ) {
        // font family applicable element
        if (
          element.hasOwnProperty("fontFamily") &&
          element.fontFamily === fontFamily
        ) {
          matchingElements.push(element);
        } else {
          const richTextMatches = getFontFamilyMatchesInRichText(
            element.value,
            fontFamily
          );
          richTextMatches.forEach(() => {
            matchingElements.push(element);
          });
        }
      }
      if ([EDITOR_ELEMENTS_MAP.TABLE].includes(element.type)) {
        const matchingCells = getCellsWithFont(element, fontFamily);
        matchingCells.forEach(() => {
          matchingElements.push(element);
        });
      }
      if ([EDITOR_ELEMENTS_MAP.TABLE2].includes(element.type)) {
        const tableCells = Object.values(element.cells);
        const hasMatchingFontFamily = tableCells.some(
          cell => cell.fontFamily === fontFamily
        );
        if (hasMatchingFontFamily) matchingElements.push(element);
        tableCells.forEach(cell => {
          const richTextMatches = getFontFamilyMatchesInRichText(
            cell.value,
            fontFamily
          );
          richTextMatches.forEach(() => {
            matchingElements.push(element);
          });
        });
      }
    });

    return matchingElements;
  }
}

export default Design;
