import React, { Component } from "react";
import { connect } from "react-redux";
import SmartImageTransferModal from "views/components/SmartImageTransferModal/SmartImageTransferModal";
import { userTeamSmartImagesEntitiesSelector } from "state/entities/userTeamSmartImages/userTeamSmartImagesSelectors";
import { teamSmartImagesEntitiesSelector } from "state/entities/teamSmartImages/teamSmartImagesSelectors";
import { teamSmartImagesApiSavingSelector } from "state/api/teamSmartImages/teamSmartImagesApiSelectors";
import { getAllTeamAssetFolders } from "state/entities/folders/foldersSelectors";
import { transferTeamSmartImage } from "state/entities/teamSmartImages/teamSmartImagesActions";
import {
  fetchBrandKitImages,
  fetchBrandKitLogos,
  searchBrandKitImages,
  searchBrandKitLogos
} from "state/ui/brandKit/BrandKitActions";

export class TransferBrandSmartImageModalContainer extends Component {
  constructor(props) {
    super(props);
    this.handleClearInput = this.handleClearInput.bind(this);
    this.setSearchTerm = this.setSearchTerm.bind(this);

    this.state = {
      term: ""
    };
  }

  handleClearInput() {
    this.setState({ term: "" });
  }

  setSearchTerm(term) {
    this.setState({ term });
  }

  render() {
    return (
      <SmartImageTransferModal
        {...this.props}
        transferRootLocation={`Brand ${this.props.isLogo ? "Logos" : "Images"}`}
        setSearchTerm={this.setSearchTerm}
        handleClearInput={this.handleClearInput}
        term={this.state.term}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const asset = ownProps.isLogo ? "logos" : "images";
  const userSmartImages = userTeamSmartImagesEntitiesSelector(state);
  const teamSmartImages = teamSmartImagesEntitiesSelector(state);
  const smartImages = {
    ...userSmartImages,
    ...teamSmartImages
  };
  const isSaving = teamSmartImagesApiSavingSelector(state);
  const brandKitState = state.ui.brandKit[asset];
  const isFetchingFirstPage =
    brandKitState.page === 1 && brandKitState?.isProcessing;

  return {
    folders: getAllTeamAssetFolders(state, asset),
    isSaving,
    smartAssets: smartImages,
    state,
    isFetchingFirstPage,
    assetEntities: [],
    rootAssets: state.ui.brandKit[asset]?.entries,
    asset
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const fetchAction = ownProps.isLogo
    ? fetchBrandKitLogos
    : fetchBrandKitImages;
  const fetchSearchAction = ownProps.isLogo
    ? searchBrandKitLogos
    : searchBrandKitImages;
  return {
    onFetchAssets: args => dispatch(fetchAction(args)),
    onFetchAssetsInFolder: args => dispatch(fetchSearchAction(args)),
    updateSmartImage: args => dispatch(transferTeamSmartImage(args)),
    onSearchAssets: args => dispatch(fetchSearchAction(args)),
    refetchAssets: args => dispatch(searchBrandKitImages(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferBrandSmartImageModalContainer);
