import FontToolBox from "lib/FontToolBox";
import { cloneDeep } from "lodash";
import Table2EditorOps from "au.com.easil.table2editorops";
import {
  getNodesInRange,
  getAllFontSizeContent,
  removeFontSizeFromSelection,
  getAllFontFamilyContent,
  removeFontFamilyFromSelection,
  removeColorFromSelection,
  getAllColoredContent
} from "lib/DOMNodeUtils";
import { createDOMElementFromHTMLString } from "lib/tests/DOMNodeUtils/testSetupFunctions";

export const getTable2Colors = table2Element => {
  if (!table2Element.type === "table2") return [];
  const colors = [];
  // get all the textFields from the cell meta data
  const textFields = Object.values(table2Element.cells);
  // get the colors from text fields
  textFields.forEach(textField => {
    if (textField.color) {
      // add colors from the text field text
      colors.push(textField.color);
    }
    if (
      textField.backgroundColor &&
      textField.backgroundColor !== "transparent"
    ) {
      // add background colour from the cell metadata
      colors.push(textField.backgroundColor);
    }
    const borders = Object.values(textField.borders);
    borders.forEach(border => {
      if (border.color) {
        // add colors from the textField borders
        colors.push(border.color);
      }
    });
  });
  return colors;
};

export const getTable2CellsWithFont = (table2, font) => {
  const cells = Object.values(table2.cells);

  const fontFamily = FontToolBox.getFontFamilyFor({
    fontName: font,
    isItalicVersion: font.includes("Italic"),
    isBoldVersion: font.includes("Bold")
  });
  const fontData = FontToolBox.getFontData(fontFamily);
  const fontsToCheck = [
    fontData.fontFamilyName,
    fontData.boldFontFamilyName,
    fontData.italicFontFamilyName,
    fontData.boldItalicFontFamilyName
  ].filter(x => x);

  const matchingCells = cells.filter(cell =>
    fontsToCheck.includes(cell.fontFamily)
  );

  return matchingCells;
};

export const updateAllTable2CellHeights = (
  elementData,
  table2DomElement,
  scale
) => {
  let updatedCells = cloneDeep(elementData.cells);

  if (table2DomElement) {
    // Get all td elements in the table
    const tdElements = table2DomElement.getElementsByTagName("td");

    // Convert the HTMLCollection to an array and map to get the heights
    const cellHeights = Array.from(tdElements).map(td => td.clientHeight);

    elementData.layout.forEach((row, rowIndex) =>
      row.forEach(({ id }, columnIndex) => {
        updatedCells[id] = {
          ...updatedCells[id],
          height: cellHeights[rowIndex * row.length + columnIndex] * scale
        };
      })
    );
  }

  return updatedCells;
};

export const getPreviousAdjacentCellDistance = (
  targetCell,
  isColumn,
  allCellCoordinates,
  coordinateIndex
) => {
  let previousCellDistance;

  if (isColumn) {
    // Mapping through this section to get previous cell for rows based off of the section
    const targetCellIndex = allCellCoordinates.findIndex(
      cell => cell.id === targetCell.id
    );

    const previousCell = allCellCoordinates[targetCellIndex - 1];
    previousCellDistance =
      targetCell.coordinates[coordinateIndex] -
      previousCell.coordinates[coordinateIndex];
  } else {
    const cellCoordinatesForRows = allCellCoordinates.filter(cell => {
      return cell.coordinates[1] === targetCell.coordinates[1];
    });

    const targetCellIndex = cellCoordinatesForRows.findIndex(
      cell => cell.id === targetCell.id
    );

    const previousCell =
      cellCoordinatesForRows[
        targetCellIndex === 0 ? targetCellIndex : targetCellIndex - 1
      ];
    previousCellDistance =
      targetCell.coordinates[coordinateIndex] -
      previousCell.coordinates[coordinateIndex];
  }

  return previousCellDistance;
};

export const getCellPositionsForCell = (elementData, cellId) => {
  const table2EditorOps = new Table2EditorOps(elementData.layout);

  const cells = table2EditorOps
    .selectCells([cellId])
    .map(cell => table2EditorOps._getCellById(cell.id));
  return table2EditorOps
    .getLayout()
    .flatMap((row, rowIndex) =>
      row.flatMap((cell, columnIndex) =>
        cells.flatMap(selectedCell =>
          cell.id === selectedCell.id ? [[rowIndex, columnIndex]] : []
        )
      )
    );
};

export const getExtremeCellIndexes = ({ elementData, cellId }) => {
  // define all coordinates for cell in case it is merged
  const allIndexesForCell = getCellPositionsForCell(elementData, cellId);
  // determine highest and lowest column indexes of cell
  const highestColumnIndex = Math.max(
    ...allIndexesForCell.map(positions => positions[1])
  );
  const lowestColumnIndex = Math.min(
    ...allIndexesForCell.map(positions => positions[1])
  );
  // determine highest and lowest row indexes of cell
  const highestRowIndex = Math.max(
    ...allIndexesForCell.map(positions => positions[0])
  );
  const lowestRowIndex = Math.min(
    ...allIndexesForCell.map(positions => positions[0])
  );

  return {
    highestColumnIndex,
    lowestColumnIndex,
    highestRowIndex,
    lowestRowIndex
  };
};

export const getTargetCellId = (layout, rowIndex, columnIndex) => {
  const cell = layout[rowIndex][columnIndex];
  const [row, column] = cell.coordinates;
  return layout[row][column].id;
};

export const getTargetCellIdForTopRow = (elementData, cellId) => {
  const { layout } = elementData;
  const targetRow = layout[0];
  if (targetRow.length === 1) {
    return cellId;
  }
  return getTargetCellsInRow(layout, 0)
    .map(cell => {
      const { highestRowIndex } = getExtremeCellIndexes({
        elementData,
        cellId: cell.id
      });
      return {
        ...cell,
        highestRowIndex
      };
    })
    .sort((a, b) => (a.highestRowIndex > b.highestRowIndex ? 1 : -1))[0].id;
};

export const getTargetCellIdForLeftColumn = (elementData, cellId) => {
  const { layout } = elementData;
  const targetColumn = layout.flatMap(row => row[0]);
  if (targetColumn.length === 1) {
    return cellId;
  }
  return getTargetCellsInColumn(layout, 0)
    .map(cell => {
      const { highestColumnIndex } = getExtremeCellIndexes({
        elementData,
        cellId: cell.id
      });
      return {
        ...cell,
        highestColumnIndex
      };
    })
    .sort((a, b) => (a.highestColumnIndex > b.highestColumnIndex ? 1 : -1))[0]
    .id;
};

export const getTable2CellCoordinatesById = (cellId, elementData) => {
  if (!cellId || elementData) return;

  return elementData.layout
    .flat()
    .find(cell => cell.id === cellId)
    .map(cell => cell.coordinates);
};

export const getContentCellDom = cellId => {
  return (
    document.getElementById(`${cellId}`) ||
    document.getElementById(`UCE-${cellId}`)
  );
};

export const getTargetCellsInRow = (layout, rowIndex) => {
  const targetCellsInRow = layout[rowIndex].map(
    cell => layout[cell.coordinates[0]][cell.coordinates[1]]
  );
  const map = new Map(targetCellsInRow.map(cell => [cell.id, cell]));
  return [...map.values()];
};

export const getTargetCellsInColumn = (layout, columnIndex) => {
  const targetColumn = layout.flatMap(row => row[columnIndex]);
  const targetCellsInColumn = targetColumn.map(
    cell => layout[cell.coordinates[0]][cell.coordinates[1]]
  );
  const map = new Map(targetCellsInColumn.map(cell => [cell.id, cell]));
  return [...map.values()];
};

/**
 * @desc takes a cells data and removes the styling attribute from the inline rich text value
 * @param {object} cellData - the target cell data
 * @param {string} attribute - the styling attribute to be removed
 * @returns {string} the updated cell innerHTML with the styling removed
 */
export const stripAttributeFromCellValue = (value, attribute) => {
  const attributeActions = {
    fontSize: {
      getAction: getAllFontSizeContent,
      removeAction: removeFontSizeFromSelection
    },
    fontFamily: {
      getAction: getAllFontFamilyContent,
      removeAction: removeFontFamilyFromSelection
    },
    color: {
      getAction: getAllColoredContent,
      removeAction: removeColorFromSelection
    }
    // can add other rich text inline styles here
  };

  // return original value when no attribute is passed in
  // or cell is empty
  if (!(attribute && value)) return value;

  let updatedValue = value;
  const table2Cell = createDOMElementFromHTMLString(updatedValue);

  table2Cell.style["position"] = "absolute";
  table2Cell.contentEditable = "true";

  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(table2Cell);

  selection.removeAllRanges();
  selection.addRange(range);

  const nodesInRange = getNodesInRange(table2Cell);

  const { getAction, removeAction } = attributeActions[attribute];
  const styledNodes = getAction(nodesInRange.allNodes);
  // only strip styling if attribute styling is present
  if (styledNodes.length) {
    removeAction({
      table2Cell,
      styledNodes: styledNodes,
      nodesInRange
    });
    // reassign the new stripped innerHTML value to the element preview
    updatedValue = table2Cell.innerHTML;
  }

  table2Cell.remove();

  return updatedValue;
};

export const getLockedCellsLayoutRestrictions = ({
  selectedTable2CellIds,
  table2Element
}) => {
  let areRowActionsRestricted = false;
  let areColumnActionsRestricted = false;

  const table2EditorOps = new Table2EditorOps(table2Element.layout);
  const cells = selectedTable2CellIds.map(cellId =>
    table2Element.layout.flat().find(cell => cell.id === cellId)
  );

  for (let cell of cells) {
    const [rowIndex, columnIndex] = cell.coordinates;
    const selectedColumnIndexes = table2EditorOps.selectColumns([columnIndex]);
    const selectedRowIndexes = table2EditorOps.selectRows([rowIndex]);

    // Check column actions restriction
    for (let index of selectedColumnIndexes) {
      const columnCellIds = table2Element.layout
        .flat()
        .filter(cell => cell.coordinates[1] === index)
        .map(cell => cell.id);
      if (columnCellIds.some(id => table2Element.lockedCellIds.includes(id))) {
        areColumnActionsRestricted = true;
        break;
      }
    }

    // Check row actions restriction
    for (let index of selectedRowIndexes) {
      const rowCellIds = table2Element.layout
        .flat()
        .filter(cell => cell.coordinates[0] === index)
        .map(cell => cell.id);
      if (rowCellIds.some(id => table2Element.lockedCellIds.includes(id))) {
        areRowActionsRestricted = true;
        break;
      }
    }
  }

  return {
    areRowActionsRestricted,
    areColumnActionsRestricted
  };
};

export const findCell = (layout, cellId) => {
  for (const row of layout) {
    for (const cell of row) {
      if (cell.id === cellId) {
        return cell;
      }
    }
  }
  return null;
};

export const isMergedCell = (layout, cellId) => {
  const cell = findCell(layout, cellId);
  if (!cell) return false;

  for (const row of layout) {
    for (const otherCell of row) {
      if (
        otherCell.id !== cellId &&
        otherCell.coordinates[0] === cell.coordinates[0]
      ) {
        return true; // Same row indicates potential adjacency
      }
    }
  }
  return false;
};

export const findLowestRowIndexCells = (cells, layout) => {
  // Group cells by columnIndex first
  const cellsByColumn = {};

  cells.forEach(cell => {
    const {
      coordinates: [rowIndex, columnIndex]
    } = cell;

    if (!cellsByColumn[columnIndex]) {
      cellsByColumn[columnIndex] = [];
    }

    cellsByColumn[columnIndex].push({ rowIndex, cell });
  });

  // Sort the cells in each column by rowIndex
  Object.values(cellsByColumn).forEach(columnCells => {
    columnCells.sort((a, b) => a.rowIndex - b.rowIndex);
  });

  // Function to find clusters (groups of adjacent cells)
  const findClusters = columnCells => {
    const clusters = [];
    let currentCluster = [];

    columnCells.forEach(({ rowIndex, cell }, index) => {
      // If the cluster is empty or the current cell is adjacent to the previous one, add it to the current cluster
      if (
        currentCluster.length === 0 ||
        rowIndex === currentCluster[currentCluster.length - 1].rowIndex + 1
      ) {
        currentCluster.push({ rowIndex, cell });
      } else {
        // If not adjacent, finish the current cluster and start a new one
        clusters.push(currentCluster);
        currentCluster = [{ rowIndex, cell }];
      }

      // Add the last cluster if we're at the end
      if (index === columnCells.length - 1) {
        clusters.push(currentCluster);
      }
    });

    return clusters;
  };

  // Find the lowest row index cell in each cluster
  const lowestRowIndexCells = [];

  Object.values(cellsByColumn).forEach(columnCells => {
    const clusters = findClusters(columnCells);

    clusters.forEach(cluster => {
      const lowestCell = cluster[0]; // The first cell in the cluster (smallest rowIndex)
      lowestRowIndexCells.push(lowestCell.cell); // Add the cell to the results
    });
  });

  const updatedLowestRowIndexCells = findTargetCellsInSelection(
    lowestRowIndexCells,
    layout
  );

  return updatedLowestRowIndexCells;
};

export const findHighestRowIndexCells = (cells, layout) => {
  // Group cells by columnIndex first
  const cellsByColumn = {};

  cells.forEach(cell => {
    const {
      coordinates: [rowIndex, columnIndex]
    } = cell;

    if (!cellsByColumn[columnIndex]) {
      cellsByColumn[columnIndex] = [];
    }

    cellsByColumn[columnIndex].push({ rowIndex, cell });
  });

  // Sort the cells in each column by rowIndex
  Object.values(cellsByColumn).forEach(columnCells => {
    columnCells.sort((a, b) => a.rowIndex - b.rowIndex);
  });

  // Function to find clusters (groups of adjacent cells)
  const findClusters = columnCells => {
    const clusters = [];
    let currentCluster = [];

    columnCells.forEach(({ rowIndex, cell }, index) => {
      // If the cluster is empty or the current cell is adjacent to the previous one, add it to the current cluster
      if (
        currentCluster.length === 0 ||
        rowIndex === currentCluster[currentCluster.length - 1].rowIndex + 1
      ) {
        currentCluster.push({ rowIndex, cell });
      } else {
        // If not adjacent, finish the current cluster and start a new one
        clusters.push(currentCluster);
        currentCluster = [{ rowIndex, cell }];
      }

      // Add the last cluster if we're at the end
      if (index === columnCells.length - 1) {
        clusters.push(currentCluster);
      }
    });

    return clusters;
  };

  // Find the highest row index cell in each cluster
  const highestRowIndexCells = [];

  Object.values(cellsByColumn).forEach(columnCells => {
    const clusters = findClusters(columnCells);

    clusters.forEach(cluster => {
      const highestCell = cluster[cluster.length - 1]; // The last cell in the cluster (highest rowIndex)
      highestRowIndexCells.push(highestCell.cell); // Add the cell to the results
    });
  });

  const updatedHighestRowIndexCells = findTargetCellsInSelection(
    highestRowIndexCells,
    layout
  );

  return updatedHighestRowIndexCells;
};

export const findLowestColumnIndexCells = (cells, layout) => {
  // Group cells by rowIndex
  const cellsByRow = {};

  cells.forEach(cell => {
    const {
      coordinates: [rowIndex, columnIndex]
    } = cell;

    if (!cellsByRow[rowIndex]) {
      cellsByRow[rowIndex] = [];
    }

    cellsByRow[rowIndex].push({ columnIndex, cell });
  });

  // Sort the cells in each row by columnIndex
  Object.values(cellsByRow).forEach(rowCells => {
    rowCells.sort((a, b) => a.columnIndex - b.columnIndex);
  });

  // Function to find clusters (groups of adjacent cells)
  const findClusters = rowCells => {
    const clusters = [];
    let currentCluster = [];

    rowCells.forEach(({ columnIndex, cell }, index) => {
      // If the cluster is empty or the current cell is adjacent to the previous one, add it to the current cluster
      if (
        currentCluster.length === 0 ||
        columnIndex ===
          currentCluster[currentCluster.length - 1].columnIndex + 1
      ) {
        currentCluster.push({ columnIndex, cell });
      } else {
        // If not adjacent, finish the current cluster and start a new one
        clusters.push(currentCluster);
        currentCluster = [{ columnIndex, cell }];
      }

      // Add the last cluster if we're at the end
      if (index === rowCells.length - 1) {
        clusters.push(currentCluster);
      }
    });

    return clusters;
  };

  // Find the lowest column index cell in each cluster
  const lowestColumnIndexCells = [];

  Object.values(cellsByRow).forEach(rowCells => {
    const clusters = findClusters(rowCells);

    clusters.forEach(cluster => {
      const lowestCell = cluster[0]; // The first cell in the cluster (lowest columnIndex)
      lowestColumnIndexCells.push(lowestCell.cell); // Add the cell to the results
    });
  });

  const updatedLowestColumnIndexCells = findTargetCellsInSelection(
    lowestColumnIndexCells,
    layout
  );

  return updatedLowestColumnIndexCells;
};

export const findHighestColumnIndexCells = (cells, layout) => {
  // Group cells by rowIndex first
  const cellsByRow = {};

  cells.forEach(cell => {
    const {
      coordinates: [rowIndex, columnIndex]
    } = cell;

    if (!cellsByRow[rowIndex]) {
      cellsByRow[rowIndex] = [];
    }

    cellsByRow[rowIndex].push({ columnIndex, cell });
  });

  // Sort the cells in each row by columnIndex
  Object.values(cellsByRow).forEach(rowCells => {
    rowCells.sort((a, b) => a.columnIndex - b.columnIndex);
  });

  // Function to find clusters (groups of adjacent cells)
  const findClusters = rowCells => {
    const clusters = [];
    let currentCluster = [];

    rowCells.forEach(({ columnIndex, cell }, index) => {
      // If the cluster is empty or the current cell is adjacent to the previous one, add it to the current cluster
      if (
        currentCluster.length === 0 ||
        columnIndex ===
          currentCluster[currentCluster.length - 1].columnIndex + 1
      ) {
        currentCluster.push({ columnIndex, cell });
      } else {
        // If not adjacent, finish the current cluster and start a new one
        clusters.push(currentCluster);
        currentCluster = [{ columnIndex, cell }];
      }

      // Add the last cluster if we're at the end
      if (index === rowCells.length - 1) {
        clusters.push(currentCluster);
      }
    });

    return clusters;
  };

  // Find the highest column index cell in each cluster
  const highestColumnIndexCells = [];

  Object.values(cellsByRow).forEach(rowCells => {
    const clusters = findClusters(rowCells);

    clusters.forEach(cluster => {
      const highestCell = cluster[cluster.length - 1]; // The last cell in the cluster (highest columnIndex)
      highestColumnIndexCells.push(highestCell.cell); // Add the cell to the results
    });
  });

  const updatedHighestColumnIndexCells = findTargetCellsInSelection(
    highestColumnIndexCells,
    layout
  );

  return updatedHighestColumnIndexCells;
};

export const findOutsideCellBorders = (cells, selectedBorder, layout) => {
  // define cells that match each borders exterior conditional
  const highestRowIndexCells = findHighestRowIndexCells(cells, layout);
  const lowestRowIndexCells = findLowestRowIndexCells(cells, layout);
  const lowestColumnIndexCells = findLowestColumnIndexCells(cells, layout);
  const highestColumnIndexCells = findHighestColumnIndexCells(cells, layout);

  // unqiue set to prevent duplicates
  const allStyledCells = new Set([
    ...highestRowIndexCells,
    ...lowestRowIndexCells,
    ...lowestColumnIndexCells,
    ...highestColumnIndexCells
  ]);

  // determine whether the selected cell is contained in the highest or lowest index cells for the selection
  // return is based on the selectedBorder. If outside we can return true, if inside we can return false
  const hasStyledBorder = (cellId, cells) => {
    const hasSelectedBorderStyle = cells.some(cell => cell.id === cellId);
    return selectedBorder === "outside"
      ? hasSelectedBorderStyle
      : !hasSelectedBorderStyle;
  };

  // iterate through cells and assign boolean for which borders are to be styled
  return Array.from(allStyledCells).map(cell => ({
    ...cell,
    borders: {
      borderTop: hasStyledBorder(cell.id, lowestRowIndexCells),
      borderRight: hasStyledBorder(cell.id, highestColumnIndexCells),
      borderBottom: hasStyledBorder(cell.id, highestRowIndexCells),
      borderLeft: hasStyledBorder(cell.id, lowestColumnIndexCells)
    }
  }));
};

const findTargetCellsInSelection = (cells, layout) => {
  return cells.map(cell => {
    const { coordinates } = layout
      .flat()
      .find(layoutCell => layoutCell.id === cell.id);

    // ensure we have the target cell in the case that the cell is merged
    const targetCell = layout[coordinates[0]][coordinates[1]];
    return targetCell.id !== cell.id ? targetCell : cell;
  });
};

export const generateBorderActiveState = (layoutCells, cellData, layout) => {
  const cellDataMap = cellData.reduce((map, cell) => {
    map[cell.uniqueId] = cell;
    return map;
  }, {});

  const isAssignedBorderActive = (cells, border) => {
    if (border === "all" && cells.length < 2) return false;
    if (border === "inside" && cells.length < 3) return false;

    return cells.every(cell => {
      const { id, borders } = cell;
      const correspondingCell = cellDataMap[id];

      const assignedBorders = Object.keys(borders).filter(
        borderKey => !!borders[borderKey]
      );
      if (!correspondingCell || !assignedBorders.length) return false;
      // Check each truthy border in the current cell from cells
      return assignedBorders.every(borderKey => {
        return (
          correspondingCell.borders[borderKey] &&
          correspondingCell.borders[borderKey].color
        );
      });
    });
  };

  const isAssignedInteriorBorderActive = (cells, axis) => {
    return cells.every(cell => {
      const { id, borders } = cell;
      const correspondingCell = cellDataMap[id];

      const verticalBorders = ["borderLeft", "borderRight"];
      const horizontalBorders = ["borderTop", "borderBottom"];
      const selectedAxisBorder =
        axis === "vertical" ? verticalBorders : horizontalBorders;
      const assignedBorders = Object.keys(borders).filter(
        borderKey =>
          !!borders[borderKey] && selectedAxisBorder.includes(borderKey)
      );

      if (!correspondingCell || !assignedBorders.length) return false;

      // Check each truthy border in the current cell from cells
      return assignedBorders.every(borderKey => {
        // Check if the border in correspondingCell has a color defined
        return (
          correspondingCell.borders[borderKey] &&
          correspondingCell.borders[borderKey].color
        );
      });
    });
  };

  const isSelectedBorderActive = (cells, borderKey) => {
    return cells.some(cell => {
      const { id } = cell;
      const correspondingCell = cellDataMap[id];

      if (!correspondingCell) return false;

      return (
        correspondingCell.borders[borderKey] &&
        correspondingCell.borders[borderKey].color
      );
    });
  };

  const borderTopCells = findLowestRowIndexCells(layoutCells, layout);
  const borderRightCells = findHighestColumnIndexCells(layoutCells, layout);
  const borderBottomCells = findHighestRowIndexCells(layoutCells, layout);
  const borderLeftCells = findLowestColumnIndexCells(layoutCells, layout);

  return {
    borderTop: isSelectedBorderActive(borderTopCells, "borderTop"),
    borderRight: isSelectedBorderActive(borderRightCells, "borderRight"),
    borderBottom: isSelectedBorderActive(borderBottomCells, "borderBottom"),
    borderLeft: isSelectedBorderActive(borderLeftCells, "borderLeft"),
    inside: isAssignedBorderActive(
      findOutsideCellBorders(layoutCells, "inside", layout),
      "inside"
    ),
    verticalInside: isAssignedInteriorBorderActive(
      findOutsideCellBorders(layoutCells, "inside", layout),
      "vertical"
    ),
    horizontalInside: isAssignedInteriorBorderActive(
      findOutsideCellBorders(layoutCells, "inside", layout),
      "horizontal"
    ),
    outside: isAssignedBorderActive(
      findOutsideCellBorders(layoutCells, "outside", layout)
    ),
    all: isAssignedBorderActive(
      layoutCells.map(cell => ({
        ...cell,
        borders: {
          borderTop: true,
          borderRight: true,
          borderBottom: true,
          borderLeft: true
        }
      })),
      "all"
    )
  };
};

export const canStyleInsideBorders = (layoutCells, layout) => {
  const insideCellBorders = findOutsideCellBorders(
    layoutCells,
    "inside",
    layout
  ).map(cell => cell.borders);

  return {
    verticalInside: insideCellBorders.some(
      border => border.borderLeft || border.borderRight
    ),
    horizontalInside: insideCellBorders.some(
      border => border.borderTop || border.borderBottom
    ),
    inside:
      layoutCells.length >= 3 &&
      insideCellBorders.some(
        border =>
          border.borderTop ||
          border.borderBottom ||
          border.borderLeft ||
          border.borderRight
      )
  };
};
