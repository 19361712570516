import { uuid } from "lib/uuid";
import Table2Borders from "state/ui/editor/designAdapter/Table2Borders";
import Table2 from "views/components/Editor/elements/Table2/Table2";
import ReactDOMServer from "react-dom/server";
import { createDOMElementFromHTMLString } from "lib/tests/DOMNodeUtils/testSetupFunctions";
import { cloneDeep, pick } from "lib";
import { Provider } from "react-redux";
import store from "state/store";

const DEFAULT_LINE_HEIGHT = 1.4;

/**
 * @desc Takes the preset table data and generates element data for the design
 * @param {object} options - the preset table data generated from the sidebar
 * @param {number} pageWidth - the width of the current design
 * @param {number} pageHeight - the height of the current design
 * @returns an object containing all properties necessary for the new table2 element
 */
export const table2Defaults = (options, pageWidth = 800, pageHeight = 600) => {
  const top = pageHeight * 0.5;
  const left = pageWidth * 0.25;
  const width = pageWidth * 0.5;
  const height = 0;
  const {
    thumbnailData: { colWidth }
  } = options;

  return {
    angle: 0,
    uniqueId: uuid(),
    opacity: 1,
    scale: 1,
    scaleX: 1,
    scaleY: 1,
    isHidden: false,
    locked: false,
    type: "table2",
    visible: true,
    resizableX: true,
    resizableY: true,
    name: options.name || "Table",
    layout: options.layout,
    cells: options.layout.reduce((obj, row) => {
      row.forEach(({ id }, columnIndex) => {
        obj[id] = buildCell({
          id,
          columnIndex,
          pageWidth,
          colWidth,
          tableWidth: width
        });
      });
      return obj;
    }, {}),
    restrictions: [],
    left,
    top,
    width,
    height,
    lockedCellIds: []
  };
};

const buildCell = ({
  id,
  columnIndex,
  pageWidth = 1,
  colWidth,
  tableWidth
}) => {
  const fontSize = (14 * pageWidth) / 800;
  const padding = (0.33 * pageWidth) / 100;
  return {
    uniqueId: id,
    color: "#000",
    backgroundColor: "transparent",
    fontFamily: "Roboto-Regular",
    borders: new Table2Borders(),
    bold: false,
    boldItalic: false,
    italic: false,
    regular: true,
    fontSize,
    letterSpacing: 0,
    lineHeight: DEFAULT_LINE_HEIGHT,
    textAlign: "left",
    textDecoration: "none",
    value: "",
    displayValue: "",
    width: ((colWidth[columnIndex] || 100) / 100) * tableWidth,
    height: fontSize * DEFAULT_LINE_HEIGHT + padding * 2,
    textboxAnchor: "top",
    paragraphSpacing: 1.4,
    type: "table2Cell",
    padding
  };
};

export const createAddCellData = ({
  id,
  height = "100%",
  width = "100%",
  pageWidth = 800,
  borders = new Table2Borders(),
  padding = (0.67 * pageWidth) / 100
}) => {
  const fontSize = (14 * pageWidth) / 800;
  return {
    uniqueId: id,
    color: "#000",
    backgroundColor: "transparent",
    fontFamily: "Roboto-Regular",
    borders,
    bold: false,
    boldItalic: false,
    italic: false,
    regular: true,
    fontSize,
    letterSpacing: 0,
    lineHeight: DEFAULT_LINE_HEIGHT,
    textAlign: "left",
    textDecoration: "none",
    value: "",
    displayValue: "",
    width: fontSize * DEFAULT_LINE_HEIGHT + padding * 2,
    height: fontSize * DEFAULT_LINE_HEIGHT + padding * 2,
    textboxAnchor: "top",
    paragraphSpacing: 1.4,
    type: "table2Cell",
    padding
  };
};

export const table2HeightUpdater = tableData => {
  // render table element to define height and assign it to new elementData
  const tableDiv = renderTable2Div(tableData);
  const tableHtml = tableDiv.querySelector("table");
  const newTableHeight = tableHtml.offsetHeight;

  tableDiv.remove();

  return {
    ...tableData,
    height: newTableHeight
  };
};

export const table2CellHeightUpdater = tableData => {
  // render table element to define height and assign it to new elementData
  const tableDiv = renderTable2Div(tableData);
  const tableHtml = tableDiv.querySelector("table");
  const rowDom = tableHtml.querySelector(`tr:nth-child(1)`);
  const rowHeight = rowDom.getBoundingClientRect().height;

  const resizedCells = {};
  Object.values(tableData.cells).forEach(cell => {
    resizedCells[cell.uniqueId] = {
      ...cell,
      height: rowHeight
    };
  });

  tableDiv.remove();

  return {
    ...tableData,
    cells: resizedCells
  };
};

export const table2CellDimensionUpdater = tableData => {
  const tableDiv = renderTable2Div(tableData);
  const tableHtml = tableDiv.querySelector("table");

  const resizedCellData = cloneDeep(tableData.cells);
  Object.values(resizedCellData).forEach(cell => {
    const cellDom = tableHtml.querySelector(`#cell-${cell.uniqueId}`);
    if (cellDom) {
      const cellDomRect = cellDom.parentNode.getBoundingClientRect();
      resizedCellData[cell.uniqueId] = {
        ...resizedCellData[cell.uniqueId],
        width: cellDomRect.width,
        height: cellDomRect.height
      };
    }
  });

  tableDiv.remove();

  return {
    ...tableData,
    cells: resizedCellData
  };
};

export const table2MinWidthGenerator = tableData => {
  // render table element to define width and assign it to new elementData
  const updatedCells = Object.values(tableData.cells).reduce((acc, cell) => {
    const updatedCell = {
      ...cell,
      width: 0
    };
    acc[cell.uniqueId] = updatedCell;
    return acc;
  }, {});

  const tableDiv = renderTable2Div({
    ...tableData,
    cells: updatedCells,
    width: "auto"
  });
  const tableHtml = tableDiv.querySelector("table");
  const newTableWidth = tableHtml.scrollWidth;

  tableDiv.remove();

  return {
    ...tableData,
    width: newTableWidth
  };
};

export const table2WidthUpdater = tableData => {
  const tableDiv = renderTable2Div(tableData);
  const tableHtml = tableDiv.querySelector("table");
  const newTableWidth = tableHtml.scrollWidth;

  tableDiv.remove();

  return {
    ...tableData,
    width: newTableWidth
  };
};

export const renderTable2Div = tableData => {
  const tableMarkup = ReactDOMServer.renderToStaticMarkup(
    <Provider store={store}>
      <Table2 elementData={tableData} />
    </Provider>
  );

  const tableElement = createDOMElementFromHTMLString(tableMarkup);
  tableElement.style["position"] = "absolute";

  return tableElement;
};

/**
 * Checks if a newly added value of a table2 cell will exceed text boundary locking
 * @param {object} tableData table data object
 * @param {string} value new value being added to cell.
 * @param {object} cell target cell data
 * @returns {boolean} whether new value is exceeding the cell boundary
 */
export const isExceedingTable2CellBoundary = (tableData, cell, value) => {
  const tableMarkup = ReactDOMServer.renderToStaticMarkup(
    <Provider store={store}>
      <Table2 elementData={tableData} />
    </Provider>
  );
  const DOMElement = document.createElement("div");
  DOMElement.innerHTML = tableMarkup;
  DOMElement.style["position"] = "absolute";
  const tableHtml = DOMElement.firstChild;
  const tableCell = tableHtml.querySelector(`#cell-${cell.uniqueId}`);
  const tableCellContentEditable = tableCell.querySelector(
    `#UCE-${cell.uniqueId}`
  );
  tableCellContentEditable.innerHTML = value;

  document.body.appendChild(DOMElement);

  const updatedCellHeight = tableCell.parentNode.getBoundingClientRect().height;

  DOMElement.remove();

  return Math.round(updatedCellHeight) > Math.round(cell.height);
};

export const generateTable2MinHeight = tableData => {
  // strip element height and any height assigned to cells
  const minHeightCells = tableData.layout.reduce((updatedCells, row) => {
    row.forEach(({ id }) => {
      updatedCells[id] = {
        ...tableData.cells[id],
        height: "0"
      };
    });
    return updatedCells;
  }, {});
  const updatedTable = {
    ...tableData,
    height: "100%",
    cells: minHeightCells
  };
  const tableDiv = table2HeightUpdater(updatedTable);
  return tableDiv.height;
};

export const generateTable2CellMinWidth = (tableData, targetCellId) => {
  // render table element to define width and assign it to new elementData
  const updatedCells = Object.values(tableData.cells).reduce((acc, cell) => {
    const updatedCell = {
      ...cell,
      width: "auto"
    };
    acc[cell.uniqueId] = updatedCell;
    return acc;
  }, {});

  const tableDiv = renderTable2Div({
    ...tableData,
    cells: updatedCells
  });
  const tableHtml = tableDiv.firstChild;
  const targetCell = tableHtml.querySelector(`#cell-${targetCellId}`);
  const targetCellScrollWidth = targetCell.scrollWidth;

  tableDiv.remove();

  return targetCellScrollWidth;
};

export const getDynamicCellStyling = cellMetadata => {
  const styleAttributes = [
    "color",
    "fontFamily",
    "fontSize",
    "letterSpacing",
    "lineHeight",
    "textAlign",
    "textDecoration",
    "padding"
  ];

  const metadataStyle = pick(cellMetadata, styleAttributes);

  return {
    ...metadataStyle,
    padding: `${metadataStyle.padding}px`,
    lineHeight: `${metadataStyle.lineHeight}em`,
    letterSpacing: `${metadataStyle.letterSpacing / 1000}em`,
    fontSize: `${metadataStyle.fontSize}px`,
    height: "auto",
    width: "100%",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    paragraphSpacing: metadataStyle.paragraphSpacing
  };
};
