import { determineImageUrl } from "lib/Renderer/ImageUtil";

/**
 * Parse a list of elements and prepare them for rendering.
 * So far, this only includes the mutation of image URLs from preview urls to HQ image urls.
 * @param {Array} elements List of elements as returned by the API (unparsed by the UI adapter)
 * @param {boolean} isThumb Whether the page is rendered for thumbnail generation.
 * @returns {*}
 */
export const parseElementsForRendering = (elements, isThumb) => {
  return elements.map(element => {
    // Standard image asset
    const isImage =
      Boolean(element.src) && element.type.toLowerCase() !== "vector";
    if (isImage) {
      return {
        ...element,
        src: determineImageUrl(element, isThumb)
      };
    }
    // Parse each instruction looking for image elements to mutate to HQ assets
    const hasImageInstructions =
      Boolean(element.imageInstructions) &&
      Boolean(element.imageInstructions.length);
    if (hasImageInstructions) {
      const parsedImageInstructions = element.imageInstructions.map(
        imageInstruction => {
          const hasImageSrc = Boolean(imageInstruction.src);
          const imageInstructionElement = {
            ...imageInstruction,
            type: "image"
          };
          if (hasImageSrc) {
            return {
              ...imageInstruction,
              src: determineImageUrl(imageInstructionElement, isThumb) // Image instruction is an image element
            };
          }
          return imageInstruction;
        }
      );
      return {
        ...element,
        imageInstructions: parsedImageInstructions
      };
    }
    // Check for the presence of an image mask and update the nested image src.
    const isTextMask =
      element.type === "textbox" &&
      Boolean(element.maskImage) &&
      Boolean(element.maskImage.src);
    if (isTextMask) {
      return {
        ...element,
        maskImage: {
          ...element.maskImage,
          src: determineImageUrl(element.maskImage, isThumb)
        }
      };
    }
    return element;
  });
};
