export const CURRENT_USER_REQUEST = "API/CURRENT_USER/REQUEST";
export const CURRENT_USER_REQUEST_FAILURE = "API/CURRENT_USER/REQUEST_FAILURE";
export const CURRENT_USER_REQUEST_SUCCESS = "API/CURRENT_USER/REQUEST_SUCCESS";

export const PORTAL_USER_REQUEST = "API/PORTAL_USER/REQUEST";
export const PORTAL_USER_REQUEST_FAILURE = "API/PORTAL_USER/REQUEST_FAILURE";
export const PORTAL_USER_REQUEST_SUCCESS = "API/PORTAL_USER/REQUEST_SUCCESS";

export const CURRENT_USER_TOKEN_REQUEST = "API/CURRENT_USER/TOKEN_REQUEST";
export const CURRENT_USER_TOKEN_REQUEST_FAILURE =
  "API/CURRENT_USER/TOKEN_REQUEST_FAILURE";
export const CURRENT_USER_TOKEN_REQUEST_SUCCESS =
  "API/CURRENT_USER/TOKEN_REQUEST_SUCCESS";

export const CURRENT_USER_SWITCH_TOKEN_REQUEST =
  "API/CURRENT_USER/SWITCH_TOKEN_REQUEST";
export const CURRENT_USER_SWITCH_TOKEN_REQUEST_FAILURE =
  "API/CURRENT_USER/SWITCH_TOKEN_REQUEST_FAILURE";
export const CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS =
  "API/CURRENT_USER/SWITCH_TOKEN_REQUEST_SUCCESS";

export const CURRENT_USER_UPDATE_REQUEST = "API/CURRENT_USER/UPDATE_REQUEST";
export const CURRENT_USER_UPDATE_REQUEST_SUCCESS =
  "API/CURRENT_USER/UPDATE_REQUEST_SUCCESS";
export const CURRENT_USER_UPDATE_REQUEST_FAILURE =
  "API/CURRENT_USER/UPDATE_REQUEST_FAILURE";

export const CURRENT_USER_SET_TOKEN_DATA = "APP/CURRENT_USER/SET_TOKEN_DATA";
export const CURRENT_USER_LOGOUT_REQUEST = "APP/CURRENT_USER/LOGOUT_REQUEST";
export const CURRENT_USER_LOGOUT_REQUEST_SUCCESS =
  "APP/CURRENT_USER/LOGOUT_REQUEST_SUCCESS";
export const CURRENT_USER_LOGOUT_REQUEST_FAILURE =
  "APP/CURRENT_USER/LOGOUT_REQUEST_FAILURE";

export const CURRENT_USER_PREFERENCES_REQUEST =
  "API/CURRENT_USER/PREFERENCES_REQUEST";
export const CURRENT_USER_PREFERENCES_REQUEST_SUCCESS =
  "API/CURRENT_USER/PREFERENCES_REQUEST_SUCCESS";
export const CURRENT_USER_PREFERENCES_REQUEST_FAILURE =
  "API/CURRENT_USER/PREFERENCES_REQUEST_FAILURE";

export const CURRENT_USER_UPDATE_PREFERENCES_REQUEST =
  "API/CURRENT_USER/UPDATE_PREFERENCES_REQUEST";
export const CURRENT_USER_UPDATE_PREFERENCES_REQUEST_SUCCESS =
  "API/CURRENT_USER/UPDATE_PREFERENCES_REQUEST_SUCCESS";
export const CURRENT_USER_UPDATE_PREFERENCES_REQUEST_FAILURE =
  "API/CURRENT_USER/UPDATE_PREFERENCES_REQUEST_FAILURE";

export const CHANGE_CURRENT_USER_PASSWORD_REQUEST =
  "API/CURRENT_USER/CHANGE_CURRENT_USER_PASSWORD_REQUEST_REQUEST";
export const CHANGE_CURRENT_USER_PASSWORD_REQUEST_SUCCESS =
  "API/CURRENT_USER/CHANGE_CURRENT_USER_PASSWORD_REQUEST_SUCCESS";
export const CHANGE_CURRENT_USER_PASSWORD_REQUEST_FAILURE =
  "API/CURRENT_USER/CHANGE_CURRENT_USER_PASSWORD_REQUEST_FAILURE";
