import React from "react";
import PropTypes from "prop-types";

const SizeColumnsEvenlyIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M20 0v20H0V0z" />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 10v7h-2v-7zm-6 0v7H9v-7zm-6 0v7H3v-7zm12-6H4m11-2 2 2-2 2M5 2 3 4l2 2"
      />
    </g>
  </svg>
);

SizeColumnsEvenlyIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default SizeColumnsEvenlyIcon;
