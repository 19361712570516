import { sixDigitHexColor } from "lib/Colors/colorUtils";
import hasMatchingColor from "lib/hasMatchingColor";
import { cloneDeep, isEmpty } from "lib/lodash";
import { immutableUpdate } from "lib/immutableUpdate";
import { createDOMElementFromHTMLString } from "lib/tests/DOMNodeUtils/testSetupFunctions";
import { updateSmartTextPlaceholder } from "lib/textUtils";
import {
  getNodesInRange,
  getAllColoredContent,
  removeColorFromSelection
} from "lib/DOMNodeUtils";
// import TextBoxElement from "state/ui/editor/elements/TextBoxElement";
import { hexToRgb } from "node-vibrant/lib/util";
import { EDITOR_ELEMENTS_MAP } from "lib/constants";
// import GridElement from "state/ui/editor/elements/GridElement";

/**
 * @desc returns a string representing the updated textbox value. Includes any rich text updates with colour attributes removed
 * @param {object} element - textbox element which requires DOM manipulation to remove rich text color changes
 */
const removeColorFromRichText = element => {
  const textboxElement = createDOMElementFromHTMLString(element.value);

  textboxElement.style["position"] = "absolute";
  textboxElement.contentEditable = "true";

  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(textboxElement);

  selection.removeAllRanges();
  selection.addRange(range);

  const nodesInRange = getNodesInRange(textboxElement);

  if (!nodesInRange) return element.value;

  const coloredNodes = getAllColoredContent(nodesInRange.allNodes);

  let updatedInnerHTML;
  // only strip color styling if coloredNodes are present
  if (coloredNodes.length) {
    removeColorFromSelection({
      textboxElement,
      styledNodes: coloredNodes,
      nodesInRange
    });
    // reassign the new stripped innerHTML value to the element preview
    updatedInnerHTML = textboxElement.innerHTML;
  }

  textboxElement.remove();

  return updatedInnerHTML;
};

const applyColorToImageInstruction = ({
  element,
  color,
  stateElementPreviews,
  selectedGridCellId
}) => {
  const targetInstructionIndex = element.imageInstructions.findIndex(
    instruction => instruction.domId === selectedGridCellId
  );

  let updatedImageInstructions = [...element.imageInstructions];

  // check if element has any updated previews in state
  // which have yet to be saved to image instructions
  const updatedElementPreviews = cloneDeep(stateElementPreviews);
  if (updatedElementPreviews[element.uniqueId]) {
    updatedImageInstructions =
      updatedElementPreviews[element.uniqueId].imageInstructions;
  }

  updatedImageInstructions[targetInstructionIndex] = {
    ...updatedImageInstructions[targetInstructionIndex],
    color
  };

  return updatedImageInstructions;
};

/**
 * @desc returns an object containing preview updates for individual element attribute colour updates
 * @param {boolean} copyOriginal - boolean for trigger to spread element attribute preview properties into new preview
 * @param {object} el - object containing individual element attributes requiring color update
 * @param {string} originalColor - string representing the HEX value for orignal elements attribute colour
 * @param {string} color - string representing the HEX value for colour change
 */
const transformColor = ({ el, copyOriginal = false, originalColor, color }) => {
  let preview = {};

  // include keys from fillColor, shadow and outline but dump anything else
  if (copyOriginal) {
    preview = { ...el };
  }

  if (el.type === EDITOR_ELEMENTS_MAP.TEXTBOX || el.type === "table2Cell") {
    const parser = new DOMParser();
    const doc = parser.parseFromString(el.value, "text/html");
    const spans = doc.getElementsByTagName("span");

    // If el.value has span tags within then it is rich text
    if (spans.length) {
      for (let i = 0; i < spans.length; i++) {
        let span = spans[i];
        let style = span.getAttribute("style");

        let currentRgbValues = style.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
        const originalColorRgb = hexToRgb(originalColor);
        const newRgbValues = hexToRgb(color);
        if (currentRgbValues !== null) {
          // Converts currentRgbValues to array of ints
          currentRgbValues = [
            parseInt(currentRgbValues[1]),
            parseInt(currentRgbValues[2]),
            parseInt(currentRgbValues[3])
          ];

          // Only adjust styling when the orginal update all color matches the current spans color
          if (
            JSON.stringify(originalColorRgb) ===
            JSON.stringify(currentRgbValues)
          ) {
            style = style.replace(
              /rgb\(\d+,\s*\d+,\s*\d+\)/g,
              "rgb(" + newRgbValues.join(", ") + ")"
            );

            // Replace old RGB values with new values
            span.setAttribute("style", style);
          }
        }
      }
      const newHtmlCode = doc.body.innerHTML;
      preview.value = newHtmlCode;
      preview.displayValue = newHtmlCode;
    }
  }

  if (el.type === EDITOR_ELEMENTS_MAP.GRID) {
    preview.imageInstructions = el.imageInstructions.map(cell => {
      if (cell.color && sixDigitHexColor(cell.color) === originalColor) {
        return {
          ...cell,
          color
        };
      } else {
        return cell;
      }
    });
  }

  if (el.color && sixDigitHexColor(el.color) === originalColor) {
    preview.color = color;
  }

  if (
    el.backgroundColor &&
    sixDigitHexColor(el.backgroundColor) === originalColor
  ) {
    preview.backgroundColor = color;
  }

  if (el.fillColors) {
    preview.fillColors = el.fillColors.map(args =>
      transformColor({ el: args, copyOriginal: true, originalColor, color })
    );
  }

  if (el.shadow) {
    preview.shadow = transformColor({
      el: el.shadow,
      copyOriginal: true,
      originalColor,
      color
    });
  }

  if (
    el.outline &&
    el.outline.color &&
    sixDigitHexColor(el.outline.color) === originalColor
  ) {
    preview.outline = { ...el.outline, ...preview.outline, color };
  }

  return preview;
};

/**
 * @desc returns an object with element uniqueId as keys, containing attribute changes for matching colour
 * @param {object} designData - designData object
 * @param {string} originalColor - string representing the HEX value for original elements attribute colour
 * @param {string} color - string representing the HEX value for colour change
 */
const updateAllMatchingElementColors = ({
  designData,
  originalColor,
  color
}) => {
  const elementPreviews = {};

  if (!designData.restrictions.includes("backgroundColor")) {
    Object.entries(designData.pages).forEach(([pageId, page]) => {
      if (
        page.backgroundColor &&
        sixDigitHexColor(page.backgroundColor) === originalColor
      ) {
        elementPreviews[pageId] = { backgroundColor: color };
      }
    });
  }

  Object.values(designData.elements).forEach(element => {
    if ((element.restrictions || []).includes("color")) {
      return;
    }

    if (!hasMatchingColor(element, originalColor)) {
      return;
    }

    if (element.type === "table") {
      const mapCellsMeta = ({ textFields, ...other }) => ({
        ...other,
        textFields: textFields.map(args =>
          transformColor({ el: args, copyOriginal: true, originalColor, color })
        )
      });

      const mapCellsByRowType = (map, [rowTypeCode, { cellsMetadata }]) => ({
        ...map,
        [rowTypeCode]: {
          cellsMetadata: cellsMetadata.map(mapCellsMeta)
        }
      });

      elementPreviews[element.uniqueId] = {
        columnsMetadata: Object.entries(element.columnsMetadata).reduce(
          mapCellsByRowType,
          {}
        )
      };
    } else if (element.type === "table2") {
      const cellsClone = cloneDeep(element.cells);
      // iterate over table cells to update any matching colour to new colour
      // transformColor function returns object with only colour property updated
      // so we need to spread cell data with new colour update
      const updatedCells = {};
      Object.keys(cellsClone).forEach(cellId => {
        const cell = cellsClone[cellId];
        updatedCells[cellId] = {
          ...cell,
          ...transformColor({
            el: cell,
            originalColor,
            color
          })
        };
      });
      elementPreviews[element.uniqueId] = {
        cells: updatedCells
      };
    } else {
      elementPreviews[element.uniqueId] = transformColor({
        el: element,
        originalColor,
        color
      });
    }
  });

  return elementPreviews;
};

/**
 * @desc returns an object with element uniqueId as keys, containing attribute changes for matching colour
 * @param {string} attributeName - string representing the attribute update targetted for colour change
 * @param {string} color - string representing the HEX value for colour change
 * @param {boolean} isUpdateAll - boolean for trigger to check and update all elements with matching colour
 * @param {string} originalColor - string representing the HEX value for orignal elements attribute colour
 * @param {object} context - object from Editor state. Contains selected grid and table cells for targetted attribute update
 * @param {string} currentPageId - string representing current pageId in editor design
 * @param {object} designData - designData object
 * @param {Array} selectedItems - array of currently selected elements in the editor
 * @param {object} stateElementPreviews - object containing unprocessed preview updates for elements. Same structure as onColorChange
 * return object with uniqueId as key and attribute changes
 */
export const onColorChange = ({
  attributeName = "color",
  color: colorsProp,
  isUpdateAll = false,
  originalColor: originalColorProp,
  context,
  currentPageId,
  designData,
  selectedItems,
  stateElementPreviews,
  smartTextState
} = {}) => {
  const elementPreviews = {};

  if (!designData || isEmpty(designData) || !colorsProp) return elementPreviews;

  const color = sixDigitHexColor(colorsProp);
  const originalColor = originalColorProp
    ? sixDigitHexColor(originalColorProp)
    : null;

  const { cellIndex, rowIndex, textFieldIndex, selectedGridCellId } = context;

  if (isUpdateAll && !originalColor) {
    throw new Error("Invalid color change combo.");
  }

  if (isUpdateAll) {
    return updateAllMatchingElementColors({ designData, originalColor, color });
  } else {
    // function checking if the color given matches the original color, not applicable for single color objects
    const shouldChange = ({ color } = {}) =>
      !originalColor || sixDigitHexColor(color) === originalColor;

    if (attributeName === "backgroundColor") {
      elementPreviews[currentPageId] = { backgroundColor: color };
    }

    selectedItems.forEach(({ itemId }) => {
      const element = designData.getElement(itemId);

      if (!element || element.restrictions.includes("color")) {
        return;
      }

      if (
        element.type === "table" &&
        cellIndex !== undefined &&
        rowIndex !== undefined &&
        textFieldIndex !== undefined
      ) {
        const { rowTypeCode } = element.rows[rowIndex];

        elementPreviews[element.uniqueId] = {
          columnsMetadata: immutableUpdate(element.columnsMetadata, {
            [rowTypeCode]: {
              cellsMetadata: {
                [cellIndex]: {
                  textFields: {
                    [textFieldIndex]: {
                      $merge: { color }
                    }
                  }
                }
              }
            }
          })
        };
      } else if (element.type === "table2") {
        const { selectedTable2CellIds } = context;
        let updatedCells = cloneDeep(element.cells);
        // reassign color for each selected cell in table
        selectedTable2CellIds.forEach(cellId => {
          const updatedCell = updatedCells[cellId];
          const updatedCellInnerHTML = removeColorFromRichText(updatedCell);
          if (attributeName === "cellBackgroundColor") {
            updatedCells = immutableUpdate(updatedCells, {
              [cellId]: {
                $merge: { backgroundColor: color }
              }
            });
          } else {
            updatedCells = immutableUpdate(updatedCells, {
              [cellId]: {
                $merge: {
                  color,
                  value: Boolean(updatedCellInnerHTML)
                    ? updatedCellInnerHTML
                    : updatedCell.value,
                  displayValue: Boolean(updatedCellInnerHTML)
                    ? updatedCellInnerHTML
                    : updatedCell.displayValue
                }
              }
            });
          }
        });
        elementPreviews[element.uniqueId] = {
          cells: updatedCells
        };
      } else if (attributeName === "outline") {
        // shouldChange should not affect this update
        elementPreviews[element.uniqueId] = {
          outline: { ...element.outline, color }
        };
      } else if (element.fillColors && element.fillColors.some(shouldChange)) {
        // multiple colors checked so shouldChange is valid
        elementPreviews[element.uniqueId] = {
          fillColors: element.fillColors.map(fillColor =>
            shouldChange(fillColor) ? { ...fillColor, color } : { ...fillColor }
          )
        };
      } else if (attributeName === "shadow") {
        // shouldChange should not affect this update
        elementPreviews[element.uniqueId] = {
          shadow: { ...element.shadow, color }
        };
      } else if (element.color) {
        // shouldChange should not affect this update
        elementPreviews[element.uniqueId] = { color };
        // handle color change when textbox has rich text changes
        if (element.type === "textbox" && !!element.value) {
          const updatedTextboxInnerHTML = removeColorFromRichText(element);
          if (updatedTextboxInnerHTML) {
            elementPreviews[element.uniqueId].value = updatedTextboxInnerHTML;
            elementPreviews[
              element.uniqueId
            ].displayValue = updateSmartTextPlaceholder(
              updatedTextboxInnerHTML,
              smartTextState
            );
          }
        }
      } else if (element.type === "grid" && selectedGridCellId) {
        const updatedGridImageInstructions = applyColorToImageInstruction({
          element,
          color,
          stateElementPreviews,
          selectedGridCellId
        });

        elementPreviews[element.uniqueId] = {
          imageInstructions: updatedGridImageInstructions
        };
      }
    });
  }

  return elementPreviews;
};
