import { push } from "react-router-redux";
import PATHS from "routes/paths";
import * as types from "state/ui/MyImages/MyImagesTypes";
import {
  searchUserImages,
  clearSearchTerm as editorSearchClear
} from "state/ui/editorImageSearch/editorImageSearchActions";
import {
  deleteUserTeamImage,
  refetchUserTeamImagesPages,
  refetchUserTeamImagesPagesInFolder,
  updateUserTeamImage
} from "state/entities/userTeamImages/userTeamImagesActions";
import {
  addImageToFolder,
  removeImageFromFolder,
  deleteImageFolder
} from "state/entities/imageFolders/imageFoldersActions";

export const deleteFolderAndGoToMyImages = ({ folderId }) => dispatch => {
  dispatch(deleteImageFolder({ folderId }));
  dispatch(push(PATHS.workspaceUploadsImages));
};

export const moveImageToFolder = ({ folderId, contentId }) => dispatch => {
  dispatch(
    addImageToFolder({
      folderId,
      contentId,
      onSuccess: () => dispatch(refetchUserTeamImagesPagesInFolder(folderId))
    })
  );
};

export const moveImageFromFolder = ({
  folderId,
  contentId,
  term
}) => dispatch => {
  dispatch(
    removeImageFromFolder({
      folderId,
      contentId,
      term,
      onSuccess: () => dispatch(refetchUserTeamImagesPages())
    })
  );
};

export const deleteImage = ({
  mediaId,
  folderId,
  isSmartImage = false
}) => dispatch => {
  dispatch(
    deleteUserTeamImage({
      mediaId,
      folderId,
      isSmartImage
    })
  );
};

export const updateImage = ({ mediaId, folderId, image }) => dispatch => {
  dispatch(
    updateUserTeamImage({
      mediaId,
      folderId,
      image
    })
  );
};

export const searchImages = ({
  term,
  page = 1,
  folderId,
  filterSmartImages
}) => dispatch => {
  if (term) {
    dispatch({
      type: types.IMAGE_SEARCH_REQUEST,
      payload: { term, page, folderId }
    });
    // TODO: Shouldn't really have anything to do with editor image search, however, leaving
    //  until these are fully separated
    dispatch(searchUserImages({ term, page, folderId, filterSmartImages }));
  } else {
    dispatch({ type: types.CLEAR_SEARCH_TERM });
    dispatch(editorSearchClear());
  }
};
